import { URL_CATALOGO_UBICAICON } from "../../../../utils/constantes";
import { apiRequest } from "../../../handlerApiRequest"

export const findAllCatalogoUbicaciones = async () =>{
    return await apiRequest(URL_CATALOGO_UBICAICON);
}

export const findAllUbicacionesByTextSearch = async (textSearch) =>{
    return await apiRequest(URL_CATALOGO_UBICAICON+`/search?textSearch=${textSearch}`);
}

export const saveUbicacion = async (ubicacion) =>{
    return await apiRequest(URL_CATALOGO_UBICAICON, 'POST', ubicacion);
}

export const updateUbicacion = async (ubicacion)=>{
    return await apiRequest(URL_CATALOGO_UBICAICON+`/${ubicacion.id}`, "PUT", ubicacion)
}

export const deleteByIdUbicacion = async(idUbicacion)=>{
    return await apiRequest(URL_CATALOGO_UBICAICON+`/${idUbicacion}`, "DELETE")
}