import { URL_ANTICIPO_COSTO_PROVEEDOR } from "../../../utils/constantes";
import { apiRequest } from "../../handlerApiRequest";

export const saveAnticipoCostoProveedor = async (
    archivoPdfACargar,
    anticipoCostoProveedor) => {
    //Establecer el id del usuario auth
    const formData = new FormData();
    if (archivoPdfACargar != null) formData.append("archivoPdfACargar", archivoPdfACargar, archivoPdfACargar.name);
    formData.append("anticipoCostoProveedor", new Blob([JSON.stringify(anticipoCostoProveedor)], {
        type: "application/json"
    }));

    return await apiRequest(URL_ANTICIPO_COSTO_PROVEEDOR, 'POST', formData, '', true)
}

export const findAllByIdCostoProveedor = async (idCostoProveedor) =>{
    return await apiRequest(URL_ANTICIPO_COSTO_PROVEEDOR, 'GET', null, `?idCostoProveedor=${idCostoProveedor}`)
}

export const logicDeleteAnticipo = async (idAnticipo, anticipoCostoProveedor) => {
    return await apiRequest(URL_ANTICIPO_COSTO_PROVEEDOR+`/${idAnticipo}`, 'DELETE', anticipoCostoProveedor)
}
