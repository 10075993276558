import React, { useState } from 'react'
import { findAllToReport } from '../../../../controllers/admin/embarques/embarquesController';
import { generarReporteEmbarque } from '../../reportes/generators/reportesEmbarque';
import Modal from '@mui/material/Modal';
import { CATALOGO_ADVANCED_SEARCH } from '../../../../utils/downloadExcelEmbarqueCatalogue';

function ModalFilterExport({ filtros, getParams }) {
    const [open, setOpen] = useState(false);
    const [catalogoAS, setCatalogoAS] = useState(CATALOGO_ADVANCED_SEARCH);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleFieldChange = (index, state, setState) => {
        const newState = [...state];
        newState[index].avoid = !newState[index].avoid;
        setState(newState);
    };

    const handleDownloadReport = async () => {
        const params = getParams();
        const data = await findAllToReport(`?page=0&size=100000&${params.toString()}`, filtros);
        generarReporteEmbarque(data.content, catalogoAS);
        handleClose();
    };

    return (
        <>
            <button type="button" className="btn btn-dark position-relative" onClick={handleOpen}>
                <i className="bi bi-file-earmark-spreadsheet"></i> DESCARGAR REPORTE
            </button>

            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <div className="card style-modal-ssm">
                    <div className="card-header d-flex justify-content-between">
                        SELECCIONA LOS CAMPOS A DESCARGAR
                        <button className="btn btn-dark btn-sm" onClick={handleClose}>
                            <i className="bi bi-x-lg"></i>
                        </button>
                    </div>
                    <div className="card-body body-modal-scroll">
                        {CATALOGO_ADVANCED_SEARCH.map((item, index) => (
                            <>
                                {item.typeAttribute === 'LABEL' ?
                                    <>
                                        <hr />
                                        <p>{item.header}</p>
                                    </>
                                    :
                                    <div key={item.atributeValue}>
                                        <input
                                            type="checkbox"
                                            checked={!item.avoid}
                                            onChange={() => handleFieldChange(index, catalogoAS, setCatalogoAS)}
                                        />
                                        <label>{item.header}</label>
                                    </div>
                                }
                            </>

                        ))}
                    </div>
                    <div className="card-footer">
                        <div className='col-12' align="right">
                            <button className="btn btn-primary" type="button" onClick={handleDownloadReport}>
                                <i className="bi bi-file-earmark-spreadsheet"></i> DESCARGA
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default ModalFilterExport