import { TIPO_ARCHIVO_PDF } from "./constantes";

import PDFMerger from "pdf-merger-js";

// Función para combinar archivos PDF
export async function mergePDFs(pdfFiles) {
    try {
        const merger = new PDFMerger();

        for (const pdfFile of pdfFiles) {
            await merger.add(pdfFile);
        }

        // Fusionar los archivos PDF en uno solo
        const mergedPdf = await merger.saveAsBlob();
        // Guardar y descargar el PDF combinado
        const blob = new Blob([mergedPdf], { type: "application/pdf" });
        const pdfFile = new File([blob], 'pdfs-combinados.pdf', { type: 'application/pdf' });

        return pdfFile; 
    } catch (error) {
        console.error(error);
        throw new Error("Error al combinar archivos PDF: " + error.message);
    }
}

export const todosArchivosSonPDFS = (archivos) => {
    for (let archivo of archivos) {
        if (!TIPO_ARCHIVO_PDF.includes(archivo.type)) {
            return false;
        }
    }
    return true;
}