import SelectSearchDB from "../inputs/SelectSearchDB"

function FormikSelectSearchDB({ field, form, ...props }) {

    const {titulo, fetchSearch, type, isDisabled} = props;

    return (
        <SelectSearchDB
            titulo={titulo}
            value={field.value}
            name={field.name}
            isDisabled={isDisabled}
            handleChange={(event, index) => {
                const {value} = event.target;
                form.setFieldValue(field.name, value)
            }}
            fetchSearch={fetchSearch}
            type={type}></SelectSearchDB>
    )
}

export default FormikSelectSearchDB