
export function calcularTotalBultos(dimensionesCarga){
    
    const totalDeBultos = dimensionesCarga.reduce((total, dimensionCarga) => {
        return total + parseFloat(dimensionCarga.cantidadBultos);
    }, 0);

    return totalDeBultos;
    
}

export function calcularPesoTotalBruto(dimensionesCarga){
    const  pesoTotalBruto = dimensionesCarga.reduce((total, dimensionCarga) => {
        return (total + parseFloat(dimensionCarga.pesoPorBulto) * dimensionCarga.cantidadBultos);
    }, 0);

    return pesoTotalBruto;
}