import { URL_CATALOGO_CONTENEDOR_MARITIMO } from "../../../../utils/constantes";
import { apiRequest } from "../../../handlerApiRequest"

export const findAllCatalogoContenedorMaritimo = async () =>{
    return await apiRequest(URL_CATALOGO_CONTENEDOR_MARITIMO);
}

export const saveContenedorMaritimo = async (catalogo) =>{
    return await apiRequest(URL_CATALOGO_CONTENEDOR_MARITIMO, 'POST', catalogo);
}

export const updateContenedorMaritimo = async (catalogo)=>{
    return await apiRequest(URL_CATALOGO_CONTENEDOR_MARITIMO+`/${catalogo.id}`, "PUT", catalogo)
}

export const deleteByIdContenedorMaritimo = async(idCatalogo)=>{
    return await apiRequest(URL_CATALOGO_CONTENEDOR_MARITIMO+`/${idCatalogo}`, "DELETE")
}