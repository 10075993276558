import DatePicker from "react-datepicker";
import moment from 'moment-timezone';

function FechaGeneric({ fecha, handleDateTimeChange, index, nameLabel, nameInput, required, disabled, useEvent }) {
    
    const handleDateTimeChangeV2 = (date, nameInput, index) => {
        if (useEvent) {
            const formattedDateTime = moment(date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
            const event = {
                target: {
                    name: nameInput,
                    value: formattedDateTime
                }
            }
            return handleDateTimeChange(event, index);
        }
        return handleDateTimeChange(date, nameInput, index);
    };

    return (
        <>
            {nameLabel ? <label htmlFor="inputOrigen" className="form-label">{nameLabel}</label> : null}
            <div className="position-relative">
                <DatePicker
                    selected={fecha ? new Date(fecha) : null}
                    onChange={(date) => handleDateTimeChangeV2(date, nameInput, index)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={60}
                    dateFormat="dd-MM-yyyy HH:mm"
                    className="form-control"
                    required={required}
                    disabled={disabled}
                />
                <button
                    type="button"
                    className="btn btn-light"
                    style={{
                        position: 'absolute',
                        right: '1px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        padding: '0.25rem 0.5rem'
                    }}
                    disabled={disabled}
                    onClick={() => handleDateTimeChangeV2(null, nameInput, index)}
                >
                    <i className="bi bi-x-lg"></i>
                </button>
            </div>
        </>
    );
}

export default FechaGeneric;