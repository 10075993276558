import BASE_URL_BACKEND from "../../../api/baseUrl";
import { ID_ESTATUS_FP_FACTURA_RECHAZADA, URL_FACTURA_PROVEEDOR } from "../../../utils/constantes";
import { errInt } from "../../../utils/resCatch";
import { authToken } from "../../../utils/authUtils";
import { apiRequest } from "../../handlerApiRequest";

export const buscarPorIdCostoProveedor = async (idCosto) => {
    let res = await apiRequest(URL_FACTURA_PROVEEDOR + `/costo-proveedor/${idCosto}`);
    // Filtrar las facturas eliminadas con el estatus deseado o cualquier otro estatus
    res.data = res.data.filter(item => (item.estaEliminado && item.estatus.id === ID_ESTATUS_FP_FACTURA_RECHAZADA) || !item.estaEliminado);
    return res;
}

export const facturasProveedorWithFilters = async (urlParams) => {
    return await apiRequest(URL_FACTURA_PROVEEDOR + "/filters","GET", null, urlParams)
}

export const facturasProveedorPPDToReporte = async (urlParams) => {
    return await apiRequest(URL_FACTURA_PROVEEDOR + "/reporte-complementos", "GET", null, urlParams)
}

export const guardarFacturaProveedorPorIdCosto = async (
    idCostoProveedor, 
    facturaProveedor, 
    archivoFactura,
    archivoFacturaXML) => {
    try {

        //Establecer el id del usuario auth
        const formData = new FormData();
        if(archivoFactura != null) formData.append("archivoFactura", archivoFactura, archivoFactura.name);
        if(archivoFacturaXML != null) formData.append("archivoFacturaXML", archivoFacturaXML, archivoFacturaXML.name);
        formData.append("facturaProveedor", new Blob([JSON.stringify(facturaProveedor)], {
            type: "application/json"
        }));

        return await apiRequest(URL_FACTURA_PROVEEDOR + "/costo-proveedor/" + idCostoProveedor, "POST", formData, "", true);
    } catch (err) {
        errInt.message += err
        return errInt
    }
}

export const putFacturaProveedor = async (
    facturaProveedor, 
    archivoFactura,
    archivoFacturaXML) => {
    try {
        //Establecer el id del usuario auth
        const formData = new FormData();
        if(archivoFactura != null) 
            formData.append("archivoFactura", archivoFactura, archivoFactura.name);
        if(archivoFacturaXML != null) 
            formData.append("archivoFacturaXML", archivoFacturaXML, archivoFacturaXML.name);
        formData.append("facturaProveedor", new Blob([JSON.stringify(facturaProveedor)], {
            type: "application/json"
        }));
        return await apiRequest(URL_FACTURA_PROVEEDOR + "/" + facturaProveedor.id, "PUT", formData, "", true);
    } catch (err) {
        errInt.message += err
        return errInt
    }
}


export const aprobarORechazarFactura = async (facturaAprobada, facturaProveedor) => {
    return await apiRequest(URL_FACTURA_PROVEEDOR+`?facturaRechazada=${facturaAprobada}`,"POST", facturaProveedor)
}

export const eliminarFacturaProveedorPorId = async (idFacturaProveedor) => {
    return await apiRequest(URL_FACTURA_PROVEEDOR+`/${idFacturaProveedor}`,"DELETE")
}

export const eliminadoLogicoFacturaProveedor = async (idFacturaProveedor, razonEliminacion) => {
    return await apiRequest(URL_FACTURA_PROVEEDOR+`/borrado-logico/${idFacturaProveedor}`,"DELETE", null, "?razonEliminacion="+razonEliminacion)
}

export const buscarComprobantesPorIdFactura = async (idFacturaProveedor) => {
    return await apiRequest(URL_FACTURA_PROVEEDOR+`/${idFacturaProveedor}/comprobante`,)
}

export const guardarComprobanteFP = async (
    idFacturaProveedor,
    archivoComprobante, 
    comprobanteFacturaProveedor, ) => {
    try {

        //Establecer el id del usuario auth
        const formData = new FormData();
        if(archivoComprobante != null ) formData.append("archivoComprobante", archivoComprobante, archivoComprobante.name);
        formData.append("comprobanteFactura", new Blob([JSON.stringify(comprobanteFacturaProveedor)], {
            type: "application/json"
        }));
        return await apiRequest(URL_FACTURA_PROVEEDOR + `/${idFacturaProveedor}/comprobante`, "POST", formData, "", true);
    } catch (err) {
        errInt.message += err
        return errInt
    }
}

export const actualizarComprobanteFP = async (
    idFacturaProveedor,
    idComprobanteFactura,
    archivoComprobante, 
    comprobanteFacturaProveedor, ) => {
    try {

        //Establecer el id del usuario auth
        const formData = new FormData();
        if(archivoComprobante != null ) formData.append("archivoComprobante", archivoComprobante, archivoComprobante.name);
        formData.append("comprobanteFactura", new Blob([JSON.stringify(comprobanteFacturaProveedor)], {
            type: "application/json"
        }));

        return await apiRequest(URL_FACTURA_PROVEEDOR + `/${idFacturaProveedor}/comprobante/${idComprobanteFactura}`, "PUT", formData, "", true);
    } catch (err) {
        errInt.message += err
        return errInt
    }
}

export const eliminadoLogicoComprobante = async (idComprobanteFactura,comprobanteFactura) => {
    return await apiRequest(URL_FACTURA_PROVEEDOR+`/comprobante/${idComprobanteFactura}`, "DELETE", comprobanteFactura)
}