import '../../../../assets/css/timeline.css'

import React from 'react';
import { useSelector } from 'react-redux'; 

function LineaTiempoEstatus({ showTimeline, setShowTimeline, isPopover }) {
  const estatus = useSelector((state) => state.estatusEmbarque.estatus);
  const isLoading = useSelector((state) => state.estatusEmbarque.isLoading);

  const handleToggleTimeline = () => {
    setShowTimeline(!showTimeline);
  };

  return (
    <>
      {isLoading ? (
        <div style={{ width: "1000px" }} className='col-sm-12' align="center">
          <div className="spinner-border" role="status"></div>
        </div>
      ) : (
        <>
          {isPopover ? "" :
            <div align="right">
              <button className="toggle-button btn btn-secondary btn-sm" onClick={handleToggleTimeline}>
                {showTimeline ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye-fill"></i>}
              </button>
            </div>
          }


          {showTimeline && (
            <div className="timeline-steps">
              {estatus.map((estatu, index) => {
                return (
                  <div className="timeline-step" key={index}>
                    <div className="timeline-content">
                      {estatu.completado ? (
                        <>
                          <div className="inner-circle"></div>
                          <p className="h6 mb-0 mb-lg-0">{estatu.estatusEmbarque.estatusCompletado}</p>
                        </>
                      ) : (
                        <>
                          <div className="inner-circle-red"></div>
                          <p className="h6 mb-0 mb-lg-0">{estatu.estatusEmbarque.estatus}</p>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
    </>
  );
}

export default LineaTiempoEstatus;

