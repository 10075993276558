import MUIDataTable from 'mui-datatables'
import { BASIC_OPTIONS_MUI, OPTIONS_CUSTOM_RETURN_COLUMN } from '../../../../utils/mui/constantes';
import { columnFormatNameUser } from '../../../mui/columnFormatNameUser';
import { columnFormatDate } from '../../../mui/columnFormatDate';
import ModalCotizacion from '../modals/ModalCotizacion';
import { generateNameUser } from '../../../../utils/helpersFormat';
import { cotizacionLogicDeleteById, findCotizacionesWithFilters } from '../../../../controllers/admin/cotizaciones/cotizacionController';
import CotizacionPdf from '../files/CotizacionPdf';
import { columnEstatusCotizacion } from './columns/columnEstatusCotizacion';
import CustomMuiDataTable from '../../../mui/CustomMuiDataTable';
import ModalEliminarV2 from '../../../ModalEliminarV2';
import { handleChangeArrayText } from '../../../../utils/helpersState';

function TableCotizaciones({ cotizaciones, loadCotizaciones, setCotizaciones, newItem }) {

    const columns = [
        {
            name: "REFERENCIA",
            options: {
                customBodyRenderLite: (index) => {
                    const totalUpdatedVigencia = cotizaciones[index]?.totalUpdatedVigencia > 0 ? `-${cotizaciones[index]?.totalUpdatedVigencia}` : "";
                    return cotizaciones[index]?.quoteReferenceKJ.concat('', totalUpdatedVigencia);
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            },
        },
        {
            name: "CLIENTE",
            options: {
                customBodyRenderLite: (index) => {
                    return cotizaciones[index]?.addUnregisteredClient ?
                        cotizaciones[index].unregisteredClient
                        :
                        generateNameUser(cotizaciones[index]?.cliente)
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            },
        },
        {
            name: "TRAFICO",
            options: {
                customBodyRenderLite: (index) => {
                    return cotizaciones[index]?.ventas.map(venta => {
                        return <>{venta?.trafico?.nombre}<br /></>;
                    })
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            },
        },
        columnFormatNameUser({ state: cotizaciones, nameAttribute: 'vendedor', nameColumn: 'VENDEDOR' }),
        columnFormatDate({ state: cotizaciones, nameAttribute: 'vigencia', nameColumn: 'VIGENCIA', typeFormatDate: 'DD-MM-YYYY' }),
        {
            name: "DETALLES",
            options: {
                customBodyRenderLite: (index) => {
                    return <p style={{ maxWidth: "300px" }}>{cotizaciones[index]?.details}</p>
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            },
        },
        columnEstatusCotizacion({ state: cotizaciones }),
        {
            name: "",
            options: {
                customBodyRenderLite: (index) => {
                    return <div className="btn-group">
                        <button type="button" className="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        </button>
                        <ul className="dropdown-menu">
                            <li><ModalCotizacion editCotizacion={cotizaciones[index]} loadCotizaciones={loadCotizaciones} customClassButton={"dropdown-item"} /></li>
                            <li><CotizacionPdf cotizacion={cotizaciones[index]} customClassButton={"dropdown-item"} /></li>
                            <li><ModalEliminarV2
                                functionEliminar={cotizacionLogicDeleteById}
                                isLogicDelete={true}
                                functionRefreshData={loadCotizaciones}
                                id={cotizaciones[index].id}
                                state={cotizaciones}
                                setState={setCotizaciones}
                                customClassButton={"dropdown-item"}
                                index={index}
                                textHeader={"¿Está seguro que deseas eliminar esta cotización?"}
                                textButton={"Eliminar"}>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    placeholder='Razón de la eliminación'
                                    name={"reasonDeleted"}
                                    value={cotizaciones[index].reasonDeleted}
                                    onChange={(e) => { handleChangeArrayText(e, index, cotizaciones, setCotizaciones, true) }} />
                            </ModalEliminarV2>
                            </li>
                        </ul>
                    </div>
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            },
        }
    ]

    return (
        <div className='row'>
            <CustomMuiDataTable
                title={"Cotizaciones"}
                columns={columns}
                fetchData={findCotizacionesWithFilters}
                setState={setCotizaciones}
                customOptions={{ ...BASIC_OPTIONS_MUI, enableNestedDataAccess: '.' }}
                newItem={newItem} />
        </div>
    )
}

export default TableCotizaciones