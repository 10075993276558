import { ID_ESTATUS_FP_PROGRAMADA_PAGO } from "../constantes";


export const validatePaymentDay = (facturaProveedor) => {
    if (facturaProveedor.estatus?.id == ID_ESTATUS_FP_PROGRAMADA_PAGO) {

        const dateTimbrado = facturaProveedor?.fechaTimbrado;
        const diasCredito = facturaProveedor?.costoProveedor?.proveedor?.diasDeCredito;

        let newDate = new Date(dateTimbrado);
        newDate.setDate(newDate.getDate() + diasCredito);

        // Calcular el próximo viernes
        const dayOfWeek = newDate.getDay();
        const daysUntilNextFriday = dayOfWeek === 5 ? 0 : (12 - dayOfWeek) % 7;  // Ajuste para el próximo viernes
        const nextFriday = new Date(newDate);
        nextFriday.setDate(newDate.getDate() + daysUntilNextFriday);

        /**
         * Si suben la factura después de las 12 pm del viernes y su fecha de pago es ese mismo viernes,
         * ponerle la fecha del siguiente viernes.
        */
        let dateUploadFactura = facturaProveedor?.archivoFacturaXML?.creadoEn;
        if (dateUploadFactura != null) {
            dateUploadFactura = new Date(dateUploadFactura);
            const uploadDayOfWeek = dateUploadFactura.getDay();
            const isAfterFridayNoon = dateUploadFactura.getHours() >= 12;

            if (
                uploadDayOfWeek === 5 &&  // Es viernes
                isAfterFridayNoon &&
                newDate.getDate() === nextFriday.getDate()  // La fecha de pago es ese mismo viernes
            ) {
                nextFriday.setDate(nextFriday.getDate() + 7);  // Obtener la fecha del siguiente viernes
            }
        }
        const infoPaymentLog = {
            fechaSubidaArchivo: dateUploadFactura,
            fechaTimbradoMasDiasCredito: newDate,
            viernesDePago: nextFriday
        }
        return nextFriday.toString();
    }
    return null;
};