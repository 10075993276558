import { URL_COMPLEMENTO_PAGO } from "../../../utils/constantes";
import { apiRequest } from "../../handlerApiRequest";
import { apiRequestV2 } from "../../handlerApiRequestV2";

export const saveComplementoDePago = async (
    archivoPdfACargar,
    archivoXMLACargar,
    complementoPago) => {
    //Establecer el id del usuario auth
    const formData = new FormData();
    if (archivoPdfACargar != null) formData.append("archivoPdfACargar", archivoPdfACargar, archivoPdfACargar.name);
    if (archivoXMLACargar != null) formData.append("archivoXMLACargar", archivoXMLACargar, archivoXMLACargar.name);
    formData.append("complementoPago", new Blob([JSON.stringify(complementoPago)], {
        type: "application/json"
    }));

    return await apiRequestV2({
        endpoint: `${URL_COMPLEMENTO_PAGO}`,
        method: "POST",
        data: formData,
        showSuccessMessage: true,
        isFormData: true
    })
}

export const updateComplementoDePago = async (
    archivoPdfACargar,
    archivoXMLACargar,
    complementoPago) => {
    //Establecer el id del usuario auth
    const formData = new FormData();
    if (archivoPdfACargar != null) formData.append("archivoPdfACargar", archivoPdfACargar, archivoPdfACargar.name);
    if (archivoXMLACargar != null) formData.append("archivoXMLACargar", archivoXMLACargar, archivoXMLACargar.name);
    formData.append("complementoPago", new Blob([JSON.stringify(complementoPago)], {
        type: "application/json"
    }));

    return await apiRequestV2({
        endpoint: `${URL_COMPLEMENTO_PAGO}`,
        method: "PUT",
        data: formData,
        showSuccessMessage: true,
        isFormData: true
    })
}


export const findAllComplementos = async () =>{
    return await apiRequest(URL_COMPLEMENTO_PAGO, 'GET', null)
}

export const findAllComplementosFilter = async (urlParams) =>{
    return await apiRequestV2({
        endpoint: URL_COMPLEMENTO_PAGO,
        urlParams: urlParams
    })
}

export const logicDeleteComplementoPago = async (idComplementoPago, complementoPago) => {
    return await apiRequestV2({
        endpoint: `${URL_COMPLEMENTO_PAGO}/${idComplementoPago}`,
        method: "DELETE",
        data: complementoPago,
        showSuccessMessage: true
    })
}


