import { URL_USER_EMAILS } from "../../utils/constantes";
import { apiRequest } from "../handlerApiRequest";

export const findAllEmailsByIdUser = async(idUser) =>{
    return await apiRequest(URL_USER_EMAILS+`/user/${idUser}`, 'GET');
}

export const saveAllEmails = async (usersEmails) => {
    return await apiRequest(URL_USER_EMAILS, "POST", usersEmails)
}

export const deleteUserEmail = async (idUserEmail) => {
    return await apiRequest(URL_USER_EMAILS+`/${idUserEmail}`, "DELETE")
}