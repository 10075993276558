export const visibilityLoader = (visibility) =>{
    const loader = document.getElementById("loader-v2")
    loader.style.visibility = visibility ? "visible" : "hidden";

}

export const visibilitySuperLoader = (visibility) =>{
    const loader = document.getElementById("super-loader-v2")
    loader.style.visibility = visibility ? "visible" : "hidden";

}