import { getIn } from "formik";
import { ID_SERVICIO_EMBARQUE_ADUANAS, ID_TRAFICO_MARITIMO, ID_TRAFICO_TERRESTRE, MODALIDAD_MARITIMA_LCL } from "../../../../utils/constantes";

import { templateAduanas, templateMaritimo, templateMaritimoLCL, templateTerrestre } from "./templatesTableCotizacionVentas";
import { CATALOGO_TIPOS_FILA } from "../../../../utils/catalogosEstaticos";
import moment from 'moment';
import { validatePrintValueOfCatalogo } from "../../../../utils/validations/dinamicTable";

export const templateTableCotizacionVentas = (values, name, setTableColumns) => {
    let typeTemplate = null;
    let venta = getIn(values, name);

    if (venta.trafico?.id == ID_TRAFICO_TERRESTRE) {
        typeTemplate = "TERRESTRE"
    }

    if (venta.trafico?.id == ID_TRAFICO_MARITIMO) {
        typeTemplate = "MARITIMO"
    }

    if (venta.modalidad == MODALIDAD_MARITIMA_LCL && venta.trafico?.id == ID_TRAFICO_MARITIMO) {
        typeTemplate = "LCL"
    }

    if (venta.idServicio == ID_SERVICIO_EMBARQUE_ADUANAS) {
        typeTemplate = "ADUANAS"
    }

    switch (typeTemplate) {
        case "TERRESTRE":
            setTableColumns(templateTerrestre.columns)
            break;
        case "MARITIMO":
            setTableColumns(templateMaritimo.columns)
            break;
        case "ADUANAS":
            setTableColumns(templateAduanas.columns)
            break;
        case "LCL":
            setTableColumns(templateMaritimoLCL.columns)
            break;
        default:
            break;
    }

}

export const validatePrintDataTable = (column, data, nameColumn) => {
    return(
        column.tipoFila == CATALOGO_TIPOS_FILA[0].value
        || column.tipoFila == CATALOGO_TIPOS_FILA[1].value
        || column.tipoFila == CATALOGO_TIPOS_FILA[2].value ?
        data[nameColumn]
        :
        column.tipoFila == CATALOGO_TIPOS_FILA[3].value ?
            moment(data[nameColumn]).local().format("DD/MM/YYYY")
            :
            column.tipoFila == CATALOGO_TIPOS_FILA[4].value ?
                validatePrintValueOfCatalogo(column.catalogo?.value, data[nameColumn])
                :
                column.tipoFila == CATALOGO_TIPOS_FILA[5].value ?
                    Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(data[nameColumn])
                    :
                    ""
    )
}