import { useState } from 'react';
import ModalCondicionesServicio from '../../components/admin/cotizaciones/modals/ModalCondicionesServicio';
import ModalCotizacion from '../../components/admin/cotizaciones/modals/ModalCotizacion';
import TableCotizaciones from '../../components/admin/cotizaciones/tables/TableCotizaciones';
import { findAllCotizaciones } from '../../controllers/admin/cotizaciones/cotizacionController';
import ModalCondicionesAduana from '../../components/admin/cotizaciones/modals/ModalCondicionesAduana';

function Cotizaciones() {
    const [cotizaciones, setCotizaciones] = useState([]);
    const [newItem, setNewItem] = useState(null);

    const loadCotizaciones = async () => {
        setNewItem(Date.now())
    }


    return (
        <div className='row m-4'>
            <div className='col-12 mb-2' align="right">
                <ModalCotizacion loadCotizaciones={loadCotizaciones}/>
                <ModalCondicionesServicio />
                <ModalCondicionesAduana />
            </div>

            <TableCotizaciones cotizaciones={cotizaciones} loadCotizaciones={loadCotizaciones} setCotizaciones={setCotizaciones} newItem={newItem}/>
        </div>
    )
}

export default Cotizaciones