import React, { useEffect, useState } from 'react'
import { listarCostosDeLogistica, listarCostosDeProveedor } from '../../../controllers/proveedor/proveedorCostosController';
import CardCosto from './CardCosto';
import { useSelector } from 'react-redux';
import { ID_ROL_LOGISTICA } from '../../../utils/constantes';

function ListCostos() {
  const [costos, setCostos] = useState([]);
  const embarqueData = useSelector((state) => state.embarque.embarqueData);
  const roleUser = useSelector((state) => state.userAuth.roleUser);

  useEffect(() => {
    fetctListarCostosDeProveedor()
  }, [])

  const fetctListarCostosDeProveedor = async () => {
    const isLogistica = await searchRoleAuthUser();
    const res = isLogistica ? await listarCostosDeLogistica(embarqueData.id) : await listarCostosDeProveedor(embarqueData.id);
    if (res.error) return ToastError(res.message)
    setCostos(res.data)
  }

  const searchRoleAuthUser = async () => {
    if (roleUser?.id == ID_ROL_LOGISTICA) {
      return true;
    }
    return false;
  }



  return (
    <>
      {costos ?
        costos.map((costo, index) => {
          return (
            <CardCosto
              costo={costo}
              key={index}
              fetctListarCostosDeProveedor={fetctListarCostosDeProveedor}></CardCosto>
          )
        })
        :
        "ESTE EMBARQUE NO TIENE FACTURAS PENDIENTES"}
    </>
  )
}

export default ListCostos