import { URL_EMBARQUES } from "../../../utils/constantes";
import { visibilitySuperLoader } from "../../../utils/loader";
import { apiRequestV2 } from "../../handlerApiRequestV2";

export const buscarDocumentosPorIdEmbarque = async (idEmbarque) =>{
    return await apiRequestV2({
        endpoint: `${URL_EMBARQUES}/${idEmbarque}/documentos`
    })
}

export const guardarDocumentoEmbarque = async (idEmbarque, shipmentFilesList) => {
    const promises = [];
    shipmentFilesList?.documents?.forEach(async (shipmentFile) => {
        const formData = new FormData();
        if (shipmentFile.file != null) formData.append("archivo", shipmentFile.file, shipmentFile.file.name);
        formData.append("embarqueArchivo", new Blob([JSON.stringify(shipmentFile)], {
            type: "application/json"
        }));
        promises.push(
            apiRequestV2({
                endpoint: `${URL_EMBARQUES}/${idEmbarque}/guardar-documento`,
                data: formData,
                method: "POST",
                showSuccessMessage: true,
                isFormData: true
            })
        );
    });
    visibilitySuperLoader(true);
    await Promise.all(promises)
    visibilitySuperLoader(false);
}

export const actualizarDocumentoEmbarque = async (idEmbarque, shipmentFilesList) => {
    const shipmenFile = shipmentFilesList?.documents[0];
    const formData = new FormData();
    if (shipmenFile.file != null) formData.append("archivo", shipmenFile.file, shipmenFile.file.name);
    formData.append("embarqueArchivo", new Blob([JSON.stringify(shipmenFile)], {
        type: "application/json"
    }));
    await apiRequestV2({
        endpoint: `${URL_EMBARQUES}/${idEmbarque}/actualizar-documento`,
        data: formData,
        method: "PUT",
        showSuccessMessage: true,
        isFormData: true
    });
}

export const eliminadoLogicoDocumento = async (idShipmentFile, reasonDeleted) =>{
    return await apiRequestV2({
        endpoint: URL_EMBARQUES + "/eliminado-logico-documento/" + idShipmentFile,
        urlParams: `?razonEliminacion=${reasonDeleted}`,
        data: null,
        method: "DELETE",
        showSuccessMessage: true,
    });
}
