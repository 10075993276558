import Modal from '@mui/material/Modal';
import { deleteItem } from '../utils/helpersState';
import { useState } from 'react';

function ModalEliminarV2({
    textBody,
    textHeader,
    functionEliminar,
    id,
    index,
    spliceItem,
    setState,
    state,
    children,
    textButton,
    customIcon,
    functionRefreshData,
    customClassButton,
    isDisabled }) {
    const [open, setOpen] = useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleEliminar = async () => {
        const res = await functionEliminar(id, state[index]);
        if(res && spliceItem) deleteItem(index, state, setState)
        if(res && functionRefreshData) functionRefreshData()
        setOpen(false);
    };

    return (
        <>
            <button type='button' className={customClassButton ? customClassButton : 'btn btn-danger'}
                onClick={handleClickOpen}
                disabled={isDisabled ? true : false}>
                {customIcon ? customIcon : <i className="bi bi-trash"></i>}
                {textButton ? textButton : ""}
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="card modal-eliminar">
                    <div className="card-header">
                        <b>{textHeader}</b>
                    </div>
                    <div className="card-body">
                        {textBody}
                        {children}
                    </div>
                    <div className="card-footer" align="right">
                        <button type='button' className='btn btn-secondary'
                            onClick={handleClose}>
                            Cancelar
                        </button>
                        <button type='button' className='btn btn-danger'
                            onClick={handleEliminar}>
                            Confirmar
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ModalEliminarV2