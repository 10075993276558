export const getImageBase64 = async (urlImg) => {
    // Lógica para obtener la imagen, por ejemplo desde una URL o archivo
    // Reemplaza esta función con la lógica adecuada
    return fetch(urlImg)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        }));
};