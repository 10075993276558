export function calcularDiasVencimiento(fechaTimbrado, diasCredito) {
    const fechaHoy = new Date();
    const fechaTimbradoObj = new Date(fechaTimbrado);
    
    // Agregar días de crédito a la fecha de timbrado
    const fechaVencimientoObj = new Date(fechaTimbradoObj.getTime());
    fechaVencimientoObj.setDate(fechaVencimientoObj.getDate() + diasCredito);
    
    // Calcular la diferencia en días
    const diferencia = fechaVencimientoObj.getTime() - fechaHoy.getTime();
    const diasFaltantes = Math.ceil(diferencia / (1000 * 60 * 60 * 24));
    
    return diasFaltantes;
}