import React, { useState } from 'react'
import Modal from '@mui/material/Modal';
import { addItem, handleChangeStateObject, searchStateItem, updateItem } from '../../utils/helpersState';
import SelectSearch from '../inputs/SelectSearch';
import { CATALOGO_TIPOS_CATALOGOS, CATALOGO_TIPOS_FILA } from '../../utils/catalogosEstaticos';
import { ToastInfo } from '../../utils/toastMessage';
import { adjustResizableTable } from '../../utils/resize-table/resize-table';


function ModalColumnDinamicTable({ tableColumns, setTableColumns, isEditColumn, indexColumn, confPdf }) {
    const [open, setOpen] = useState(false);
    const [tableColumn, setTableColumn] = useState({});

    const generateColumn = () => {
        isEditColumn ? updateItem(indexColumn, tableColumns, setTableColumns, tableColumn) : addItem(tableColumns, setTableColumns, tableColumn);
        adjustResizableTable(setTableColumns, true);
        handleClose();
        const messageInfo = isEditColumn ? 'Columna modificada correctamente.' :
            'Columna agregada correctamente';
        return ToastInfo(messageInfo);
    };

    const handleOpen = () => {
        if (isEditColumn) {
            const itemEncontrado = searchStateItem(indexColumn, tableColumns);
            setTableColumn(itemEncontrado)
        }
        setOpen(true)
    }

    const handleClose = () => {
        setTableColumn({});
        setOpen(false);
    }

    return (
        <>
            {isEditColumn ?
                <i className="bi bi-pen item-pointer" onClick={handleOpen}></i> :
                <button
                    className="btn btn-dark m-1"
                    type="button" onClick={handleOpen}>
                    <i className="bi bi-plus"></i>COLUMNA
                </button>
            }

            <Modal
                open={open}
                onClose={handleClose}
            >
                <div className='card style-modal-sm'>
                    <div className='card-header d-flex justify-content-between'>
                        COLUMNA
                        <button className='btn btn-dark btn-sm'
                            onClick={handleClose}>
                            <i className="bi bi-x-lg"></i>
                        </button>
                    </div>
                    <div className='card-body'>

                        <div className="col-sm-12 mb-3">
                            <label className="form-label">Nombre de la Columna<span className='required-span'>*</span></label>
                            <input type='text'
                                value={tableColumn?.nombreColumna}
                                className='form-control'
                                name='nombreColumna'
                                onChange={(e) => { handleChangeStateObject(e, setTableColumn) }} />
                        </div>

                        <div className="col-sm-12 mb-3">
                            <SelectSearch
                                data={CATALOGO_TIPOS_FILA}
                                titulo={"Tipo de la Fila"}
                                value={tableColumn?.tipoFila}
                                name={"tipoFila"}
                                handleChange={(e) => { handleChangeStateObject(e, setTableColumn) }}
                            ></SelectSearch>
                        </div>
                        {tableColumn?.tipoFila == CATALOGO_TIPOS_FILA[4].value ?
                            <div className="col-sm-12 mb-3">
                                <SelectSearch
                                    data={CATALOGO_TIPOS_CATALOGOS}
                                    titulo={"Catalogo"}
                                    name={"catalogo"}
                                    value={tableColumn?.catalogo}
                                    returnObject={true}
                                    handleChange={(e) => { handleChangeStateObject(e, setTableColumn) }}
                                ></SelectSearch>
                            </div> : <></>
                        }

                        <div className='col-sm-12' align="right">
                            <button className='btn btn-primary'
                                onClick={() => generateColumn()}>{isEditColumn ? "ACTUALIZAR" : "GUARDAR"}</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ModalColumnDinamicTable