import React, { useEffect, useState } from 'react'
import { ARCHIVOS_PERMITIDOS_V2, ID_ROL_LOGISTICA, ID_ROL_PROVEEDORES } from '../../../utils/constantes'
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from 'react-redux';
import { actualizarPod, buscarPodsConFormatoPorIdEmbarque, eliminadoLogicoPOD, subirPod } from '../../../controllers/admin/embarques/podsController';
import { ToastError, ToastSuccess, ToastWarning } from '../../../utils/toastMessage';
import SelectDesglosesConCheckBox from './catalogos-select/SelectDesglosesConCheckBox';
import { setLoading } from "../../../redux/features/loaderSlice"
import ModalArchivo from '../../ModalArchivo';
import { BASE_URL_S3_PODS_EMBARQUES } from '../../../utils/constantes';
import { eliminarRelacionDesgloseEmbarqueConPod, relacionarDesgloseEmbarqueConPod } from '../../../controllers/admin/embarques/desgloses/desgloseEmbarqueController';
import { fetchEstatusEmbarque } from '../../../redux/features/admin/estatusEmbarqueSlice';
import ModalEliminarObjeto from '../ModalEliminarObjeto';
import { OPTIONS_MUI_ONLY_TABLE } from '../../../utils/mui/constantes';
import CustomFileUploader from '../../inputs/CustomFileUploader';
import { addItem, deleteItem, handleChangeMultipleFile } from '../../../utils/helpersState';
import { authUserIsAdminOrOperativo, getAuthIdUser } from '../../../utils/authUtils';
import { podHaveRelationWithDesglose, validatePrintRole } from './validations/podsValidations';
import { bloquearEmbarque } from './validations/embarqueValidations';

function PODS({ idEmbarque }) {
    const [pods, setPods] = useState([]);
    const [authEsAdmin, setEsAdmin] = useState(false);
    const dispatch = useDispatch();
    const embarqueData = useSelector((state) => state.embarque.embarqueData);
    const userAuth = useSelector((state) => state.userAuth);

    useEffect(() => {
        fetchBuscarPodsConFormatoPorIdEmbarque();
        setAuthAdmin()
    }, [])

    function setAuthAdmin() {
        setEsAdmin(authUserIsAdminOrOperativo());
    }

    const fetchBuscarPodsConFormatoPorIdEmbarque = async () => {
        try {
            const res = await buscarPodsConFormatoPorIdEmbarque(idEmbarque);
            if (res.error) return ToastError(res.message)
            setPods(res.data)
        } catch (err) {
            return ToastError(err.message);
        }
    }

    const agregarPod = () => {
        addItem(pods, setPods, { desglosesEmbarque: [] })
    }

    const eliminarPod = (indexPOD) => {
        deleteItem(indexPOD, pods, setPods)
    }

    const handleChange = (e, indexPOD) => {
        const { name, value } = e.target;
        const newPods = [...pods];
        newPods[indexPOD] = { ...newPods[indexPOD], [name]: value };
        setPods(newPods);
    }

    const onChangeFiles = async (indexPOD, file, name) => {
        await handleChangeMultipleFile(indexPOD, file, name, pods, setPods, dispatch)
    }

    const handlerOnSubmit = async (indexPOD, cargarTodos) => {
        try {
            if (cargarTodos) {
                pods.forEach((pod, index) => {
                    if (pod.archivoACargar) {
                        onSubmitFiles(pod.archivoACargar, index);
                    }
                });
            } else {
                if (!pods[indexPOD].archivoACargar) return ToastWarning("Se debe subir un archivo")
                onSubmitFiles(pods[indexPOD].archivoACargar, indexPOD);
            }
        } catch (error) {
            ToastError(error.message);
            dispatch(setLoading({ isLoading: false }))
        }
    };

    const onSubmitFiles = async (file, indexPOD) => {
        try {
            dispatch(setLoading({ isLoading: true }))
            let res;
            //Si es proveedor mandarle el id de este
            let isProveedor = userAuth.roleUser?.id == ID_ROL_PROVEEDORES || userAuth.roleUser?.id == ID_ROL_LOGISTICA ? true : false;
            let idProveedor = null;
            if (isProveedor)  idProveedor = getAuthIdUser();
            if (pods[indexPOD].archivoPOD != null) {
                res = await actualizarPod(pods[indexPOD].archivoPOD, file, idProveedor)

            } else {
                res = await subirPod(idEmbarque, file, idProveedor)

            }

            dispatch(setLoading({ isLoading: false }))
            if (res.error) return ToastError(res.message);
            fetchBuscarPodsConFormatoPorIdEmbarque()


            ToastSuccess(res.message)
        } catch (error) {
            dispatch(setLoading({ isLoading: false }))
            return ToastError(error.message)
        }
    }

    const handleChangeCheckbox = async (event, dataIndexDesglose, indexPod) => {
        const isChecked = event.target.checked;

        // Actualizar el estado de pods
        setPods((prevPods) => {
            const updatedPods = [...prevPods];
            const selectedDesglose = updatedPods[indexPod].desglosesEmbarque[dataIndexDesglose];
            selectedDesglose.tieneRelacionConPod = isChecked;
            return updatedPods;
        });

        if (isChecked) {
            await fetchRelacionarDesgloseEmbarqueConPod(pods[indexPod].desglosesEmbarque[dataIndexDesglose].id, pods[indexPod].archivoPOD)
        } else {
            await fetchEliminarRelacionDesgloseEmbarqueConPod(pods[indexPod].desglosesEmbarque[dataIndexDesglose].id)
        }
        await fetchBuscarPodsConFormatoPorIdEmbarque()
        dispatch(fetchEstatusEmbarque(idEmbarque))
    };

    const fetchRelacionarDesgloseEmbarqueConPod = async (idDesgloseEmbarque, archivoS3) => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const res = await relacionarDesgloseEmbarqueConPod(idDesgloseEmbarque, archivoS3)

            dispatch(setLoading({ isLoading: false }))
            if (res.error) return ToastError(res.message);

            ToastSuccess(res.message)
        } catch (error) {
            return ToastError(error)
        }
    }

    const fetchEliminarRelacionDesgloseEmbarqueConPod = async (idDesgloseEmbarque) => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const res = await eliminarRelacionDesgloseEmbarqueConPod(idDesgloseEmbarque)

            dispatch(setLoading({ isLoading: false }))
            if (res.error) return ToastError(res.message);

            ToastSuccess(res.message)
        } catch (error) {
            return ToastError(error)
        }
    }

    const fecthEliminadoLogicoPod = async (idEmbarqueArchivo, indexPOD) => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const res = await eliminadoLogicoPOD(idEmbarqueArchivo, pods[indexPOD].razonEliminado)
            dispatch(setLoading({ isLoading: false }))
            if (res.error) return ToastError(res.message)

            eliminarPod(indexPOD)
            return ToastSuccess(res.message)
        } catch (err) {
            return ToastError(err)
        }
    }

    const columns = [
        {
            name: "Id",
            options: {
                display: "excluded",
                filter: false,
                sort: false,
                download: false,
            }
        },
        {
            name: "POD",
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <div align="center">
                            {pods[dataIndex].archivoPOD ?

                                <>
                                    <ModalArchivo
                                        title={"Prueba de entrega"}
                                        urlBase={BASE_URL_S3_PODS_EMBARQUES}
                                        archivoS3={pods[dataIndex].archivoPOD}></ModalArchivo>
                                    <br />
                                </>
                                :
                                <></>

                            }
                            {pods[dataIndex].nombreArchivo ?
                                <p>{pods[dataIndex].nombreArchivo}</p>
                                :
                                ""

                            }

                            {validatePrintRole(pods[dataIndex], authEsAdmin) ?
                                <CustomFileUploader
                                    handleChangeFile={onChangeFiles}
                                    index={dataIndex}
                                    fileTypes={ARCHIVOS_PERMITIDOS_V2}
                                    multiple={true}
                                    name={"archivoACargar"}
                                    nameFile={pods[dataIndex].archivoACargar?.name}></CustomFileUploader>
                                :
                                ""
                            }
                        </div>
                    )
                },
                sort: false,
                download: false,
            }
        },
        {
            name: authEsAdmin ? "Relaciones de POD" : "",
            options: {
                customBodyRenderLite: (indexPOD) => {
                    return (
                        pods[indexPOD].id && authEsAdmin ?
                            <SelectDesglosesConCheckBox
                                desgloses={pods[indexPOD].desglosesEmbarque}
                                handleChangeCheckbox={handleChangeCheckbox}
                                indexPOD={indexPOD}>
                            </SelectDesglosesConCheckBox>
                            : <></>
                    );
                },
                sort: false,
                download: false,
            }
        },
        {
            name: "",
            options: {
                customBodyRenderLite: (indexPOD) => {
                    return (
                        validatePrintRole(pods[indexPOD], authEsAdmin) ?
                            <button type='button'
                                className='btn btn-dark'
                                onClick={() => { handlerOnSubmit(indexPOD) }}
                                disabled={bloquearEmbarque(embarqueData, userAuth)}>
                                <i className="bi bi-cloud-arrow-up-fill"></i>
                                {pods[indexPOD].archivoPOD ?
                                    " Actualizar" : " Subir"
                                }
                            </button>
                            :
                            ""
                    );
                },
                sort: false,
                download: false,
            }
        },
        {
            name: "",
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return (
                        pods[dataIndex].id ?
                            <>
                                {!podHaveRelationWithDesglose(pods[dataIndex].desglosesEmbarque) ?
                                    validatePrintRole(pods[dataIndex], authEsAdmin) ?
                                        <ModalEliminarObjeto
                                            textHeader={"¿Seguro de eliminar este POD?"}
                                            textBody={"Verifica que no tenga alguna relación con un desglose."}
                                            functionEliminar={fecthEliminadoLogicoPod}
                                            id={pods[dataIndex].id}
                                            index1={dataIndex}
                                            isDisabled={bloquearEmbarque(embarqueData, userAuth)}>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                placeholder='Razón de la eliminación'
                                                name='razonEliminado'
                                                value={pods[dataIndex].razonEliminado}
                                                onChange={(e) => { handleChange(e, dataIndex) }} />
                                        </ModalEliminarObjeto>
                                        :
                                        ""
                                    :
                                    ""
                                }
                            </>
                            :
                            <button type='button' className='btn btn-danger'
                                onClick={() => eliminarPod(dataIndex)}>
                                <i className="bi bi-trash"></i>
                            </button>
                    );
                },
                sort: false,
                download: false,
            }
        }];

    return (
        <div>
            <div className='col-12' align="right">
                <button type='button' className='btn btn-dark mt-2 mb-2 me-2'
                    onClick={() => agregarPod()}
                    disabled={bloquearEmbarque(embarqueData, userAuth)}>
                    <i className="bi bi-plus-lg"></i> Agregar POD
                </button>
                {userAuth.roleUser?.id != ID_ROL_PROVEEDORES ?
                    <button type='button' className='btn btn-dark mt-2 mb-2'
                        onClick={() => handlerOnSubmit(null, true)}
                        disabled={bloquearEmbarque(embarqueData, userAuth)}>
                        <i className="bi bi-cloud-arrow-up-fill"></i> CARGAR TODOS
                    </button> : ""
                }

            </div>
            <MUIDataTable
                title={"Pruebas de Entrega"}
                data={pods}
                columns={columns}
                options={{
                    ...OPTIONS_MUI_ONLY_TABLE,
                    responsive: "standard",
                    setRowProps: (row, dataIndex) => {
                        if (pods[dataIndex].guardoProveedor) {
                            return {
                                style: { backgroundColor: "#9ca8b27c" }
                            };
                        }
                    }
                }}
            />
        </div>
    )
}

export default PODS