import { OPTIONS_CUSTOM_RETURN_COLUMN } from '../../utils/mui/constantes';
import moment from "moment";
import "moment/locale/es";

export const columnFormatDate = (params) => {
    const { state, nameAttribute, nameColumn, typeFormatDate } = params;
    
    return {
        name: nameColumn,
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (
                    moment(state[dataIndex][nameAttribute]).format(typeFormatDate)
                )
            },
            ...OPTIONS_CUSTOM_RETURN_COLUMN
        }
    }
}