import { generatePdf, todosArchivosSonImagen } from "./imgToPdf";
import { ToastError } from "./toastMessage";
import { setLoading } from "../redux/features/loaderSlice";
import moment from 'moment-timezone';
import { mergePDFs, todosArchivosSonPDFS } from "./combinePdfs";
import { TIPO_ARCHIVO_OUTLOOK, TIPO_ARCHIVO_ZIP } from "./constantes";
import { handleChangeArrayTextPage } from "./helpersStatePage";
import { visibilityLoader } from "./loader";

export const addItemPage = (state, setState, attributes, isPush) => {
    const newState = communsAddItem(state.data, attributes, isPush);
    setState(prevState => ({ ...prevState, data: newState }));
}

export const addItem = (state, setState, attributes, isPush) => {
    const newState = communsAddItem(state, attributes, isPush);
    setState(newState);
}

const communsAddItem = (state, attributes, isPush) =>{
    const newState = [...state];
    if(newState == undefined || newState == null ) newState = [];
    let validateState = attributes ? attributes : {};
    validateState.idItem = Math.random().toString(16).slice(2);
    isPush ? newState.push(validateState) : newState.unshift(validateState);
    return newState;
}

export const updateItem = (index, state, setState, item) => {
    if(state){
        const newState = [...state];
        newState[index] = item;
        setState(newState);
    }else{
        setState((prevState) => {
            const newState = [...prevState];
            newState[index] = item;
            return newState;
        });
    } 
}

export const updateAttributeArray = (index, setState, item) => {
    setState((prevState) => {
        const newState = [...prevState];
        const updatedItem = { ...newState[index], ...item };
        newState[index] = updatedItem;
        return newState;
    });
}

export const deleteItem = (index, state, setState) => {
    const newState = [...state];
    newState.splice(index, 1);
    setState(newState);
}

export const deleteItemPage = (index, state, setState) => {
    const newState = {...state};
    newState.data.splice(index, 1);
    setState(newState);
}

export const searchStateItem = (targetIndex, state) =>{
    const foundElement = state.find((element, index) => index === targetIndex);
    return foundElement;
}

export const pushItemExistArray = (item, state, setState) => {
    if(!item) return;
    
    if(!Array.isArray(state)){
        console.error("El state debe ser un Array");
        return;
    }
    const newState = [...state];
    const foundIndex = newState.findIndex((element) => element?.id === item?.id);
    if (foundIndex !== -1) {
        newState[foundIndex] = item;
    } else {
        newState.unshift(item);
    }

    setState(newState);
};

export const handleChangeArrayText = (e, index, state, setState, isUpperCase) => {
    let { name, value } = e.target;
    if (isUpperCase) value = value.toUpperCase();
    const newState = [...state];
    if (!newState[index]) {
        newState[index] = {};
    }
    newState[index][name] = value
    setState(newState);
}

export const handleChangeSimpleArrayText = (e, index, state, setState, isUpperCase) => {
    let { value } = e.target;
    if (isUpperCase) value = value.toUpperCase();
    const newState = [...state];
    if (!newState[index]) {
        newState[index] = {};
    }
    newState[index] = value
    setState(newState);
}

export const handleChangeStateObject = (e, setState, isUpperCase) => {
    let { name, value } = e.target;
    if (isUpperCase) value = value.toUpperCase();
    setState(prevState => ({ ...prevState, [name]: value }));
}

export const handleChangeFile = (index, file, name, state, setState, isPage) => {
    if (file != null) {
        let e = {
            target: {
                name: name,
                value: file
            }
        }
        isPage ? handleChangeArrayTextPage(e, index, state, setState) : handleChangeArrayText(e, index, state, setState);
    }
}

export const handleArrayDateTimeChange = (date, name, index, state, setState) => {
    const formattedDateTime = date ?  moment(date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : null;
    let e = {
        target: {
            name: name,
            value: formattedDateTime
        }
    }
    handleChangeArrayText(e, index, state, setState);
}

export const handleChangeDate = (date, setState) => {
    if (date) {
        const formattedDateTime = moment(date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        setState(formattedDateTime)
    } else {
        setState(null)
    }
};


export const handleChangeArrayNumber = (e, index, state, setState) => {
    const { name, value } = e.target;
    const newValue = parseFloat(value);
    const newState = [...state];
    newState[index][name] = newValue;
    setState(newState);
}

//RETURN FILE
export const formikHandleChangeMultipleFile = async (file) =>{
    try {
        visibilityLoader(true);
        let fileBuilt = null;
        if(validateArchivoComprimido(file)){
            fileBuilt = file[0]
        }else if (validateFileEML(file)){
            fileBuilt = file[0];
        }else if (todosArchivosSonImagen(file)) {
            const files = [...file];
            fileBuilt = await generatePdf(files);
        } else if(todosArchivosSonPDFS(file)){
            fileBuilt = file.length > 1 ? await mergePDFs(file) : file[0];
        }else{
            throw new Error("Error: Todos los archivos deben ser imagenes o todos los archivos deben ser PDF.")
        }
        visibilityLoader(false);
        return fileBuilt;
    } catch (err) {
        visibilityLoader(false);
        return ToastError(err.message)
    }
}


export const handleChangeMultipleFile = async (index, archivo, name, state, setSate, dispatch) => {
    try {
        dispatch(setLoading({ isLoading: true, message: "Cargando archivos..." }))
        if(validateArchivoComprimido(archivo)){
            handleChangeFile(index, archivo[0], name, state, setSate)
            dispatch(setLoading({ isLoading: false, message: null }))
        }else if (todosArchivosSonImagen(archivo)) {
            const archivos = [...archivo];
            
            const pdf = await generatePdf(archivos);
            dispatch(setLoading({ isLoading: false, message: null }))
            handleChangeFile(index, pdf, name, state, setSate)
        } else if(todosArchivosSonPDFS(archivo)){
            const pdf = archivo.length > 1 ? await mergePDFs(archivo) : archivo[0]
            dispatch(setLoading({ isLoading: false, message: null }))
            handleChangeFile(index, pdf, name, state, setSate)
        }else{
            throw new Error("Error: Todos los archivos deben ser imagenes o todos los archivos deben ser PDF.")
        }
    } catch (err) {
        dispatch(setLoading({ isLoading: false, message: null }))
        return ToastError(err.message)
    }

}

function validateArchivoComprimido(archivos){
    for (let archivo of archivos) {

        const fullNameFile = archivo.name.split(".");
        if(archivo.type == null || archivo.type == "") archivo.type = fullNameFile[1]

        if (!TIPO_ARCHIVO_ZIP.includes(archivo.type)) {
            return false;
        }
    }
    if(archivos.length > 1) throw new Error("Solo puedes subir un archivo comprimido")
    return true;
}

function validateFileEML(files){
    for (let file of files) {
        if (!TIPO_ARCHIVO_OUTLOOK.includes(file.type)) {
            return false;
        }
        
    }
    if(files.length > 1) throw new Error("Solo puedes subir un archivo de este tipo.");
    return true;
}
