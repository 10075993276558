import { URL_GASTO_FACTURA_CLIENTE } from "../../../utils/constantes";
import { apiRequest } from "../../handlerApiRequest";

export const findAllByIdFacturaCliente = async (idFacturaCliente) => {
    return await apiRequest(URL_GASTO_FACTURA_CLIENTE+`/cliente/${idFacturaCliente}`)
}

export const saveAllGastoFacturaCliente = async (gastos) => {
    return await apiRequest(URL_GASTO_FACTURA_CLIENTE+'/all', "POST", gastos);
}

export const deleteGastoById = async (idGastoFacturaCliente) => {
    return await apiRequest(URL_GASTO_FACTURA_CLIENTE+`/${idGastoFacturaCliente}`, "DELETE");
}