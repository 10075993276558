import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { deleteByIdDetalleCargaMaritimo } from '../../../../../controllers/admin/embarques/desgloses/detalleCargaMaritimo';
import { ToastError, ToastSuccess } from '../../../../../utils/toastMessage';
import ModalEliminarObjeto from '../../../ModalEliminarObjeto';
import TipoCarga from '../TipoCarga';
import { CATALOGO_TIPO_UNIDADES_MEDIDA } from '../../../../../utils/catalogosEstaticos';
import { ID_SERVICIO_EMBARQUE_IMPORTACION, ID_SERVICIO_MARITIMO_D2D, ID_SERVICIO_MARITIMO_P2D, MODALIDAD_MARITIMA_FCL } from '../../../../../utils/constantes';
import { Field, FieldArray } from 'formik';
import FormikSelectSearch from '../../../../formik/FormikSelectSearch';
import FormikGenericDate from '../../../../formik/FormikGenericDate';

function DetalleDeCargaMaritimo({
    values,
    contenedoresMaritimos,
    tiposCargaDesglose }) {
    const embarqueData = useSelector((state) => state.embarque.embarqueData);
    const [indexSelected, setInexSelected] = useState(0);

    const fetchEliminarDetalleDeCarga = async (idDetalleDeCarga, index, arrayHelpers) => {
        const res = await deleteByIdDetalleCargaMaritimo(idDetalleDeCarga)
        if (res.error) return ToastError(res.message)
        arrayHelpers.remove(index)
        return ToastSuccess(res.message)
    }

    return (

        <>

            <FieldArray
                name="datosDesgloseMaritimo.detallesCargaMaritimo"
                render={arrayHelpers => (
                    <>
                        <div className='col-12 mb-3 mt-2' align="right">
                            <button type='button' className='btn btn-primary'
                                onClick={() => { arrayHelpers.push({}) }}>
                                <i className="bi bi-plus-lg"></i> Detalle de Carga
                            </button>
                        </div>
                        {values?.datosDesgloseMaritimo?.detallesCargaMaritimo?.length >= 1 ?
                            <div className='col-2' style={{ overflowY: "scroll", maxHeight: "360px" }}>
                                <div className='background-containers' style={{ marginBottom: "8px", color: "#fff", padding: "3px" }} align="center">
                                    <span >Contenedores: {values?.datosDesgloseMaritimo?.detallesCargaMaritimo?.length}</span>
                                </div>
                                {values?.datosDesgloseMaritimo?.detallesCargaMaritimo.map((detalleDeCarga, index) => {
                                    return (
                                        <>
                                            <span style={{ color: indexSelected == index ? "#278196" : null, cursor: "pointer", padding: "1px", fontSize: "15px" }} onClick={() => setInexSelected(index)}>
                                                {indexSelected == index ? <i className="bi bi-caret-right-fill"></i> : <i className="bi bi-caret-right-fill" style={{ visibility: "hidden" }}></i>}
                                                {detalleDeCarga?.noDeContenedor ? detalleDeCarga?.noDeContenedor : "???????????"} - {detalleDeCarga?.contenedorMaritimo?.nombre ? detalleDeCarga?.contenedorMaritimo?.nombre : "????"}
                                            </span>
                                            {detalleDeCarga?.id == null ?
                                                <span style={{ color: "red", cursor: "pointer" }} onClick={() => { arrayHelpers.remove(index) }}>
                                                    <i className="bi bi-trash"></i>
                                                </span>
                                                : null
                                            }
                                            <br />
                                        </>
                                    )
                                })}
                            </div>
                            :
                            null
                        }
                        <div className='col-10'>
                            {values.datosDesgloseMaritimo?.detallesCargaMaritimo?.map((detalleDeCarga, index) => {
                                return (
                                    <>
                                        {indexSelected == index ?
                                            <div className='row' key={index}>
                                                <div className='col-sm-12 d-flex justify-content-between'>
                                                    Detalle de Carga
                                                    {detalleDeCarga.id ?
                                                        <ModalEliminarObjeto
                                                            textHeader={"¿Está seguro que desea eliminar este detalle de carga?"}
                                                            textBody={"Se eliminara permanentemente"}
                                                            functionEliminar={(id) => fetchEliminarDetalleDeCarga(id, index, arrayHelpers)}
                                                            id={detalleDeCarga.id}></ModalEliminarObjeto> :
                                                        <button type='button' className='btn btn-danger'
                                                            onClick={() => { arrayHelpers.remove(index) }}>
                                                            <i className="bi bi-trash"></i>
                                                        </button>
                                                    }
                                                </div>
                                                <div className="col-sm-2 mb-3">
                                                    <Field
                                                        name={`datosDesgloseMaritimo.detallesCargaMaritimo[${index}].contenedorMaritimo`}
                                                        component={FormikSelectSearch}
                                                        titulo="Contenedor"
                                                        data={contenedoresMaritimos}
                                                        returnObject={true} />
                                                </div>
                                                <div className="col-sm-4">
                                                    <label htmlFor="noDeContenedor" className="form-label">No. de Contenedor</label>
                                                    <Field id="noDeContenedor" name={`datosDesgloseMaritimo.detallesCargaMaritimo[${index}].noDeContenedor`} className="form-control" />
                                                </div>
                                                <div className='col-sm-2'>
                                                    <label htmlFor="volumen" className="form-label">Volumen</label>
                                                    <Field id="volumen" name={`datosDesgloseMaritimo.detallesCargaMaritimo[${index}].volumen`} className="form-control" type={"number"} />
                                                </div>
                                                <div className='col-sm-2'>
                                                    <label htmlFor="sello" className="form-label">Sello</label>
                                                    <Field id="sello" name={`datosDesgloseMaritimo.detallesCargaMaritimo[${index}].sello`} className="form-control" />

                                                </div>
                                                <div className='col-sm-2 mb-3'>
                                                    <label className="form-label">Modalidad</label>
                                                    <br />
                                                    <label>
                                                        <Field type="radio" name={`datosDesgloseMaritimo.detallesCargaMaritimo[${index}].modalidad`} value="SENCILLO" />
                                                        SENCILLO
                                                    </label><br />
                                                    <label>
                                                        <Field type="radio" name={`datosDesgloseMaritimo.detallesCargaMaritimo[${index}].modalidad`} value="FULL" />
                                                        FULL
                                                    </label>
                                                </div>
                                                <div className='col-12'></div>

                                                <div className="col-sm-3 mb-3">
                                                    <label htmlFor="mercanciaTrasladar" className="form-label">Mercancía a trasladar</label>
                                                    <Field id="mercanciaTrasladar" name={`datosDesgloseMaritimo.detallesCargaMaritimo[${index}].mercanciaTrasladar`} className="form-control" component="textarea" />
                                                </div>
                                                <div className='col-sm-3 mb-3'>
                                                    <TipoCarga
                                                        catalogoTipoCarga={detalleDeCarga.catalogoTipoCarga}
                                                        values={values}
                                                        tiposCargaDesglose={tiposCargaDesglose}
                                                        name={`datosDesgloseMaritimo.detallesCargaMaritimo[${index}].`}></TipoCarga>
                                                </div>
                                                <div className="col-sm-2 mb-3">
                                                    <label htmlFor="cantidadTotalBultos" className="form-label">Total de Bultos</label>
                                                    <Field id="cantidadTotalBultos" name={`datosDesgloseMaritimo.detallesCargaMaritimo[${index}].cantidadTotalBultos`} className="form-control" type={"number"} />
                                                </div>
                                                <div className="col-sm-2">
                                                    <label htmlFor="pesoBruto" className="form-label">Peso bruto</label>
                                                    <Field id="pesoBruto" name={`datosDesgloseMaritimo.detallesCargaMaritimo[${index}].pesoBruto`} className="form-control" type={"number"} />
                                                </div>
                                                <div className='col-sm-2 mb-3'>
                                                    <label htmlFor="pesoBruto" className="form-label">Unidad de medida</label>
                                                    <Field as="select" name={`datosDesgloseMaritimo.detallesCargaMaritimo[${index}].unidadDeMedida`} className="form-select">
                                                        <option value="">Unidades de medida</option>
                                                        {CATALOGO_TIPO_UNIDADES_MEDIDA.map(tipoUnidad => {
                                                            return <option value={tipoUnidad.value}>{tipoUnidad.label}</option>
                                                        })}
                                                    </Field>
                                                </div>
                                                {(embarqueData.datosEmbarqueMaritimo?.servicioMaritimo?.id != ID_SERVICIO_MARITIMO_P2D
                                                    || embarqueData.tipoServicio?.id != ID_SERVICIO_EMBARQUE_IMPORTACION) ?
                                                    <div className='col-sm-4 mb-3'>
                                                        <Field
                                                            name={`datosDesgloseMaritimo.detallesCargaMaritimo[${index}].fechaIngresoLleno`}
                                                            component={FormikGenericDate}
                                                            nameLabel="Ingreso de lleno" />
                                                    </div>
                                                    :
                                                    ""
                                                }

                                                {(embarqueData.datosEmbarqueMaritimo?.servicioMaritimo?.id == ID_SERVICIO_MARITIMO_D2D
                                                    || embarqueData.datosEmbarqueMaritimo?.servicioMaritimo?.id == ID_SERVICIO_MARITIMO_P2D)
                                                    && embarqueData.datosEmbarqueMaritimo?.modalidadMaritima == MODALIDAD_MARITIMA_FCL ?
                                                    <div className='col-sm-4 mb-3'>
                                                        <Field
                                                            name={`datosDesgloseMaritimo.detallesCargaMaritimo[${index}].fechaRetornoVacios`}
                                                            component={FormikGenericDate}
                                                            nameLabel="Retorno Vacios" />
                                                    </div>
                                                    :
                                                    <></>
                                                }
                                            </div>
                                            : null
                                        }
                                    </>
                                )
                            })}
                        </div>
                    </>)} />
        </>
    )
}

export default DetalleDeCargaMaritimo