import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isVisible: false,
    navegation: []
}


export const navbarSlice = createSlice({
    name: 'navbar',
    initialState,
    reducers: {
        changeVisible: (state, action) =>{
            state.isVisible = action.payload;
        },
        setNavegation: (state, action) =>{
            state.navegation = action.payload;
        }
    }
});

export const {changeVisible, setNavegation} = navbarSlice.actions;

export default navbarSlice.reducer;