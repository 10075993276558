import React, { useState } from 'react';
import * as ExcelJS from 'exceljs';
import CartaPorte from '../../../../../assets/templates/excel/CartaPorte.xlsx';
import CartaPorteMaritimo from '../../../../../assets/templates/excel/CartaPorte-Maritimo.xlsx'
import CartaPorteAereo from '../../../../../assets/templates/excel/CartaPorte-Aereo.xlsx'
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../../../../redux/features/loaderSlice';
import { ToastError, ToastWarning } from '../../../../../utils/toastMessage';
import { ID_TRAFICO_MARITIMO, ID_TRAFICO_AEREO, ID_SERVICIO_EMBARQUE_EXPORTACION } from '../../../../../utils/constantes';
import { isMaritimoAndIntermodal } from '../../validations/embarqueValidations';

function GenerarCartaPorte({ desglose }) {
    const [workbook, setWorkbook] = useState(null);
    const dispatch = useDispatch();
    const embarqueData = useSelector((state) => state.embarque.embarqueData);

    const esMaritimo = isMaritimoAndIntermodal(embarqueData)
    const esAereo = embarqueData.tipoTrafico?.id == ID_TRAFICO_AEREO;

    // Cargar el archivo Excel como dependencia
    useState(() => {
        fetch(esMaritimo ? CartaPorteMaritimo : esAereo ? CartaPorteAereo : CartaPorte)
            .then(res => res.arrayBuffer())
            .then(ab => {
                const wb = new ExcelJS.Workbook();
                wb.xlsx.load(ab).then(() => setWorkbook(wb));
            });
    }, []);

    const style = {
        font: { bold: true, name: 'Calibri' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'b4c6e7' }
        },
        border: {
            top: { style: 'thin', color: { argb: '202124' } },
            left: { style: 'thin', color: { argb: '202124' } },
            bottom: { style: 'thin', color: { argb: '202124' } },
            right: { style: 'thin', color: { argb: '202124' } }
        }
    };
    const styleContent = {
        font: { name: 'Calibri', size: 10 },
        border: {
            top: { style: 'thin', color: { argb: '202124' } },
            left: { style: 'thin', color: { argb: '202124' } },
            bottom: { style: 'thin', color: { argb: '202124' } },
            right: { style: 'thin', color: { argb: '202124' } }
        },
    };
    const aligmentCenter = { vertical: 'middle', horizontal: 'center', wrapText: true };

    async function handleGenerateCartaPorte(format) {
        dispatch(setLoading({ isLoading: true }))
        try {
            if (!workbook) {
                dispatch(setLoading({ isLoading: false }))
                return;
            }

            //Establecer informacion en celdas
            if (esMaritimo) {
                setCellsMaritimo(workbook);
            } else if (esAereo) {
                setCellsAereo(workbook);

            } else {
                setCellsTerrestre(workbook);
            }

            // Esperar a que workbook esté cargado antes de descargar el archivo
            if (format === "xlsx") {
                await new Promise((resolve, reject) => {
                    workbook.xlsx.writeBuffer().then(buffer => {
                        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        const url = URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = 'CartaPorte.xlsx';
                        a.click();
                        resolve();
                    }).catch(error => {
                        reject(error);
                    });
                });
                // Descargar el archivo PDF
            } else if (format === "pdf") {
                ToastWarning("Servicio en construccion, seleccione otra opcion.")
            }
            dispatch(setLoading({ isLoading: false }))
        } catch (err) {
            dispatch(setLoading({ isLoading: false }))
            return ToastError(err.message)
        }

    }

    function setCellsTerrestre(workbook) {
        const sheet = workbook.getWorksheet('CartaPorte');
        //Datos embarque
        sheet.getCell('B9').value = embarqueData.referenciaKJ
        sheet.getCell('D9').value = embarqueData.tipoTrafico.nombre
        sheet.getCell('F9').value = embarqueData.tipoServicio.nombre
        // Rellenar los datos en las celdas correspondientes
        sheet.getCell('A12').value = desglose.direccionRecoleccion?.razonSocial;
        sheet.getCell('B12').value = desglose.direccionRecoleccion?.rfc;
        sheet.getCell('C12').value = desglose.direccionRecoleccion?.direccion;
        sheet.getCell('D12').value = moment(desglose.fechaEstimadaRecoleccion).local().format('YYYY-MM-DD HH:mm');
        sheet.getCell('E12').value = desglose.poCliente;
        sheet.getCell('F12').value = desglose.instruccionesEspeciales;

        sheet.getCell('A16').value = desglose.direccionEntrega?.razonSocial;
        sheet.getCell('B16').value = desglose.direccionEntrega?.rfc;
        sheet.getCell('C16').value = desglose.direccionEntrega?.direccion;
        sheet.getCell('D16').value = moment(desglose.fechaEstimadaEntrega).local().format('YYYY-MM-DD HH:mm');
        sheet.getCell('F16').value = desglose.instruccionesEspeciales;
        //Detalles de carga
        sheet.getCell('B20').value = desglose.tipoUnidad?.nombre;
        sheet.getCell('B21').value = desglose.catalogoTipoCarga?.nombre;
        //Establecer los datos extra de acuerdo al tipo de carga
        setTipoCargaDetalle(sheet, 21);

        sheet.getCell('B22').value = desglose.tipoServicio?.nombre;

        sheet.getCell('A25').value = desglose.mercanciaTrasladar;
        const pesoUnitrio = parseFloat(desglose.pesoTotalBruto / desglose.cantidadTotalBultos).toFixed(2)
        sheet.getCell('E25').value = pesoUnitrio;
        sheet.getCell('C25').value = desglose.cantidadTotalBultos;
        sheet.getCell('F25').value = desglose.pesoTotalBruto;

        setDimensiones(sheet)

        return sheet;
    }

    function setCellsMaritimo(workbook) {
        const sheet = workbook.getWorksheet('CartaPorte');

        //Datos embarque
        sheet.getCell('B8').value = embarqueData.referenciaKJ
        sheet.getCell('D8').value = embarqueData.tipoTrafico.nombre
        sheet.getCell('F8').value = embarqueData.tipoServicio.nombre

        sheet.getCell('B9').value = embarqueData.datosEmbarqueMaritimo.puertoDeCarga
        sheet.getCell('D9').value = embarqueData.datosEmbarqueMaritimo.puertoDescarga
        sheet.getCell('F9').value = embarqueData.datosEmbarqueMaritimo.servicioMaritimo?.nombre

        sheet.getCell('B10').value = embarqueData.datosEmbarqueMaritimo.buque
        sheet.getCell('D10').value = embarqueData.datosEmbarqueMaritimo.viaje
        sheet.getCell('F10').value = embarqueData.datosEmbarqueMaritimo.modalidadMaritima

        sheet.getCell('B11').value = embarqueData.datosEmbarqueMaritimo.noDeBooking
        sheet.getCell('D11').value = embarqueData.datosEmbarqueMaritimo.noDeBL
        sheet.getCell('F11').value = embarqueData.datosEmbarqueMaritimo.naviera?.nombre

        sheet.getCell('B12').value = moment(embarqueData.datosEmbarqueMaritimo.fechaCierreDocumental).local().format('YYYY-MM-DD')
        sheet.getCell('D12').value = moment(embarqueData.datosEmbarqueMaritimo.fechaCierreFisico).local().format('YYYY-MM-DD')

        sheet.getCell('B13').value = moment(embarqueData.datosEmbarqueMaritimo.fechaETD).local().format('YYYY-MM-DD')
        sheet.getCell('D13').value = moment(embarqueData.datosEmbarqueMaritimo.fechaETA).local().format('YYYY-MM-DD')

        // Rellenar los datos en las celdas correspondientes
        sheet.getCell('A16').value = desglose.direccionRecoleccion?.razonSocial;
        sheet.getCell('B16').value = desglose.direccionRecoleccion?.rfc;
        sheet.getCell('C16').value = desglose.direccionRecoleccion?.direccion;
        sheet.getCell('D16').value = moment(desglose.fechaEstimadaRecoleccion).local().format('YYYY-MM-DD HH:mm');;
        sheet.getCell('E16').value = desglose.poCliente;
        sheet.getCell('F16').value = desglose.instruccionesEspeciales;

        sheet.getCell('A20').value = desglose.datosDesgloseMaritimo?.direccionDescargaDestinoFinal;
        sheet.getCell('B20').value = null;
        sheet.getCell('C20').value = desglose.datosDesgloseMaritimo?.direccionDescargaDestinoFinal;
        sheet.getCell('D20').value = moment(desglose.datosDesgloseMaritimo?.fechaEntregaEfectivaDestinoFinal).local().format('YYYY-MM-DD HH:mm');
        sheet.getCell('F20').value = desglose.instruccionesEspeciales;

        if (embarqueData.datosEmbarqueMaritimo?.modalidadMaritima === "LCL") {
            //Detalles de carga
            sheet.getCell('B23').value = desglose.catalogoTipoCarga?.nombre;

            sheet.getCell('A25').value = desglose.mercanciaTrasladar;
            const pesoUnitario = parseFloat(desglose.pesoTotalBruto / desglose.cantidadTotalBultos).toFixed(2)
            sheet.getCell('E25').value = pesoUnitario;
            sheet.getCell('C25').value = desglose.cantidadTotalBultos;
            sheet.getCell('F25').value = desglose.pesoTotalBruto;
            setDimensiones(sheet)
            setTipoCargaDetalle(sheet, 23)
        } else if (embarqueData.datosEmbarqueMaritimo?.modalidadMaritima === "FCL") {
            sheet.getCell('A23').value = "";
            let noCell = 25;
            sheet.getCell('H24').value = "CONTENEDOR";
            sheet.getCell('H24').style = style;
            sheet.getCell('H24').alignment = aligmentCenter;
            for (const detalleCarga of desglose.datosDesgloseMaritimo?.detallesCargaMaritimo) {
                sheet.getCell('A' + noCell).value = detalleCarga.mercanciaTrasladar;
                sheet.getCell('A' + noCell).style = styleContent;
                sheet.getCell('A' + noCell).alignment = aligmentCenter;

                sheet.getCell('B' + noCell).style = styleContent;

                sheet.getCell('C' + noCell).value = detalleCarga.cantidadTotalBultos;
                sheet.getCell('C' + noCell).style = styleContent;
                sheet.getCell('C' + noCell).alignment = aligmentCenter;

                sheet.getCell('D' + noCell).style = styleContent;

                sheet.getCell('E' + noCell).value = parseFloat(detalleCarga.pesoBruto / detalleCarga.cantidadTotalBultos).toFixed(2);
                sheet.getCell('E' + noCell).style = styleContent;
                sheet.getCell('E' + noCell).alignment = aligmentCenter;
                sheet.getCell('F' + noCell).value = detalleCarga.pesoBruto;
                sheet.getCell('F' + noCell).style = styleContent;
                sheet.getCell('F' + noCell).alignment = aligmentCenter;
                sheet.getCell('G' + noCell).style = styleContent;
                sheet.getCell('H' + noCell).value = detalleCarga.contenedorMaritimo?.nombre;
                sheet.getCell('H' + noCell).style = styleContent;
                sheet.getCell('H' + noCell).alignment = aligmentCenter;

                noCell++;
            }
        }


        return sheet;
    }

    function setCellsAereo(workbook) {
        const sheet = workbook.getWorksheet('CartaPorte');

        //Datos embarque
        sheet.getCell('B8').value = embarqueData.referenciaKJ
        sheet.getCell('D8').value = embarqueData.tipoTrafico.nombre
        sheet.getCell('F8').value = embarqueData.tipoServicio.nombre

        sheet.getCell('B9').value = embarqueData.datosEmbarqueAereo.aeropuertoDeCarga
        sheet.getCell('D9').value = embarqueData.datosEmbarqueAereo.aeropuertoDescarga
        sheet.getCell('F9').value = embarqueData.datosEmbarqueAereo.servicioAereo?.nombre

        sheet.getCell('B10').value = embarqueData.datosEmbarqueAereo.noDeVuelo
        sheet.getCell('D10').value = embarqueData.datosEmbarqueAereo.noDeGuia
        sheet.getCell('F10').value = embarqueData.datosEmbarqueAereo.catalogoAerolinea?.nombre

        sheet.getCell('B11').value = moment(embarqueData.datosEmbarqueAereo.fechaCierreDocumental).local().format('YYYY-MM-DD')
        sheet.getCell('D11').value = moment(embarqueData.datosEmbarqueAereo.fechaCierreFisico).local().format('YYYY-MM-DD')
        if (embarqueData.tipoServicio?.id != ID_SERVICIO_EMBARQUE_EXPORTACION) {
            sheet.getCell('E11').value = ""
        } else {
            sheet.getCell('F11').value = embarqueData.datosEmbarqueAereo.noDeBooking
        }

        sheet.getCell('B12').value = moment(embarqueData.datosEmbarqueAereo.fechaETD).local().format('YYYY-MM-DD')
        sheet.getCell('D12').value = moment(embarqueData.datosEmbarqueAereo.fechaETA).local().format('YYYY-MM-DD')

        // Rellenar los datos en las celdas correspondientes
        sheet.getCell('A16').value = desglose.direccionRecoleccion?.razonSocial;
        sheet.getCell('B16').value = desglose.direccionRecoleccion?.rfc;
        sheet.getCell('C16').value = desglose.direccionRecoleccion?.direccion;
        sheet.getCell('D16').value = moment(desglose.fechaEstimadaRecoleccion).local().format('YYYY-MM-DD HH:mm');
        sheet.getCell('E16').value = desglose.poCliente;
        sheet.getCell('F16').value = desglose.instruccionesEspeciales;

        sheet.getCell('A20').value = desglose.direccionEntrega?.razonSocial;
        sheet.getCell('B20').value = desglose.direccionEntrega?.rfc;
        sheet.getCell('C20').value = desglose.direccionEntrega?.direccion;
        sheet.getCell('D20').value = moment(desglose.fechaEstimadaEntrega).local().format('YYYY-MM-DD HH:mm');
        sheet.getCell('F20').value = desglose.instruccionesEspeciales;

        //Detalles de carga
        sheet.getCell('B23').value = desglose.catalogoTipoCarga?.nombre;

        sheet.getCell('A25').value = desglose.mercanciaTrasladar;
        const pesoUnitario = parseFloat(desglose.pesoTotalBruto / desglose.cantidadTotalBultos).toFixed(2)
        sheet.getCell('E25').value = pesoUnitario;
        sheet.getCell('C25').value = desglose.cantidadTotalBultos;
        sheet.getCell('F25').value = desglose.pesoTotalBruto;
        setDimensiones(sheet)
        setTipoCargaDetalle(sheet, 23)

        return sheet;
    }

    function setDimensiones(sheet) {
        //Si tiene dimensiones se pintan
        if (desglose.dimensionesCarga
            && desglose.dimensionesCarga.length > 0) {

            sheet.getCell('A27').value = "Dimensiónes de Carga";
            sheet.getCell('A27').style = style;
            sheet.getCell('A28').value = "Cantidad de Bultos";
            sheet.getCell('A28').style = style;
            sheet.getCell('B28').value = "Peso por Bulto";
            sheet.getCell('B28').style = style;
            sheet.getCell('C28').value = "Largo";
            sheet.getCell('C28').style = style;
            sheet.getCell('D28').value = "Alto";
            sheet.getCell('D28').style = style;
            sheet.getCell('E28').value = "Ancho";
            sheet.getCell('E28').style = style;
            sheet.getCell('F28').value = "Volumen";
            sheet.getCell('F28').style = style;
            let noCell = 29;

            for (const dimension of desglose.dimensionesCarga) {
                sheet.getCell('A' + noCell).value = dimension.cantidadBultos;
                sheet.getCell('A' + noCell).style = styleContent;
                sheet.getCell('B' + noCell).value = dimension.pesoPorBulto;
                sheet.getCell('B' + noCell).style = styleContent;
                sheet.getCell('C' + noCell).value = dimension.alto;
                sheet.getCell('C' + noCell).style = styleContent;
                sheet.getCell('D' + noCell).value = dimension.largo;
                sheet.getCell('D' + noCell).style = styleContent;
                sheet.getCell('E' + noCell).value = dimension.ancho;
                sheet.getCell('E' + noCell).style = styleContent;
                sheet.getCell('F' + noCell).value = dimension.volumen;
                sheet.getCell('F' + noCell).style = styleContent;
                noCell++;
            }
        }
    }

    function setTipoCargaDetalle(sheet, row) {
        if (desglose.catalogoTipoCarga?.id == 3) {
            sheet.getCell('C' + row).value = "Temperatura:" + desglose.tipoCargaDetalle?.refrigeradaTemperatura;
            sheet.getCell('D' + row).value = "Humedad:" + desglose.tipoCargaDetalle?.refrigeradaHumedad;
            sheet.getCell('E' + row).value = "Ventilación:" + desglose.tipoCargaDetalle?.refrigeradaVentilacion;
        } else if (desglose.catalogoTipoCarga?.id == 2) {
            sheet.getCell('C' + row).value = "No. UN:" + desglose.tipoCargaDetalle?.noUn;
        }
    }

    return (
        <span className="dropdown me-2">
            <button className="btn btn-dark dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                Carta Porte
            </button>
            <ul className="dropdown-menu">
                <li>
                    <button type='button' className='dropdown-item'
                        onClick={() => handleGenerateCartaPorte('xlsx')}>
                        <i className="bi bi-file-earmark-spreadsheet"></i>EXCEL
                    </button>
                </li>
                <li>
                    <button type='button' className='dropdown-item'
                        onClick={() => handleGenerateCartaPorte('pdf')}>
                        <i className="bi bi-file-earmark-pdf"></i> PDF
                    </button>
                </li>
            </ul>
        </span>
    )
}

export default GenerarCartaPorte;