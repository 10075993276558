import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import PreviewPDF from "../react-pdf-utils/PreviewPDF";

function ModalFacturaClientes({ archivos }) {
  const [open, setOpen] = useState(false);

  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = async () => {
    setOpen(false);
  };

  return (
    <div>
      <div align="center">
        <button className="btn btn-primary" onClick={handleOpen}>
          <i className="bi bi-file-text"></i>
        </button>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="card style-modal-md">
          <div className="card-header d-flex justify-content-between">
            FACTURAS 
            <button className="btn btn-dark btn-sm" onClick={handleClose}>
              <i className="bi bi-x-lg"></i>
            </button>
          </div>
          <div className="card-body body-modal-scroll">
            <div className="row align-items-center mx-auto mt-4">
              {archivos.map((archivo) => (
                <div className="col-md-4 col-sm-6 col-12 mb-2">
                  {archivo.archivoFactura && <PreviewPDF archivo={archivo} />}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ModalFacturaClientes;
