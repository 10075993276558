import React, { useState } from 'react'
import Modal from '@mui/material/Modal';
import {BASE_URL_S3_COMPROBANTES_PROVEEDORES } from '../../../../utils/constantes';
import ModalArchivo from '../../../ModalArchivo';
import Table from 'react-bootstrap/Table';
import { fidnAllComprobantesProveedor } from '../../../../controllers/proveedor/comprobantePagoController';
import { validatePrint } from '../../../admin/facturas/validations/validationComprobantes';
import FechaGeneric from '../../../inputs/FechaGeneric';

function ModalComprobantesProveedor({ idFacturaProveedor }) {
  const [open, setOpen] = useState(false);
  const [comprobantes, setComprobantes] = useState([]);

  const handleOpen = () => {
    fetchComprobantes()
    setOpen(true)
  };

  const fetchComprobantes = async () => {
    const data = await fidnAllComprobantesProveedor(idFacturaProveedor);
    setComprobantes(data)
  }

  const handleClose = async () => {
    setOpen(false);
  }


  return (
    <div>
      <div align="center">
        <button className='btn btn-primary' onClick={handleOpen}>
          Comprobantes de Pago
        </button>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className='card style-modal-md'>
          <div className='card-header d-flex justify-content-between'>
            COMPROBANTES DE PAGO
            <button className='btn btn-dark btn-sm'
              onClick={handleClose}>
              <i className="bi bi-x-lg"></i>
            </button>
          </div>
          <div className='card-body body-modal-scroll'>
            <div style={{ backgroundColor: "white" }}>
              <Table responsive>
                <thead>
                  <tr>
                    <th>ARCHIVO</th>
                    <th>ANTICIPO</th>
                    <th>DESCRIPCIÓN</th>
                    <th>FECHA PAGO</th>
                  </tr>
                </thead>
                <tbody>
                  {comprobantes ? comprobantes.map((comprobante, indexComprobante) => {
                    return (
                      <tr>
                        <td>
                          <div align="center">
                            <ModalArchivo
                              title={"Comprobante de Pago"}
                              urlBase={BASE_URL_S3_COMPROBANTES_PROVEEDORES}
                              archivoS3={comprobante.archivo}></ModalArchivo>
                          </div>
                        </td>
                        <td align='center'>
                          <input
                            className="form-check-input mt-1"
                            type="checkbox"
                            disabled={true}
                            checked={comprobante.esAnticipo}
                            name="esAnticipo"
                          />
                        </td>
                        <td>
                          <textarea className="form-control mt-1"
                            type="text"
                            disabled={true}
                            value={comprobante.descripcion}
                            name="descripcion"></textarea>
                        </td>
                        <td style={{ minWidth: "220px" }}>
                          <FechaGeneric
                            fecha={comprobante.fechaPagoRealizado}
                            index={indexComprobante}
                            nameInput={"fechaPagoRealizado"}
                            disabled={true}>
                          </FechaGeneric>
                        </td>
                      </tr>
                    )
                  }) : ""}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ModalComprobantesProveedor