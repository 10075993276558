import React from 'react'
import ModalArchivo from '../../../ModalArchivo'
import { BASE_URL_S3_FACTURAS_PROVEEDORES, ESTATUS_FP_COMPLETADO, ID_ESTATUS_FP_FACTURA_RECHAZADA } from '../../../../utils/constantes'
import ToolTipMui from '../../../mui/ToolTipMui';
import TooltipEstatusFacturaProveedor from '../../../admin/facturas/proveedor/TooltipEstatusFacturaProveedor';
import { OPTIONS_MUI_ONLY_TABLE, OPTIONS_CUSTOM_RETURN_COLUMN } from '../../../../utils/mui/constantes';
import MUIDataTable from "mui-datatables";
import { deleteItem, handleChangeArrayText } from '../../../../utils/helpersState';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../../redux/features/loaderSlice';
import { ToastError, ToastSuccess, ToastWarning } from '../../../../utils/toastMessage';
import { eliminadoLogicoFacturaProveedor, guardarFacturaProveedorPorIdCosto, putFacturaProveedor } from '../../../../controllers/admin/facturas/facturaProveedorController';
import { formatMoney } from '../../../../utils/formatMoney';
import ModalEliminarObjeto from '../../../admin/ModalEliminarObjeto';
import { validatePrintProveedor } from '../../../admin/facturas/validations/validationsFacturaProveedor';
import CustomFileUploader from '../../../inputs/CustomFileUploader';
import ModalComprobantesProveedor from './ModalComprobantesProveedor';

function TableFacturas({
    costo,
    facturas,
    setFacturas,
    fetchBuscarPorIdCostoProveedor,
    fetctListarCostosDeProveedor }) {
    const dispatch = useDispatch();

    const handleChangeFile = async (indexFP, file, nameArchivo) => {
        let e = {
            target: {
                name: nameArchivo,
                value: file
            }
        }
        handleChangeArrayText(e, indexFP, facturas, setFacturas)
    };

    const fetchProcesarFactura = async (facturaProveedor, indexFP) => {
        try {
            if (facturaProveedor == null && (!facturas[indexFP].archivoPDFASubir || !facturas[indexFP].archivoXMLASubir)) return ToastWarning("Los archivos de la factura (PDF y XML) son necesarios.")
            if (facturaProveedor.fechaPagoRealizado === null) return ToastWarning("El comprobante necesita ser enviado con la fecha de pago")
            dispatch(setLoading({ isLoading: true }))

            let res;
            if (facturaProveedor.id) {
                res = await putFacturaProveedor(
                    facturaProveedor,
                    facturas[indexFP].archivoPDFASubir,
                    facturas[indexFP].archivoXMLASubir)
            } else {
                res = await guardarFacturaProveedorPorIdCosto(
                    costo.id,
                    facturaProveedor,
                    facturas[indexFP].archivoPDFASubir,
                    facturas[indexFP].archivoXMLASubir)
            }

            dispatch(setLoading({ isLoading: false }))
            if (res.error) return ToastError(res.message)
            ToastSuccess(res.message)
            //Recargar facturas
            fetchBuscarPorIdCostoProveedor()
            //Recargar Costos
            fetctListarCostosDeProveedor()
        } catch (e) {
            dispatch(setLoading({ isLoading: false }))
            ToastError(e.message)
        }
    }

    const fetcheliminadoLogicoFacturaProveedor = async (idFacturaProveedor, index1) => {
        try {
            if (!facturas[index1].razonEliminacion) return ToastWarning("Necesitas especificar el porqué estás eliminando la factura.");

            dispatch(setLoading({ isLoading: true }))
            const res = await eliminadoLogicoFacturaProveedor(idFacturaProveedor, facturas[index1].razonEliminacion)
            dispatch(setLoading({ isLoading: false }))
            if (res.error) return ToastError(res.message)
            ToastSuccess(res.message)
            fetchBuscarPorIdCostoProveedor()
        } catch (e) {
            return ToastError(e.message)
        }
    }

    const columnsFacturasProovedor = [
        {
            name: "Costo Neto",
            options: {
                customBodyRenderLite: (indexFP) => {
                    return (
                        <div align="center" style={{ minWidth: "50px" }}>
                            <p>{formatMoney(facturas[indexFP].costoNeto)}</p>
                        </div>
                    )
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            }
        },
        {
            name: "Factura",
            options: {
                customBodyRenderLite: (indexFP) => {
                    return (
                        <div align="center" className='pb-2' style={{ minWidth: "200px", maxWidth: "400px" }}>
                            {facturas[indexFP].archivoFactura ?
                                <div>
                                    <ModalArchivo
                                        title={"Factura"}
                                        urlBase={BASE_URL_S3_FACTURAS_PROVEEDORES}
                                        archivoS3={facturas[indexFP].archivoFactura}></ModalArchivo>
                                </div>
                                : ""}
                            {!facturas[indexFP].estaEliminado
                                && validatePrintProveedor(facturas[indexFP], false) ?
                                <CustomFileUploader
                                    handleChangeFile={handleChangeFile}
                                    name={"archivoPDFASubir"}
                                    fileTypes={["PDF"]}
                                    index={indexFP}
                                    nameFile={facturas[indexFP].archivoPDFASubir?.name}></CustomFileUploader>
                                :
                                ""
                            }
                        </div>
                    )
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            }
        },
        {
            name: "Factura XML",
            options: {
                customBodyRenderLite: (indexFP) => {
                    const serie = facturas[indexFP].serie ? facturas[indexFP].serie : "";
                    const folio = facturas[indexFP].folio ? facturas[indexFP].folio : "";
                    const uuid = facturas[indexFP].uuid ? facturas[indexFP].uuid : "";
                    return (
                        <div align="center" className='pb-2' style={{ minWidth: "200px", maxWidth: "400px" }}>
                            {facturas[indexFP].archivoFacturaXML ?
                                <div>
                                    <button
                                        type='button'
                                        className='btn btn-dark'
                                        onClick={() => saveAs(BASE_URL_S3_FACTURAS_PROVEEDORES + facturas[indexFP].archivoFacturaXML.keyS3, facturas[indexFP].archivoFacturaXML.keyS3)}>
                                        <i className="bi bi-file-earmark-arrow-down"></i>
                                    </button>
                                    <ToolTipMui
                                        title={
                                            <div style={{ fontSize: "12px" }}>
                                                <b>Serie: </b> {serie}<br />
                                                <b>Folio: </b> {folio}<br />
                                                <b>Folio Fiscal (uuid): </b> {uuid}
                                            </div>}>
                                    </ToolTipMui>
                                    <br />
                                </div>

                                : ""}

                            {!facturas[indexFP].estaEliminado
                                && validatePrintProveedor(facturas[indexFP], false) ?
                                <CustomFileUploader
                                    handleChangeFile={handleChangeFile}
                                    name={"archivoXMLASubir"}
                                    fileTypes={["XML"]}
                                    index={indexFP}
                                    nameFile={facturas[indexFP].archivoXMLASubir?.name}></CustomFileUploader>
                                :
                                ""
                            }
                        </div>
                    )
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            }
        },
        {
            name: "Comprobante",
            options: {
                customBodyRenderLite: (indexFP) => {
                    return (
                        <>
                            {facturas[indexFP].id
                                && !facturas[indexFP].estaEliminado
                                && facturas[indexFP].estatus?.id != ID_ESTATUS_FP_FACTURA_RECHAZADA ?
                                <ModalComprobantesProveedor
                                    idFacturaProveedor={facturas[indexFP].id}
                                    fetchFacturaProveedor={fetchBuscarPorIdCostoProveedor}></ModalComprobantesProveedor>
                                :
                                <></>
                            }
                        </>
                    )
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            }
        },
        {
            name: "",
            options: {
                customBodyRenderLite: (indexFP) => {
                    return (
                        <>
                            <TooltipEstatusFacturaProveedor
                                facturaProveedor={facturas[indexFP]}>
                            </TooltipEstatusFacturaProveedor>
                            {facturas[indexFP].metodoPago == "PPD" ?
                                !facturas[indexFP].facturaTieneComplentoPago ?
                                    <span className="badge bg-warning">PENDIENTE COMPLEMENTO PAGO</span>
                                    : ""
                                : ""
                            }
                        </>
                    )
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            }
        },
        {
            name: "",
            options: {
                customBodyRenderLite: (indexFP) => {
                    return (
                        <>
                            {!facturas[indexFP].estaEliminado
                                && validatePrintProveedor(facturas[indexFP], false) ?
                                <button type='button' className='btn btn-primary'
                                    onClick={() => fetchProcesarFactura(facturas[indexFP], indexFP)}>
                                    {facturas[indexFP].id ?
                                        "Actualizar" : "Guardar"}
                                </button>
                                :
                                ""
                            }
                        </>
                    )
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            }
        }
        ,
        {
            name: "",
            options: {
                customBodyRenderLite: (indexFP) => {
                    return (
                        <>
                            {!facturas[indexFP].estaEliminado
                                && validatePrintProveedor(facturas[indexFP], false)
                                && facturas[indexFP].estatus?.id != ESTATUS_FP_COMPLETADO ?
                                <>
                                    {
                                        facturas[indexFP].id ?
                                            <ModalEliminarObjeto
                                                textHeader={"¿Seguro de eliminar esta factura?"}
                                                textBody={"Al eliminar la factura tendras que solicitar al administrador/operativo que vuelva a establecer el monto de la provision."}
                                                functionEliminar={fetcheliminadoLogicoFacturaProveedor}
                                                id={facturas[indexFP].id}
                                                index1={indexFP}>
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    placeholder='Razón de la eliminación'
                                                    name='razonEliminacion'
                                                    value={facturas[indexFP].razonEliminacion}
                                                    onChange={(e) => { handleChangeArrayText(e, indexFP, facturas, setFacturas, true) }} />
                                            </ModalEliminarObjeto>
                                            :
                                            <button type='button' className='btn btn-danger'
                                                onClick={() => deleteItem(indexFP, facturas, setFacturas)}>
                                                <i className="bi bi-trash"></i>
                                            </button>
                                    }
                                </>
                                :
                                ""
                            }
                        </>
                    )
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            }
        }
    ]

    return (
        <MUIDataTable
            title={""}
            data={facturas}
            columns={columnsFacturasProovedor}
            options={{
                ...OPTIONS_MUI_ONLY_TABLE,
                responsive: "standard",
                pagination: false,
                setRowProps: (row, dataIndex) => {
                    if (facturas[dataIndex].estaEliminado) {
                        return {
                            style: { backgroundColor: "#f98e8e78" }
                        };
                    } else if (facturas[dataIndex].guardoProveedor) {
                        return {
                            style: { backgroundColor: "#9ca8b27c" }
                        };
                    }
                }
            }}
        />
    )
}

export default TableFacturas