import React, { useEffect, useState } from 'react'
import MUIDataTable from "mui-datatables";
import ModalEmbarques from './ModalEmbarques';
import Popover from '@mui/material/Popover';
import LineaTiempoEstatus from './estatus/LineaTiempoEstatus';
import { useDispatch } from 'react-redux';
import { fetchEstatusEmbarque } from '../../../redux/features/admin/estatusEmbarqueSlice';
import { updateParamsEmbarque, updateSearchValue } from '../../../redux/features/admin/filtrosBusquedas';
import { BASIC_OPTIONS_MUI } from '../../../utils/mui/constantes';
import ToolTipMui from '../../mui/ToolTipMui';
import { useSelector } from 'react-redux';

function TableEmbarques({ embarques, setEmbarques, handleEmbarqueSearch }) {
    const dispatch = useDispatch()
    const [dataMuiEmbarques, setDataMuiEmbarques] = useState({
        data: [],
        count: 10,
        page: 0,
        rowsPerPage: 10
    });
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [textSearch, setTextSearch] = useState('');
    const fastSearch = useSelector((state) => state.filtrosBusquedas.fastSearch);
    const filtersShipment = useSelector((state) => state.filtrosBusquedas.filters);

    const handlePopoverOpen = (event, idEmbarque) => {
        setAnchorEl(event.currentTarget);
        dispatch(fetchEstatusEmbarque(idEmbarque))
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    useEffect(() => {
        establecerData()
    }, [embarques]);

    const establecerData = async () => {
        if (embarques && embarques.content) {
            const data = embarques.content.map(embarque => [
                embarque.id,
                embarque.referenciaKJ,
                embarque.cliente.nombreComercial ? embarque.cliente.nombreComercial : embarque.cliente.nombre + " " + embarque.cliente.primerApellido + " " + embarque.cliente.segundoApellido,
                embarque.origen?.nombre + ` (${embarque.origen?.portCode})`,
                embarque.destino?.nombre + ` (${embarque.destino?.portCode})`,
                embarque.tipoServicio.nombre,
                embarque.tipoTrafico.nombre,
                embarque.totalContainers,
                embarque.estatusNoCompletado ? embarque.estatusNoCompletado.estatus : null,
                embarque.usuarioRegistro.nombre + " " + embarque.usuarioRegistro.primerApellido + " " + embarque.usuarioRegistro.segundoApellido,
                embarque.embarqueFinalizado,
                embarque.totalVenta,
                embarque.totalCostos,
                embarque.serieFolio,
                embarque.estaCancelado,
                embarque.razonCancelamiento,
                embarque.totalMissingDates,
                embarque.missingDates
            ]);
            setDataMuiEmbarques({
                data: data,
                count: embarques.totalElements,
                page: embarques.pageable?.pageNumber,
                rowsPerPage: embarques.pageable?.pageSize,
            });
        }
    }

    const handleChangeRowsPerPage = async (pageSize) => {
        await handleEmbarqueSearch(0, pageSize, false, null, fastSearch.searchValue ? [fastSearch] : null);
    };

    const handleOnChangePage = async (newPage) => {
        await handleEmbarqueSearch(newPage, dataMuiEmbarques.rowsPerPage, false, null, fastSearch.searchValue ? [fastSearch] : null);
    }

    const handleChangeSearch = async (hideSearch, cancelarSearch) => {
        if (cancelarSearch) {
            hideSearch()
            setTextSearch('')
            dispatch(updateParamsEmbarque(null))
            dispatch(updateSearchValue(null))
        }
        await handleEmbarqueSearch(0, 10, cancelarSearch, null, fastSearch.searchValue ? [fastSearch] : null)
    }

    const handleTextSearch = async (textSearch) => {
        setTextSearch(textSearch);
        dispatch(updateSearchValue(textSearch))
    }

    const columns = [
        {
            name: "Id",
            options: {
                display: "excluded",
                filter: false,
                sort: false,
                download: false,
            }
        },
        {
            name: "Referencia",
            options: {
                filter: false
            }
        },
        "Cliente",
        "Origen",
        "Destino",
        "Servicio",
        "Trafico",
        {
            name: "Contenedores",
            options: {
                customBodyRender: (value) => {
                    return (
                        <div align="center">
                            {value >= 1 ? value : ""}
                        </div>
                    );
                },
            },
        },
        {
            name: "ESTATUS",
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <div className='centerContentRow'>
                            {dataMuiEmbarques.data[dataIndex][14] ?
                                <span className='badge text-bg-danger'>
                                    <ToolTipMui
                                        title={dataMuiEmbarques.data[dataIndex][15]}
                                        ></ToolTipMui>
                                    CANCELADO
                                </span>
                                :
                                dataMuiEmbarques.data[dataIndex][10] ?
                                
                                    <span className="badge text-bg-primary"
                                        aria-owns={open ? 'mouse-over-popover' : undefined}
                                        onMouseEnter={(event) => handlePopoverOpen(event, dataMuiEmbarques.data[dataIndex][0])}
                                        onMouseLeave={handlePopoverClose}>
                                        FINALIZADO
                                    </span>
                                    :
                                    <>
                                        {//Validate alert missing dates
                                            dataMuiEmbarques.data[dataIndex][16] > 0 ?
                                                <ToolTipMui
                                                    title={
                                                        <div style={{ fontSize: "12px" }}>
                                                            <br />
                                                            {dataMuiEmbarques.data[dataIndex][17]?.split('\n').map((line, index) => (
                                                                <React.Fragment key={index}>
                                                                    {line}
                                                                    <br />
                                                                </React.Fragment>
                                                            ))}
                                                        </div>}
                                                    customIcon={<i className="bi bi-exclamation-triangle-fill" style={{color: '#f4b400'}}></i>}>
                                                </ToolTipMui>
                                                : ""
                                        }
                                        <span className="badge text-bg-warning"
                                            aria-owns={open ? 'mouse-over-popover' : undefined}
                                            onMouseEnter={(event) => handlePopoverOpen(event, dataMuiEmbarques.data[dataIndex][0])}
                                            onMouseLeave={handlePopoverClose}
                                            style={{
                                                display: 'inline-block',
                                                maxWidth: '100px', // ajusta el valor según tus necesidades
                                                whiteSpace: 'pre-wrap' // o 'normal'
                                            }}>

                                            {dataMuiEmbarques.data[dataIndex][8]}
                                        </span>
                                        <Popover
                                            id="mouse-over-popover"
                                            sx={{
                                                pointerEvents: 'none',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    cursor: 'pointer',
                                                },
                                            }}
                                            open={open}
                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                                vertical: 'center',
                                                horizontal: 'left',
                                            }}
                                            transformOrigin={{
                                                vertical: 'center',
                                                horizontal: 'right',
                                            }}
                                            onClose={handlePopoverClose}
                                            disableRestoreFocus
                                        >
                                            <LineaTiempoEstatus
                                                isPopover={true}
                                                showTimeline={true}></LineaTiempoEstatus>
                                        </Popover>
                                    </>
                            }
                        </div>

                    )
                },
                sort: false
            }
        },
        "Usuario",
        {
            name: "embarqueFinalizado",
            options: {
                display: "excluded",
                filter: false,
                sort: false,
                download: false,
            }
        },
        {
            name: "totalVenta",
            options: {
                display: "excluded",
                filter: false,
                sort: false,
                download: false,
            }
        },
        {
            name: "totalCostos",
            options: {
                display: "excluded",
                filter: false,
                sort: false,
                download: false,
            }
        },
        {
            name: "SERIE FOLIO",
            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRender: (value) => {
                    return <div style={{ whiteSpace: 'pre-wrap' }}>{value}</div>;
                },
            },
        },
        {
            name: "",
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <ModalEmbarques
                            idEmbarque={dataMuiEmbarques.data[dataIndex][0]}
                            setEmbarques={setEmbarques}
                            indexEmbarque={dataIndex}>
                            <i className="bi bi-folder-fill"></i>
                        </ModalEmbarques>
                    )
                },
                filter: false,
                sort: false,
                download: false,
            }
        }];

    return (
        <MUIDataTable
            title={"Embarques"}
            data={dataMuiEmbarques.data}
            columns={columns}
            options={{
                ...BASIC_OPTIONS_MUI,
                filter: false,
                downloadOptions: { filename: "embarques.csv", separator: "," },
                onChangePage: handleOnChangePage,
                onChangeRowsPerPage: handleChangeRowsPerPage,
                rowsPerPageOptions: [5, 10, 50, 100, 1000],
                count: dataMuiEmbarques.count,
                page: dataMuiEmbarques.page,
                rowsPerPage: dataMuiEmbarques?.rowsPerPage,
                serverSide: true,
                responsive: "standard",
                customSearchRender: (searchText, handleSearch, hideSearch, options) => (
                    <div className="input-group input-group-sm">
                        <input
                            type="text"
                            className="form-control "
                            value={textSearch}
                            onChange={(event) => handleTextSearch(event.target.value)}
                            placeholder="Buscar..."
                            disabled={filtersShipment}
                        />
                        <button onClick={() => handleChangeSearch(hideSearch, false)} className='btn btn-primary btn-sm' disabled={filtersShipment}><i className="bi bi-search"></i></button>
                        <button onClick={() => { handleChangeSearch(hideSearch, true) }} className='btn btn-danger btn-sm' disabled={filtersShipment}><i className="bi bi-x"></i></button>
                    </div>
                ),
                setRowProps: row => {
                    if (row[11] < row[12]) {
                        return {
                            style: { backgroundColor: "#f98e8e78" }
                        };
                    }
                }
            }}
        />
    )
}

export default TableEmbarques