import { CP_KJ, DATA_CLAUDIA, DATA_JOSIMAR, DATA_KARLA, PAGINA_WEB_KJ, REGIMEN_FISCAL_KJ } from "../datosKJ";
import { getImageBase64 } from "./helpersImage";

export const getHeaderReport = async (sheet, workbook) => {

    // Agregar la imagen
    const logoImage = await getImageBase64('https://kjlogistics.s3.us-west-1.amazonaws.com/logo.png');
    const logoId = workbook.addImage({
        base64: logoImage,
        extension: 'png',
    });
    sheet.addImage(logoId, 'A1:C5');

    sheet.getCell('D2').value = { richText: [{ text: 'KLO210317KN1', font: { bold: true } }] };
    sheet.getCell('D3').value = { richText: [{ text: 'KARLA-JOSIMAR LOGISTICS', font: { bold: true } }] };
    sheet.getCell('D4').value = {
        richText: [
            { text: 'Régimen fiscal: ', font: { bold: true } },
            { text: `${REGIMEN_FISCAL_KJ}`, font: { bold: false } }
        ]
    };
    sheet.getCell('D5').value = { richText: [{ text: `${CP_KJ}`, font: { bold: true } }] };

    sheet.getCell('G2').value = {
        richText: [
            { text: 'Gerente de Aduanas: ', font: { bold: true } },
            { text: `${DATA_JOSIMAR}`, font: { bold: false } }
        ]
    };

    sheet.getCell('G3').value = {
        richText: [
            { text: 'Gerente de Logística: ', font: { bold: true } },
            { text: `${DATA_CLAUDIA}`, font: { bold: false } }
        ]
    };
    sheet.getCell('G4').value = `${PAGINA_WEB_KJ}`;

}