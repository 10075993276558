import React, { useEffect, useState } from 'react'
import MUIDataTable from "mui-datatables";
import { ID_ROL_PROVEEDORES } from '../../../utils/constantes';
import ModalFormUsuarios from '../ModalFormUsuarios';
import { BASIC_OPTIONS_MUI, OPTIONS_CUSTOM_RETURN_COLUMN } from '../../../utils/mui/constantes';
import ModalAdminEmails from '../ModalAdminEmails';

function TableProveedores({ proveedores, fetchDataUsuarios }) {
  const [formatData, setFormatData] = useState([])

  useEffect(() => {
    setFormatData(
      proveedores.map(proveedor => [
        proveedor.id,
        proveedor.nombreComercial,
        proveedor.razonSocial,
        proveedor.rfc,
        proveedor.diasDeCredito,
        proveedor.creadoEn
      ])
    );
  }, [proveedores]);

  const columns = [
    {
      name: "Id",
      options: {
        display: "excluded",
        filter: false,
        sort: false,
        download: false,
      }
    },
    "Nombre",
    "Razón Social",
    "RFC",
    "Días de credito",
    "Fecha Creación",
    {
      name: "CORREOS ELECTRÓNICOS",
      options: {
        ...OPTIONS_CUSTOM_RETURN_COLUMN,
        customBodyRenderLite: (dataIndex) => {
          return (
            <ModalAdminEmails
              usuario={proveedores[dataIndex]}></ModalAdminEmails>
          )
        },
      }
    },
    {
      name: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ModalFormUsuarios
              url={"/guardar-cliente"}
              idUsuario={tableMeta.rowData[0]}
              idRol={ID_ROL_PROVEEDORES}
              fetchDataUsuarios={fetchDataUsuarios}>
              <i className="bi bi-pencil"></i>
            </ModalFormUsuarios>
          )
        },
        ...OPTIONS_CUSTOM_RETURN_COLUMN
      },
    },
  ]


  return (
    <MUIDataTable
      title={"Proveedores"}
      data={formatData}
      columns={columns}
      options={{
        ...BASIC_OPTIONS_MUI,
        responsive: "standard"
      }}
    />
  )
}

export default TableProveedores