import React, { useEffect, useState } from 'react'
import SelectSearch from '../../../inputs/SelectSearch';
import { generarCatalogo } from '../../../../controllers/admin/embarques/catalogos/catalogoBuscadorController';
import { ToastError } from '../../../../utils/toastMessage';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { createFilterFastSearch, updateFiltersEmbarque, updateParamsEmbarque } from '../../../../redux/features/admin/filtrosBusquedas';
import FechaGeneric from '../../../inputs/FechaGeneric';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CATALOGO_OPERATORS_FILTERS_EMBARQUE } from '../../../../utils/catalogosEstaticos';
import { useSelector } from 'react-redux';
import { generarReporteEmbarque } from '../../reportes/generators/reportesEmbarque';
import {findAllByFilters, findAllToReport} from '../../../../controllers/admin/embarques/embarquesController';
import ModalFilterExport from './ModalFilterExport';

function BuscadorAvanzado({ handleEmbarqueSearch }) {
    const [filtros, setFiltros] = useState([]);
    const [catalogo, setCatalogo] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [open, setOpen] = useState(false);
    const [orderRadioValue, setOrderRadioValue] = useState("DESC");
    const [attributeOrder, setAttributeOrder] = useState("e.creado_en");
    const dispatch = useDispatch()
    const fastSearch = useSelector((state) => state.filtrosBusquedas.fastSearch);

    const handleOpen = () => {
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false);
    }

    useEffect(() => {
        fetchGenerarCatalogo()
    }, [])

    const agregarFiltro = () => {
        const nuevosFiltros = [...filtros];
        nuevosFiltros.push({ tipoFiltro: {}, operator: " OR " })
        setFiltros(nuevosFiltros);
    }

    const quitarFiltro = (indexFiltro) => {
        const nuevosFiltros = [...filtros];
        nuevosFiltros.splice(indexFiltro, 1)
        setFiltros(nuevosFiltros)
    }

    const fetchGenerarCatalogo = async () => {
        const res = await generarCatalogo();
        if (res.error) return ToastError(res.message);
        dispatch(createFilterFastSearch(res.data[0]))
        setCatalogo(res.data)
    }

    const handleChange = (e, indexFiltro) => {
        const { name, value } = e.target;
        const newFiltros = [...filtros]
        if (name == "tipoFiltro") newFiltros[indexFiltro].searchValue = null;
        newFiltros[indexFiltro] = { ...newFiltros[indexFiltro], [name]: value }
        setFiltros(newFiltros)
    }

    const handleDateFilters = (value, name, indexFiltro) => {
        if (value) value = moment(value).utc().format('YYYY-MM-DD')
        let e = {
            target: {
                name: name,
                value: value
            }
        }
        handleChange(e, indexFiltro);
    }

    const aplicarFiltros = async () => {
        const params = getParams();
        if (params) {
            dispatch(updateParamsEmbarque(params.toString()));
            dispatch(updateFiltersEmbarque(filtros));
            handleEmbarqueSearch(0, 10, false, params.toString(), filtros);
            setOpen(false)
        }
    }

    const limpiarFiltros = async () => {
        setFiltros([])
        setEndDate(null)
        setStartDate(null)
        dispatch(updateParamsEmbarque(null));
        dispatch(updateFiltersEmbarque(null));
        handleEmbarqueSearch(0, 10, true, null);
        setOpen(false)
    }

    const getParams = () => {
        const params = new URLSearchParams();

        params.set('typeOrder', orderRadioValue);
        params.set('attributeOrder', attributeOrder);

        if (startDate != null) params.set('startDate', startDate);
        if (endDate != null) params.set('endDate', endDate);
        return params;
    };

    const checkRadio = (e) => {
        const { value } = e.target;
        setOrderRadioValue(value);
    }

    const handleChangeSelectOrder = (e) => {
        const { value } = e.target;
        setAttributeOrder(value)
    }

    return (
        <>
            <button type="button" className="btn btn-dark position-relative"
                onClick={handleOpen} disabled={fastSearch.searchValue}>
                <i className="bi bi-filter"></i>BUSQUEDA AVANZADA
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {filtros.length}
                </span>
            </button>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='card style-modal-sm'>
                    <div className='card-header d-flex justify-content-between'>
                        BUSCADOR AVANZADO
                        <button className='btn btn-dark btn-sm'
                            onClick={handleClose}>
                            <i className="bi bi-x-lg"></i>
                        </button>
                    </div>
                    <div className='card-body body-modal-scroll'>

                        <div className='m-3'>
                            <div className='col-12' align="right">
                                <button type='button' className='btn btn-dark m-2'
                                    onClick={() => agregarFiltro()}>
                                    <i className="bi bi-filter"></i>AGREGAR FILTRO
                                </button>

                                <ModalFilterExport filtros={filtros} getParams={getParams}/>

                                <div className='row'>
                                    <div className='col-12 mt-4'>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            value={orderRadioValue}
                                            onChange={checkRadio}
                                            style={{ justifyContent: 'right' }}
                                        >
                                            <FormControlLabel value="DESC" control={<Radio />} label="Descendente" />
                                            <FormControlLabel value="ASC" control={<Radio />} label="Ascendente" />
                                            <SelectSearch
                                                data={[
                                                    { label: "Fecha Creación Embarque", value: "e.creado_en" },
                                                ]}
                                                name={"tipoFiltro"}
                                                handleChange={handleChangeSelectOrder}
                                                value={attributeOrder}
                                            ></SelectSearch>
                                        </RadioGroup>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12'>
                                {filtros.map((filtro, index) => {
                                    return (
                                        <div key={index} className='row align-items-end g-0'>
                                            {index !== 0 ?
                                                <div className='col-2'>
                                                    <SelectSearch
                                                        data={CATALOGO_OPERATORS_FILTERS_EMBARQUE}
                                                        name={"operator"}
                                                        handleChange={handleChange}
                                                        index={index}
                                                        value={filtro.operator}
                                                        isDisabled={index === 0}
                                                        returnObject={false}
                                                    ></SelectSearch>
                                                </div>
                                                : null
                                            }
                                            <div className={index !== 0 ? 'col-3' : 'col-5'}>
                                                <SelectSearch
                                                    data={catalogo}
                                                    name={"tipoFiltro"}
                                                    handleChange={handleChange}
                                                    index={index}
                                                    value={filtro.tipoFiltro}
                                                ></SelectSearch>
                                            </div>
                                            {filtro.tipoFiltro?.esPorCatalogo ?
                                                <div className='col-5'>
                                                    <SelectSearch
                                                        data={filtro.tipoFiltro?.catalogo}
                                                        name={"searchValue"}
                                                        handleChange={handleChange}
                                                        index={index}
                                                        value={filtro.searchValue}></SelectSearch>
                                                </div>
                                                : filtro.tipoFiltro?.esFecha ?
                                                    <>
                                                        <div className='col-3'>
                                                            <FechaGeneric
                                                                fecha={filtro.searchValue}
                                                                handleDateTimeChange={handleDateFilters}
                                                                nameLabel={"Desde"}
                                                                nameInput={"searchValue"}
                                                                index={index}></FechaGeneric>
                                                        </div>
                                                        <div className='col-3'>
                                                            <FechaGeneric
                                                                fecha={filtro.searchEndValue}
                                                                handleDateTimeChange={handleDateFilters}
                                                                nameLabel={"Hasta"}
                                                                nameInput={"searchEndValue"}
                                                                index={index}></FechaGeneric>
                                                        </div>
                                                    </>
                                                    :
                                                    <div className='col-6'>
                                                        <input
                                                            type='text'
                                                            name='searchValue'
                                                            value={filtro.searchValue}
                                                            placeholder='Busqueda...'
                                                            onChange={(e) => handleChange(e, index)}
                                                            className='form-control' />
                                                    </div>

                                            }
                                            <div className='col-1'>
                                                <button type='button'
                                                    className='btn btn-danger'
                                                    onClick={() => quitarFiltro(index)}>
                                                    <i className="bi bi-x"></i>
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>


                        </div>
                        <div className='card-footer' align="right">
                            <button type='button' className='btn btn-dark m-2'
                                onClick={() => { aplicarFiltros() }}>
                                <i className="bi bi-search"></i>BUSCAR
                            </button>
                            <button type='button' className='btn btn-dark m-2'
                                onClick={() => { limpiarFiltros() }}>
                                <i className="bi bi-eraser"></i>LIMPIAR TODO
                            </button>
                        </div>

                    </div>
                </div>
            </Modal>
        </>

    )
}

export default BuscadorAvanzado