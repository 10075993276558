import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import FormikSelectSearchDB from '../../../formik/FormikSelectSearchDB';
import { findAllUsersBySearchText, findClientesBySearchText } from '../../../../controllers/admin/usuariosController';
import FormikGenericDate from '../../../formik/FormikGenericDate';
import { useEffect, useState } from 'react';
import TableCustomCS from '../tables/TableCustomCS';
import { getCondicionesServicio } from '../../../../controllers/admin/cotizaciones/condicionesServicio';
import { formatFormikCotizacion, saveCotizacion, updateCotizacion } from '../../../../controllers/admin/cotizaciones/cotizacionController';
import FieldArrayVentas from './fields/FieldArrayVentas';
import CotizacionPdf from '../files/CotizacionPdf';
import FormikSelectSearch from '../../../formik/FormikSelectSearch';
import { CATALOGO_ESTATUS_COTIZACIONES } from '../../../../utils/catalogosEstaticos';
import { getCondicionesAduanas } from '../../../../controllers/admin/cotizaciones/condicionesAduanaController';

function FormCotizacion({ cotizacion, setCotizacion }) {
    const [condicionesServicio, setCondicionesServicio] = useState([]);

    useEffect(() => {
        if (cotizacion?.id) {
            setCondicionesServicio(JSON.parse(cotizacion.arrayCondicionesServicio))
        }
    }, [])

    const generarCondiciones = async (valuesForm) => {
        let condiciones = await getCondicionesServicio(valuesForm);
        let condicionesAduana = await getCondicionesAduanas(valuesForm);
        let mergeCondiciones = condiciones?.concat(condicionesAduana);
        setCondicionesServicio(mergeCondiciones);

    }

    return (
        <Formik
            initialValues={cotizacion}
            onSubmit={async (values) => {

                values?.ventas.forEach(venta => {
                    const tableVentas = venta.tableVentas;
                    const dimensionesCarga = venta.dimensionesCarga;
                    venta.tableVentas = typeof tableVentas == "string" ? tableVentas : JSON.stringify(venta.tableVentas);
                    venta.dimensionesCarga = typeof dimensionesCarga == "string" ? dimensionesCarga : JSON.stringify(venta.dimensionesCarga);
                });

                const cotizacion = {
                    ...values,
                    arrayCondicionesServicio: JSON.stringify(condicionesServicio),
                }

                let data = cotizacion?.id ? await updateCotizacion(cotizacion, cotizacion.id) : await saveCotizacion(cotizacion);
                if (data) {
                    formatFormikCotizacion(data);
                    setCotizacion(data)
                }
            }}
            enableReinitialize
            validationSchema={Yup.object({
                //cliente: Yup.mixed().nullable().required('El cliente es requerido'),\
                vendedor: Yup.mixed().nullable().required('El vendedor es requerido'),
                /*ventas: Yup.array().of(
                    Yup.object().shape({
                        trafico: Yup.mixed().nullable().required('El trafico es requerido'),
                        idServicio: Yup.mixed().nullable().required('El servicio es requerido'),
                        tipoCarga: Yup.mixed().nullable().required('El tipo de carga es requerido'),
                    })
                )*/
            })}
        >
            {({ values }) => (
                <Form>
                    <div className='row'>
                        <div className='col-sm-6 mb-3'>
                            <div className="form-check form-switch">
                                <Field type="checkbox" name="addUnregisteredClient" className="form-check-input" role="switch" />
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Cliente no registrado</label>
                            </div>
                        </div>
                        <div className="col-sm-12 mb-3">
                            <label htmlFor="detallesCotizacion" className="form-label">Detalles</label>
                            <Field id="detallesCotizacion" name="details" className="form-control" component="textarea" />
                        </div>

                        <div className="col-sm-6 mb-3">
                            {values.addUnregisteredClient ?
                                <>
                                    <label htmlFor="unregisteredClient" className="form-label">Cliente</label>
                                    <Field id="unregisteredClient" name="unregisteredClient" className="form-control" />
                                </>
                                :
                                <Field
                                    name="cliente"
                                    component={FormikSelectSearchDB}
                                    titulo="Cliente"
                                    fetchSearch={findClientesBySearchText}
                                    type={"USERS"} />
                            }

                        </div>

                        <div className="col-sm-6 mb-3">
                            <Field
                                name={`idStatus`}
                                id="estatusCotizacion"
                                component={FormikSelectSearch}
                                data={CATALOGO_ESTATUS_COTIZACIONES}
                                titulo={"Estatus"}
                                isDisabled={false} />
                        </div>
                        {values.idStatus == 3 || values.idStatus == 4 ?
                            <div className="col-sm-12 mb-3">
                                <label htmlFor="notasStatus" className="form-label">Notas del Estatus</label>
                                <Field id="notasStatus" name={`notasStatus`} className="form-control" component="textarea" />
                            </div>
                            :
                            ""
                        }

                        <div className="col-sm-12 mb-3">
                            <Field
                                name="vigencia"
                                component={FormikGenericDate}
                                nameLabel="Vigencia" />
                        </div>

                        <div className="col-sm-12 mb-3">
                            <Field
                                name="vendedor"
                                component={FormikSelectSearchDB}
                                titulo="Vendedor"
                                fetchSearch={findAllUsersBySearchText}
                                type={"USERS"} />
                            <ErrorMessage name={`vendedor`} component='span' className='text-danger'></ErrorMessage>
                        </div>

                        <div className="col-sm-12 mb-3">
                            <label htmlFor="contactoCotizacion" className="form-label">Contacto</label>
                            <Field id="contactoCotizacion" name="contacto" className="form-control" />
                        </div>

                        <FieldArrayVentas
                            values={values} />

                        <div className="col-sm-12 mb-3" align="right">
                            <TableCustomCS
                                condicionesServicio={condicionesServicio}
                                setCondicionesServicio={setCondicionesServicio}
                                buttonGenerateCondiciones={
                                    <button className='btn btn-warning' type='button' onClick={() => { generarCondiciones(values) }}>
                                        GENERAR CONDICIONES
                                    </button>
                                }></TableCustomCS>
                        </div>

                        <div className='col-sm-12' align="right">
                            <button type="submit" className='btn btn-primary'>{cotizacion?.id ? "ACTUALIZAR" : "GUARDAR"}</button>
                            {cotizacion?.id ?
                                <CotizacionPdf cotizacion={cotizacion} />
                                :
                                ""
                            }

                        </div>
                    </div>
                </Form>
            )}

        </Formik>
    )
}

export default FormCotizacion