import { URL_ADMIN_USERS } from "../../utils/constantes";
import { apiRequest } from "../handlerApiRequest";
import { apiRequestV2 } from "../handlerApiRequestV2";


export const listaUsuariosPorIdRol = async (idRol) => {
    return await apiRequestV2({
        endpoint: URL_ADMIN_USERS + "/rol/"+idRol,
    })
}

export const guardarAdmin = async (usuario) => {
    return await apiRequestV2({
        endpoint: URL_ADMIN_USERS + "/guardar-admin",
        method: "POST",
        data: usuario,
        showSuccessMessage: true
    })
}

export const findProveedoresBysearchText = async(searchText) =>{
    return await apiRequest(URL_ADMIN_USERS+"/proveedores", 'GET', null, `?searchText=${searchText}`);
}

export const findLogisticaesBysearchText = async(searchText) =>{
    return await apiRequest(URL_ADMIN_USERS+"/logistica", 'GET', null, `?searchText=${searchText}`);
}

export const findClientesBySearchText = async(searchText) =>{
    return await apiRequest(URL_ADMIN_USERS+"/clientes", 'GET', null, `?searchText=${searchText}`);
}

export const findAllUsersBySearchText = async(searchText) =>{
    return await apiRequest(URL_ADMIN_USERS+"/all-users", 'GET', null, `?searchText=${searchText}`);
}

export const findAllClientesProveedoresBySearchText = async(searchText) =>{
    return await apiRequest(URL_ADMIN_USERS+"/clientes-proveedores", 'GET', null, `?searchText=${searchText}`);
}

export const guardarUsuario = async (url, usuario) => {
    return await apiRequestV2({
        endpoint: URL_ADMIN_USERS + url,
        method: "POST",
        data: usuario,
        showSuccessMessage: true
    })
}

export const actualizarUsuario = async (usuario) => {
    return await apiRequestV2({
        endpoint: URL_ADMIN_USERS + "/"+usuario.id,
        method: "PUT",
        data: usuario,
        showSuccessMessage: true
    })
}

export const buscarPorIdUsuario = async (idUsuario) => {
    return await apiRequest("/admin/usuarios/"+idUsuario);
}

export const buscarPorIdUsuarioParaActualizar = async (idUsuario) => {
    return await apiRequest("/admin/usuarios/para-actualizar/"+idUsuario);
}

export const buscarArchivosDeUsuario = async (idUsuario) => {
    return await apiRequest(URL_ADMIN_USERS+ "/" + idUsuario +"/archivos");
}

export const eliminadoLogicoUserArchivo = async (idUserArchivo, razonEliminacion) => {
    return await apiRequest(URL_ADMIN_USERS+ "/eliminado-logico-archivousuario/"+idUserArchivo, "PUT", null, "?razonEliminacion="+razonEliminacion)
}

export const changePassword = async (idUsuario, password) => {
    return await apiRequest(URL_ADMIN_USERS+ `/${idUsuario}/change-password`, "POST", null, "?password="+password)
}

export const subirArchivoUser = async (archivo, idUsuario, userArchivo) => {

    const formData = new FormData();
    if(archivo != null ) formData.append("archivo", archivo, archivo.name);
    formData.append("userArchivo", new Blob([JSON.stringify(userArchivo)], {
        type: "application/json"
    }));

    return await apiRequest(URL_ADMIN_USERS+ "/" + idUsuario+ "/subir-archivo", "POST", formData, "", true);
}

export const actualizarArchivoUser = async (archivo, userArchivo) => {

    const formData = new FormData();
    if(archivo != null ) formData.append("archivo", archivo, archivo.name);
    formData.append("userArchivo", new Blob([JSON.stringify(userArchivo)], {
        type: "application/json"
    }));

    return await apiRequest(URL_ADMIN_USERS + "/actualizar-archivo" + "/" + userArchivo.id, "PUT", formData, "", true);
}