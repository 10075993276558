import { useState } from 'react'
import Modal from '@mui/material/Modal';
import FormCotizacion from '../forms/FormCotizacion';
import { Cotizacion } from '../../../../models/admin/cotizaciones/Cotizacion';
import { formatFormikCotizacion } from '../../../../controllers/admin/cotizaciones/cotizacionController';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import DocumentsGenerics from '../../../generics/documents/DocumentsGenerics';
import { deleteQuotationFile, findAllByIdQuotation, saveQuotationFiles, updateQuotationFile } from '../../../../controllers/admin/cotizaciones/quotationFileController';
import { BASE_URL_S3_QUOTATION_FILES } from '../../../../utils/constantes';

function ModalCotizacion({ editCotizacion, loadCotizaciones, customClassButton }) {
    const [open, setOpen] = useState(false);
    const [cotizacion, setCotizacion] = useState(Cotizacion);

    const handleOpen = () => {
        if (editCotizacion?.id) {
            setCotizacion(editCotizacion);
            formatFormikCotizacion(editCotizacion);
        }
        setOpen(true)
    }

    const handleClose = () => {
        if (editCotizacion?.id || cotizacion.id) loadCotizaciones();
        setCotizacion(Cotizacion);
        setOpen(false);
    }


    return (
        <>
            <button
                className={customClassButton ? customClassButton : "btn btn-primary m-1"}
                type="button" onClick={handleOpen}>
                {editCotizacion?.id ? <><i className="bi bi-pen-fill"></i> EDITAR</> : <><i className="bi bi-plus"></i>NUEVA COTIZACIÓN</>}
            </button>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <div className='card style-modal-xl'>
                    <div className='card-header d-flex justify-content-between'>
                        COTIZACIÓN {cotizacion?.id ? cotizacion.quoteReferenceKJ : ""}
                        <button className='btn btn-dark btn-sm'
                            onClick={handleClose}>
                            <i className="bi bi-x-lg"></i>
                        </button>
                    </div>
                    <div className='card-body body-modal-scroll'>

                        <Tab.Container defaultActiveKey="cotizacion">
                            <Nav variant="pills">
                                <Nav.Item>
                                    <Nav.Link eventKey="cotizacion">COTIZACIÓN</Nav.Link>
                                </Nav.Item>
                                {editCotizacion || cotizacion?.id ?
                                    <Nav.Item>
                                        <Nav.Link eventKey="cotizacion-documents">DOCUMENTOS</Nav.Link>
                                    </Nav.Item>
                                    : null

                                }

                            </Nav>

                            <Tab.Content>
                                <Tab.Pane eventKey="cotizacion">
                                    <FormCotizacion
                                        cotizacion={cotizacion}
                                        setCotizacion={setCotizacion}></FormCotizacion>
                                </Tab.Pane>

                                {editCotizacion || cotizacion?.id ?
                                <Tab.Pane eventKey="cotizacion-documents">
                                    <DocumentsGenerics
                                        functionSaveDocument={(quotationFilesList) => saveQuotationFiles(quotationFilesList, cotizacion)}
                                        functionFindAll={() => findAllByIdQuotation(cotizacion?.id)}
                                        baseUrlS3={BASE_URL_S3_QUOTATION_FILES}
                                        attributeNameFile={"documentName"}
                                        attributeDocument={"document"}
                                        functionUpdateDocument={updateQuotationFile}
                                        functionDeleteDocument={deleteQuotationFile}></DocumentsGenerics>
                                </Tab.Pane>
                                :
                                null}

                            </Tab.Content>


                        </Tab.Container>


                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ModalCotizacion