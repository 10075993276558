import { URL_DIRECCION_CLIENTE } from "../../../utils/constantes";
import { apiRequest } from "../../handlerApiRequest"

/**
 * Se recicla este contolador para las Direcciones de entrega y recoleccion.
 * En el backend cada direccion si tiene su propio servicio y controller
 * 
 * Con la refactorizacion 12 abril ahora se usa un solo servicio para las direcciones del cliente
 */

export const listarDirecciones = async () => {
    return await apiRequest(URL_DIRECCION_CLIENTE)
}

export const findAllDireccionesBySearchText = async (textSearch) => {
    return await apiRequest(URL_DIRECCION_CLIENTE+"/search"+`?textSearch=${textSearch}`)
}

export const guardarEditarDireccion = async (direccion) => {
    return await apiRequest(URL_DIRECCION_CLIENTE, 'POST', direccion);
}

export const eliminaDireccionCliente = async (idDireccion) => {
    return await apiRequest(URL_DIRECCION_CLIENTE+"/"+idDireccion, 'DELETE', null);
}