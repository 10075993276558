import React, { useState } from 'react'
import Modal from '@mui/material/Modal';
import { styleModalSM } from '../../../../assets/css/styles-react';
import { ToastError, ToastSuccess } from '../../../../utils/toastMessage';
import MUIDataTable from "mui-datatables";
import { useDispatch } from 'react-redux';
import ModalEliminarObjeto from '../../ModalEliminarObjeto';
import { setLoading } from '../../../../redux/features/loaderSlice';
import { deleteByIdAerolinea, findAllCatalogoAerolineas, saveAerolinea, updateAerolinea } from '../../../../controllers/admin/embarques/catalogos/catalogoAerolineas';
import { BASIC_OPTIONS_MUI } from '../../../../utils/mui/constantes';

function ModalAdministracionAerolineas() {
    const aerolineasEntity = {
        id: '',
        nombre: '',
        creadoEn: ''
      }
      const [open, setOpen] = useState(false);
      const [aerolinea, setAerolinea] = useState(aerolineasEntity)
      const [aerolineas, setAerolineas] = useState([])
      const dispatch = useDispatch();
    
      const handleOpen = async () => {
        dispatch(setLoading({isLoading: true}))
        await fetchFindAllAerolineas();
        setOpen(true)
        dispatch(setLoading({isLoading: false}))
      };
    
      const handleChange = event => {
        event.target.value = event.target.value.toUpperCase();
    
        setAerolinea({
          ...aerolinea,
          [event.target.name]: event.target.value
        })
      }
    
      const addNewAerolinea = (newNaviera) => {
        setAerolineas((prevNavieras) => [
          ...prevNavieras,
          [
            newNaviera.id,
            newNaviera.nombre,
            newNaviera.creadoEn,
          ],
        ]);
      };
    
      const updateExistingAerolinea = (updatedAerolinea) => {
        setAerolineas((prevAerolinea) => {
          const indexAerolinea = prevAerolinea.findIndex(item => item[0] === updatedAerolinea.id);
          const newAerolinea = [...prevAerolinea];
          newAerolinea[indexAerolinea] = [
            updatedAerolinea.id,
            updatedAerolinea.nombre,
            updatedAerolinea.creadoEn,
          ];
          return newAerolinea;
        });
      };
    
      const handleSubmit = async (event) => {
        event.preventDefault();
        try {
          const isUpdate = aerolinea?.id != "";
          const action = isUpdate ? updateAerolinea : saveAerolinea;
    
          dispatch(setLoading({isLoading: true}))
          const res = await action(aerolinea);
          dispatch(setLoading({isLoading: false}))
    
          if (res.error) return ToastError(res.message);
    
          ToastSuccess(res.message);
          isUpdate ? updateExistingAerolinea(res.data) : addNewAerolinea(res.data);
          setAerolinea(aerolineasEntity);
        } catch (error) {
          return ToastError(error);
        }
      };
    
      const fetchFindAllAerolineas = async () => {
        try {
          const res = await findAllCatalogoAerolineas()
          if (res.error) return ToastError(res.message)
          await formatoData(res.data)
        } catch (e) {
          ToastError(e)
        }
      }
    
      const fetchDeleteByIdAerolinea = async (idAerolinea) =>{
        try{
          const res = await deleteByIdAerolinea(idAerolinea);
          if (res?.error) return ToastError(res.message);
    
          //Eliminar la aerolinea del array
          setAerolineas((prevAerolineas) => {
            const indexAerolinea = prevAerolineas.findIndex(item => item[0] === idAerolinea);
            const newAerolineas = [...prevAerolineas];
            newAerolineas.splice(indexAerolinea, 1)
            return newAerolineas;
          });
          ToastSuccess(res.message);
        }catch(err){
          return ToastError(err)
        }
      }
    
      async function formatoData(data) {
        setAerolineas(data.map((di) => [
          di.id,
          di.nombre,
          di.creadoEn
        ]))
      }
    
      const handleClose = () => setOpen(false);
    
      const columns = [
        {
          name: "Id",
          options: {
            display: "excluded",
            filter: false,
            sort: false,
            download: false,
          }
        },
        "AEROLINEA",
        {
          name: "Fecha Registro",
          options: {
            display: "excluded",
            filter: false,
            sort: false,
            download: false,
          }
        },
        {
          name: "",
          options: {
            customBodyRenderLite: (dataIndex) => {
              return (
                <button className='btn btn-warning'
                  onClick={() => setAerolinea({ id: aerolineas[dataIndex][0], nombre: aerolineas[dataIndex][1], creadoEn: aerolineas[dataIndex][2] })}>
                  <i className="bi bi-pencil"></i>
                </button>
              )
            },
            filter: false,
            sort: false,
            download: false,
          }
        },
        {
          name: "",
          options: {
            customBodyRenderLite: (dataIndex) => {
              return (
                <ModalEliminarObjeto
                functionEliminar={fetchDeleteByIdAerolinea}
                id={aerolineas[dataIndex][0]}
                textHeader={"¿Estás seguro de eliminar esta aerolinea?"}>
                </ModalEliminarObjeto>
              )
            },
            filter: false,
            sort: false,
            download: false,
          }
        },]
    
      return (
        <>
          <button className='dropdown-item' onClick={handleOpen}>
            Administración aerolineas
          </button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="card" style={styleModalSM}>
              <div className="card-header">
                Administración aerolineas
              </div>
              <div className="card-body body-modal-scroll">
                <form onSubmit={handleSubmit}>
                  <div className='row g-3 row-cols-3'>
                    <div className="col-12 mb-3">
                      <label htmlFor="inputNombre" className="form-label">Aerolinea<span className='required-span'>*</span></label>
                      <input
                        type="text"
                        required
                        className="form-control"
                        name='nombre'
                        value={aerolinea.nombre}
                        placeholder="Ingresa el nombre"
                        onChange={handleChange} />
                    </div>
                    <div className='col-12' align="right">
                      {aerolinea.id ?
                        <>
                          <span type='button' className="btn btn-secondary col-4" onClick={() => setAerolinea(aerolineasEntity)}>Cancelar</span>
                          <button type="submit" className="btn btn-primary col-4">Actualizar</button>
                        </>
                        :
                        <button type="submit" className="btn btn-primary col-4">Guardar</button>
    
                      }
                    </div>
                  </div>
                </form>
    
                <MUIDataTable
                  data={aerolineas}
                  columns={columns}
                  options={BASIC_OPTIONS_MUI}
                />
              </div>
            </div>
          </Modal>
        </>
      )
    }
    

export default ModalAdministracionAerolineas