export const templateTerrestre = {
  "columns": [
    {
      "nombreColumna": "ORIGEN",
      "tipoFila": "SELECT",
      "catalogo": {
        "id": "U",
        "value": "U",
        "label": "UBICACIONES (ORIGEN/DESTINO)"
      },
      "idItem": "ca4d2ec65c10c",
      "width": 300
    },
    {
      "nombreColumna": "DESTINO",
      "tipoFila": "SELECT",
      "catalogo": {
        "id": "U",
        "value": "U",
        "label": "UBICACIONES (ORIGEN/DESTINO)"
      },
      "idItem": "c96cc2022bfda",
      "width": 300
    },
    {
      "nombreColumna": "NOTAS",
      "tipoFila": "TEXTAREA",
      "idItem": "48de1a70b1124",
      "width": 800
    }
  ],
  "data": []
}

export const templateMaritimo = {
  "columns": [
    {
      "nombreColumna": "PICK UP",
      "tipoFila": "SELECT",
      "catalogo": {
        "id": "U",
        "value": "U",
        "label": "UBICACIONES (ORIGEN/DESTINO)"
      },
      "idItem": "c511a43e9d108"
    },
    {
      "nombreColumna": "POL",
      "tipoFila": "SELECT",
      "catalogo": {
        "id": "U",
        "value": "U",
        "label": "UBICACIONES (ORIGEN/DESTINO)"
      },
      "idItem": "49653aaa19501"
    },
    {
      "nombreColumna": "POD",
      "tipoFila": "SELECT",
      "catalogo": {
        "id": "U",
        "value": "U",
        "label": "UBICACIONES (ORIGEN/DESTINO)"
      },
      "idItem": "c8f9b0891ad06"
    },
    {
      "nombreColumna": "CARRIER",
      "tipoFila": "SELECT",
      "catalogo": {
        "id": "P",
        "value": "P",
        "label": "PROVEEDORES"
      },
      "idItem": "13b80b244983f"
    },
    {
      "nombreColumna": "VIA (1)",
      "tipoFila": "OTS",
      "idItem": "a039bcf6f0d5f"
    },
    {
      "nombreColumna": "TT APROX",
      "tipoFila": "OTS",
      "idItem": "ab91bc3020a41"
    },
    {
      "nombreColumna": "DIAS LIBRES",
      "tipoFila": "OTS",
      "idItem": "288204465402f"
    }
  ],
  "data": []
}

export const templateAduanas = {
  "columns": [
    {
      "nombreColumna": "ORIGEN",
      "tipoFila": "SELECT",
      "catalogo": {
        "id": "U",
        "value": "U",
        "label": "UBICACIONES (ORIGEN/DESTINO)"
      },
      "idItem": "fd78754cf10f5"
    },
    {
      "nombreColumna": "DESTINO",
      "tipoFila": "SELECT",
      "catalogo": {
        "id": "U",
        "value": "U",
        "label": "UBICACIONES (ORIGEN/DESTINO)"
      },
      "idItem": "95bcea2fb754"
    },
    {
      "nombreColumna": "EQUIPO",
      "tipoFila": "TEXTAREA",
      "idItem": "cebc356c5b12a"
    },
    {
      "nombreColumna": "RECOLECCIÓN",
      "tipoFila": "OTS",
      "idItem": "4cdb344080dd9"
    },
    {
      "nombreColumna": "FLETE INTERNACIONAL",
      "tipoFila": "OTS",
      "idItem": "344ab4252e9d"
    },
    {
      "nombreColumna": "CARGOS LOCALES DESTINO (ISPS, BL, AMS, CFS)",
      "tipoFila": "OTS",
      "idItem": "58d1d8ecd1a93"
    },
    {
      "nombreColumna": "DESPACHO ADUANAL",
      "tipoFila": "TEXTAREA",
      "idItem": "7e0c31335f11a"
    },
    {
      "nombreColumna": "VALIDACION PEDIMENTO",
      "tipoFila": "OTS",
      "idItem": "50b52a03b00d2"
    },
    {
      "nombreColumna": "VUCEM",
      "tipoFila": "OTS",
      "idItem": "b716e02431273"
    },
    {
      "nombreColumna": "SEGURO",
      "tipoFila": "OTS",
      "idItem": "e67be42e275ab"
    },
    {
      "nombreColumna": "ENTREGA",
      "tipoFila": "TEXTAREA",
      "idItem": "4375c52b39595"
    },
    {
      "nombreColumna": "TIEMPO TRANSITO",
      "tipoFila": "OTS",
      "idItem": "b21df4f97f49f"
    }
  ],
  "data": []
}

export const templateMaritimoLCL = {
  "columns": [
    {
      "nombreColumna": "PICK UP",
      "tipoFila": "SELECT",
      "catalogo": {
        "id": "U",
        "value": "U",
        "label": "UBICACIONES (ORIGEN/DESTINO)"
      },
      "idItem": "848c777340c28"
    },
    {
      "nombreColumna": "POL",
      "tipoFila": "SELECT",
      "catalogo": {
        "id": "U",
        "value": "U",
        "label": "UBICACIONES (ORIGEN/DESTINO)"
      },
      "idItem": "c77a432e5c121"
    },
    {
      "nombreColumna": "POD",
      "tipoFila": "SELECT",
      "catalogo": {
        "id": "U",
        "value": "U",
        "label": "UBICACIONES (ORIGEN/DESTINO)"
      },
      "idItem": "fa564bba28d98"
    },
    {
      "nombreColumna": "TARIFA ALL IN (USD) LCL",
      "tipoFila": "TEXTAREA",
      "idItem": "aa02298e0fc93"
    },
    {
      "nombreColumna": "CARRIER",
      "tipoFila": "SELECT",
      "catalogo": {
        "id": "P",
        "value": "P",
        "label": "PROVEEDORES"
      },
      "idItem": "8d401d7c05261"
    },
    {
      "nombreColumna": "VIA",
      "tipoFila": "OTS",
      "idItem": "e045e6cc67609"
    },
    {
      "nombreColumna": "TT APROX",
      "tipoFila": "OTS",
      "idItem": "d3077122e984d"
    }
  ],
  "data": []
}

export const templateAereo = {
  "columns": [
    {
      "nombreColumna": "PICK UP",
      "tipoFila": "SELECT",
      "catalogo": {
        "id": "U",
        "value": "U",
        "label": "UBICACIONES (ORIGEN/DESTINO)"
      },
      "idItem": "c1daeff68d1fe"
    },
    {
      "nombreColumna": "AOL",
      "tipoFila": "SELECT",
      "catalogo": {
        "id": "U",
        "value": "U",
        "label": "UBICACIONES (ORIGEN/DESTINO)"
      },
      "idItem": "10ed421df7df8"
    },
    {
      "nombreColumna": "AOD",
      "tipoFila": "SELECT",
      "catalogo": {
        "id": "U",
        "value": "U",
        "label": "UBICACIONES (ORIGEN/DESTINO)"
      },
      "idItem": "2a713f502a93"
    },
    {
      "nombreColumna": "TARIFA ALL IN (USD)",
      "tipoFila": "TEXTAREA",
      "idItem": "34679d8bd16e4"
    },
    {
      "nombreColumna": "CARRIER",
      "tipoFila": "SELECT",
      "catalogo": {
        "id": "P",
        "value": "P",
        "label": "PROVEEDORES"
      },
      "idItem": "b603aa647df26"
    },
    {
      "nombreColumna": "VIA",
      "tipoFila": "OTS",
      "idItem": "478cf82489a3c"
    },
    {
      "nombreColumna": "TT APROX",
      "tipoFila": "OTS",
      "idItem": "c3b1e97cd4442"
    }
  ],
  "data": []
}