import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

function ToolTipMui({ title, customIcon }) {

  return (
    <Tooltip
      title={title}
      placement="left"
    >
      <IconButton>
        {customIcon ?
          customIcon :
          <InfoIcon />
        }
      </IconButton>
    </Tooltip>
  );
}

export default ToolTipMui