import React, { useState } from 'react'
import CustomFileUploader from '../inputs/CustomFileUploader'
import { formikHandleChangeMultipleFile } from '../../utils/helpersState';

function FormikAdapterCustomFileUpl({ field, form, ...props }) {
    const [nameFile, setNameFile] = useState(null);

    const {fileTypes, index, isMultiple,  } = props;

    const onChangeFiles = async (indexDoc, file, name) => {
        const fileBuilt = file instanceof FileList ? await formikHandleChangeMultipleFile(file) : file;
        setNameFile(fileBuilt.name)
        form.setFieldValue(field.name, fileBuilt);
    }


    return (
        <CustomFileUploader
            handleChangeFile={onChangeFiles}
            index={index}
            fileTypes={fileTypes}
            multiple={isMultiple}
            name={field.name}
            nameFile={nameFile}></CustomFileUploader>
    )
}

export default FormikAdapterCustomFileUpl