import React from 'react'

function CommunDirecciones({ value, handleChange }) {
    return (
        <>
            <div className="col-6 mb-3">
                <label htmlFor="inputNombre" className="form-label">Calle<span className='required-span'>*</span></label>
                <input
                    type="text"
                    required
                    className="form-control"
                    name='calle'
                    value={value.calle}
                    onChange={handleChange} />
            </div>
            <div className="col-6 mb-3">
                <label htmlFor="inputNombre" className="form-label">No. Exterior<span className='required-span'>*</span></label>
                <input
                    type="text"
                    className="form-control"
                    name='numeroExt'
                    required
                    value={value.numeroExt}
                    onChange={handleChange} />
            </div>
            <div className="col-6 mb-3">
                <label htmlFor="inputNombre" className="form-label">No. Interior</label>
                <input
                    type="text"
                    className="form-control"
                    name='numeroInt'
                    value={value.numeroInt}
                    onChange={handleChange} />
            </div>
            <div className="col-6 mb-3">
                <label htmlFor="inputNombre" className="form-label">Colonia<span className='required-span'>*</span></label>
                <input
                    type="text"
                    required
                    className="form-control"
                    name='colonia'
                    value={value.colonia}
                    onChange={handleChange} />
            </div>
            <div className="col-6 mb-3">
                <label htmlFor="inputNombre" className="form-label">Municipo o Delegación<span className='required-span'>*</span></label>
                <input
                    type="text"
                    required
                    className="form-control"
                    name='municipio'
                    value={value.municipio}
                    onChange={handleChange} />
            </div>
            <div className="col-6 mb-3">
                <label htmlFor="inputNombre" className="form-label">Codigo Postal<span className='required-span'>*</span></label>
                <input
                    type="number"
                    required
                    className="form-control"
                    name='codigoPostal'
                    value={value.codigoPostal}
                    onChange={handleChange} />
            </div>
            <div className="col-6 mb-3">
                <label htmlFor="inputNombre" className="form-label">Estado<span className='required-span'>*</span></label>
                <input
                    type="text"
                    required
                    className="form-control"
                    name='estado'
                    value={value.estado}
                    onChange={handleChange} />
            </div>
            <div className="col-6 mb-3">
                <label htmlFor="inputNombre" className="form-label">País<span className='required-span'>*</span></label>
                <input
                    type="text"
                    required
                    className="form-control"
                    name='pais'
                    value={value.pais}
                    onChange={handleChange} />
            </div>
        </>
    )
}

export default CommunDirecciones