import { URL_CATALOGO_AEROLINEA } from "../../../../utils/constantes";
import { apiRequest } from "../../../handlerApiRequest"

export const findAllCatalogoAerolineas = async () =>{
    return await apiRequest(URL_CATALOGO_AEROLINEA);
}

export const saveAerolinea = async (aerolinea) =>{
    return await apiRequest(URL_CATALOGO_AEROLINEA, 'POST', aerolinea);
}

export const updateAerolinea = async (aerolinea)=>{
    return await apiRequest(URL_CATALOGO_AEROLINEA+`/${aerolinea.id}`, "PUT", aerolinea)
}

export const deleteByIdAerolinea = async(idAerolinea)=>{
    return await apiRequest(URL_CATALOGO_AEROLINEA+`/${idAerolinea}`, "DELETE")
}