import {BASE_URL_S3_COTIZACIONES, BASE_URL_S3_DOCUMENTOS_EMBARQUE } from '../../../utils/constantes';
import {useSelector } from 'react-redux';
import { buscarDocumentosPorIdEmbarque } from '../../../controllers/admin/embarques/embarqueArchivoController';
import ModalArchivo from '../../ModalArchivo';
import { actualizarDocumentoEmbarque, eliminadoLogicoDocumento, guardarDocumentoEmbarque } from '../../../controllers/admin/embarques/embarqueArchivoController';
import CotizacionPdf from '../cotizaciones/files/CotizacionPdf';
import DocumentsGenerics from '../../generics/documents/DocumentsGenerics';

function DocumentosEmbarque() {
  const embarqueData = useSelector((state) => state.embarque.embarqueData);

  return (
    <div>
      <div className='col-12 mb-2' align="right">
        {embarqueData.fileCotizacion ?
          <ModalArchivo
            title={`Cotización Embarque ${embarqueData.referenciaKJ}`}
            urlBase={BASE_URL_S3_COTIZACIONES}
            textButton={" COTIZACIÓN"}
            archivoS3={embarqueData.fileCotizacion}>
            <div className='col-12' align="right">
              <CotizacionPdf
                cotizacion={embarqueData.cotizacion}
                embarque={{
                  id: embarqueData.id,
                  referenciaKJ: embarqueData.referenciaKJ,
                  fileCotizacion: embarqueData.fileCotizacion
                }}
                nameButton={"ACTUALIZAR DOCUMENTO"} />
            </div>
          </ModalArchivo> : null
        }
      </div>
      <DocumentsGenerics
        functionSaveDocument={(shipmentFilesList) => guardarDocumentoEmbarque(embarqueData.id, shipmentFilesList)}
        functionFindAll={() => buscarDocumentosPorIdEmbarque(embarqueData.id)}
        attributeNameFile={"nombreArchivo"}
        baseUrlS3={BASE_URL_S3_DOCUMENTOS_EMBARQUE}
        attributeDocument={"documento"}
        functionUpdateDocument={(shipmentFilesList) => actualizarDocumentoEmbarque(embarqueData.id, shipmentFilesList)}
        functionDeleteDocument={eliminadoLogicoDocumento}></DocumentsGenerics>
    </div>
  )
}

export default DocumentosEmbarque