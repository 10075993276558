import FechaGeneric from "../inputs/FechaGeneric";
import moment from 'moment-timezone';

function FormikGenericDate({ field, form, ...props }) {

    const {nameLabel} = props;

    const handleDateTimeChange = (date) => {
        let formattedDateTime = null;
        if (date) {
            formattedDateTime = moment(date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        }

        form.setFieldValue(field.name, formattedDateTime);
    }

    return (
        <FechaGeneric
            fecha={field.value}
            handleDateTimeChange={handleDateTimeChange}
            nameLabel={nameLabel}
            nameInput={field.name}></FechaGeneric>
    )
}

export default FormikGenericDate