import { URL_CATALOGO_NAVIERA } from "../../../../utils/constantes";
import { apiRequest } from "../../../handlerApiRequest"

export const findAllCatalogoNaviera = async () =>{
    return await apiRequest(URL_CATALOGO_NAVIERA);
}

export const saveCatalogoNaviera = async (naviera) =>{
    return await apiRequest(URL_CATALOGO_NAVIERA, 'POST', naviera);
}

export const updateNaviera = async (naviera)=>{
    return await apiRequest(URL_CATALOGO_NAVIERA+`/${naviera.id}`, "PUT", naviera)
}

export const deleteByIdNaviera = async(idNaviera)=>{
    return await apiRequest(URL_CATALOGO_NAVIERA+`/${idNaviera}`, "DELETE")
}