import MUIDataTable from 'mui-datatables'
import { BASIC_OPTIONS_MUI, OPTIONS_CUSTOM_RETURN_COLUMN } from '../../../../utils/mui/constantes';
import { useEffect, useState } from 'react';
import {deleteCondicionService, findAllCondicionesServicio} from '../../../../controllers/admin/cotizaciones/condicionesServicio'
import { columnDeleteData } from '../../../mui/columnDeleteData';

function TableCondicionesServicio({refreshData, setCondicionServicio}) {
  const [condicionesServ, setCondicionesServ] = useState([])

  useEffect(() => {
    sendfindAllCondicionesServicio();
  }, [refreshData]);


  const sendfindAllCondicionesServicio = async () =>{
    const data = await findAllCondicionesServicio();
    if(data != null) setCondicionesServ(data);
  }

  const columns = [
    { label: "Servicio", name: "nombre" },
    { label: "Trafico", name: "trafico.nombre" },
    {
      name: "",
      options: {
        customBodyRenderLite: (index) => {
          return (
            <button type='button'
            className='btn btn-dark'
            onClick={() =>{
              setCondicionServicio(condicionesServ[index])
            }}><i className="bi bi-pen-fill"></i></button>
          )
        },
        ...OPTIONS_CUSTOM_RETURN_COLUMN
      },
    },
    columnDeleteData({
      functionEliminar: deleteCondicionService, 
      data: condicionesServ, 
      textHeader: "¿Está seguro que deseas eliminar esta condicion de servicio?",
      state: condicionesServ,
      setState: setCondicionesServ,
      spliceItem: true
    }),
  ]

  return (
    <div className='row mt-2'>

      <MUIDataTable
        title={"CONDICIONES SERVICIO PREDEFINIDAS"}
        data={condicionesServ}
        columns={columns}
        options={{
          ...BASIC_OPTIONS_MUI,
          responsive: "standard",
          enableNestedDataAccess: '.'
        }}
      />
    </div>
  )
}

export default TableCondicionesServicio