import React, { useEffect, useState } from 'react'
import CostosProveedores from './CostosProveedores';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { useDispatch, useSelector } from 'react-redux';
import { ToastError } from '../../../../utils/toastMessage';
import { setEmbarque } from '../../../../redux/features/admin/embarqueSlice';
import FacturasCliente from '../cliente/FacturasCliente';
import { setLoading } from '../../../../redux/features/loaderSlice';
import { formatMoney } from '../../../../utils/formatMoney';
import { buscarEmbarqueReqDTOPorId } from '../../../../controllers/admin/embarques/embarquesController';
import { fetchEstatusEmbarque } from '../../../../redux/features/admin/estatusEmbarqueSlice';

function ControlCostos({ idEmbarque }) {
    const embarqueData = useSelector((state) => state.embarque.embarqueData);
    const dispatch = useDispatch();

    const fetchbuscarEmbarqueReqDTOPorId = async () => {
        const data = await buscarEmbarqueReqDTOPorId(idEmbarque);
        dispatch(setEmbarque(data));
        dispatch(fetchEstatusEmbarque(data.id));
        return data;
    }

    return (
        <>

            <Tab.Container defaultActiveKey="facturaCliente">
                <Nav variant="pills">
                    <Nav.Item>
                        <Nav.Link eventKey="facturaCliente">FACTURA CLIENTE</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="costosProveedores">COSTOS PROVEEDORES</Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="facturaCliente">
                        <FacturasCliente
                            idEmbarque={idEmbarque}
                            fetchbuscarEmbarqueReqDTOPorId={fetchbuscarEmbarqueReqDTOPorId}></FacturasCliente>
                    </Tab.Pane>
                    <Tab.Pane eventKey="costosProveedores">
                        <CostosProveedores
                            idEmbarque={idEmbarque}
                            recargarEmbarqueData={fetchbuscarEmbarqueReqDTOPorId}></CostosProveedores>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>

            <hr />
            <div className='col-3'>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">MXN</th>
                            <th scope="col">
                                {embarqueData.totalVentaUSD > 0 || embarqueData.totalCostosUSD > 0 ?
                                    "USD" : ""
                                }
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td scope="row">Total Venta:</td>
                            <th>
                                {embarqueData.totalVenta && embarqueData.totalVenta >= 0 ? formatMoney(embarqueData.totalVenta) : "$0"}
                            </th>
                            <th>
                                {embarqueData.totalVentaUSD > 0 ? formatMoney(embarqueData.totalVentaUSD) : null}
                            </th>
                        </tr>
                        <tr>
                            <td scope="row">Total Costos:</td>
                            <th>
                                {embarqueData.totalCostos && embarqueData.totalCostos >= 0 ? formatMoney(embarqueData.totalCostos) : "$0"}
                            </th>
                            <th>
                                {embarqueData.totalCostosUSD > 0 ? formatMoney(embarqueData.totalCostosUSD) : null}
                            </th>
                        </tr>
                        <tr>
                            <td scope="row">Profit:</td>
                            <th>
                                {embarqueData.totalVenta >= 0 && embarqueData.totalCostos >= 0 ?
                                    formatMoney(embarqueData.totalVenta - embarqueData.totalCostos) : "$0"}
                            </th>
                            <th>
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default ControlCostos