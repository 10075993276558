import React from 'react'
import { useState } from 'react';
import Modal from '@mui/material/Modal';
import { useDispatch } from 'react-redux';
import { changePassword } from '../../controllers/admin/usuariosController';
import { ToastError, ToastSuccess, ToastWarning } from '../../utils/toastMessage';
import { setLoading } from '../../redux/features/loaderSlice';

function UpdatePasswordModal({idUsuario, buttonTittle}) {
    const [open, setOpen] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const dispatch = useDispatch();

    const changeInputPass = () => {
        if (passwordType === "password") return setPasswordType("text")
        setPasswordType("password")
    }

    const handleOpen = async () => {
        setOpen(true)
    }

    const handleClose = () => {
        setPassword("")
        setOpen(false);
    }

    const submitChangePassword = async () => {
        if(password == null || password == "") return ToastWarning("Se necesita la contraseña")
        try {
            dispatch(setLoading({ isLoading: true }))
            const res = await changePassword(idUsuario, password)
            dispatch(setLoading({ isLoading: false }))

            if (res.error) return ToastError(res.message)
            ToastSuccess(res.message)
            setOpen(false)
        } catch (e) {
            ToastError(e.message)
        }
    };

    return (
        <>
            <button
                className="btn btn-primary"
                type="button" onClick={handleOpen}>
                {buttonTittle ? buttonTittle : "CAMBIAR"}
            </button>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <div className='card style-modal-ssm'>
                    <div className='card-header d-flex justify-content-between'>
                        CAMBIAR CONTRASEÑA

                        <button className='btn btn-dark btn-sm'
                            onClick={handleClose}>
                            <i className="bi bi-x-lg"></i>
                        </button>
                    </div>
                    <div className='card-body'>
                        <div className="col-sm-12 mb-3">
                            <label htmlFor="inputPassword" className="form-label">Nueva Contraseña</label>
                            <div className="input-group mb-3">
                                <input
                                    type={passwordType}
                                    className="form-control"
                                    name='password'
                                    value={password}
                                    onChange={(e) => { setPassword(e.target.value) }} />
                                <button type='button' className='btn btn-dark'
                                    onClick={changeInputPass}>
                                    <i className="bi bi-eye-fill"></i>
                                </button>
                            </div>
                        </div>
                        <div className="col-sm-12 mb-3" align="right">
                            <button className='btn btn-primary'
                            onClick={() => submitChangePassword()}>ACTUALIZAR</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default UpdatePasswordModal