import { Document, Page, Image, View, StyleSheet } from '@react-pdf/renderer';
import { pdf } from "@react-pdf/renderer";
import { TIPO_ARCHIVOS_IMG } from './constantes';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4',
        padding: 10,
    },
    imageWrapper: {
        height: '100%',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        width: '100%',
        height: 'auto',
    },
});

export const sePuedeConvertirAPDF = (archivos) => {
    if (archivos.length >= 2
        || (archivos.length == 1 && TIPO_ARCHIVOS_IMG.includes(archivos[0].type))) {
        return true
    }
    return false
}

export const todosArchivosSonImagen = (archivos) => {
    for (let archivo of archivos) {
        if (!TIPO_ARCHIVOS_IMG.includes(archivo.type)) {
            return false;
        }
    }
    return true;
}

export const generatePdf = async (archivos) => {
    try {
        if (!todosArchivosSonImagen(archivos)) {
            throw new Error("Solo se puede subir multiples archivos si son imagenes. SE DETECTO UN ARCHIVO QUE NO ES IMAGEN");
        }

        const doc = (
            <Document>
                {archivos.map((image, index) => (
                    <Page size="A4" style={styles.page} key={index}>
                        <View style={styles.imageWrapper}>
                            <Image style={styles.image} src={URL.createObjectURL(image)} />
                        </View>
                    </Page>
                ))}
            </Document>
        );
        const blob = await pdf(doc).toBlob();
        const pdfFile = new File([blob], 'pdf-generado.pdf', { type: 'application/pdf' });
        return pdfFile;
    } catch (err) {
        throw err;
    }

}
