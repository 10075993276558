import React, { useState } from 'react'
import Modal from '@mui/material/Modal';
import { CATALOGO_SAT_FORMA_PAGO, CATALOGO_SAT_METODO_PAGO, CATALOGO_SAT_TIPO_COMPROBANTE_FACTURA, CATALOGO_SAT_USO_CFDI, buscarLabel } from '../../../../utils/catalogosEstaticos';
import { formatMoney } from '../../../../utils/formatMoney';
import { PDFViewer, Document, Page, Text, View } from '@react-pdf/renderer';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useSelector } from 'react-redux';
import { findAllByIdFacturaCliente } from '../../../../controllers/admin/facturas/gastoFacturaClienteController';
import { styles } from '../../../../utils/pdf/styles-react-pdf';
import HeaderKjPdf from '../../../../utils/pdf/HeaderKjPdf';

function ProformaFactura({ factura }) {
    const [open, setOpen] = useState(false);
    const [gastos, setGastos] = useState([]);
    const [espacioCT, setEspacioCT] = useState(0);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const embarqueData = useSelector((state) => state.embarque.embarqueData);
    let totalIva = 0.0;

    const handleOpen = async () => {
        await fetchGastos()
        setOpen(true)
    };

    const fetchGastos = async () => {
        const res = await findAllByIdFacturaCliente(factura.id);
        setGastos(res.data)
    }

    const handleClose = () => {
        setEspacioCT(0);
        setOpen(false);
    }

    let totalGastos = 0;
    const doc = (
        <Document>
            {factura ?
                <Page size="A4" style={styles.pageBody}>
                    <View style={styles.container}>
                        <HeaderKjPdf />
                        <Text style={styles.headingProforma}>PROFORMA FACTURA</Text>
                        <View style={styles.col} id="datos-cliente">
                            <View style={styles.col}>
                                <Text style={styles.heading}>DATOS FISCALES DEL CLIENTE</Text>
                                <Text>
                                    <Text style={styles.textBold}>RFC:</Text> {factura.cliente?.rfc}
                                </Text>
                                <Text><Text style={styles.textBold}>Razón Social:</Text> {factura.cliente?.razonSocial}
                                </Text>
                                <Text>
                                    <Text style={styles.textBold}>Régimen Fiscal:</Text> {factura.cliente.regimenFiscal?.claveRegimenFiscal + " - " + factura.cliente.regimenFiscal?.regimenFiscalSat}
                                </Text>
                                <Text>
                                    <Text style={styles.textBold}>Uso CFDI:</Text> {buscarLabel(factura.cliente?.usoDeCFDI, CATALOGO_SAT_USO_CFDI)}
                                </Text>
                                <Text>
                                    <Text style={styles.textBold}>Domicilio Fiscal:</Text> {factura.cliente.direccionFiscal?.direccionFiscal}
                                </Text>
                            </View>
                            <View style={styles.col}>
                                <Text style={styles.heading}>DATOS FACTURA</Text>
                                <Text>
                                    <Text style={styles.textBold}>Tipo Comprobante:</Text> {buscarLabel(factura.tipoDeComprobante, CATALOGO_SAT_TIPO_COMPROBANTE_FACTURA)}
                                </Text>
                                <Text>
                                    <Text style={styles.textBold}>Método Pago:</Text> {buscarLabel(factura.cliente?.metodoDePago, CATALOGO_SAT_METODO_PAGO)}
                                </Text>
                                <Text>
                                    <Text style={styles.textBold}>Forma Pago:</Text> {buscarLabel(factura.formaPago, CATALOGO_SAT_FORMA_PAGO)}
                                </Text>
                                <Text>
                                    <Text style={styles.textBold}>Moneda:</Text> {factura.tipoMoneda}
                                </Text>
                            </View>
                        </View>
                        <View>
                            <Text style={styles.heading}>CONCEPTOS</Text>

                            <Text>
                                <Text style={styles.textBold}>Referencia Embarque:</Text> {embarqueData.referenciaKJ}
                            </Text>

                            <View style={styles.table}>
                                <View style={styles.tableRow}>
                                    <View style={styles.tableColHeader}>
                                        <Text style={styles.tableCell}>CANTIDAD</Text>
                                    </View>
                                    <View style={styles.tableColHeader}>
                                        <Text style={styles.tableCell}>NO. IDENTIFICACION</Text>
                                    </View>
                                    <View style={styles.tableColHeader40}>
                                        <Text style={styles.tableCell}>DESCRIPCIÓN</Text>
                                    </View>
                                    <View style={styles.tableColHeader}>
                                        <Text style={styles.tableCell}>VALOR UNITARIO</Text>
                                    </View>
                                    <View style={styles.tableColHeader}>
                                        <Text style={styles.tableCell}>IMPORTE</Text>
                                    </View>
                                </View>

                                {factura.conceptosFactura ? (
                                    factura.conceptosFactura.map((concepto) => (
                                        <React.Fragment key={concepto.id}>
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>{concepto.cantidad}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>{concepto.noIdentificacion}</Text>
                                                </View>
                                                <View style={styles.tableCol40}>
                                                    <Text style={styles.tableCell}>{concepto.descripcion}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>{formatMoney(concepto.valorUnitario)}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>{formatMoney(concepto.importe)}</Text>
                                                </View>
                                            </View>
                                            {concepto.impuestos && concepto.impuestos.length > 0 && (
                                                <View style={styles.impuestosTable}>
                                                    <Text style={styles.dataCol}>IMPUESTO(S) POR CONCEPTO(S) </Text>

                                                    {concepto.impuestos.map((impuesto) => {
                                                        const importeImpuesto = impuesto.tasaOCuota * concepto.importe;
                                                        totalIva = totalIva + importeImpuesto;
                                                        return (
                                                            <React.Fragment key={impuesto.id}>
                                                                <Text style={styles.dataCol}><Text style={styles.textBold}>Impuesto:</Text>{impuesto.impuesto}</Text>
                                                                <Text style={styles.dataCol}><Text style={styles.textBold}>Tipo Factor:</Text> {impuesto.tipoFactor}</Text>
                                                                <Text style={styles.dataCol}><Text style={styles.textBold}>Tasa o Cuota:</Text> {impuesto.tasaOCuota}</Text>
                                                                <Text style={styles.dataCol}><Text style={styles.textBold}>Importe:</Text> {formatMoney(importeImpuesto)}</Text>
                                                            </React.Fragment>
                                                        )
                                                    })}

                                                </View>
                                            )}
                                        </React.Fragment>
                                    ))
                                ) : (
                                    <Text>SIN CONCEPTOS</Text>
                                )}
                            </View>
                        </View>

                        <View style={{ marginTop: `${espacioCT}px` }}></View>

                        <View style={styles.totalRow}>
                            <View style={styles.dataCol}></View>
                            <View style={styles.dataCol}></View>
                            <View style={styles.dataCol}></View>
                            <View style={styles.dataCol}>
                                <Text style={styles.textBold}>SUBTOTAL:</Text>
                                <Text style={styles.textBold}>IVA TASA 16%:</Text>
                                <View style={{ backgroundColor: "black", height: 1 }} />
                                <Text style={styles.textBold}>TOTAL:</Text>
                            </View>
                            <View style={styles.dataCol}>
                                <Text>{formatMoney(factura.subTotal)}</Text>
                                <Text>{formatMoney(totalIva)}</Text>
                                <View style={{ backgroundColor: "black", height: 1 }} />
                                <Text>{formatMoney(factura.total)}</Text>
                            </View>
                        </View>
                        {gastos.length >= 1 ?
                            <View>
                                <Text style={styles.heading}>GASTOS POR CUENTA DEL CLIENTE</Text>

                                <View style={styles.table}>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColHeader60}>
                                            <Text style={styles.tableCell}>CONCEPTO</Text>
                                        </View>
                                        <View style={styles.tableColHeader40}>
                                            <Text style={styles.tableCell}>IMPORTE</Text>
                                        </View>
                                    </View>
                                    {gastos.map((gasto) => {
                                        totalGastos = totalGastos + gasto.importe;
                                        return (
                                            <View style={styles.tableRow} key={gasto.id}>
                                                <View style={styles.tableCol60}>
                                                    <Text style={styles.tableCell}>{gasto.concepto}</Text>
                                                </View>
                                                <View style={styles.tableCol40}>
                                                    <Text style={styles.tableCell}>{formatMoney(gasto.importe)}</Text>
                                                </View>
                                            </View>
                                        )
                                    })
                                    }
                                </View>


                                <View style={styles.totalRow}>
                                    <View style={styles.dataCol}></View>
                                    <View style={styles.dataCol}></View>
                                    <View style={styles.dataCol}></View>
                                    <View style={styles.dataCol}>
                                        <Text style={styles.textBold}>TOTAL FACTURA:</Text>
                                        <Text style={styles.textBold}>ANTICIPO:</Text>
                                        <Text style={styles.textBold}>SALDO:</Text>
                                    </View>
                                    <View style={styles.dataCol}>
                                        <Text>{formatMoney(factura.total + totalGastos)}</Text>
                                        <Text>{factura.anticipo ? formatMoney(factura.anticipo) : "$0.00"}</Text>
                                        <Text>{formatMoney((factura.total + totalGastos) - factura.anticipo)}</Text>
                                    </View>
                                </View>
                            </View>
                            :
                            ""
                        }
                        <Text style={{ marginTop: 10 }}></Text>
                        <Text style={styles.heading}>CUENTAS BANCARIAS</Text>
                        <View style={styles.header}>
                            <View style={styles.dataCol}>
                                <Text style={styles.textBold}>DIVISA: PESOS MEXICANOS</Text>
                                <Text>BANCO: BBVA BANCOMER </Text>
                                <Text>CUENTA: 0118417865 </Text>
                                <Text>CLABE INTERBANCARIA: 012680001184178650  </Text>
                            </View>
                            <View style={styles.dataCol}>
                                <Text style={styles.textBold}>DIVISA: DOLARES AMERICANOS </Text>
                                <Text>BANCO: BBVA BANCOMER </Text>
                                <Text >CUENTA: 011 862 3422 </Text>
                                <Text >CLABE INTERBANCARIA: 012680001186234220 </Text>
                            </View>
                        </View>
                        <Text>BENEFICIARIO: KARLA JOSIMAR LOGISTICS SA DE CV </Text>
                        <Text>RFC: KLO210317KN1</Text>
                    </View>
                </Page>
                :
                <Page size="A4">
                </Page>
            }
        </Document>
    );

    return (
        <>
            <button
                className="btn btn-dark"
                type="button" onClick={handleOpen}>
                <i className="bi bi-file-earmark-arrow-down-fill"></i> Proforma
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='card style-modal-md'>
                    <div className='card-header d-flex justify-content-between'>
                        Preview Proforma Factura

                        <button className='btn btn-dark btn-sm'
                            onClick={handleClose}>
                            <i className="bi bi-x-lg"></i>
                        </button>
                    </div>
                    <div className='card-body body-modal-scroll'>
                        <PDFViewer
                            style={{
                                width: '100%',
                                height: '100%',
                                border: 'none',
                            }}
                            plugins={[defaultLayoutPluginInstance]}>
                            {doc}
                        </PDFViewer>

                        <div className='col-3 m-2'>
                            <label htmlFor="dimensionVolumen" className="form-label">ESPACIO ENTRE CONCEPTOS y TOTALES</label>
                            <input className='form-control' name='espacioCT'
                                value={espacioCT}
                                onChange={(e) => { setEspacioCT(e.target.value) }} />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ProformaFactura