import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal';
import { styleModalSM } from '../../../../assets/css/styles-react';
import { ToastError, ToastSuccess } from '../../../../utils/toastMessage';
import MUIDataTable from "mui-datatables";
import { deleteByIdNaviera, findAllCatalogoNaviera, saveCatalogoNaviera, updateNaviera } from '../../../../controllers/admin/embarques/catalogos/catalogoNaviera';
import { useDispatch } from 'react-redux';
import ModalEliminarObjeto from '../../ModalEliminarObjeto';
import { setLoading } from '../../../../redux/features/loaderSlice';
import { BASIC_OPTIONS_MUI } from '../../../../utils/mui/constantes';

function ModalAdministracionNavieras() {
  const navieraEntity = {
    id: '',
    nombre: '',
    creadoEn: ''
  }
  const [open, setOpen] = useState(false);
  const [naviera, setNaviera] = useState(navieraEntity)
  const [navieras, setNavieras] = useState([])
  const dispatch = useDispatch();

  const handleOpen = async () => {
    dispatch(setLoading({isLoading: true}))
    await fetchFindALLCatalogoNaviera();
    setOpen(true)
    dispatch(setLoading({isLoading: false}))
  };

  const handleChange = event => {
    event.target.value = event.target.value.toUpperCase();

    setNaviera({
      ...naviera,
      [event.target.name]: event.target.value
    })
  }

  const addNewNaviera = (newNaviera) => {
    setNavieras((prevNavieras) => [
      ...prevNavieras,
      [
        newNaviera.id,
        newNaviera.nombre,
        newNaviera.creadoEn,
      ],
    ]);
  };

  const updateExistingNaviera = (updatedNaviera) => {
    setNavieras((prevNavieras) => {
      const indexNav = prevNavieras.findIndex(item => item[0] === updatedNaviera.id);
      const newNavieras = [...prevNavieras];
      newNavieras[indexNav] = [
        updatedNaviera.id,
        updatedNaviera.nombre,
        updatedNaviera.creadoEn,
      ];
      return newNavieras;
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const isUpdate = naviera?.id != "";
      const action = isUpdate ? updateNaviera : saveCatalogoNaviera;

      dispatch(setLoading({isLoading: true}))
      const res = await action(naviera);
      dispatch(setLoading({isLoading: false}))

      if (res.error) return ToastError(res.message);

      ToastSuccess(res.message);
      isUpdate ? updateExistingNaviera(res.data) : addNewNaviera(res.data);
      setNaviera(navieraEntity);
    } catch (error) {
      return ToastError(error);
    }
  };


  const fetchFindALLCatalogoNaviera = async () => {
    try {
      const res = await findAllCatalogoNaviera()
      if (res.error) return ToastError(res.message)
      await formatoData(res.data)
    } catch (e) {
      ToastError(e)
    }
  }

  const fetchDeleteByIdNaviera = async (idNaviera) =>{
    try{
      const res = await deleteByIdNaviera(idNaviera);
      if (res?.error) return ToastError(res.message);

      //Eliminar la naviera del array
      setNavieras((prevNavieras) => {
        const indexNav = prevNavieras.findIndex(item => item[0] === idNaviera);
        const newNavieras = [...prevNavieras];
        newNavieras.splice(indexNav, 1)
        return newNavieras;
      });
      ToastSuccess(res.message);
    }catch(err){
      return ToastError(err)
    }
  }

  async function formatoData(data) {
    setNavieras(data.map((di) => [
      di.id,
      di.nombre,
      di.creadoEn
    ]))
  }

  const handleClose = () => setOpen(false);

  const columns = [
    {
      name: "Id",
      options: {
        display: "excluded",
        filter: false,
        sort: false,
        download: false,
      }
    },
    "Naviera",
    {
      name: "Fecha Registro",
      options: {
        display: "excluded",
        filter: false,
        sort: false,
        download: false,
      }
    },
    {
      name: "",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <button className='btn btn-warning'
              onClick={() => setNaviera({ id: navieras[dataIndex][0], nombre: navieras[dataIndex][1], creadoEn: navieras[dataIndex][2] })}>
              <i className="bi bi-pencil"></i>
            </button>
          )
        },
        filter: false,
        sort: false,
        download: false,
      }
    },
    {
      name: "",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <ModalEliminarObjeto
            functionEliminar={fetchDeleteByIdNaviera}
            id={navieras[dataIndex][0]}
            textHeader={"¿Estás seguro de eliminar esta naviera?"}>
            </ModalEliminarObjeto>
          )
        },
        filter: false,
        sort: false,
        download: false,
      }
    },]

  return (
    <>
      <button className='dropdown-item' onClick={handleOpen}>
        Administración Navieras
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="card" style={styleModalSM}>
          <div className="card-header">
            Administración Navieras
          </div>
          <div className="card-body body-modal-scroll">
            <form onSubmit={handleSubmit}>
              <div className='row g-3 row-cols-3'>
                <div className="col-12 mb-3">
                  <label htmlFor="inputNombre" className="form-label">Naviera<span className='required-span'>*</span></label>
                  <input
                    type="text"
                    required
                    className="form-control"
                    name='nombre'
                    value={naviera.nombre}
                    placeholder="Ingresa el nombre"
                    onChange={handleChange} />
                </div>
                <div className='col-12' align="right">
                  {naviera.id ?
                    <>
                      <span type='button' className="btn btn-secondary col-4" onClick={() => setNaviera(navieraEntity)}>Cancelar</span>
                      <button type="submit" className="btn btn-primary col-4">Actualizar</button>
                    </>
                    :
                    <button type="submit" className="btn btn-primary col-4">Guardar</button>

                  }
                </div>
              </div>
            </form>

            <MUIDataTable
              data={navieras}
              columns={columns}
              options={BASIC_OPTIONS_MUI}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalAdministracionNavieras