import { useState } from 'react'
import Modal from '@mui/material/Modal';
import { CondicionAduana } from '../../../../models/admin/cotizaciones/CondicionAduana';
import FormCondicionesAduana from '../forms/FormCondicionesAduana';
import TableCondicionesAduana from '../tables/TableCondicionesAduana';

function ModalCondicionesAduana() {
    const [open, setOpen] = useState(false);
    const [refreshData, setRefreshData] = useState({});
    const [condicionAduana, setCondicionAduana] = useState(CondicionAduana);

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setCondicionAduana(CondicionAduana);
        setOpen(false);
    }

    return (
        <>
            <button
                className="btn btn-primary m-1"
                type="button" onClick={handleOpen}>
                <i className="bi bi-plus"></i>CONDICIONES ADUANA
            </button>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <div className='card style-modal-sm'>
                    <div className='card-header d-flex justify-content-between'>
                        CONDICIONES DE ADUANA
                        <button className='btn btn-dark btn-sm'
                            onClick={handleClose}>
                            <i className="bi bi-x-lg"></i>
                        </button>
                    </div>
                    <div className='card-body body-modal-scroll'>
                        <FormCondicionesAduana
                        setRefreshData={setRefreshData}
                        condicionAduana={condicionAduana}
                        setCondicionAduana={setCondicionAduana} />
                        <TableCondicionesAduana 
                        refreshData={refreshData}
                        setCondicionAduana={setCondicionAduana}/>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ModalCondicionesAduana