import { CATALOGO_UNIDAD_MEDIDA_DIMENSION_C } from '../../../../utils/catalogosEstaticos';
import { FieldArray, Field, useFormikContext } from 'formik'
import FormikSelectSearch from '../../../formik/FormikSelectSearch';
import { useEffect } from 'react';

function DimensionCarga({ values }) {
    const { setFieldValue } = useFormikContext();

    const calculateVolumen = (largo, alto, ancho) => {
        return largo * alto * ancho || 0;
    };

    const calculateTotalVolume = (index, volumen, cantidadBultos) => {
        let totalVolumen = (cantidadBultos * volumen)?.toFixed(2);
        setFieldValue(`dimensionesCarga[${index}].totalVolumen`, totalVolumen);
        return totalVolumen;
    };

    const handleDimensionChange = (event, setFieldValue, index) => {
        let { name, value } = event.target;
        value = parseFloat(value);
        setFieldValue(name, value);

        const largo = values.dimensionesCarga[index]?.largo || 0;
        const alto = values.dimensionesCarga[index]?.alto || 0;
        const ancho = values.dimensionesCarga[index]?.ancho || 0;

        const volumen = calculateVolumen(
            name.includes('largo') ? value : largo,
            name.includes('alto') ? value : alto,
            name.includes('ancho') ? value : ancho
        );

        setFieldValue(`dimensionesCarga[${index}].volumen`, parseFloat(volumen.toFixed(2)));
    };

    useEffect(() => {
        let totalVolumenes = 0;
        values.dimensionesCarga?.map((cc, index) => {
            const totalVolumen = calculateTotalVolume(index, cc.volumen, cc.cantidadBultos);
            totalVolumenes += parseFloat(totalVolumen) || 0
        })
        setFieldValue(`totalVolumenes`, totalVolumenes?.toFixed(2));
    }, [values.dimensionesCarga])

    return (
        <>
            <div className='row mb-2'>
                <div className="col-sm-10">
                </div>
                <div className="col-sm-2">
                    <label htmlFor="totalVolumen" className="form-label">Total volumenes</label>
                    <Field id="totalVolumen" name={`totalVolumenes`} className="form-control" type={"number"} readOnly disabled />
                </div>
            </div>
            <FieldArray
                name="dimensionesCarga"
                render={arrayHelpers => (
                    <>
                        <div className="col-sm-12 mb-3" align="right">
                            <button type="button" className='btn btn-primary' onClick={() => arrayHelpers.push({})}>
                                <i className="bi bi-plus" /> Dimension
                            </button>
                        </div>
                        {values.dimensionesCarga.length >= 1 ? <b>Dimensiónes de Carga <hr /></b> : null}
                        {values.dimensionesCarga?.map((cc, index) => {
                            return (
                                <div className='row' key={index} style={{fontSize: "13px"}}>
                                    <div className='col-12' align="right">
                                        <button type='button' className='btn btn-sm btn-danger'
                                            onClick={() => arrayHelpers.remove(index)}>
                                            <i className="bi bi-trash"></i>
                                        </button>
                                    </div>
                                    <div className="col-sm-1 mb-2">
                                        <label htmlFor="cantidadBultos" className="form-label">Bultos</label>
                                        <Field id="cantidadBultos" name={`dimensionesCarga[${index}].cantidadBultos`} className="form-control" type={"number"}
                                            onChange={(event) => handleDimensionChange(event, setFieldValue, index)} />
                                    </div>
                                    <div className="col-sm-1">
                                        <label htmlFor="peroPorBulto" className="form-label">Peso por bulto</label>
                                        <Field id="pesoPorBulto" name={`dimensionesCarga[${index}].pesoPorBulto`} className="form-control" type={"number"} />
                                    </div>
                                    <div className="col-sm-3">
                                        <label htmlFor="embalaje" className="form-label">Embalaje</label>
                                        <Field id="embalaje" name={`dimensionesCarga[${index}].embalaje`} className="form-control" component="textarea" />
                                    </div>
                                    <div className='col-sm-2 mb-1'>
                                        <Field
                                            name={`dimensionesCarga[${index}].idUnidadMedidaDimension`}
                                            component={FormikSelectSearch}
                                            titulo="Unidad de medida"
                                            data={CATALOGO_UNIDAD_MEDIDA_DIMENSION_C} />
                                    </div>
                                    <div className="col-sm-1">
                                        <label htmlFor="dimensionLargo" className="form-label">Largo</label>
                                        <Field id="largo" name={`dimensionesCarga[${index}].largo`} className="form-control" type={"number"}
                                            onChange={(event) => handleDimensionChange(event, setFieldValue, index)} />
                                    </div>
                                    <div className='col-sm-1'>
                                        <label htmlFor="dimensionAlto" className="form-label">Alto</label>
                                        <Field id="alto" name={`dimensionesCarga[${index}].alto`} className="form-control" type={"number"}
                                            onChange={(event) => handleDimensionChange(event, setFieldValue, index)} />
                                    </div>
                                    <div className='col-sm-1'>
                                        <label htmlFor="dimensionAncho" className="form-label">Ancho</label>
                                        <Field id="ancho" name={`dimensionesCarga[${index}].ancho`} className="form-control" type={"number"}
                                            onChange={(event) => handleDimensionChange(event, setFieldValue, index)} />
                                    </div>
                                    <div className="col-sm-1 mb-3">
                                        <label htmlFor="dimensionVolumen" className="form-label">Volumen</label>
                                        <Field id="volumen" name={`dimensionesCarga[${index}].volumen`} className="form-control" type={"number"} readOnly disabled />
                                    </div>
                                    <div className="col-sm-1 mb-3">
                                        <label htmlFor="totalVolumen" className="form-label">Total volumen</label>
                                        <Field id="totalVolumen" name={`dimensionesCarga[${index}].totalVolumen`} className="form-control" type={"number"} readOnly disabled />
                                    </div>
                                </div>
                            )
                        })}
                        {values.dimensionesCarga.length >= 1 ? <hr /> : null}
                    </>
                )}
            />
        </>
    );
}

export default DimensionCarga;