import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    message: null
}


export const loaderSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        setLoading: (state, action) =>{
            const {isLoading, message} = action.payload
            state.loading = isLoading;
            state.message = message;
        },
    }
});

export const {setLoading} = loaderSlice.actions;

export default loaderSlice.reducer;