import React, { useState } from 'react'
import Modal from '@mui/material/Modal';
import { addItem, deleteItem, handleChangeArrayNumber, handleChangeArrayText } from '../../../../utils/helpersState';
import MUIDataTable from "mui-datatables";
import { OPTIONS_MUI_ONLY_TABLE } from '../../../../utils/mui/constantes';
import ModalEliminarObjeto from '../../ModalEliminarObjeto';
import { deleteGastoById, findAllByIdFacturaCliente, saveAllGastoFacturaCliente } from '../../../../controllers/admin/facturas/gastoFacturaClienteController';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../../redux/features/loaderSlice';
import { ToastError, ToastSuccess } from '../../../../utils/toastMessage';

function GastosCuentaCliente({facturaCliente}) {
    const [open, setOpen] = useState(false);
    const [gastos, setGastos] = useState([]);
    const dispatch = useDispatch()

    const handleOpen = () => {
        fetchGastos()
        setOpen(true)
    };

    const fetchGastos = async () => {
        const res = await findAllByIdFacturaCliente(facturaCliente.id);
        setGastos(res.data)
    }

    const handleClose = () => {
        setOpen(false);
    }

    const addGasto = () => {
        const attributes = {factura: {id: facturaCliente.id}}
        addItem(gastos, setGastos, attributes);
    }

    const onSubmit = async () =>{
        try {
            dispatch(setLoading({isLoading: true}))
            const res = await saveAllGastoFacturaCliente(gastos);
            dispatch(setLoading({isLoading: false}))
            if (res.error) return ToastError(res.message)
            await fetchGastos();
            return ToastSuccess(res.message)
        } catch (e) {
            dispatch(setLoading({isLoading: false}))
            return ToastError(e.message)
        }
    }

    const fetchDeleteGastoById = async (idGastoFacturaCliente, index) =>{
        try {
            dispatch(setLoading({isLoading: true}))
            const res = await deleteGastoById(idGastoFacturaCliente);
            dispatch(setLoading({isLoading: false}))
            if (res.error) return ToastError(res.message)
            deleteItem(index, gastos, setGastos)
            return ToastSuccess(res.message)
        } catch (e) {
            dispatch(setLoading({isLoading: false}))
            return ToastError(e.message)
        }
    }

    const columnsGastos = [
        {
            name: "Id",
            options: {
                display: "excluded",
                filter: false,
                sort: false,
                download: false,
            }
        },
        {
            name: "CONCEPTO",
            options: {
                customBodyRenderLite: (index) => {
                    return (
                        <input
                            type="text"
                            className="form-control"
                            name='concepto'
                            value={gastos[index].concepto}
                            onChange={(e) => handleChangeArrayText(e, index, gastos, setGastos, true)} />
                    )
                },
                sort: false,
                download: false,
            },
        },
        {
            name: "IMPORTE",
            options: {
                customBodyRenderLite: (index) => {
                    return (
                        <input
                            type="number"
                            className="form-control"
                            name='importe'
                            value={gastos[index].importe}
                            onChange={(e) => handleChangeArrayNumber(e, index, gastos, setGastos)} />
                    )
                },
                sort: false,
                download: false,
            },
        },
        {
            name: "",
            options: {
                customBodyRenderLite: (index) => {
                    return (
                        gastos[index].id ?
                            <>
                                <ModalEliminarObjeto
                                    textHeader={"¿Seguro de eliminar este gasto?"}
                                    textBody={""}
                                    functionEliminar={fetchDeleteGastoById}
                                    id={gastos[index].id}
                                    index1={index}>
                                </ModalEliminarObjeto>
                            </>
                            :
                            <button type='button' className='btn btn-danger'
                                onClick={() => deleteItem(index, gastos, setGastos)}>
                                <i className="bi bi-trash-fill"></i>
                            </button>
                    )
                },
                sort: false,
                download: false,
            },
        },
    ]

    return (
        <>
            <button
                className="btn btn-dark m-1"
                type="button" onClick={handleOpen}>
                <i className="bi bi-cash"></i> GASTOS
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='card style-modal-sm'>
                    <div className='card-header d-flex justify-content-between'>
                        GASTOS CUENTA CLIENTE

                        <button className='btn btn-dark btn-sm'
                            onClick={handleClose}>
                            <i className="bi bi-x-lg"></i>
                        </button>
                    </div>
                    <div className='card-body body-modal-scroll'>

                        <div className='col-12' align="right">
                            <button type='button' className='btn btn-dark m-2'
                                onClick={addGasto}>
                                <i className="bi bi-plus"></i> AGREGAR
                            </button>
                            <button type='button'
                                className='btn btn-primary'
                                onClick={() => { onSubmit() }}>
                                    Guardar
                            </button>
                        </div>

                        <div className='col-12'>
                            <MUIDataTable
                                title={"DESGLOSE DE GASTOS"}
                                data={gastos}
                                columns={columnsGastos}
                                options={{
                                    ...OPTIONS_MUI_ONLY_TABLE,
                                    responsive: "standard"
                                }}
                            />

                        </div>


                    </div>
                </div>
            </Modal>
        </>
    )
}

export default GastosCuentaCliente