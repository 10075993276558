import Table from 'react-bootstrap/Table';
import { useDispatch } from 'react-redux';
import { agregarConceptoFactura, eliminarConceptoFactura, updateFacturaConcepto } from '../../../../redux/features/admin/controlCostosSlice';
import { eliminarConcepotPorId, guardarConceptosFactura } from '../../../../controllers/admin/facturas/facturaClienteController';
import { ToastError, ToastSuccess } from '../../../../utils/toastMessage';
import ModalEliminarObjeto from '../../ModalEliminarObjeto';
import { formatMoney } from '../../../../utils/formatMoney';
import { setLoading } from '../../../../redux/features/loaderSlice';
import { validateSaveConceptos } from './validations/validate-conceptos';
import { useEffect, useState } from 'react';

function ConceptosFactura({
    conceptos,
    indexFC,
    idFacturaCliente,
    recargarEmbarqueData,
    facturaCliente }) {
    const dispatch = useDispatch();
    const [existRetenciones, setExistRetenciones] = useState(false);

    useEffect(() => {
        let retenciones = facturaCliente?.conceptosFactura?.some(concepto => 
            Array.isArray(concepto.retenciones) && concepto.retenciones.length > 0
        );
        setExistRetenciones(retenciones)
    }, [facturaCliente])

    const onChangeConceptosF = (event, indexCF) => {
        const { name, value } = event.target;
        dispatch(updateFacturaConcepto({ indexFC, name, value, indexCF }))
    }

    const handleChangeChecked = (event, indexCF) => {
        const { name, checked } = event.target;
        dispatch(updateFacturaConcepto({ indexFC, name, value: checked, indexCF }))
    }


    const fetchGuardarConceptosFactura = async () => {
        try {
            validateSaveConceptos(conceptos)
            dispatch(setLoading({ isLoading: true }))
            const res = await guardarConceptosFactura(idFacturaCliente, conceptos)
            dispatch(setLoading({ isLoading: false }))
            if (res.error) return ToastError(res.message)
            recargarEmbarqueData()
            ToastSuccess(res.message)
        } catch (e) {
            ToastError(e.message)
        }
    }

    const fetchEliminarConcepotPorId = async (idConcepto) => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const res = await eliminarConcepotPorId(idConcepto);
            dispatch(setLoading({ isLoading: false }))
            if (res.error) return ToastError(res.message)
            recargarEmbarqueData()
            ToastSuccess(res.message)
        } catch (e) {
            ToastError(e)
        }
    }

    return (
        <div style={{ backgroundColor: "white" }}>
            <div className='col-12' align="right">
                <button type='button' className='btn btn-sm btn-dark m-1'
                    onClick={() => { dispatch(agregarConceptoFactura({ indexFC })) }}>
                    <i className="bi bi-plus-lg"></i> Agregar Concepto
                </button>
                <button type='button' className='btn btn-sm btn-primary m-1'
                    onClick={() => { fetchGuardarConceptosFactura() }}>
                    {conceptos?.length >= 1 ? "Actualizar Conceptos" : "Guardar Conceptos"}
                </button>
            </div>
            <Table responsive>
                <thead>
                    <tr>
                        <th>CANTIDAD</th>
                        <th>NO. IDENTIFICACIÓN</th>
                        <th>DESCRIPCIÓN</th>
                        <th>IVA</th>
                        <th>VALOR UNITARIO</th>
                        <th>IMPORTE</th>
                        {existRetenciones && <th>RETENCIONES</th>}
                        <th>TOTAL</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {conceptos ?
                        conceptos.map((concepto, indexCF) => {
                            return (
                                <tr key={indexCF}>
                                    <td>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name='cantidad'
                                            value={concepto.cantidad}
                                            onChange={event => { onChangeConceptosF(event, indexCF) }}
                                            style={{ width: '80px' }} />
                                    </td>
                                    <td>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            name='noIdentificacion'
                                            value={concepto.noIdentificacion}
                                            onChange={event => { onChangeConceptosF(event, indexCF) }}></textarea>
                                    </td>
                                    <td>
                                        <textarea
                                            className="form-control"
                                            name='descripcion'
                                            value={concepto.descripcion}
                                            onChange={event => { onChangeConceptosF(event, indexCF) }}
                                            style={{ width: '400px' }} ></textarea>
                                    </td>
                                    <td>
                                        <input
                                            className="form-check-input mt-1"
                                            type="checkbox"
                                            checked={concepto.tieneIVA}
                                            onChange={(e) => { handleChangeChecked(e, indexCF) }}
                                            name="tieneIVA"
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name='valorUnitario'
                                            value={concepto.valorUnitario}
                                            onChange={event => { onChangeConceptosF(event, indexCF) }}
                                            style={{ minWidth: '100px' }} />
                                    </td>
                                    <td>
                                        {formatMoney(concepto.importe)}
                                    </td>
                                    {existRetenciones && <td>{concepto?.retenciones?.map(retencion => {return ( <> {formatMoney(retencion?.importe)} <br/> </> ) } )}</td>}
                                    <td>
                                        {formatMoney(concepto.totalConcepto)}
                                    </td>
                                    <td>
                                        {
                                            concepto.id ?
                                                <ModalEliminarObjeto
                                                    textHeader={"¿Seguro de eliminar este concepto?"}
                                                    textBody={"Se recalculara el total de la factura y el profit"}
                                                    functionEliminar={fetchEliminarConcepotPorId}
                                                    id={concepto.id}>
                                                </ModalEliminarObjeto>
                                                :
                                                <button type='button' className='btn btn-danger'
                                                    onClick={() => { dispatch(eliminarConceptoFactura({ indexFC, indexCF })) }}>
                                                    <i className="bi bi-trash"></i>
                                                </button>
                                        }
                                    </td>
                                </tr>
                            )
                        })
                        :
                        "NO SE ENCONTRARON CONCEPTOS"}
                    {conceptos &&
                        <>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <th>SUBTOTAL</th>
                                {existRetenciones && <th>TOTAL RETENCIONES</th>}
                                <th>TOTAL</th>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{formatMoney(facturaCliente.subTotal)}</td>
                                {existRetenciones && <td>{formatMoney(facturaCliente.totalRetenciones)}</td>}
                                <td>{formatMoney(facturaCliente.total)}</td>
                                <td></td>
                            </tr>
                        </>
                    }
                </tbody>
            </Table>
        </div>
    )
}

export default ConceptosFactura