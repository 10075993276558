export const getIPFromApi64 = async () => {
    try {
        const response = await fetch("https://api64.ipify.org?format=json");
        const data = await response.json();
        return data.ip;
    } catch (error) {
        console.error("Error fetching IP:", error);
        return null;
    }
}

export const traductHttpMethods = (method) =>{
    let traduction = "";
    if (method == "POST") {
        traduction = "GUARDADO"
    } else if (method == "PUT") {
        traduction = "ACTUALIZACIÓN"
    } else if (method == "DELETE") {
        traduction = "ELIMINACIÓN"
    }
    return traduction
}