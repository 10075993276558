import React, { useState } from 'react'
import Modal from '@mui/material/Modal';
import { ToastError, ToastSuccess } from '../../../utils/toastMessage';
import { eliminaDireccionCliente, guardarEditarDireccion, listarDirecciones } from '../../../controllers/admin/direcciones/direccionesClienteController';
import MUIDataTable from "mui-datatables";
import CommunDirecciones from './CommunDirecciones';
import Accordion from 'react-bootstrap/Accordion';
import { BASIC_OPTIONS_MUI } from '../../../utils/mui/constantes';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../redux/features/loaderSlice';

function ModalAdministracionDirecciones({ titulo }) {
    const direccionesModel = {
        id: '',
        razonSocial: '',
        calle: '',
        numeroInt: '',
        numeroExt: '',
        colonia: '',
        municipio: '',
        codigoPostal: '',
        estado: '',
        pais: '',
        rfc: '',
        direccion: '',
        creadoEn: ''
    }

    const [open, setOpen] = useState(false);
    const [direccion, setDireccion] = useState(direccionesModel)
    const [direcciones, setDirecciones] = useState([])
    const [direccionesMUI, setDireccionesMUI] = useState([]);
    const dispatch = useDispatch();

    const handleOpen = () => {
        fetchDataDE()
        setDireccion(direccionesModel)
        setOpen(true)
    };

    const handleClose = () => setOpen(false);

    const handleChange = event => {
        event.target.value = event.target.value.toUpperCase();
        setDireccion({
            ...direccion,
            [event.target.name]: event.target.value
        })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            dispatch(setLoading({ isLoading: true }))
            const res = await guardarEditarDireccion(direccion)
            dispatch(setLoading({ isLoading: false }))
            if (res.error) return ToastError(res.message)
            ToastSuccess(res.message)
            setDireccion(direccionesModel)
            fetchDataDE()
        } catch (error) {
            return ToastError(error);
        }
    }

    const fetchDataDE = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const res = await listarDirecciones()
            dispatch(setLoading({ isLoading: false }))
            if (res.error) return ToastError(res.message)
            setDireccionesMUI(res.data.map((di) => [
                di.id,
                di.direccion,
                di.creadoEn
            ]))
            setDirecciones(res.data)
        } catch (e) {
            ToastError(e)
        }
    };

    const fetchEliminaDireccion = async (idDireccion, indexDireccion) => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const res = await eliminaDireccionCliente(idDireccion)
            dispatch(setLoading({ isLoading: false }))
            if (res.error) return ToastError(res.message)
            // Eliminar la dirección del array
            const newDirecciones = [...direccionesMUI];
            newDirecciones.splice(indexDireccion, 1);
            // Actualizar el estado con el nuevo array de direcciones
            setDireccionesMUI(newDirecciones);
            return ToastSuccess(res.message)
        } catch (e) {
            return ToastError(e)
        }
    };

    function editarDireccion(direccion) {
        setDireccion(direccionesModel)
        setDireccion(direccion);
    }

    const columns = [
        {
            name: "Direcciones",
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return (
                        direccionesMUI[dataIndex][1]
                    )
                },
            },
        },
        {
            name: "",
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <button className='btn btn-warning'
                            onClick={() => editarDireccion(direcciones[dataIndex])}>
                            <i className="bi bi-pencil"></i>
                        </button>
                    )
                },
                filter: false,
                sort: false,
                download: false,
            },
        },
        {
            name: "",
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <button className='btn btn-danger'
                            onClick={() => fetchEliminaDireccion(direcciones[dataIndex].id, dataIndex)}>
                            <i className="bi bi-trash"></i>
                        </button>
                    )
                },
                filter: false,
                sort: false,
                download: false,
            },
        }
    ]

    return (
        <>
            <button className='dropdown-item' onClick={handleOpen}>
                {titulo}
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="card style-modal-sm">
                    <div className="card-header d-flex justify-content-between">
                        {titulo}
                        <button className='btn btn-dark btn-sm'
                            onClick={handleClose}>
                            <i className="bi bi-x-lg"></i>
                        </button>
                    </div>
                    <div className="card-body body-modal-scroll">
                        <form onSubmit={handleSubmit}>
                            <div className='row g-3 row-cols-3'>
                                <div className='col-12 mb-4'>
                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>{direccion.id ? `EDITAR DIRECCIÓN - ${direccion.razonSocial}` : "REGISTRAR DIRECCIÓN"} </Accordion.Header>
                                            <Accordion.Body>
                                                <div className='row'>
                                                    <div className="col-12 mb-3">
                                                        <label htmlFor="inputNombre" className="form-label">Razón Social</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name='razonSocial'
                                                            value={direccion.razonSocial}
                                                            onChange={handleChange}
                                                            required />
                                                    </div>
                                                    <CommunDirecciones
                                                        value={direccion}
                                                        handleChange={handleChange}></CommunDirecciones>
                                                    <div className="col-6 mb-3">
                                                        <label htmlFor="inputNombre" className="form-label">RFC</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name='rfc'
                                                            value={direccion.rfc}
                                                            onChange={handleChange} />
                                                    </div>
                                                    <div className="col-12 mb-3">
                                                        <label htmlFor="inputNombre" className="form-label">Dirección</label>
                                                        <input
                                                            type="text"
                                                            readOnly
                                                            className="form-control"
                                                            name='direccion'
                                                            value={direccion.direccion}
                                                            onChange={handleChange} />
                                                    </div>
                                                    <div className='col-12' align="right">
                                                        {direccion.id ?
                                                            <>
                                                                <button type="button" className="btn btn-secondary col-4" onClick={() => setDireccion(direccionesModel)}>Cancelar</button>
                                                                <button type="submit" className="btn btn-primary col-4">Actualizar</button>
                                                            </>
                                                            :
                                                            <button type="submit" className="btn btn-primary col-4">Guardar</button>

                                                        }
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>

                            </div>
                        </form>

                        <MUIDataTable
                            data={direccionesMUI}
                            columns={columns}
                            options={BASIC_OPTIONS_MUI}
                        />
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ModalAdministracionDirecciones