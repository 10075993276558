import { URL_FACTURA_CLIENTE } from "../../../utils/constantes";
import { apiRequest } from "../../handlerApiRequest";

export const listarFacturasClientePorIdEmbarque = async (idEmbarque) => {
    return await apiRequest(URL_FACTURA_CLIENTE+`/embarque/${idEmbarque}`)
}

export const facturasClienteWithFilters = async (urlParams) => {
    return await apiRequest(URL_FACTURA_CLIENTE + "/filters","GET", null, urlParams)
}

export const logicDeleteFacturaCliente = async (idFacturaCliente, razonEliminacion) => {
    return await apiRequest(URL_FACTURA_CLIENTE+`/${idFacturaCliente}?razonEliminacion=${razonEliminacion}`, 'DELETE')
}

export const guardarFacturaDTO = async (factura, idEmbarque) => {
    //Se agrega aqui el id del embarque por la sincronia de javascriptn que no se alcance a setear en el store
    const facturaCopy = structuredClone(factura);
    facturaCopy.idEmbarque = idEmbarque
    return await apiRequest(URL_FACTURA_CLIENTE, "POST", facturaCopy);
}

export const actualizarFacturaDTO = async (factura) => {
    return await apiRequest(URL_FACTURA_CLIENTE, "PUT", factura);
}

export const guardarConceptosFactura = async (idFactura, conceptosFactura) => {
    return await apiRequest(URL_FACTURA_CLIENTE+`/${idFactura}/conceptos-factura`, "POST", conceptosFactura);
}

export const eliminarConcepotPorId = async (idConceptoFactura) => {
    return await apiRequest(URL_FACTURA_CLIENTE+`/concepto-factura/${idConceptoFactura}`, "DELETE");
}

export const subirArchivoFacturaCliente = async (archivo, idFacturaCliente, tipoArchivo, importarDatos) => {
    const formData = new FormData();
    if(importarDatos == undefined || importarDatos == null) importarDatos = false;
    if (archivo != null) formData.append("archivo", archivo, archivo.name);
    formData.append("tipoArchivo", tipoArchivo)

    return await apiRequest(URL_FACTURA_CLIENTE + "/" + idFacturaCliente + "/subir-archivo", "POST", formData, "?importarDatosXML="+importarDatos, true);
}

export const actualizarArchivoFacturaCliente = async (idFacturaCliente, archivoS3, archivo, tipoArchivo, importarDatos) => {
    const formData = new FormData();
    if(importarDatos == undefined || importarDatos == null) importarDatos = false;
    if (archivo != null) formData.append("archivo", archivo, archivo.name);
    formData.append("archivoS3", new Blob([JSON.stringify(archivoS3)], {
        type: "application/json"
    }));
    formData.append("tipoArchivo", tipoArchivo)

    return await apiRequest(URL_FACTURA_CLIENTE + "/" + idFacturaCliente  + "/actualizar-archivo", "PUT", formData, "?importarDatosXML="+importarDatos, true);
}