import { getBlobCotizacionesCustomPDF, getBlobCotizacionesPDF } from "../../../components/admin/cotizaciones/files/CotizacionGenerateDoc"
import { URL_COTIZACIONES } from "../../../utils/endpoints/cotizaciones"
import { apiRequest } from "../../handlerApiRequest"
import { apiRequestV2 } from "../../handlerApiRequestV2"

export const findAllCotizaciones = async () => {
    return await apiRequestV2({
        endpoint: URL_COTIZACIONES
    })
}

export const findCotizacionesWithFilters = async (urlParams) => {
    return await apiRequestV2({
        endpoint: `${URL_COTIZACIONES}/filters`,
        urlParams: urlParams
    })
}

export const saveCotizacion = async (cotizacion) => {
    return await apiRequestV2({
        endpoint: URL_COTIZACIONES,
        method: "POST",
        data: cotizacion,
        showSuccessMessage: true
    })
}

export const updateCotizacion = async (cotizacion, idCotizacion) => {
    return await apiRequestV2({
        endpoint: `${URL_COTIZACIONES}/${idCotizacion}`,
        method: "PUT",
        data: cotizacion,
        showSuccessMessage: true
    })
}

export const cotizacionLogicDeleteById = async (idCotizacion, cotizacion) => {
    return await apiRequestV2({
        endpoint: `${URL_COTIZACIONES}/logic/${idCotizacion}?reasonDeleted=${cotizacion.reasonDeleted}`,
        method: "DELETE",
        showSuccessMessage: true
    })
}

export const findCotizacionBySearchText = async (searchText) => {
    return await apiRequest(`${URL_COTIZACIONES}/search`, 'GET', null, `?searchText=${searchText}`);
}


export const uploadFileCotizacion = async (cotizacion, idEmbarque) => {

    if (cotizacion) {
        const formData = new FormData();
        const fileBlob = await getBlobCotizacionesPDF(cotizacion);
        formData.append("file", fileBlob, 'cotizacion-pdf.pdf');
        return await apiRequestV2({
            endpoint: `${URL_COTIZACIONES}/${cotizacion.id}/upload-file`,
            urlParams: `?idEmbarque=${idEmbarque}`,
            data: formData,
            isFormData: true,
            method: "POST",
            showSuccessMessage: true
        })
    }

}

export const updateFileCotizacion = async ({
    cotizacion,
    orientation,
    fileCotizacion,
    fontSizeTitle,
    fontSizeContent}) => {

    if (cotizacion) {
        const formData = new FormData();
        const fileBlob = await getBlobCotizacionesCustomPDF({
            orientation: orientation,
            fontSizeTitle: fontSizeTitle,
            fontSizeContent: fontSizeContent,
            cotizacion: cotizacion
        });
        formData.append("fileToUpdate", fileBlob, 'cotizacion-pdf.pdf');
        formData.append("fileUploaded", new Blob([JSON.stringify(fileCotizacion)], {
            type: "application/json"
        }));
        return await apiRequestV2({
            endpoint: `${URL_COTIZACIONES}/update-file`,
            data: formData,
            isFormData: true,
            method: "PUT",
            showSuccessMessage: true
        })
    }

}



export const formatFormikCotizacion = (cotizacion) => {
    cotizacion.ventas?.map(venta => {
        const dimensionCarga = venta.dimensionesCarga;
        dimensionCarga ? venta.dimensionesCarga = JSON.parse(dimensionCarga) : dimensionCarga;
    })
}