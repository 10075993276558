import React, { useEffect, useState } from 'react'
import MUIDataTable from 'mui-datatables'
import { deleteCondicionAduana, findAllCondicionesAduana } from '../../../../controllers/admin/cotizaciones/condicionesAduanaController';
import { BASIC_OPTIONS_MUI, OPTIONS_CUSTOM_RETURN_COLUMN } from '../../../../utils/mui/constantes';
import { columnDeleteData } from '../../../mui/columnDeleteData';

function TableCondicionesAduana({refreshData, setCondicionAduana}) {
    const [condicionesAduana, setCondicionesAduana] = useState([])

    useEffect(() => {
        sendfindAllCondicionesAduana();
    }, [refreshData]);

    const sendfindAllCondicionesAduana = async () => {
        const data = await findAllCondicionesAduana();
        if (data != null) setCondicionesAduana(data);
    }

    const columns = [
        { label: "Servicio", name: "nombre" },
        {
            name: "",
            options: {
                customBodyRenderLite: (index) => {
                    return (
                        <button type='button'
                            className='btn btn-dark'
                            onClick={() => {
                                setCondicionAduana(condicionesAduana[index])
                            }}><i className="bi bi-pen-fill"></i></button>
                    )
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            },
        },
        columnDeleteData({
            functionEliminar: deleteCondicionAduana,
            data: condicionesAduana,
            textHeader: "¿Está seguro que deseas eliminar esta condicion de aduana?",
            state: condicionesAduana,
            setState: setCondicionesAduana,
            spliceItem: true
        }),
    ]



    return (
        <div className='row mt-2'>

            <MUIDataTable
                title={"CONDICIONES ADUANA PREDEFINIDAS"}
                data={condicionesAduana}
                columns={columns}
                options={{
                    ...BASIC_OPTIONS_MUI,
                    responsive: "standard",
                    enableNestedDataAccess: '.'
                }}
            />
        </div>
    )
}

export default TableCondicionesAduana