import React from 'react'
import RepartosDireccionDesglose from '../RepartosDireccionDesglose';
import DimensionCarga from '../DimensionCarga';
import TipoCarga from '../TipoCarga';
import { CATALOGO_TIPO_UNIDADES_MEDIDA } from '../../../../../utils/catalogosEstaticos';
import { useSelector } from 'react-redux';
import { findAllDireccionesBySearchText } from '../../../../../controllers/admin/direcciones/direccionesClienteController';
import { ErrorMessage, Field, Form } from 'formik';
import FormikSelectSearchDB from '../../../../formik/FormikSelectSearchDB';
import FormikGenericDate from '../../../../formik/FormikGenericDate';
import FormikSelectSearch from '../../../../formik/FormikSelectSearch';

function DesgloseTerrestre({
    values,
    tiposUnidadDesglose,
    tiposCargaDesglose,
    tiposServicioDesglose,
    indexDesglose }) {
    const embarqueData = useSelector((state) => state.embarque.embarqueData);

    return (
        <>
            {!embarqueData.soloDespachoAduanal ?
                <>
                    <div className='row'>
                        <div className='col-sm-6 mb-1'>
                            <Field
                                name="direccionRecoleccion"
                                component={FormikSelectSearchDB}
                                titulo="Direcciones de Recolección"
                                fetchSearch={findAllDireccionesBySearchText}
                                type={"DIRECCION"} />
                            <ErrorMessage name={`direccionRecoleccion`} component='span' className='text-danger'></ErrorMessage>
                        </div>
                        <div className='col-sm-6 mb-1'>
                            <Field
                                name="direccionEntrega"
                                component={FormikSelectSearchDB}
                                titulo="Direcciones de Entrega"
                                fetchSearch={findAllDireccionesBySearchText}
                                type={"DIRECCION"} />
                            <ErrorMessage name={`direccionEntrega`} component='span' className='text-danger'></ErrorMessage>
                        </div>
                        <div className='col-sm-6 mb-1'>
                            <Field
                                name="fechaEstimadaRecoleccion"
                                component={FormikGenericDate}
                                nameLabel="Fecha Estimada de Recolección" />
                        </div>
                        <div className='col-sm-6 mb-1'>
                            <Field
                                name="fechaEstimadaEntrega"
                                component={FormikGenericDate}
                                nameLabel="Fecha Estimada de Entrega" />
                        </div>
                        <div className='col-sm-6 mb-1'>
                            <Field
                                name="fechaEfectivaRecoleccion"
                                component={FormikGenericDate}
                                nameLabel="Fecha Efectiva de Recolección" />
                        </div>
                        <div className='col-sm-6 mb-1'>
                            <Field
                                name="fechaEfectivaEntrega"
                                component={FormikGenericDate}
                                nameLabel="Fecha Efectiva de Entrega" />
                        </div>
                    </div>
                </>
                :
                ""
            }

            {values.id != null ?
                <RepartosDireccionDesglose
                    values={values}>
                </RepartosDireccionDesglose>
                :
                <></>
            }
            <div className='row'>


                <div className='col-sm-3 mb-3'>
                    <Field
                        name="tipoUnidad"
                        component={FormikSelectSearch}
                        titulo="Tipo de Unidad"
                        data={tiposUnidadDesglose} />
                </div>
                <div className='col-sm-1'>
                    <label htmlFor="inputDestino" className="form-label">Cantidad</label>
                    <Field id="cantidadUnidad" name="cantidadUnidad" className="form-control" type={"number"}/>
                </div>
                <div className='col-sm-2 mb-3'>
                    <label className="form-label">Modalidad</label>
                    <br/>
                    <label>
                        <Field type="radio" name="modalidadUnidad" value="SENCILLO" />
                        SENCILLO
                    </label><br/>
                    <label>
                        <Field type="radio" name="modalidadUnidad" value="FULL" />
                        FULL
                    </label>
                </div>
                <div className="col-sm-3 mb-3">
                    <label htmlFor="mercanciaTrasladar" className="form-label">Mercancía a trasladar</label>
                    <Field id="mercanciaTrasladar" name="mercanciaTrasladar" className="form-control" component={"textarea"}/>
                </div>
                <div className='col-sm-3 mb-3'>
                    <TipoCarga
                        catalogoTipoCarga={values?.catalogoTipoCarga}
                        tiposCargaDesglose={tiposCargaDesglose}
                        values={values}
                        name={""}></TipoCarga>
                </div>
                <div className="col-sm-12 mb-3">
                    <label className="form-label"></label>
                    <div className="form-check form-switch">
                        <Field type="checkbox" name="esFletePrincipal" className="form-check-input" role="switch" />
                        <label className="form-check-label" htmlFor={`fletePrincipal-${indexDesglose}`}>Flete Principal</label>
                    </div>
                </div>

                <div className='col-sm-4 mb-1'>
                    <Field
                        name="tipoServicio"
                        component={FormikSelectSearch}
                        titulo="Tipo de Servicio"
                        data={tiposServicioDesglose}
                        returnObject={true} />
                </div>
                <div className="col-sm-3 mb-3">
                    <label htmlFor="inputDestino" className="form-label">Cantidad Total de Bultos</label>
                    <Field id="cantidadTotalBultos" name="cantidadTotalBultos" className="form-control" type={"number"} />
                </div>
                <div className="col-sm-3 mb-3">
                    <label htmlFor="inputDestino" className="form-label">Peso Total Bruto</label>
                    <Field id="pesoTotalBruto" name="pesoTotalBruto" className="form-control" type={"number"} />
                </div>
                <div className='col-sm-2 mb-3'>
                    <Field
                        name="unidadDeMedida"
                        component={FormikSelectSearch}
                        titulo="Unidad de medida"
                        data={CATALOGO_TIPO_UNIDADES_MEDIDA} />
                </div>
            </div>
            <DimensionCarga values={values}></DimensionCarga>
        </>
    )
}

export default DesgloseTerrestre