import React, { useState } from 'react'
import Modal from '@mui/material/Modal';
import { useDispatch } from 'react-redux';
import { resetEmbarque, setEmbarque } from '../../../redux/features/admin/embarqueSlice';
import HeaderInfoEmbarque from '../../admin/embarques/HeaderInfoEmbarque';
import ListCostos from './ListCostos';

function ModalCostos({embarque,fetchEmbarque}) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleOpen = () => {
    dispatch(setEmbarque(embarque))
    setOpen(true);
    localStorage.setItem("idEmbarque", embarque?.id);
  };

  const handleClose = () => {
    dispatch(resetEmbarque())
    fetchEmbarque()
    setOpen(false);
    localStorage.removeItem("idEmbarque");
  }

  return (
    <>
      <button
        className="btn btn-primary"
        type="button" onClick={handleOpen}>
        <i className="bi bi-coin"></i>
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className='card style-modal-md'>
          <div className='card-header d-flex justify-content-between'>
            Costos
            <button className='btn btn-dark btn-sm'
              onClick={handleClose}>
              <i className="bi bi-x-lg"></i>
            </button>
          </div>
          <div className='card-body body-modal-scroll'>
            <HeaderInfoEmbarque></HeaderInfoEmbarque>

            <ListCostos></ListCostos>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalCostos