import React, { useState } from 'react'
import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { actualizarUsuario, buscarPorIdUsuarioParaActualizar, guardarUsuario } from '../../controllers/admin/usuariosController';
import { resetUsuario, setIdUsuario, setUsuario } from '../../redux/features/usuarioSlice';
import { ToastError, ToastSuccess } from '../../utils/toastMessage';
import { ID_ROL_CLIENTES, ID_ROL_PROVEEDORES } from '../../utils/constantes';
import ModalDireccionFiscal from './clientes/ModalDireccionFiscal';
import ArchivosUsuario from './ArchivosUsuario';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import SelectSearch from '../inputs/SelectSearch';
import { findAllByPerteneceA } from '../../controllers/admin/sat/CatalogoRegimenFiscal';
import { CATALOGO_SAT_METODO_PAGO, CATALOGO_SAT_USO_CFDI } from '../../utils/catalogosEstaticos';
import { setLoading } from '../../redux/features/loaderSlice';
import UpdatePasswordModal from './ChangePasswordModal';
import ModalAdminEmails from './ModalAdminEmails';

function ModalFormUsuarios({
    url,
    idUsuario,
    titulo,
    fetchDataUsuarios,
    children,
    idRol }) {
    const usuario = useSelector((state) => state.usuario.usuarioModel)
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [passwordType, setPasswordType] = useState("password");
    const [regimenFiscal, setRegimenFiscal] = useState([]);

    const handleOpen = async () => {
        dispatch(setLoading({ isLoading: true }))
        if (idUsuario != null) {
            await fetchDataUsuario();
        } else {
            await fetchfindAllByPerteneceA(usuario.tipoDePersona)
            dispatch(setUsuario({
                ...usuario,
                direccionFiscal: null
            }))
        }
        setOpen(true)
        dispatch(setLoading({ isLoading: false }))

    };

    const handleClose = () => {
        dispatch(setIdUsuario(null))
        dispatch(resetUsuario())
        fetchDataUsuarios()
        setOpen(false);
    }

    const fetchfindAllByPerteneceA = async (tipoDePersona) => {
        try {
            const res = await findAllByPerteneceA(tipoDePersona);
            if (res.error) return ToastError(res.message)
            setRegimenFiscal(res.data.map((rf) => (
                {
                    id: rf.id,
                    value: rf,
                    label: rf.regimenFiscalSat
                })))
        } catch (err) {
            return ToastError(err)
        }
    }


    const fetchDataUsuario = async () => {
        try {
            const res = await buscarPorIdUsuarioParaActualizar(idUsuario)
            if (res.error) return ToastError(res.message)
            dispatch(setUsuario(res.data))
            dispatch(setIdUsuario(res.data.id))
            await fetchfindAllByPerteneceA(res.data.tipoDePersona)
        } catch (e) {
            ToastError(e)
        }
    };

    const handleChange = event => {
        if (event.target.type === "text") {
            event.target.value = event.target.value.toUpperCase();
        }
        if (event.target.name === "tipoDePersona") {
            fetchfindAllByPerteneceA(event.target.value)
        }
        const { name, value } = event.target;

        dispatch(setUsuario({
            ...usuario,
            [name]: value
        }))



    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let data = usuario?.id ? await actualizarUsuario(usuario) : await guardarUsuario(url, usuario);
        if (data) dispatch(setUsuario(data));
    }

    const changeInputPass = () => {
        if (passwordType === "password") return setPasswordType("text")
        setPasswordType("password")
    }

    return (
        <div align="right">
            <button className='btn btn-primary mb-1' onClick={handleOpen}>
                {children}{titulo}
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='card style-modal-md'>
                    <div className='card-header d-flex justify-content-between'>
                        {usuario?.id != null ? <>Editar {idRol === ID_ROL_PROVEEDORES ? "Proveedor" : idRol === ID_ROL_CLIENTES ? "Cliente" : "Logistica"} : {usuario.nombreComercial} </> :
                            <>Registro de {idRol === ID_ROL_PROVEEDORES ? "Proveedor" : idRol === ID_ROL_CLIENTES ? "Cliente" : "Logistica"}</>}
                        <button className='btn btn-dark btn-sm'
                            onClick={handleClose}>
                            <i className="bi bi-x-lg"></i>
                        </button>
                    </div>
                    <div className='card-body body-modal-scroll'>

                        <Tabs
                            defaultActiveKey="datos"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="datos" title="Datos">
                                <form onSubmit={handleSubmit}>
                                    <div className='row  '>
                                        <div className='row'>
                                            <div className='col-sm-4 mb-3'>
                                                <label className="form-label">Tipo Persona</label>
                                                <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name="tipoDePersona"
                                                    value={usuario.tipoDePersona}
                                                    onChange={handleChange}
                                                    style={{ display: 'flex', flexDirection: 'row' }}
                                                >
                                                    <FormControlLabel value="PF" control={<Radio />} label="Persona Fisica" />
                                                    <FormControlLabel value="PM" control={<Radio />} label="Persona Moral" />
                                                </RadioGroup>
                                            </div>
                                            <div className='col-sm-4 mb-3'>
                                                <label className="form-label">Permitir acceso</label>
                                                <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name="enable"
                                                    value={usuario.enable}
                                                    onChange={handleChange}
                                                    style={{ display: 'flex', flexDirection: 'row' }}
                                                >
                                                    <FormControlLabel value={true} control={<Radio />} label="SI" />
                                                    <FormControlLabel value={false} control={<Radio />} label="NO" />
                                                </RadioGroup>
                                            </div>
                                            <div className='col-sm-12'></div>
                                            <div className="col-sm-6 mb-3">
                                                <label htmlFor="inputNombre" className="form-label">Nombre Comercial<span className='required-span'>*</span></label>
                                                <input
                                                    type="text"
                                                    required
                                                    className="form-control"
                                                    name='nombreComercial'
                                                    value={usuario.nombreComercial}
                                                    onChange={handleChange} />
                                            </div>
                                            <div className='col-sm-6'>
                                                <SelectSearch
                                                    value={usuario.regimenFiscal}
                                                    data={regimenFiscal}
                                                    titulo={"Regimen Fiscal"}
                                                    name={"regimenFiscal"}
                                                    handleChange={handleChange}></SelectSearch>
                                            </div>
                                            {usuario.tipoDePersona === "PM" ?
                                                <div className="col-sm-4 mb-3">
                                                    <label htmlFor="inputRazonSocial" className="form-label">Razón Social<span className='required-span'>*</span></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name='razonSocial'
                                                        value={usuario.razonSocial}
                                                        onChange={handleChange} />
                                                </div> :
                                                <></>

                                            }
                                            <div className="col-sm-4 mb-3">
                                                <label htmlFor="inputRFC" className="form-label">RFC<span className='required-span'>*</span></label>
                                                <input
                                                    type="text"
                                                    required
                                                    className="form-control"
                                                    name='rfc'
                                                    value={usuario.rfc}
                                                    onChange={handleChange} />
                                            </div>
                                            {usuario.tipoDePersona === "PF" ?
                                                <>
                                                    <div className="col-sm-4 mb-3">
                                                        <label htmlFor="inputNombre" className="form-label">Nombre <span className='required-span'>*</span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name='nombre'
                                                            value={usuario.nombre}
                                                            onChange={handleChange} />
                                                    </div>
                                                    <div className="col-sm-4 mb-3">
                                                        <label htmlFor="inputPrimerApellido" className="form-label">Primer Apellido</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name='primerApellido'
                                                            value={usuario.primerApellido}
                                                            onChange={handleChange} />
                                                    </div>
                                                    <div className="col-sm-4 mb-3">
                                                        <label htmlFor="inputSegundoApellido" className="form-label">Segundo Apellido</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name='segundoApellido'
                                                            value={usuario.segundoApellido}
                                                            onChange={handleChange} />
                                                    </div>
                                                </> :
                                                <></>
                                            }
                                            {usuario.id ?
                                                <div className="col-sm-4 mb-3">
                                                    <label htmlFor="inputEmail" className="form-label">Correos Electrónicos</label>
                                                    <br />
                                                    <ModalAdminEmails
                                                        usuario={usuario}></ModalAdminEmails>
                                                </div>
                                                :
                                                <></>
                                            }
                                            <div className="col-sm-4 mb-3">
                                                <label htmlFor="inputPassword" className="form-label">Contraseña<span className='required-span'>*</span></label>
                                                <div className="input-group mb-3">
                                                    <input
                                                        type={passwordType}
                                                        className="form-control"
                                                        name='password'
                                                        readOnly={usuario.id}
                                                        value={usuario.password}
                                                        onChange={handleChange} />
                                                    <button type='button' className='btn btn-dark'
                                                        disabled={usuario.id}
                                                        onClick={changeInputPass}>
                                                        <i className="bi bi-eye-fill"></i>
                                                    </button>
                                                    {usuario.id ?
                                                        <UpdatePasswordModal
                                                            idUsuario={usuario.id}></UpdatePasswordModal> : ""

                                                    }
                                                </div>
                                            </div>
                                            <div className="col-sm-4 mb-3">
                                                <label htmlFor="inputRFC" className="form-label">Dias de Crédito<span className='required-span'>*</span></label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    name='diasDeCredito'
                                                    required
                                                    value={usuario.diasDeCredito}
                                                    onChange={handleChange} />
                                            </div>
                                            <div className="col-sm-4 mb-3">
                                                <SelectSearch
                                                    value={usuario.metodoDePago}
                                                    data={CATALOGO_SAT_METODO_PAGO}
                                                    titulo={"Metodo de Pago"}
                                                    name={"metodoDePago"}
                                                    handleChange={handleChange}></SelectSearch>
                                            </div>
                                            <div className="col-sm-4 mb-3">
                                                <SelectSearch
                                                    value={usuario.usoDeCFDI}
                                                    data={CATALOGO_SAT_USO_CFDI}
                                                    titulo={"Uso de CFDI"}
                                                    name={"usoDeCFDI"}
                                                    handleChange={handleChange}></SelectSearch>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-sm-12' align="right">
                                        <button type="submit" className="btn btn-primary col-2">
                                            {usuario?.id ? "Actualizar" : "Guardar"}
                                        </button>
                                    </div>
                                </form>
                            </Tab>
                            {idRol == ID_ROL_CLIENTES ?
                                <Tab eventKey="direccionFiscal" title="Direccion Fiscal">
                                    <div className="col-12 mb-3">
                                        <label htmlFor="inputDireccionFiscal" className="form-label">Dirección Fiscal</label>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                readOnly
                                                name='direccionFiscal'
                                                value={usuario.direccionFiscal ? usuario.direccionFiscal.direccionFiscal : ""}
                                                onChange={handleChange} />
                                            <ModalDireccionFiscal
                                                usuario={usuario}></ModalDireccionFiscal>
                                        </div>
                                    </div>
                                </Tab>
                                :
                                <></>
                            }
                            {usuario.id ?
                                <Tab eventKey="documentos" title="Documentos">
                                    <ArchivosUsuario
                                        idUsuario={usuario.id}></ArchivosUsuario>
                                </Tab>
                                : <></>
                            }
                        </Tabs>

                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ModalFormUsuarios