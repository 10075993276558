import React from 'react'
import FormikGenericDate from '../../../formik/FormikGenericDate'
import { Field, FieldArray } from 'formik'
import { Tooltip } from '@mui/material'

function FieldArrayPedimentos({ values }) {

    return (
        <FieldArray
            name="pedimentos"
            render={arrayHelpers => (
                <>
                    <div className="col-sm-12 mb-3" align="right">
                        <Tooltip followCursor title={values.id ? null : 'Necesitas primero guardar el embarque para poder agregar no. de pedimentos'}>
                            <button type="button" className='btn btn-primary' onClick={() => values.id ? arrayHelpers.push({}) : null}>
                                <i className="bi bi-plus" /> Pedimento
                            </button>
                        </Tooltip>
                    </div>
                    <div className='col-12'></div>
                    {values.pedimentos?.length >= 1 ?
                        <div className='row'>
                            <div className="col-sm-4 mb-1">
                                <label htmlFor="noDePedimento" className="form-label">No. de Pedimento</label>
                            </div>
                            <div className='col-sm-4 mb-1'>
                                <label htmlFor="fechaPagoPedimento" className="form-label">Fecha Pago Pedimento</label>
                            </div>
                        </div>
                        :
                        null
                    }

                    <div className='row'>
                        {values.pedimentos?.map((pedimento, index) => {
                            return (
                                <>
                                    <div className="col-sm-4 mb-1">
                                        <Field id="noDePedimento" name={`pedimentos[${index}].noDePedimento`} className="form-control" />
                                    </div>
                                    <div className='col-sm-4 mb-1'>
                                        <Field
                                            name={`pedimentos[${index}].fechaPagoPedimento`}
                                            component={FormikGenericDate} />
                                    </div>
                                    <div className="col-sm-2 mb-3" align="left">
                                        <button className='btn btn-sm btn-danger' type='button' onClick={() => arrayHelpers.remove(index)}><i className="bi bi-trash-fill"></i></button>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </>)} />
    )
}

export default FieldArrayPedimentos