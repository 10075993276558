import { getAuthRoleUser } from "../../../../utils/authUtils"
import { ID_ROL_ADMIN } from "../../../../utils/constantes";

export const isDisabledDeleteButton = (costoProovedor) =>{
    const roleUser = getAuthRoleUser();
    if(roleUser?.id == ID_ROL_ADMIN) return false

    if(costoProovedor.esAdicional) return false

    return true;

}