import { useState } from 'react'
import Modal from '@mui/material/Modal';
import { styleModalSM } from '../../../../assets/css/styles-react';
import MUIDataTable from "mui-datatables";
import { guardarTipoUnidad, listarCatalogoTiposUnidades } from '../../../../controllers/admin/embarques/catalogos/catalogoTipoUnidadDesgloseController';
import { BASIC_OPTIONS_MUI } from '../../../../utils/mui/constantes';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../../redux/features/loaderSlice';


function ModalAdministracionTipoUnidad() {
    const tipoUnidadModel = {
        id: '',
        nombre: '',
        creadoEn: ''
    }

    const [open, setOpen] = useState(false);
    const [tipoUnidad, setTipoUnidad] = useState(tipoUnidadModel)
    const [tipoUnidades, setTipoUnidades] = useState([])
    const dispatch = useDispatch();

    const handleOpen = () => {
        dispatch(setLoading({isLoading: true}))
        fetchDataListarTipoUnidad()
        setOpen(true)
        dispatch(setLoading({isLoading: false}))
    };

    const handleClose = () => setOpen(false);

    const handleChange = event => {
        setTipoUnidad({
            ...tipoUnidad,
            [event.target.name]: event.target.value
        })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = await guardarTipoUnidad(tipoUnidad);
        if(data){
            setTipoUnidad(tipoUnidadModel)
            fetchDataListarTipoUnidad()
        }
    }

    const fetchDataListarTipoUnidad = async () => {
        const data = await listarCatalogoTiposUnidades();
        if(data){
            setTipoUnidades(data.map((di) => [
                di.id,
                di.nombre,
                di.creadoEn
            ]))
        }
    };

    const columns = [
        {
            name: "Id",
            options: {
                display: "excluded",
                filter: false,
                sort: false,
                download: false,
            }
        },
        "Tipo Unidad",
        {
            name: "Fecha Registro",
            options: {
                display: "excluded",
                filter: false,
                sort: false,
                download: false,
            }
        },
        {
            name: "",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <button className='btn btn-warning'
                            onClick={() => setTipoUnidad({ id: tableMeta.rowData[0], nombre: tableMeta.rowData[1], creadoEn: tableMeta.rowData[2] })}>
                            <i className="bi bi-pencil"></i>
                        </button>
                    )
                },
                filter: false,
                sort: false,
                download: false,
            },
        }]

    return (
        <>
            <button className='dropdown-item' onClick={handleOpen}>
                Administración tipos unidades
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="card" style={styleModalSM}>
                    <div className="card-header">
                        Administración tipos unidades
                    </div>
                    <div className="card-body body-modal-scroll">
                        <form onSubmit={handleSubmit}>
                            <div className='row g-3 row-cols-3'>
                                <div className="col-12 mb-3">
                                    <label htmlFor="inputNombre" className="form-label">Tipo Unidad<span className='required-span'>*</span></label>
                                    <input
                                        type="text"
                                        required
                                        className="form-control"
                                        name='nombre'
                                        value={tipoUnidad.nombre}
                                        placeholder="Ingresa el tipo de unidad"
                                        onChange={handleChange} />
                                </div>
                                <div className='col-12' align="right">
                                    {tipoUnidad.id ?
                                        <>
                                            <button className="btn btn-secondary col-4" onClick={() => setTipoUnidad(tipoUnidadModel)}>Cancelar</button>
                                            <button type="submit" className="btn btn-primary col-4">Actualizar</button>
                                        </>
                                        :
                                        <button type="submit" className="btn btn-primary col-4">Guardar</button>

                                    }
                                </div>
                            </div>
                        </form>

                        <MUIDataTable
                            data={tipoUnidades}
                            columns={columns}
                            options={BASIC_OPTIONS_MUI}
                        />
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ModalAdministracionTipoUnidad