import BASE_URL_BACKEND from "../../api/baseUrl";
import { URL_Logging_Filter } from "../../utils/endpoints/actividades";
import { getIPFromApi64 } from "../../utils/webUtils";
import { apiRequest } from "../handlerApiRequest";

export const findAllLogging = async (urlParams) => {
    return await apiRequest(URL_Logging_Filter,'GET', null , urlParams);
}

export const saveLogging = async (email, status) =>{
    const ipRequest = await getIPFromApi64();
    const data = {
        emailRequest: email,
        statusHTTP: status,
        ipAddressRequest: ipRequest
    }
    return await fetch(BASE_URL_BACKEND + URL_Logging_Filter, {
        method: "POST",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
}