import React, { useState } from 'react'
import MUIDataTable from "mui-datatables";
import Modal from '@mui/material/Modal';
import { BASE_URL_S3_FACTURAS_PROVEEDORES, ID_ESTATUS_FP_COMPLETADO, ID_ESTATUS_FP_FACTURA_RECHAZADA, ID_ESTATUS_FP_PENDIENTE_APROBACION, ID_ESTATUS_FP_PROGRAMADA_PAGO } from '../../../../utils/constantes';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../../../redux/features/loaderSlice';
import { actualizarFacturaProveedor, agregarFacturaProveedor, eliminarFacturaProveedor, setFacturasProveedor } from '../../../../redux/features/admin/controlCostosSlice';
import { aprobarORechazarFactura, buscarPorIdCostoProveedor, eliminadoLogicoFacturaProveedor, guardarFacturaProveedorPorIdCosto, putFacturaProveedor } from '../../../../controllers/admin/facturas/facturaProveedorController';
import { ToastError, ToastSuccess, ToastWarning } from '../../../../utils/toastMessage';
import ModalEliminarObjeto from '../../ModalEliminarObjeto';
import ModalArchivo from '../../../ModalArchivo';
import { saveAs } from 'file-saver';
import HeaderInfoEmbarque from '../../embarques/HeaderInfoEmbarque';
import { fetchEstatusEmbarque } from '../../../../redux/features/admin/estatusEmbarqueSlice';
import ModalComprobantesFacturaProveedor from './ModalComprobantesFacturaProveedor';
import { OPTIONS_MUI_ONLY_TABLE,OPTIONS_CUSTOM_RETURN_COLUMN } from '../../../../utils/mui/constantes';
import { authUserIsAdminOrOperativo } from '../../../../utils/authUtils';
import { validateAddFactura, validatePrint } from '../validations/validationsFacturaProveedor';
import { handleChangeArrayText } from '../../../../utils/helpersState';
import TooltipEstatusFacturaProveedor from './TooltipEstatusFacturaProveedor';
import ToolTipMui from '../../../mui/ToolTipMui';
import { formatMoney } from '../../../../utils/formatMoney';
import ModalSendEmails from '../../ModalSendEmails';
import moment from "moment";
import "moment/locale/es";
import CustomFileUploader from '../../../inputs/CustomFileUploader';
import { validatePaymentDay } from '../../../../utils/proveedor/validationsPayment';

function FacturaProveedor({ idCostoProveedor,
    nombreProveedor,
    fetchListaCostos,
    recargarEmbarqueData,
    idEmbarque }) {
    const controlCostoData = useSelector((state) => state.controlCostos);
    const embarque = useSelector((state) => state.embarque.embarqueData);
    const [archivos, setArchivos] = useState([]);
    const [authEsAdmin, setEsAdmin] = useState(false);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [sendEmail, setSendEmail] = useState(false);
    const [facturaUpdated, setFacturaUpdated] = useState({}); //Se usa para el envio de emails, saber cual factura se acepto o rechazo
    const [propsSendEmail, setPropsSendEmail] = useState({});

    const handleOpen = () => {
        fetchBuscarPorIdCostoProveedor(idCostoProveedor);
        setEsAdmin(authUserIsAdminOrOperativo());

        //Se hace una copia del tamanio del array para poder manipular los nombres de los archivos
        //Ya que redux no permite manipular en su estado datos de tipo archivo
        const newArray = Array.from({ length: controlCostoData.facturasProveedor.length }, () => ({}))
        setArchivos(newArray)
        setOpen(true)
    };

    const handleClose = () => {
        if (fetchListaCostos) fetchListaCostos()
        if (recargarEmbarqueData) recargarEmbarqueData()
        setOpen(false);
    }

    const handleChange = (e, indexFP) => {
        const { value, name } = e.target;
        dispatch(actualizarFacturaProveedor({ value, name, indexFP }))
    }

    const handleChangeFile = async (indexFP, file, nameArchivo) => {
        let e = {
            target: {
                name: nameArchivo,
                value: file
            }
        }
        handleChangeArrayText(e, indexFP, archivos, setArchivos)
    };

    const fetchProcesarFactura = async (facturaProveedor, indexFP) => {
        try {
            if (facturaProveedor == null && (!archivos[indexFP].archivoPDFASubir || !archivos[indexFP].archivoXMLASubir)) return ToastWarning("Los archivos de la factura (PDF y XML) son necesarios.")
            if (facturaProveedor.fechaPagoRealizado === null) return ToastWarning("El comprobante necesita ser enviado con la fecha de pago")
            dispatch(setLoading({ isLoading: true }))

            let res;
            if (facturaProveedor.id) {
                res = await putFacturaProveedor(
                    facturaProveedor,
                    archivos[indexFP].archivoPDFASubir,
                    archivos[indexFP].archivoXMLASubir)
            } else {
                res = await guardarFacturaProveedorPorIdCosto(
                    idCostoProveedor,
                    facturaProveedor,
                    archivos[indexFP].archivoPDFASubir,
                    archivos[indexFP].archivoXMLASubir)
            }

            dispatch(setLoading({ isLoading: false }))
            if (res.error) return ToastError(res.message)
            ToastSuccess(res.message)
            //Recargar estatus
            dispatch(fetchEstatusEmbarque(idEmbarque))
            fetchBuscarPorIdCostoProveedor()
        } catch (e) {
            dispatch(setLoading({ isLoading: false }))
            ToastError(e.message)
        }
    }

    const fetchBuscarPorIdCostoProveedor = async () => {
        try {
            const res = await buscarPorIdCostoProveedor(idCostoProveedor)
            if (res.error) return ToastError(res.message)
            dispatch(setFacturasProveedor(res.data))
        } catch (e) {
            ToastError(e)
        }
    }

    const fetcheliminadoLogicoFacturaProveedor = async (idFacturaProveedor, index1) => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const res = await eliminadoLogicoFacturaProveedor(idFacturaProveedor, controlCostoData.facturasProveedor[index1].razonEliminacion)
            dispatch(setLoading({ isLoading: false }))
            if (res.error) return ToastError(res.message)
            ToastSuccess(res.message)
            fetchBuscarPorIdCostoProveedor()
            dispatch(fetchEstatusEmbarque(idEmbarque))
        } catch (e) {
            ToastError(e)
        }
    }

    const fetchAprobarORechazarFactura = async (facturaRechazada, indexFP) => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const res = await aprobarORechazarFactura(facturaRechazada, controlCostoData.facturasProveedor[indexFP])
            dispatch(setLoading({ isLoading: false }))
            if (res.error) return ToastError(res.message)
            fetchBuscarPorIdCostoProveedor();
            //Eventos para el envio de email
            setFacturaUpdated(controlCostoData.facturasProveedor[indexFP])
            const props = {
                endPoint: facturaRechazada ? "/factura-proveedor-rechazada" : "/factura-proveedor-aprobada", 
                cardHeader: facturaRechazada ? "NOTIFICAR A PROVEEDOR, SU FACTURA FUE RECHAZADA": "NOTIFICAR A PROVEEDOR, SU FACTURA FUE APROBADA"};
            setPropsSendEmail(props)
            setSendEmail(true)
            return ToastSuccess(res.message)
        } catch (e) {
            return ToastError(e.message)
        }
    }

    const columnsFacturasProovedor = [
        {
            name: "Costo neto",
            options: {
                customBodyRenderLite: (indexFP) => {
                    return (
                        <div align="center" style={{ minWidth: "50px" }}>
                            <b>
                                <p>{formatMoney(controlCostoData.facturasProveedor[indexFP].costoNeto)}</p>
                            </b>
                        </div>
                    )
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            }
        },
        {
            name: "Factura",
            options: {
                customBodyRenderLite: (indexFP) => {
                    return (
                        <div align="center" className='pb-2' style={{ minWidth: "200px", maxWidth: "400px" }}>
                            {controlCostoData.facturasProveedor[indexFP].archivoFactura ?
                                <div>
                                    <ModalArchivo
                                        title={"Factura"}
                                        urlBase={BASE_URL_S3_FACTURAS_PROVEEDORES}
                                        archivoS3={controlCostoData.facturasProveedor[indexFP].archivoFactura}></ModalArchivo>
                                </div>
                                : ""}
                            {!controlCostoData.facturasProveedor[indexFP].estaEliminado
                                && validatePrint(controlCostoData.facturasProveedor[indexFP], authEsAdmin) ?
                                <CustomFileUploader
                                handleChangeFile={handleChangeFile}
                                index={indexFP}
                                fileTypes={["PDF"]}
                                name={"archivoPDFASubir"}
                                nameFile={archivos[indexFP]?.archivoPDFASubir?.name}
                                ></CustomFileUploader>
                                :
                                ""
                            }
                        </div>
                    )
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            }
        },
        {
            name: "Factura XML",
            options: {
                customBodyRenderLite: (indexFP) => {
                    const serie = controlCostoData.facturasProveedor[indexFP].serie ? controlCostoData.facturasProveedor[indexFP].serie : "";
                    const folio = controlCostoData.facturasProveedor[indexFP].folio ? controlCostoData.facturasProveedor[indexFP].folio : "";
                    const uuid = controlCostoData.facturasProveedor[indexFP].uuid ? controlCostoData.facturasProveedor[indexFP].uuid : "";
                    return (
                        <div align="center" className='pb-2' style={{ minWidth: "200px", maxWidth: "400px" }}>
                            {controlCostoData.facturasProveedor[indexFP].archivoFacturaXML ?
                                <div>
                                    <button
                                        type='button'
                                        className='btn btn-dark'
                                        onClick={() => saveAs(BASE_URL_S3_FACTURAS_PROVEEDORES + controlCostoData.facturasProveedor[indexFP].archivoFacturaXML.keyS3, controlCostoData.facturasProveedor[indexFP].archivoFacturaXML.keyS3)}>
                                        <i className="bi bi-file-earmark-arrow-down"></i>
                                    </button>
                                    <ToolTipMui
                                        title={
                                            <div style={{ fontSize: "12px" }}>
                                                <b>Serie: </b> {serie}<br />
                                                <b>Folio: </b> {folio}<br />
                                                <b>Folio Fiscal (uuid): </b> {uuid}
                                            </div>}>
                                    </ToolTipMui>
                                    <br />
                                </div>

                                : ""}

                            {!controlCostoData.facturasProveedor[indexFP].estaEliminado
                                && validatePrint(controlCostoData.facturasProveedor[indexFP], authEsAdmin) ?
                                <CustomFileUploader
                                handleChangeFile={handleChangeFile}
                                index={indexFP}
                                fileTypes={["XML"]}
                                name={"archivoXMLASubir"}
                                nameFile={archivos[indexFP]?.archivoXMLASubir?.name}
                                ></CustomFileUploader>
                                :
                                ""
                            }
                        </div>
                    )
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            }
        },
        {
            name: "Comprobante",
            options: {
                customBodyRenderLite: (indexFP) => {
                    return (
                        <>
                            {controlCostoData.facturasProveedor[indexFP].id
                                && !controlCostoData.facturasProveedor[indexFP].estaEliminado
                                && controlCostoData.facturasProveedor[indexFP].estatus?.id != ID_ESTATUS_FP_FACTURA_RECHAZADA ?
                                <ModalComprobantesFacturaProveedor
                                    idFacturaProveedor={controlCostoData.facturasProveedor[indexFP].id}
                                    fetchFacturaProveedor={fetchBuscarPorIdCostoProveedor}></ModalComprobantesFacturaProveedor>
                                :
                                <></>
                            }
                        </>
                    )
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            }
        },
        {
            name: "",
            options: {
                customBodyRenderLite: (indexFP) => {
                    return (
                        <>
                            <TooltipEstatusFacturaProveedor
                                facturaProveedor={controlCostoData.facturasProveedor[indexFP]}>
                            </TooltipEstatusFacturaProveedor>
                            {controlCostoData.facturasProveedor[indexFP].metodoPago == "PPD" ?
                                !controlCostoData.facturasProveedor[indexFP].facturaTieneComplentoPago ?
                                    <span className="badge bg-warning">PENDIENTE COMPLEMENTO PAGO</span>
                                    : ""
                                : ""
                            }
                        </>
                    )
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            }
        },
        {
            name: "Fecha estimada pago",
            options: {
                customBodyRenderLite: (indexFP) => {
                    let facturaProveedor = controlCostoData.facturasProveedor[indexFP];
                    let nextFriday = validatePaymentDay(facturaProveedor);

                    return facturaProveedor.estatus?.id == ID_ESTATUS_FP_PROGRAMADA_PAGO ?
                        moment(nextFriday).format('DD/MM/YYYY')
                        :
                        ""

                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN,
            }
        },
        {
            name: "",
            options: {
                customBodyRenderLite: (indexFP) => {
                    return (
                        <>
                            {controlCostoData.facturasProveedor[indexFP].estatus?.id == ID_ESTATUS_FP_PENDIENTE_APROBACION ?
                                controlCostoData.facturasProveedor[indexFP].guardoProveedor && authEsAdmin
                                    && !controlCostoData.facturasProveedor[indexFP].estaEliminado ?
                                    <div align="center">
                                        <button className='btn btn-primary mb-1'
                                            onClick={() => { fetchAprobarORechazarFactura(false, indexFP) }}>APROBAR</button>
                                        <br />
                                        <ModalEliminarObjeto
                                            textHeader={"¿Seguro de rechazar esta factura?"}
                                            textBody={"Se recalculara los totales sin tener en cuenta el costo neto de esta factura."}
                                            functionEliminar={fetchAprobarORechazarFactura}
                                            id={true}
                                            index1={indexFP}
                                            textButton={"RECHAZAR"}
                                            customIcon={" "}>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                placeholder='Razón de rechazo'
                                                name='razonEliminacion'
                                                value={controlCostoData.facturasProveedor[indexFP].razonEliminacion}
                                                onChange={(e) => { handleChange(e, indexFP) }} />
                                        </ModalEliminarObjeto>
                                    </div>
                                    :
                                    ""
                                :
                                ""
                            }
                        </>)
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            }
        },
        {
            name: "",
            options: {
                customBodyRenderLite: (indexFP) => {
                    return (
                        <>
                            {!controlCostoData.facturasProveedor[indexFP].estaEliminado
                                && validatePrint(controlCostoData.facturasProveedor[indexFP], authEsAdmin) ?
                                <button type='button' className='btn btn-primary'
                                    onClick={() => fetchProcesarFactura(controlCostoData.facturasProveedor[indexFP], indexFP)}>
                                    {controlCostoData.facturasProveedor[indexFP].id ?
                                        "Actualizar" : "Guardar"}
                                </button>
                                :
                                ""
                            }
                        </>
                    )
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            }
        }
        ,
        {
            name: "",
            options: {
                customBodyRenderLite: (indexFP) => {
                    return (
                        <>
                            {!controlCostoData.facturasProveedor[indexFP].estaEliminado
                                && validatePrint(controlCostoData.facturasProveedor[indexFP], authEsAdmin)
                                && controlCostoData.facturasProveedor[indexFP].estatus?.id != ID_ESTATUS_FP_COMPLETADO ?
                                <>
                                    {
                                        controlCostoData.facturasProveedor[indexFP].id ?
                                            <ModalEliminarObjeto
                                                textHeader={"¿Seguro de eliminar esta factura?"}
                                                textBody={"Se recalculara los totales sin tener en cuenta el costo neto de esta factura."}
                                                functionEliminar={fetcheliminadoLogicoFacturaProveedor}
                                                id={controlCostoData.facturasProveedor[indexFP].id}
                                                index1={indexFP}>
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    placeholder='Razón de la eliminación'
                                                    name='razonEliminacion'
                                                    value={controlCostoData.facturasProveedor[indexFP].razonEliminacion}
                                                    onChange={(e) => { handleChange(e, indexFP) }} />
                                            </ModalEliminarObjeto>
                                            :
                                            <button type='button' className='btn btn-danger'
                                                onClick={() => (dispatch(eliminarFacturaProveedor({ indexFP })))}>
                                                <i className="bi bi-trash"></i>
                                            </button>
                                    }
                                </>
                                :
                                ""
                            }
                        </>
                    )
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            }
        }
    ]

    return (
        <>
            <button
                className="btn btn-dark btn-sm"
                type="button" onClick={handleOpen}>
                <i className="bi bi-eye"></i>
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='card style-modal-lg'>
                    <div className='card-header d-flex justify-content-between'>
                        Facturas del Proveedor - {nombreProveedor}
                        <button className='btn btn-dark btn-sm'
                            onClick={handleClose}>
                            <i className="bi bi-x-lg"></i>
                        </button>
                    </div>
                    <div className='card-body body-modal-scroll'>

                        <HeaderInfoEmbarque></HeaderInfoEmbarque>
                        <div align="right" className='mt-2'>
                            <button type='button' className='btn btn-dark mb-2'
                                disabled={validateAddFactura(embarque, authEsAdmin)}
                                onClick={() => { dispatch(agregarFacturaProveedor()); }}>
                                <i className="bi bi-plus-lg"></i> Agregar Factura
                            </button>
                        </div>
                        <MUIDataTable
                            title={"Facturas"}
                            data={controlCostoData.facturasProveedor}
                            columns={columnsFacturasProovedor}
                            options={{
                                ...OPTIONS_MUI_ONLY_TABLE,
                                responsive: "standard",
                                setRowProps: (row, dataIndex) => {
                                    if (controlCostoData.facturasProveedor[dataIndex].estaEliminado) {
                                        return {
                                            style: { backgroundColor: "#f98e8e78" }
                                        };
                                    } else if (controlCostoData.facturasProveedor[dataIndex].guardoProveedor) {
                                        return {
                                            style: { backgroundColor: "#9ca8b27c" }
                                        };
                                    }
                                }
                            }}
                        />
                    </div>

                    <ModalSendEmails
                        sendEmail={sendEmail}
                        setSendEmail={setSendEmail}
                        user={facturaUpdated?.costoProveedor?.proveedor}
                        logistica={facturaUpdated?.costoProveedor?.desgloseEmbarque?.logistica}
                        entity={facturaUpdated}
                        cardHeader={propsSendEmail.cardHeader}
                        endPoint={propsSendEmail.endPoint}></ModalSendEmails>
                </div>
            </Modal>
        </>
    )
}

export default FacturaProveedor