import React, { useEffect, useState } from 'react'
import { ToastError } from '../../../utils/toastMessage';
import { findAllCatalogoNaviera } from '../../../controllers/admin/embarques/catalogos/catalogoNaviera';
import { findAllCTSM } from '../../../controllers/admin/embarques/catalogos/catalogoTipoServicioMaritimoController';
import { ID_SERVICIO_EMBARQUE_IMPORTACION, ID_SERVICIO_MARITIMO_P2D } from '../../../utils/constantes';
import { findProveedoresBysearchText } from '../../../controllers/admin/usuariosController';
import { ErrorMessage, Field } from 'formik';
import FormikSelectSearch from '../../formik/FormikSelectSearch';
import FormikSelectSearchDB from '../../formik/FormikSelectSearchDB';
import FormikGenericDate from '../../formik/FormikGenericDate';
import FieldArrayPedimentos from './forms/FieldArrayPedimentos';

function DatosEmbarqueMaritimo({ values }) {
    const [navieras, setNavieras] = useState([]);
    const [catalogoSM, setCatalogoSM] = useState([]);

    useEffect(() => {
        findAllNavieras()
        findAllCatalogoServicioMaritimo()
    }, [])

    const findAllNavieras = async () => {
        try {
            const res = await findAllCatalogoNaviera();
            if (res.error) return ToastError(res.message);
            setNavieras(res.data.map(naviera => ({
                id: naviera.id,
                value: naviera,
                label: naviera.nombre
            })))
        } catch (err) {
            return ToastError(err)
        }
    }

    const findAllCatalogoServicioMaritimo = async () => {
        try {
            const res = await findAllCTSM();
            if (res.error) return ToastError(res.message);
            setCatalogoSM(res.data.map(sMaritimo => ({
                id: sMaritimo.id,
                value: sMaritimo,
                label: sMaritimo.nombre
            })))
        } catch (err) {
            return ToastError(err)
        }
    }

    return (
        <>
            <div className='row'>
                <div className='col-sm-3 mb-1'>
                    <Field
                        name={"datosEmbarqueMaritimo.servicioMaritimo"}
                        id="servicioMaritimo"
                        component={FormikSelectSearch}
                        data={catalogoSM}
                        titulo={"Tipo de Servicio"}
                        isDisabled={false}
                        returnObject={true} />
                </div>
                <div className='col-sm-3 mb-1'>
                    <Field
                        name={"datosEmbarqueMaritimo.modalidadMaritima"}
                        id="modalidadMaritima"
                        component={FormikSelectSearch}
                        data={[{ value: "LCL", nombre: "LCL", label: "LCL" }, { value: "FCL", nombre: "FCL", label: "FCL" }]}
                        titulo={"Modalidad Maritima"}
                        isDisabled={false}
                        returnObject={false} />
                </div>

                <div className="col-sm-3 mb-1">
                    <label htmlFor="inputDestino" className="form-label">Puerto de Carga</label>
                    <Field id="puertoDeCarga" name="datosEmbarqueMaritimo.puertoDeCarga" className="form-control" />
                </div>
                <div className="col-sm-3 mb-1">
                    <label htmlFor="inputDestino" className="form-label">Puerto de Descarga</label>
                    <Field id="puertoDescarga" name="datosEmbarqueMaritimo.puertoDescarga" className="form-control" />
                </div>
                {values.datosEmbarqueMaritimo.navieraConProovedor == true
                    || values.datosEmbarqueMaritimo.navieraConProovedor == "true" ?
                    <div className='col-sm-5 mb-1'>
                        <Field
                            name="datosEmbarqueMaritimo.proveedorNaviera"
                            component={FormikSelectSearchDB}
                            titulo="Naviera"
                            fetchSearch={findProveedoresBysearchText}
                            type={"USERS"} />
                    </div>
                    :
                    <div className='col-sm-5 mb-1'>
                        <Field
                            name={"datosEmbarqueMaritimo.naviera"}
                            id="naviera"
                            component={FormikSelectSearch}
                            data={navieras}
                            titulo={"Naviera"}
                            isDisabled={false}
                            returnObject={true} />
                    </div>
                }

                <div className='col-sm-1 mb-3'>
                    <label className="form-label">¿Proveedor?</label>
                    <div className="form-check form-switch">
                        <Field type="checkbox" name="datosEmbarqueMaritimo.navieraConProovedor" className="form-check-input" role="switch" />
                    </div>
                </div>
                <div className='col-12'></div>


                <div className="col-sm-3 mb-1">
                    <label htmlFor="buque" className="form-label">Buque</label>
                    <Field id="buque" name="datosEmbarqueMaritimo.buque" className="form-control" />
                </div>

                <div className="col-sm-3 mb-1">
                    <label htmlFor="viaje" className="form-label">Viaje</label>
                    <Field id="viaje" name="datosEmbarqueMaritimo.viaje" className="form-control" />
                </div>

                <div className={`col-sm-3 mb-1 ${values.soloDespachoAduanal ? 'd-none' : ''}`}>
                    <label htmlFor="noDeBooking" className="form-label">No. de Booking</label>
                    <Field id="noDeBooking" name="datosEmbarqueMaritimo.noDeBooking" className="form-control" />
                </div>

                <div className="col-sm-3 mb-1">
                    <label htmlFor="noDeBL" className="form-label">No. de BL</label>
                    <Field id="noDeBL" name="datosEmbarqueMaritimo.noDeBL" className="form-control" />
                </div>
                <div className="col-12"></div>

                {values.datosEmbarqueMaritimo?.servicioMaritimo?.id != ID_SERVICIO_MARITIMO_P2D
                    || values.tipoServicio?.id != ID_SERVICIO_EMBARQUE_IMPORTACION ?
                    <>
                        <div className={`col-sm-4 mb-1 ${values.soloDespachoAduanal ? 'd-none' : ''}`}>
                            <Field
                                name="datosEmbarqueMaritimo.fechaCierreDocumental"
                                component={FormikGenericDate}
                                nameLabel="Cierre Documental" />
                        </div>
                        <div className={`col-sm-4 mb-1 ${values.soloDespachoAduanal ? 'd-none' : ''}`}>
                            <Field
                                name="datosEmbarqueMaritimo.fechaCierreFisico"
                                component={FormikGenericDate}
                                nameLabel="Cierre Fisico" />
                        </div>
                    </>
                    :
                    <div className='col-12'></div>
                }

                <div className='col-sm-1 mb-3'>
                    <div role="group" aria-labelledby="demo-controlled-radio-buttons-group">
                        <label className="form-label">Despacho Aduanal</label>
                        <div className='form-check'>
                            <label>
                                <Field type="radio" name="haceDespachoAduanal" value="CLIENTE" className="form-check-input" />
                                CLIENTE
                            </label>
                        </div>
                        <div className='form-check'>
                            <label>
                                <Field type="radio" name="haceDespachoAduanal" value="KJ" className="form-check-input" />
                                KJ LOGISTICS
                            </label>
                        </div>
                    </div>
                </div>
                
                {values?.haceDespachoAduanal == "KJ" &&
                    <div className='col-sm-3 mb-3'>
                        <Field
                            name="kjcustomBrokerProvider"
                            component={FormikSelectSearchDB}
                            titulo="Proveedor despacho aduanal"
                            fetchSearch={findProveedoresBysearchText}
                            type={"USERS"} />
                    </div>

                }


                <FieldArrayPedimentos values={values} />

                <div className='col-12'></div>

                <div className='col-sm-4 mb-1'>
                    <Field
                        name="datosEmbarqueMaritimo.fechaETD"
                        component={FormikGenericDate}
                        nameLabel="ETD" />
                </div>

                <div className='col-sm-4 mb-1'>
                    <Field
                        name="datosEmbarqueMaritimo.fechaETA"
                        component={FormikGenericDate}
                        nameLabel="ETA" />
                </div>
                <div className='col-12'></div>
                <div className='col-sm-4 mb-1'>
                    <Field
                        name="datosEmbarqueMaritimo.fechaATD"
                        component={FormikGenericDate}
                        nameLabel="ATD" />
                </div>
                <div className='col-sm-4 mb-1'>
                    <Field
                        name="datosEmbarqueMaritimo.fechaATA"
                        component={FormikGenericDate}
                        nameLabel="ATA" />
                </div>

                <div className="col-sm-12 mb-3">
                    <label htmlFor="instruccionesEspeciales" className="form-label">Instrucciones Especiales</label>
                    <Field id="instruccionesEspeciales" name="datosEmbarqueMaritimo.instruccionesEspeciales" className="form-control" component="textarea" />
                </div>
            </div>
        </>
    )
}

export default DatosEmbarqueMaritimo