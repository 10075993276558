import React from 'react'
import Table from 'react-bootstrap/Table';
import { useSelector } from 'react-redux';
import LineaTiempoEstatus from './estatus/LineaTiempoEstatus';
import { ID_ROL_ADMIN } from '../../../utils/constantes';

function HeaderInfoEmbarque() {
    const embarqueData = useSelector((state) => state.embarque.embarqueData);
    const roleUser = useSelector((state) => state.userAuth.roleUser);


    return (
        <>
            <div className='col-6 m-2'>
                <Table striped>
                    <tbody>
                        <tr>
                            <td>Referencia: <b>{embarqueData.referenciaKJ}</b></td>
                            <td>Origen: <b>{embarqueData.origen.nombre}</b></td>
                            <td>Destino: <b>{embarqueData.destino.nombre}</b></td>
                        </tr>
                    </tbody>
                </Table>
            </div>
            <div className='col-12'>
                {roleUser?.id == ID_ROL_ADMIN ?
                    <LineaTiempoEstatus></LineaTiempoEstatus>
                    :
                    <></>
                }
                
            </div>
        </>
    )
}

export default HeaderInfoEmbarque