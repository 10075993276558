import React, { useState } from 'react'
import Modal from '@mui/material/Modal';
import SelectSearchDB from '../../inputs/SelectSearchDB';
import { findClientesBySearchText, findProveedoresBysearchText } from '../../../controllers/admin/usuariosController';
import FechaGeneric from '../../inputs/FechaGeneric';
import moment from 'moment-timezone';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { facturasProveedor, facturasProveedorPPDValidateComplemento, fetchFacturasProveedorPPDToReporte, fetchFacturasProveedorWithFilters } from './generators/reportesFacturaProveedor';
import { facturasCliente, fetchFacturasClienteWithFilters } from './generators/reportesFacturaCliente';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../redux/features/loaderSlice';

function ModalReportes() {
    const [open, setOpen] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [cliente, setCliente] = useState(null);
    const [proveedor, setProveedor] = useState(null);
    const dispatch = useDispatch();

    const handleOpen = async () => {
        setOpen(true)
    }

    const handleClose = () => {
        setCliente(null)
        setProveedor(null)
        setEndDate(null)
        setStartDate(null)
        setOpen(false);
    }

    const handleDateStartEnd = (date, setDate) => {
        if (date) {
            const formattedDateTime = moment(date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
            setDate(formattedDateTime)
        } else {
            setDate(null)
        }
    };

    const handleChangeProveedor= (e, index) => {
        setProveedor(e.target.value)
    }

    const handleChangeCliente= (e, index) => {
        setCliente(e.target.value)
    }

    const handlerFacturasProveedor = async () => {
        dispatch(setLoading({isLoading: true}))
        const res = await fetchFacturasProveedorWithFilters(proveedor, startDate, endDate);
        dispatch(setLoading({isLoading: false}))
        facturasProveedor(res.data)
    }

    const handlerFacturasCliente = async () =>{
        dispatch(setLoading({isLoading: true}))
        const res = await fetchFacturasClienteWithFilters(cliente, startDate, endDate);
        dispatch(setLoading({isLoading: false}))
        facturasCliente(res.data)
    }

    const handlerfacturasProveedorPPDToReporte = async () =>{
        dispatch(setLoading({isLoading: true}))
        const res = await fetchFacturasProveedorPPDToReporte(proveedor, startDate, endDate);
        dispatch(setLoading({isLoading: false}))
        facturasProveedorPPDValidateComplemento(res.data)
    }


    return (
        <>
            <button
                className="btn btn-dark m-1"
                type="button" onClick={handleOpen}
            >
                <i className="bi bi-archive-fill"></i> REPORTES FACTURAS
            </button>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <div className='card style-modal-sm'>
                    <div className='card-header d-flex justify-content-between'>
                        REPORTES FACTURAS
                        <button className='btn btn-dark btn-sm'
                            onClick={handleClose}>
                            <i className="bi bi-x-lg"></i>
                        </button>
                    </div>
                    <div className='card-body body-modal-scroll'>
                        <div className='row mb-4'>
                            <div className='col-4'></div>
                            <div className='col-4'>
                                <FechaGeneric
                                    fecha={startDate}
                                    handleDateTimeChange={handleDateStartEnd}
                                    nameLabel={"Desde"}
                                    nameInput={setStartDate}
                                ></FechaGeneric>
                            </div>
                            <div className='col-4'>
                                <FechaGeneric
                                    fecha={endDate}
                                    handleDateTimeChange={handleDateStartEnd}
                                    nameLabel={"Hasta"}
                                    nameInput={setEndDate}
                                ></FechaGeneric>
                            </div>
                        </div>

                        <div className='col-12'>

                            <Tabs
                                defaultActiveKey="proveedores"
                                id="uncontrolled-tab-example"
                                className="mb-3"
                            >
                                <Tab eventKey="proveedores" title="Proveedores">
                                    <div className='row'>
                                        <div className='col-8'></div>
                                        <div className='col-4'>
                                            <SelectSearchDB
                                                value={proveedor}
                                                name={'user'}
                                                fetchSearch={findProveedoresBysearchText}
                                                type={"USERS"}
                                                handleChange={handleChangeProveedor}></SelectSearchDB>
                                        </div>

                                        <tbody align="right" className='mt-4'>
                                            <tr>
                                                <th scope="row">Facturas Proveedor</th>
                                                <td>
                                                    <button className='btn btn-primary'
                                                        onClick={() => { handlerFacturasProveedor() }}>
                                                        <i className="bi bi-file-earmark-spreadsheet"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Complementos de pago</th>
                                                <td>
                                                    <button className='btn btn-primary'
                                                        onClick={() => { handlerfacturasProveedorPPDToReporte() }}>
                                                        <i className="bi bi-file-earmark-spreadsheet"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </div>
                                </Tab>
                                <Tab eventKey="clientes" title="Clientes">
                                    <div className='row'>
                                        <div className='col-8'></div>
                                        <div className='col-4'>
                                            <SelectSearchDB
                                                value={cliente}
                                                name={'user'}
                                                fetchSearch={findClientesBySearchText}
                                                type={"USERS"}
                                                handleChange={handleChangeCliente}></SelectSearchDB>
                                        </div>

                                        <tbody align="right" className='mt-4'>
                                            <tr>
                                                <th scope="row">Facturas Cliente</th>
                                                <td>
                                                    <button className='btn btn-primary'
                                                        onClick={() => { handlerFacturasCliente() }}>
                                                        <i className="bi bi-file-earmark-spreadsheet"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </div>
                                </Tab>

                            </Tabs>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ModalReportes