import React from 'react'

function SelectTipoMoneda({dataIndex, functionOnChange, valueSelect}) {

    return (
        <select className="form-select"
            name="tipoMoneda"
            onChange={(e)=>{functionOnChange(e, dataIndex)}}
            value={valueSelect}
            style={{ minWidth: "100px" }}>
            <option value="">Tipos de Moneda</option>
            <option value="MXN">MXN</option>
            <option value="USD">USD</option>
        </select>
    )
}

export default SelectTipoMoneda