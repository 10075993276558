import { ID_ROL_ADMIN, ID_TRAFICO_INTERMODAL, ID_TRAFICO_MARITIMO } from "../../../../utils/constantes";

export function isMaritimoAndIntermodal(embarque) {
    if (embarque.tipoTrafico?.id == ID_TRAFICO_MARITIMO
        || embarque.tipoTrafico?.id == ID_TRAFICO_INTERMODAL) {
        return true;
    }
    return false
}

export function bloquearEmbarque(embarque, userAuth){
    if(embarque.embarqueFinalizado
        && userAuth?.roleUser?.id == ID_ROL_ADMIN) return false

    if(embarque.embarqueFinalizado) return true

    return false    
}