import { findAllUsersBySearchText, findClientesBySearchText, findProveedoresBysearchText } from "../../controllers/admin/usuariosController";
import { CATALOGO_TIPOS_CATALOGOS } from "../catalogosEstaticos";
import { findAllUbicacionesByTextSearch } from "../../controllers/admin/embarques/catalogos/catalogoUbicacionController";
import { formatNameUser } from "../userFormat";

export const validationsFetchPetition = (value) => {
    switch (value) {
        case CATALOGO_TIPOS_CATALOGOS[0].value:
            return findProveedoresBysearchText;
        case CATALOGO_TIPOS_CATALOGOS[1].value:
            return findClientesBySearchText;
        case CATALOGO_TIPOS_CATALOGOS[2].value:
            return findAllUsersBySearchText;
        case CATALOGO_TIPOS_CATALOGOS[3].value:
            return findAllUbicacionesByTextSearch;
        default:
            return null;
    }
}

export const validataTypeSearchDB = (value) => {
    switch (value) {
        case CATALOGO_TIPOS_CATALOGOS[0].value:
            return "USERS";
        case CATALOGO_TIPOS_CATALOGOS[1].value:
            return "USERS";
        case CATALOGO_TIPOS_CATALOGOS[2].value:
            return "USERS";
        case CATALOGO_TIPOS_CATALOGOS[3].value:
            return "ORIGEN/DESTINO";
        default:
            return null;
    }

}

export const validatePrintValueOfCatalogo = (type, value) => {
    if (value) {
        switch (type) {
            case CATALOGO_TIPOS_CATALOGOS[0].value
                || CATALOGO_TIPOS_CATALOGOS[1].value
                || CATALOGO_TIPOS_CATALOGOS[2].value:
                return formatNameUser(value);
            case CATALOGO_TIPOS_CATALOGOS[3].value:
                return `${value.nombre} (${value.portCode})`;
            default:
                return "ERROR CONSULTA AL ADMINISTRADOR";
        }
    }

}