import { URL_QUOTATION_FILES } from "../../../utils/endpoints/cotizaciones"
import { visibilitySuperLoader } from "../../../utils/loader";
import { apiRequestV2 } from "../../handlerApiRequestV2";

export const saveQuotationFiles = async (quotationFilesList, quotation) => {
    const promises = [];
    quotationFilesList?.documents?.forEach(async (quotationFile) => {
        quotationFile.quotation = { id: quotation?.id };
        const formData = new FormData();
        if (quotationFile.file != null) formData.append("file", quotationFile.file, quotationFile.file.name);
        formData.append("quotationData", new Blob([JSON.stringify(quotationFile)], {
            type: "application/json"
        }));
        promises.push(
            apiRequestV2({
                endpoint: URL_QUOTATION_FILES,
                data: formData,
                method: "POST",
                showSuccessMessage: true,
                isFormData: true
            })
        );
    });
    visibilitySuperLoader(true);
    await Promise.all(promises)
    visibilitySuperLoader(false);
};

export const findAllByIdQuotation = async (idQuotation) => {
    return await apiRequestV2({
        endpoint: URL_QUOTATION_FILES,
        urlParams: `?idQuotation=${idQuotation}`,
    });
}

export const updateQuotationFile = async (quotationFilesList) => {
    const quotationFile = quotationFilesList?.documents[0];
    const formData = new FormData();
    if (quotationFile.file != null) formData.append("file", quotationFile.file, quotationFile.file.name);
    formData.append("quotationData", new Blob([JSON.stringify(quotationFile)], {
        type: "application/json"
    }));
    await apiRequestV2({
        endpoint: URL_QUOTATION_FILES,
        data: formData,
        method: "PUT",
        showSuccessMessage: true,
        isFormData: true
    });
};

export const deleteQuotationFile = async (idQuotationFile, reasonDeleted) => {
    return await apiRequestV2({
        endpoint: URL_QUOTATION_FILES + `/${idQuotationFile}`,
        urlParams: `?reasonDeleted=${reasonDeleted}`,
        data: null,
        method: "DELETE",
        showSuccessMessage: true,
    });
}