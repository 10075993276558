import React from 'react'
import Spinner from 'react-bootstrap/Spinner';
import { useSelector } from 'react-redux';

function Loader() {
    const loader = useSelector((state) => state.loader);

    return (
        <div className="loader-container">
            <div className="overlay"></div>
            <div className="loader-content">
                <Spinner animation="border" variant="light" />
                <p>
                    <b>
                        {loader.message ? 
                            loader.message 
                            :
                            "PROCESANDO..."
                            
                        }
                    </b>
                </p>
            </div>
        </div>
    )
}

export default Loader