import React, { useState } from 'react'
import Modal from '@mui/material/Modal';
import { ToastError, ToastSuccess, ToastWarning } from '../../../../utils/toastMessage';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../../../redux/features/loaderSlice';
import { ARCHIVOS_PERMITIDOS, BASE_URL_S3_COMPROBANTES_PROVEEDORES } from '../../../../utils/constantes';
import { actualizarComprobanteFP, buscarComprobantesPorIdFactura, eliminadoLogicoComprobante, guardarComprobanteFP } from '../../../../controllers/admin/facturas/facturaProveedorController';
import ModalArchivo from '../../../ModalArchivo';
import ModalEliminarObjeto from '../../ModalEliminarObjeto';
import { fetchEstatusEmbarque } from '../../../../redux/features/admin/estatusEmbarqueSlice';
import { authUserIsAdminOrOperativo } from '../../../../utils/authUtils';
import Table from 'react-bootstrap/Table';
import { validatePrint } from '../validations/validationComprobantes';
import FechaGeneric from '../../../inputs/FechaGeneric'
import { addItem, deleteItem, handleArrayDateTimeChange, handleChangeMultipleFile } from '../../../../utils/helpersState';
import CustomFileUploader from '../../../inputs/CustomFileUploader';
import ModalSendEmails from '../../ModalSendEmails';

function ModalComprobantesFacturaProveedor({ idFacturaProveedor, fetchFacturaProveedor }) {
  const [open, setOpen] = useState(false);
  const [comprobantes, setComprobantes] = useState([]);
  const embarqueData = useSelector((state) => state.embarque.embarqueData);
  const [authIsAdmin, setAuthIsAdmin] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [comprobanteSaved, setComprobanteSaved] = useState({});

  const dispatch = useDispatch();

  const handleOpen = () => {
    fetchComprobantes()
    setAuthIsAdmin(authUserIsAdminOrOperativo())
    setOpen(true)
  };

  const fetchComprobantes = async () => {
    const res = await buscarComprobantesPorIdFactura(idFacturaProveedor);
    setComprobantes(res.data)
  }

  const handleClose = async () => {
    setComprobantes([])
    dispatch(fetchEstatusEmbarque(embarqueData.id))
    fetchFacturaProveedor()
    setOpen(false);
  }

  const addComprobante = () => {
    addItem(comprobantes, setComprobantes, { esAnticipo: false, descripcion: "" })
  }

  const deleteComprobante = (indexComprobante) => {
    deleteItem(indexComprobante, comprobantes, setComprobantes)
  }

  const handleChange = (e, indexComprobante) => {
    const { name, value, type, checked } = e.target;
    const copyComprobantes = [...comprobantes];
    copyComprobantes[indexComprobante][name] = type == "checkbox" ? checked : value;
    setComprobantes(copyComprobantes);
  }

  const onChangeFiles = async (indexComprobante, file, name) => {
    await handleChangeMultipleFile(indexComprobante, file, name, comprobantes, setComprobantes, dispatch)
  }

  const procesarComprobante = async (indexComprobante) => {
    try {
      if (!comprobantes[indexComprobante].id
        && comprobantes[indexComprobante].archivoACargar == null) return ToastWarning("Se necesita el archivo.")

      dispatch(setLoading({ isLoading: true }))
      let res;

      if (comprobantes[indexComprobante].id) {

        res = await actualizarComprobanteFP(idFacturaProveedor,
          comprobantes[indexComprobante].id,
          comprobantes[indexComprobante].archivoACargar,
          comprobantes[indexComprobante])
        
      } else {
        res = await guardarComprobanteFP(idFacturaProveedor,
          comprobantes[indexComprobante].archivoACargar,
          comprobantes[indexComprobante])
          
      }
      dispatch(setLoading({ isLoading: false }))
      if (res.error) return ToastError(res.message);
      fetchComprobantes();
      setSendEmail(true);
      setComprobanteSaved(res.data);
      return ToastSuccess(res.message);
    } catch (err) {
      dispatch(setLoading({ isLoading: false }))
      return ToastError(err.message)
    }
  }

  const fetchEliminadoLogico = async (idComprobante, indexComprobante) => {
    try {
      dispatch(setLoading({ isLoading: true }))
      const res = await eliminadoLogicoComprobante(idComprobante, comprobantes[indexComprobante]);
      dispatch(setLoading({ isLoading: false }))
      if (res.error) return ToastError(res.message);

      deleteComprobante(indexComprobante)
      return ToastSuccess(res.message)
    } catch (err) {
      dispatch(setLoading({ isLoading: false }))
      return ToastError(err.message)
    }
  }

  const handleDateTimeChange = (date, nameInput, index) => {
    handleArrayDateTimeChange(date, nameInput, index, comprobantes, setComprobantes);
  }

  return (
    <div>
      <div align="center">
        <button className='btn btn-primary' onClick={handleOpen}>
          Comprobantes de Pago
        </button>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className='card style-modal-md'>
          <div className='card-header d-flex justify-content-between'>
            COMPROBANTES DE PAGO
            <button className='btn btn-dark btn-sm'
              onClick={handleClose}>
              <i className="bi bi-x-lg"></i>
            </button>
          </div>
          <div className='card-body body-modal-scroll'>
            <div className='col-12 mb-2' align="right">
              {validatePrint(authIsAdmin) ?
                <button className='btn btn-dark'
                  onClick={addComprobante}>
                  <i className="bi bi-plus-lg"></i> Agregar Comprobante
                </button>
                :
                ""
              }

            </div>
            <div style={{ backgroundColor: "white" }}>
              <Table>
                <thead>
                  <tr>
                    <th>ARCHIVO</th>
                    <th>ANTICIPO</th>
                    <th>DESCRIPCIÓN</th>
                    <th>FECHA PAGO</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {comprobantes ? comprobantes.map((comprobante, indexComprobante) => {
                    return (
                      <tr>
                        <td>
                          <div align="center">
                            {comprobante.id ?
                              <><ModalArchivo
                                title={"Comprobante de Pago"}
                                urlBase={BASE_URL_S3_COMPROBANTES_PROVEEDORES}
                                archivoS3={comprobante.archivo}></ModalArchivo><br /></>
                              : <></>}
                            {validatePrint(authIsAdmin) ?
                              <CustomFileUploader
                                handleChangeFile={onChangeFiles}
                                index={indexComprobante}
                                fileTypes={ARCHIVOS_PERMITIDOS}
                                multiple={true}
                                name={"archivoACargar"}
                                nameFile={comprobante.archivoACargar?.name}></CustomFileUploader>
                              :
                              ""
                            }
                          </div>
                        </td>
                        <td align='center'>
                          <input
                            className="form-check-input mt-1"
                            type="checkbox"
                            disabled={!validatePrint(authIsAdmin)}
                            checked={comprobante.esAnticipo}
                            onChange={(e) => { handleChange(e, indexComprobante) }}
                            name="esAnticipo"
                          />
                        </td>
                        <td>
                          <textarea className="form-control mt-1"
                            type="text"
                            disabled={!validatePrint(authIsAdmin)}
                            value={comprobante.descripcion}
                            onChange={(e) => { handleChange(e, indexComprobante) }}
                            name="descripcion"></textarea>
                        </td>
                        <td style={{ minWidth: "350px" }}>
                          <FechaGeneric
                            fecha={comprobante.fechaPagoRealizado}
                            handleDateTimeChange={handleDateTimeChange}
                            index={indexComprobante}
                            nameInput={"fechaPagoRealizado"}
                            disabled={!validatePrint(authIsAdmin)}>
                          </FechaGeneric>
                        </td>
                        <td>
                          {validatePrint(authIsAdmin) ?
                            <button className='btn btn-primary'
                              onClick={() => { procesarComprobante(indexComprobante) }}>
                              {comprobante.id ? "ACTUALIZAR" : "GUARDAR"}
                            </button>
                            :
                            ""}
                        </td>
                        <td>
                          {validatePrint(authIsAdmin) ?
                            <>
                              {

                                comprobante.id ?
                                  <ModalEliminarObjeto
                                    textHeader={"¿Seguro de eliminar este comprobante?"}
                                    textBody={""}
                                    functionEliminar={fetchEliminadoLogico}
                                    id={comprobante.id}
                                    index1={indexComprobante}>
                                    <textarea
                                      type="text"
                                      className="form-control"
                                      placeholder='Razón de la eliminación'
                                      name='razonEliminado'
                                      value={comprobante.razonEliminado}
                                      onChange={(e) => { handleChange(e, indexComprobante) }} />
                                  </ModalEliminarObjeto>
                                  :

                                  <button className='btn btn-danger'
                                    onClick={() => { deleteComprobante(indexComprobante) }}>
                                    <i className="bi bi-trash"></i>
                                  </button>

                              }
                            </>
                            :
                            ""}
                        </td>
                      </tr>
                    )
                  }) : ""}
                </tbody>
              </Table>
            </div>

          </div>

          <ModalSendEmails
            sendEmail={sendEmail}
            setSendEmail={setSendEmail}
            user={comprobanteSaved?.facturaProveedor?.costoProveedor?.proveedor}
            logistica={comprobanteSaved?.facturaProveedor?.costoProveedor?.desgloseEmbarque?.logistica}
            entity={comprobanteSaved}
            cardHeader={"NOTIFICAR A PROVEEDORES DE COMPROBANTE DE PAGO"}
            endPoint={"/comprobante-pago-proveedor"}></ModalSendEmails>
        </div>
      </Modal>
    </div>
  )
}

export default ModalComprobantesFacturaProveedor