import moment from 'moment';
import { CATALOGO_TIPOS_SERVICIO_COTIZACIONES, CATALOGO_UNIDAD_MEDIDA_BULTOS, CATALOGO_UNIDAD_MEDIDA_DIMENSION_C, buscarLabel } from '../../../../utils/catalogosEstaticos';
import { ID_SERVICIO_CARGA_CONSOLIDADA, ID_TRAFICO_AEREO } from '../../../../utils/constantes';
import { formatNameUser } from '../../../../utils/userFormat';
import { getEmailAdmin } from '../../../../utils/admins/getEmailAdmin';
import LogoKJ from '../../../../assets/imgs/logo.png';
import Linkedin from '../../../../assets/imgs/linkedin.png';
import Facebook from '../../../../assets/imgs/facebook.png';
import Instagram from '../../../../assets/imgs/instagram.png';
import { generateColCW, generateColHeaderCW, styles } from '../../../../utils/pdf/styles-react-pdf';
import { Document, Image, Page, Text, View, pdf } from '@react-pdf/renderer';
import { validatePrintDataTable } from '../../../table-dinamic/defaults/cotizacion/defaultTablesCotizacionVentas';

export const pdfCotizacion = ({ orientation, customSize, fontSizeTitle, fontSizeContent, fontSizeCondiciones, cotizacion }) => {
    const jsonCondiciones = JSON.parse(cotizacion.arrayCondicionesServicio)
    return (
        <Document>
            <Page size="A4" orientation={orientation} style={styles.pageBody}>
                <View style={styles.container}>
                    <View style={styles.header}>
                        <View style={styles.dataCol}>
                            <Image src={LogoKJ} style={{ maxWidth: 150 }} />
                        </View>
                        <View style={{ ...styles.dataCol, fontSize: "8px" }}>
                            <Text style={styles.textBold}>KLO210317KN1</Text>
                            <Text style={styles.textBold}>KARLA-JOSIMAR LOGISTICS</Text>
                            <Text style={{ margin: 2 }}></Text>

                            <Text style={styles.textBold}>Contacto:</Text>
                            <Text>
                                {formatNameUser(cotizacion.vendedor)} - {getEmailAdmin(cotizacion.vendedor?.id)}
                            </Text>
                            <Text>
                                {cotizacion?.vendedor?.phoneNumber}
                            </Text>

                            <Text style={{ margin: 2 }}></Text>

                            <View style={styles.iconsContainer}>
                                <Image src={Linkedin} style={styles.icons} />
                                <Image src={Facebook} style={styles.icons} />
                                <Image src={Instagram} style={styles.icons} />
                                <Text style={styles.textBold}>
                                    kj_logistics
                                </Text>
                            </View>

                        </View>
                    </View>
                    <View style={{ fontSize: "8px" }}>
                        <Text style={styles.headingProforma}>COTIZACIÓN</Text>
                        <Text>
                            <Text style={styles.textBold}>Referencia:</Text> {cotizacion?.quoteReferenceKJ}
                        </Text>
                    </View>

                    <View style={{ ...styles.col, fontSize: "8px" }}>
                        <View style={styles.col}>
                            <Text style={styles.heading}>DATOS DEL CLIENTE</Text>
                            <Text>
                                <Text style={styles.textBold}>Contacto:</Text> {cotizacion?.contacto}
                            </Text>
                            <Text><Text style={styles.textBold}>Empresa:</Text> {cotizacion?.addUnregisteredClient ? cotizacion?.unregisteredClient : cotizacion?.cliente?.razonSocial}
                            </Text>
                        </View>
                    </View>

                    <Text style={{ ...styles.heading, fontSize: "8px" }}>VENTA</Text>

                    {cotizacion?.ventas.map((venta) => {
                        const dimensiones = typeof venta.dimensionesCarga == "string" ? JSON.parse(venta.dimensionesCarga) : venta.dimensionesCarga
                        const tableVentas = typeof venta.tableVentas == "string" ? JSON.parse(venta.tableVentas) : venta.tableVentas;
                        const sizeColumn = 100 / tableVentas.columns.length;
                        const widthDimensiones = venta.trafico?.id == ID_TRAFICO_AEREO ? ["8.33%", "8.33%", "8.33%", "16.66%", "8.33%", "8.33%", "8.33%", "8.33%", "8.33%", "8.33%", "8.33%"]
                            : ["10%", "10%", "10%", "20%", "10%", "10%", "10%", "10%", "10%"];
                        let totalCantidad = 0;
                        let totalPeso = 0;
                        let totalVolumen = 0;

                        return (
                            <View key={venta.id}>
                                <View style={{ fontSize: "8px" }}>
                                    <Text>
                                        <Text style={styles.textBold}>Tráfico:</Text> {venta.trafico?.nombre}
                                    </Text>
                                    <Text>
                                        <Text style={styles.textBold}>Servicio:</Text> {CATALOGO_TIPOS_SERVICIO_COTIZACIONES.find(item => item.id == venta.idServicio).nombre}
                                    </Text>
                                    <Text>
                                        <Text style={styles.textBold}>Tipo de Carga:</Text> {venta.tipoCarga?.nombre}
                                    </Text>
                                    <Text>
                                        <Text style={styles.textBold}>Mercancía:</Text> {venta.mercancia}
                                    </Text>
                                    <Text>
                                        <Text style={styles.textBold}>Vigencia:</Text> {moment(cotizacion?.vigencia).local().format("DD/MM/YYYY")}
                                    </Text>
                                </View>

                                {venta.tipoCarga?.id == ID_SERVICIO_CARGA_CONSOLIDADA ?
                                    <View>
                                        <View style={styles.table}>
                                            <View style={styles.tableRow}>
                                                <View style={{ ...generateColHeaderCW("100%"), backgroundColor: "#043f52" }}>
                                                    <Text style={{...styles.tableCell, fontSize: fontSizeTitle}}>DIMENSIONES</Text>
                                                </View>
                                            </View>
                                            <View style={styles.tableRow}>
                                                <View style={{ ...generateColHeaderCW(widthDimensiones[0]), backgroundColor: "#043f52" }}>
                                                    <Text style={{ ...styles.tableCell, fontSize: fontSizeTitle }}>CANT.</Text>
                                                </View>
                                                <View style={{ ...generateColHeaderCW(widthDimensiones[1]), backgroundColor: "#043f52" }}>
                                                    <Text style={{ ...styles.tableCell, fontSize: fontSizeTitle }}>PESO POR BULTO</Text>
                                                </View>
                                                {venta.trafico?.id == ID_TRAFICO_AEREO ?
                                                    <View style={{ ...generateColHeaderCW(widthDimensiones[10]), backgroundColor: "#043f52" }}>
                                                        <Text style={{ ...styles.tableCell, fontSize: fontSizeTitle }}>PESO CARGABLE</Text>
                                                    </View> : null
                                                }
                                                <View style={{ ...generateColHeaderCW(widthDimensiones[2]), backgroundColor: "#043f52" }}>
                                                    <Text style={{ ...styles.tableCell, fontSize: fontSizeTitle }}>UNIDAD DE MEDIDA</Text>
                                                </View>
                                                <View style={{ ...generateColHeaderCW(widthDimensiones[3]), backgroundColor: "#043f52" }}>
                                                    <Text style={{ ...styles.tableCell, fontSize: fontSizeTitle }}>EMBALAJE</Text>
                                                </View>
                                                <View style={{ ...generateColHeaderCW(widthDimensiones[4]), backgroundColor: "#043f52" }}>
                                                    <Text style={{ ...styles.tableCell, fontSize: fontSizeTitle }}>UNIDAD DE MEDIDA</Text>
                                                </View>
                                                <View style={{ ...generateColHeaderCW(widthDimensiones[5]), backgroundColor: "#043f52" }}>
                                                    <Text style={{ ...styles.tableCell, fontSize: fontSizeTitle }}>LARGO</Text>
                                                </View>
                                                <View style={{ ...generateColHeaderCW(widthDimensiones[6]), backgroundColor: "#043f52" }}>
                                                    <Text style={{ ...styles.tableCell, fontSize: fontSizeTitle }}>ALTO</Text>
                                                </View>
                                                <View style={{ ...generateColHeaderCW(widthDimensiones[7]), backgroundColor: "#043f52" }}>
                                                    <Text style={{ ...styles.tableCell, fontSize: fontSizeTitle }}>ANCHO</Text>
                                                </View>
                                                <View style={{ ...generateColHeaderCW(widthDimensiones[8]), backgroundColor: "#043f52" }}>
                                                    <Text style={{ ...styles.tableCell, fontSize: fontSizeTitle }}>VOLUMEN</Text>
                                                </View>
                                                {venta.trafico?.id == ID_TRAFICO_AEREO ?
                                                    <View style={{ ...generateColHeaderCW(widthDimensiones[9]), backgroundColor: "#043f52" }}>
                                                        <Text style={{ ...styles.tableCell, fontSize: fontSizeTitle }}>PESO VOLUMEN</Text>
                                                    </View>
                                                    : null
                                                }
                                            </View>
                                            {
                                                dimensiones?.map((dimension) => {
                                                    totalCantidad = totalCantidad + dimension.cantidadBultos;
                                                    totalPeso = totalPeso + parseFloat(dimension.pesoPorBulto);
                                                    totalVolumen = totalVolumen + parseFloat(dimension.dimensionVolumen);
                                                    let pesoCargable = parseFloat(dimension.pesoPorBulto) * parseFloat(dimension.cantidadBultos);
                                                    let pesoVolumen = ((parseFloat(dimension.dimensionLargo) * parseFloat(dimension.dimensionAlto) * parseFloat(dimension.dimensionAncho)) / 6000) * parseFloat(dimension.cantidadBultos);
                                                    return (
                                                        <View style={styles.tableRow}>
                                                            <View style={generateColCW(widthDimensiones[0])}>
                                                                <Text style={{ ...styles.tableCell, fontSize: fontSizeContent }}>{dimension.cantidadBultos}</Text>
                                                            </View>
                                                            <View style={generateColCW(widthDimensiones[1])}>
                                                                <Text style={{ ...styles.tableCell, fontSize: fontSizeContent }}>{dimension.pesoPorBulto}</Text>
                                                            </View>
                                                            {venta.trafico?.id == ID_TRAFICO_AEREO ?
                                                                <View style={generateColCW(widthDimensiones[10])}>
                                                                    <Text style={{ ...styles.tableCell, fontSize: fontSizeContent }}>{pesoCargable.toFixed(2)}</Text>
                                                                </View>
                                                                : null
                                                            }
                                                            <View style={generateColCW(widthDimensiones[2])}>
                                                                <Text style={{ ...styles.tableCell, fontSize: fontSizeContent }}>{buscarLabel(dimension.idUnidadMedidaBultos, CATALOGO_UNIDAD_MEDIDA_BULTOS)}</Text>
                                                            </View>
                                                            <View style={generateColCW(widthDimensiones[3])}>
                                                                <Text style={{ ...styles.tableCell, fontSize: fontSizeContent }}>{dimension.embalaje}</Text>
                                                            </View>
                                                            <View style={generateColCW(widthDimensiones[4])}>
                                                                <Text style={{ ...styles.tableCell, fontSize: fontSizeContent }}>{buscarLabel(dimension.idUnidadMedidaDimension, CATALOGO_UNIDAD_MEDIDA_DIMENSION_C)}</Text>
                                                            </View>
                                                            <View style={generateColCW(widthDimensiones[5])}>
                                                                <Text style={{ ...styles.tableCell, fontSize: fontSizeContent }}>{dimension.dimensionLargo}</Text>
                                                            </View>
                                                            <View style={generateColCW(widthDimensiones[6])}>
                                                                <Text style={{ ...styles.tableCell, fontSize: fontSizeContent }}>{dimension.dimensionAlto}</Text>
                                                            </View>
                                                            <View style={generateColCW(widthDimensiones[7])}>
                                                                <Text style={{ ...styles.tableCell, fontSize: fontSizeContent }}>{dimension.dimensionAncho}</Text>
                                                            </View>
                                                            <View style={generateColCW(widthDimensiones[8])}>
                                                                <Text style={{ ...styles.tableCell, fontSize: fontSizeContent }}>{dimension.dimensionVolumen}</Text>
                                                            </View>
                                                            {venta.trafico?.id == ID_TRAFICO_AEREO ?
                                                                <View style={generateColCW(widthDimensiones[9])}>
                                                                    <Text style={{ ...styles.tableCell, fontSize: fontSizeContent }}>{pesoVolumen.toFixed(2)}</Text>
                                                                </View>
                                                                : null
                                                            }
                                                        </View>
                                                    )
                                                })}

                                            <View style={styles.tableRow}>
                                                <View style={{ ...generateColHeaderCW("10%"), backgroundColor: "#043f52" }}>
                                                    <Text style={{ ...styles.tableCell, fontSize: fontSizeTitle }}>BULTOS TOTALES</Text>
                                                </View>
                                                <View style={{ ...generateColHeaderCW("10%"), backgroundColor: "#043f52" }}>
                                                    <Text style={{ ...styles.tableCell, fontSize: fontSizeTitle }}>PESO TOTAL</Text>
                                                </View>
                                                <View style={{ width: "70%" }}>
                                                </View>
                                                <View style={{ ...generateColHeaderCW("10%"), backgroundColor: "#043f52" }}>
                                                    <Text style={{ ...styles.tableCell, fontSize: fontSizeTitle }}>VOLUMEN TOTAL</Text>
                                                </View>
                                            </View>
                                            <View style={styles.tableRow}>
                                                <View style={generateColCW("10%")}>
                                                    <Text style={{ ...styles.tableCell, fontSize: fontSizeContent }}>{totalCantidad}</Text>
                                                </View>
                                                <View style={generateColCW("10%")}>
                                                    <Text style={{ ...styles.tableCell, fontSize: fontSizeContent }}>{totalPeso.toFixed(2)}</Text>
                                                </View>
                                                <View style={{ width: "70%" }}>
                                                    <Text></Text>
                                                </View>
                                                <View style={generateColCW("10%")}>
                                                    <Text style={{ ...styles.tableCell, fontSize: fontSizeContent }}>{totalVolumen}</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>

                                    :
                                    ""
                                }

                                <View style={{ marginTop: `10px` }}></View>

                                {tableVentas && tableVentas.columns.length > 0 ?
                                    <View style={styles.table}>
                                        <View style={styles.tableRow}>
                                            {tableVentas?.columns?.map((column) => {
                                                let widthPdf = orientation == "landscape" ? column?.widthHPdf : column?.widthVPdf;
                                                const sizeColumnWidth = widthPdf ? widthPdf / 1.33 : `${sizeColumn}%`;
                                                return (
                                                    <View style={{ ...generateColHeaderCW(`${sizeColumnWidth}`), backgroundColor: "#043f52"}} key={column.idItem}>
                                                        <Text style={{ ...styles.tableCell, fontSize: fontSizeTitle }}>{column.nombreColumna}</Text>
                                                    </View>
                                                )
                                            })}
                                        </View>

                                        {tableVentas?.data?.map((data) => {
                                            return (
                                                <View style={styles.tableRow}>
                                                    {tableVentas?.columns?.map((column) => {
                                                        const nameColumn = column.idItem;
                                                        let widthPdf = orientation == "landscape" ? column?.widthHPdf : column?.widthVPdf;
                                                        const sizeColumnWidth = widthPdf ? widthPdf / 1.33 : `${sizeColumn}%`;
                                                        return (
                                                            <View style={generateColCW(`${sizeColumnWidth}`)} key={column.idItem}>
                                                                <Text style={{ ...styles.tableCell, fontSize: fontSizeContent }}>
                                                                    {validatePrintDataTable(column, data, nameColumn)}
                                                                </Text>
                                                            </View>
                                                        )
                                                    })}
                                                </View>
                                            )
                                        })}

                                    </View>
                                    :
                                    ""
                                }

                                <View style={{ backgroundColor: "black", height: 1, margin: "10px" }} />
                            </View>

                        )
                    })}

                    <Text style={styles.headingProforma}>CONDICIONES DE SERVICIO</Text>
                    {
                        jsonCondiciones?.map(condicion => {
                            return (
                                <Text style={{ fontSize: fontSizeCondiciones }}>
                                    <Text style={styles.textBold}> - </Text> {condicion}
                                </Text>
                            )
                        })
                    }

                </View>

                <Text style={styles.footer} fixed>
                    <Text>KARLA-JOSIMAR LOGISTICS, KLO210317KN1, C.P. 76147 | https://kj-logistics.com/{'\n'}</Text>
                    <Text>josimar.ramirez@kj-logistics.com 55 3443 9138 | claudia.lopez@kj-logistics.com 442 389 7588{'\n'}</Text>
                </Text>

            </Page>
        </Document>
    )
}

export const getBlobCotizacionesPDF = async (cotizacion) => {
    return await pdf(pdfCotizacion({
        orientation: "landscape",
        fontSizeTitle: 8,
        fontSizeContent: 8,
        cotizacion: cotizacion
    })).toBlob();
}

export const getBlobCotizacionesCustomPDF = async ({
    cotizacion,
    orientation,
    fontSizeTitle,
    fontSizeContent,
}) => {
    return await pdf(pdfCotizacion({
        orientation: orientation,
        fontSizeTitle: fontSizeTitle,
        fontSizeContent: fontSizeContent,
        cotizacion: cotizacion
    })).toBlob();
}