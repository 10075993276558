import React, { useEffect, useState } from 'react'
import { buscarArchivosDeUsuario, actualizarArchivoUser, subirArchivoUser, eliminadoLogicoUserArchivo } from '../../controllers/admin/usuariosController';
import { setLoading } from '../../redux/features/loaderSlice';
import ModalArchivo from '../ModalArchivo';
import { ARCHIVOS_PERMITIDOS_V2, BASE_URL_S3_ARCHIVOS_USUARIOS } from '../../utils/constantes';
import { ToastError, ToastSuccess, ToastWarning } from '../../utils/toastMessage';
import MUIDataTable from "mui-datatables";
import { useDispatch } from 'react-redux';
import ModalEliminarObjeto from './ModalEliminarObjeto';
import { OPTIONS_MUI_ONLY_TABLE } from '../../utils/mui/constantes';
import CustomFileUploader from '../inputs/CustomFileUploader';
import { addItem, deleteItem, handleChangeArrayText, handleChangeMultipleFile } from '../../utils/helpersState';

function ArchivosUsuario({ idUsuario }) {
    const [archivosUser, setArchivosUser] = useState([])
    const dispatch = useDispatch();

    useEffect(() => {
        fetchBuscarArchivosDeUsuario()
    }, [])

    const fetchBuscarArchivosDeUsuario = async () => {
        try {
            const res = await buscarArchivosDeUsuario(idUsuario)
            if (res.error) return ToastError(res.message)
            setArchivosUser(res.data);
        } catch (e) {
            ToastError(e)
        }
    };

    const onChangeFiles = async (indexDoc, file, name) => {
        await handleChangeMultipleFile(indexDoc, file, name, archivosUser, setArchivosUser, dispatch);
    }

    const handlerOnSubmit = async (indexDOC, cargarTodos) => {
        try {
            if (cargarTodos) {
                archivosUser.forEach((archivo, index) => {
                    if (archivo.archivo) {
                        onSubmitFiles(archivosUser[index]);
                    }
                });
            } else {
                if (!archivosUser[indexDOC].archivo) return ToastWarning("Se debe subir un archivo")
                onSubmitFiles(archivosUser[indexDOC]);
            }
        } catch (error) {
            ToastError(error.message);
            dispatch(setLoading({ isLoading: false }))
        }
    };

    const onSubmitFiles = async (userArchivo) => {

        if (userArchivo.nombreArchivo === null || userArchivo.nombreArchivo === "") {
            return ToastWarning("Se necesita el nombre del archivo.");
        }

        dispatch(setLoading({ isLoading: true }))
        try {
            let res;
            if (userArchivo.id != null) {
                res = await actualizarArchivoUser(userArchivo.archivo, userArchivo)
            } else {
                res = await subirArchivoUser(userArchivo.archivo, idUsuario, userArchivo)
            }

            dispatch(setLoading({ isLoading: false }))
            if (res.error) return ToastError(res.message)
            ToastSuccess(res.message)
            fetchBuscarArchivosDeUsuario()
        } catch (error) {
            return ToastError(error)
        }
    }

    const handleChangeInputArchivo = (e, index) => {
        handleChangeArrayText(e, index, archivosUser, setArchivosUser, true)
    };

    const addArchivo = () => {
        addItem(archivosUser, setArchivosUser, {nombreArchivo: '',archivo: {}});
    }

    const deleteArchivo = (index) => {
        deleteItem(index, archivosUser, setArchivosUser);
    }

    const fecthEliminadoLogicoDoc = async (idEmbarqueArchivo, index) => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const res = await eliminadoLogicoUserArchivo(idEmbarqueArchivo, archivosUser[index].razonEliminado)
            dispatch(setLoading({ isLoading: false }))
            if (res.error) return ToastError(res.message)

            deleteArchivo(index)
            return ToastSuccess(res.message)
        } catch (err) {
            return ToastError(err)
        }
    }

    const columns = [
        {
            name: "",
            options: {
                customBodyRenderLite: (index) => {
                    return (
                        <input
                            type="text"
                            required
                            className="form-control"
                            placeholder='Nombre del Archivo'
                            name='nombreArchivo'
                            value={archivosUser[index].nombreArchivo}
                            onChange={(e) => handleChangeInputArchivo(e, index)} />
                    )
                },
                sort: false,
                download: false,
            },
        },
        {
            name: "",
            options: {
                customBodyRenderLite: (index) => {
                    return (
                        <>
                            <CustomFileUploader
                                handleChangeFile={onChangeFiles}
                                index={index}
                                fileTypes={ARCHIVOS_PERMITIDOS_V2}
                                multiple={true}
                                name={"archivo"}
                                nameFile={archivosUser[index].archivo?.name}></CustomFileUploader>
                        </>

                    )
                },
                sort: false,
                download: false,
            },
        },
        {
            name: "",
            options: {
                customBodyRenderLite: (index) => {
                    return (
                        archivosUser[index].archivo.id != null ?
                            <ModalArchivo
                                title={archivosUser[index].nombreArchivo}
                                urlBase={BASE_URL_S3_ARCHIVOS_USUARIOS}
                                archivoS3={archivosUser[index].archivo}></ModalArchivo>
                            :
                            ""
                    )
                },
                sort: false,
                download: false,
            },
        },
        {
            name: "",
            options: {
                customBodyRenderLite: (indexDoc) => {
                    return (
                        <button type='button'
                            className='btn btn-dark'
                            disabled={!archivosUser[indexDoc].archivo.size}
                            onClick={() => { handlerOnSubmit(indexDoc) }}>
                            <i className="bi bi-cloud-arrow-up-fill"></i>
                            {archivosUser[indexDoc].id ?
                                " Actualizar" : " Subir"

                            }
                        </button>
                    );
                },
                sort: false,
                download: false,
            }
        },
        {
            name: "",
            options: {
                customBodyRenderLite: (index) => {
                    return (
                        archivosUser[index].id ?
                            <>
                                <ModalEliminarObjeto
                                    textHeader={"¿Seguro de eliminar este documento?"}
                                    textBody={""}
                                    functionEliminar={fecthEliminadoLogicoDoc}
                                    id={archivosUser[index].id}
                                    index1={index}>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        placeholder='Razón de la eliminación'
                                        name='razonEliminado'
                                        value={archivosUser[index].razonEliminado}
                                        onChange={(e) => { handleChangeInputArchivo(e, index) }} />
                                </ModalEliminarObjeto>
                            </>
                            :
                            <button type='button' className='btn btn-danger'
                                onClick={() => deleteArchivo(index)}>
                                <i className="bi bi-trash-fill"></i>
                            </button>
                    )
                },
                sort: false,
                download: false,
            },
        },
    ]

    return (
        <>
            <div className='col-12' align="right">
                <button type='button' className='btn btn-dark me-2'
                    onClick={addArchivo}>
                    <i className="bi bi-plus-lg"></i> Agregar Documento
                </button>
                <button type='button' className='btn btn-dark'
                    onClick={() => handlerOnSubmit(null, true)}>
                    <i className="bi bi-cloud-arrow-up-fill"></i> CARGAR TODOS
                </button>
            </div>
            <MUIDataTable
                title={"Documentos"}
                data={archivosUser}
                columns={columns}
                options={OPTIONS_MUI_ONLY_TABLE}
            />
        </>
    )
}

export default ArchivosUsuario