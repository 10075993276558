import React from 'react'
import { deleteItem, handleChangeSimpleArrayText } from '../../../../utils/helpersState'

function TableCustomCS({ condicionesServicio, setCondicionesServicio, buttonGenerateCondiciones }) {

    return (
        <>
            <hr />
            <div className='col-12' align="right">
                <h6>CONDICIONES DEL SERVICIO</h6>
            </div>

            <div className='col-12'>
                <button type='button' className='btn btn-dark m-1'
                    onClick={() => {
                        const state = [...condicionesServicio];
                        state.push("");
                        setCondicionesServicio(state);
                    }}><i className="bi bi-plus"></i>CONDICION DE SERVICIO</button>

                {buttonGenerateCondiciones}
            </div>

            <div className='col-12'>
                {condicionesServicio?.map((condicion, index) => {
                    return (
                        <div className='input-group mt-2' key={index}>
                            <input
                                type="text"
                                className="form-control"
                                value={condicion}
                                onChange={(e) => { handleChangeSimpleArrayText(e, index, condicionesServicio, setCondicionesServicio) }} />
                            <button className='btn btn-danger' type='button'
                                onClick={() => { deleteItem(index, condicionesServicio, setCondicionesServicio) }}><i className="bi bi-trash3"></i></button>
                        </div>
                    )
                })}
            </div>

        </>
    )
}

export default TableCustomCS