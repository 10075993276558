import SelectSearch from "../inputs/SelectSearch";

function FormikSelectSearch({ field, form, ...props }) {

    const {titulo, isDisabled, returnObject, data, onChange  } = props;

    return (
        <SelectSearch
            data={data}
            titulo={titulo}
            value={field.value}
            name={field.name}
            handleChange={(event) => {
                const {value} = event.target;
                form.setFieldValue(field.name, value)

                if (onChange) {
                    onChange(event);
                }
            }}
            isDisabled={isDisabled}
            returnObject={returnObject}></SelectSearch>
    )
}

export default FormikSelectSearch