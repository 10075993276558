import { useEffect, useState } from 'react';
import TableDinamic from '../table-dinamic/TableDinamic';

function FormikTableDinamic({ field, form, ...props }) {

    const {functionGenerateTable, objectValue, attributeName } = props;

    return (
        <>

            <TableDinamic
                dinamicTable={typeof field.value == 'string' ? JSON.parse(field.value) : {}}
                isFormik={true}
                functionGenerateTable={functionGenerateTable}
                objectValue={objectValue}
                attributeName={attributeName}
                handleChange={(data) => {
                    form.setFieldValue(field.name, data)
                }}></TableDinamic>

        </>

    )
}

export default FormikTableDinamic