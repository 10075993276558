export const getEmailAdmin = (id) => {

    //Se necesita refactorizar esto y traer desde la DB. se realizo asi por los tiempos.
    switch (id) {
        case 1:
            return "mares.bueno.brayan@gmail.com"
        case 2:
            return "karla.lopez@kj-logistics.com"
        case 3:
            return "josimar.ramirez@kj-logistics.com"
        case 12:
            return "alberto.lopez@kj-logistics.com"
        case 61:
            return "rogelio.gomez@kj-logistics.com"
        case 62:
            return "karen.aguilar@kj-logistics.com"
        case 93:
            return "juan.uribe@kj-logistics.com"
        default:
            return "consulta un administrador"
    }

}