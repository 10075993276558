import React, { useState, useEffect, useCallback } from 'react'
import { authLogin } from '../../controllers/auth/authController.js';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { ToastError } from '../../utils/toastMessage';
import LogoKJ from '../../assets/imgs/logo.png'
import '../../assets/css/login.css'
import { getAuthRoleUser } from '../../utils/authUtils.js';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/features/loaderSlice.js';
import { setRole } from '../../redux/features/userAuthSlice.js';

function Login() {
    const [user, setUser] = useState({
        email: '',
        password: ''
    });
    const [cookies, setCookie] = useCookies(['auth-token']);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const redirectUserRol = useCallback(async () => {
        if (cookies['auth-token'] && localStorage.getItem('auth-user')) {
            const userRol = getAuthRoleUser();
            dispatch(setRole(userRol))
            switch (userRol.name) {
                case "ROLE_ADMIN":
                    navigate('/admin');
                    break;
                case "ROLE_PROVEEDOR":
                    navigate('/proveedor');
                    break;
                case "ROLE_CLIENTE":
                    navigate('/cliente');
                    break;
                case "ROLE_OPERATIVO":
                    navigate('/operativo')
                    break;
                case "ROLE_LOGISTICA":
                    navigate('/logistica')
                    break;
                default:
                    navigate('/no-autorizado');
                    break;
            }
        }
    }, [cookies, navigate]);

    useEffect(() => {
        redirectUserRol()
    }, [redirectUserRol]);

    const handleChange = e => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            dispatch(setLoading({ isLoading: true }))
            const res = await authLogin(user.email, user.password)
            dispatch(setLoading({ isLoading: false }))
            if (res.error) {
                return ToastError(res.message);
            } else {
                setCookie('auth-token', res.data.token, { path: '/' });
                localStorage.setItem('auth-user', res.data.user);
                redirectUserRol();
            }
        } catch (error) {
            return ToastError(error);
        }
    }

    return (
        <div className='row m-4' onSubmit={handleSubmit}>
            <div className='col-12'>
                <img src={LogoKJ} className="mx-auto d-block" />
            </div>
            <div className='col-3'>
            </div>
            <div className='col-6'>
                <form>
                    <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">Correo Electronico</label>
                        <input
                            type="email"
                            required
                            className="form-control"
                            name='email'
                            value={user.email}
                            onChange={handleChange}
                            aria-describedby="email" />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="passwordLogin" className="form-label">Contraseña</label>
                        <input
                            type="password"
                            required
                            className="form-control"
                            name='password'
                            value={user.password}
                            onChange={handleChange}
                            autoComplete="current-password"
                            id="passwordLogin" />
                    </div>
                    <div className='col-12' align="right">
                        <button type="submit" className="btn btn-primary">Ingresar</button>
                    </div>

                </form>
            </div>
            <div className='col-3'>

            </div>
        </div>
    )
}

export default Login