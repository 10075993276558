import React from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { saveCondicionAduana, updateCondicionAduana } from '../../../../controllers/admin/cotizaciones/condicionesAduanaController';
import { CondicionAduana } from '../../../../models/admin/cotizaciones/CondicionAduana';

function FormCondicionesAduana({ setRefreshData, condicionAduana, setCondicionAduana }) {
    return (
        <Formik
            initialValues={condicionAduana}
            onSubmit={async (condicion, { resetForm }) => {
                const data = condicion.id ? await updateCondicionAduana(condicion, condicion.id) : await saveCondicionAduana(condicion);
                setRefreshData(data);
                resetForm();
                setCondicionAduana(CondicionAduana);
            }}
            enableReinitialize
            validationSchema={Yup.object({
                nombre: Yup.string()
                    .required('El nombre del servicio es requerido'),
            })}
        >
            <Form>
                <div className="col-sm-12 mb-3">
                    <label htmlFor="nombreServicio" className="form-label">Nombre</label>
                    <Field id="nombreServicio" name="nombre" className="form-control" />
                    <ErrorMessage name='nombre' component='span' className='text-danger'></ErrorMessage>
                </div>

                <div className='col-sm-12' align="right">
                    {condicionAduana.id ? <button className='btn btn-secondary' onClick={() => { setCondicionAduana(CondicionAduana) }}>CANCELAR</button> : <></>}
                    <button type="submit" className='btn btn-primary'>{condicionAduana.id ? "ACTUALIZAR" : "GUARDAR"}</button>
                </div>
            </Form>
        </Formik>
    )
}

export default FormCondicionesAduana