import React from "react";
import ModalArchivo from "../ModalArchivo";
import {
  BASE_URL_S3_PODS_EMBARQUES,
} from "../../utils/constantes";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { Card } from "react-bootstrap";
import "@react-pdf-viewer/core/lib/styles/index.css";

function PreviewPODS({ embarque }) {
  return (
    <>
      <Card>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
          <div style={{ height: "200px", width: "200px", margin: "0 auto" }}>
            <Viewer
              fileUrl={
                BASE_URL_S3_PODS_EMBARQUES +
                embarque?.archivoPOD?.keyS3 +
                "?" +
                embarque?.actualizadoEn
              }
              alt={embarque.archivoPOD}
              renderError={(error) => (
                <div>
                  <p>El archivo no se pudo cargar. </p>
                  <button className="btn btn-primary" onClick={() => saveAs(
                    BASE_URL_S3_PODS_EMBARQUES +
                    embarque?.archivoPOD.keyS3 + "?" + embarque?.actualizadoEn
                  )}>DESCARGAR</button>
                </div>
              )}
            />
          </div>
        </Worker>
        <center>
          <ModalArchivo
            title={"Prueba de entrega"}
            urlBase={BASE_URL_S3_PODS_EMBARQUES}
            archivoS3={embarque?.archivoPOD}
          ></ModalArchivo>
        </center>
        <Card.Body style={{ backgroundColor: "white", fontSize: "13px" }}>
          <Card.Text>
            {embarque?.direccionEntrega?.direccion ?
              <><b>Dirección de entrega: </b>
                {embarque.direccionEntrega.direccion}</>
              :
              ""}

          </Card.Text>
          <Card.Text>
            {embarque?.direccionRecoleccion?.direccion ?
              <><b>Dirección de recolección: </b>
                {embarque.direccionRecoleccion.direccion}</>
              :
              ""}
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
}

export default PreviewPODS;
