import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    idUsuario: null,
    usuarioModel: {
        id: null,
        nombreComercial: '',
        tipoDePersona: 'PF',
        enable: true,
        nombre: '',
        primerApellido: '',
        segundoApellido: '',
        password: '',
        razonSocial: '',
        direccionFiscal: {},
        rfc: '',
        diasDeCredito: 0,
        metodoDePago: '',
        usoDeCFDI: '',
    }
}


export const usuarioSlice = createSlice({
    name: 'usuario',
    initialState,
    reducers: {
        setIdUsuario: (state, action) =>{
            state.idUsuario = action.payload;
        },
        setUsuario: (state, action) =>{
            state.usuarioModel = action.payload;
        },
        resetUsuario: (state) => {
            state.usuarioModel = initialState.usuarioModel;
        },
        sliceActualizarDireccionFiscal: (state,action) =>{
            state.usuarioModel.direccionFiscal = action.payload
        }
    }
});

export const {setIdUsuario, setUsuario, resetUsuario, sliceActualizarDireccionFiscal} = usuarioSlice.actions;

export default usuarioSlice.reducer;