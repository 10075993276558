import { URL_EMBARQUES } from "../../../utils/constantes";
import { apiRequest } from "../../handlerApiRequest";
import { apiRequestV2 } from "../../handlerApiRequestV2";


export const guardarEmbarque = async (embarque) => {
    return await apiRequestV2({
        endpoint: `${URL_EMBARQUES}`,
        method: "POST",
        data: embarque,
        showSuccessMessage: true
    })
}

export const actualizarEmbarque = async (embarque) => {
    return await apiRequestV2({
        endpoint: `${URL_EMBARQUES}/${embarque.id}`,
        method: "PUT",
        data: embarque,
        showSuccessMessage: true
    })
}

export const buscarEmbarquePorId = async (idEmbarque) => {
    return await apiRequestV2({
        endpoint: `${URL_EMBARQUES}/${idEmbarque}`,
        method: "GET"
    })
}


export const guardarDesgloseEmbarque = async (idEmbarque, desgloseEmbarque) => {
    /**
     * La razón por la que no puedes manipular directamente el objeto 
     * desgloseEmbarque que se le pasa como argumento a la función 
     * guardarDesgloseEmbarque es porque en JavaScript los objetos son 
     * pasados por referencia y no por valor. 
     * Esto significa que cualquier modificación que hagas en el objeto 
     * dentro de la función también se reflejará en el objeto original 
     * fuera de la función.
     */
    const desgloseEmbarqueCopy = structuredClone(desgloseEmbarque);
    if (!desgloseEmbarqueCopy.tipoCargaDetalle || Object.keys(desgloseEmbarqueCopy.tipoCargaDetalle).length === 0) {
        desgloseEmbarqueCopy.tipoCargaDetalle = null;
    }
    return await apiRequestV2({
        endpoint: URL_EMBARQUES + "/" + idEmbarque + "/desglose-embarque",
        method: "POST",
        data: desgloseEmbarqueCopy,
        showSuccessMessage: true
    })

}

export const actualizarDesgloseEmbarque = async (idEmbarque, desgloseEmbarque) => {

    const desgloseEmbarqueCopy = structuredClone(desgloseEmbarque);
    if (!desgloseEmbarqueCopy.tipoCargaDetalle || Object.keys(desgloseEmbarqueCopy.tipoCargaDetalle).length === 0) {
        desgloseEmbarqueCopy.tipoCargaDetalle = null;
    }

    return await apiRequestV2({
        endpoint: URL_EMBARQUES + "/" + idEmbarque + "/desglose-embarque/" + desgloseEmbarque.id,
        method: "PUT",
        data: desgloseEmbarqueCopy,
        showSuccessMessage: true
    })
}

export const findAllByFilters = async (urlParams, filters) => {
    if (!filters) filters = []
    return await apiRequestV2({
        endpoint: URL_EMBARQUES + "/search",
        method: "POST",
        urlParams: urlParams,
        data: filters
    });
}

export const findAllToReport = async (urlParams, filters) => {
    if (!filters) filters = []
    return await apiRequestV2({
        endpoint: URL_EMBARQUES + "/search-report",
        method: "POST",
        urlParams: urlParams,
        data: filters
    });
}


export const buscarEmbarqueReqDTOPorId = async (idEmbarque) => {
    return await apiRequestV2({
        endpoint: URL_EMBARQUES + "/embarque-req/" + idEmbarque
    });
}

export const cancelarEmbarque = async (idEmbarque, razonCancelar) => {
    return await apiRequestV2({
        endpoint: URL_EMBARQUES + "/" + idEmbarque + "/cancelar-embarque",
        method: "PUT",
        urlParams: "?razonCancelar=" + razonCancelar,
        showSuccessMessage: true
    });
}

export const construisEstatusEmbarquePorId = async (idEmbarque) => {
    return await apiRequest(URL_EMBARQUES + "/" + idEmbarque + "/obtener-estatus");
}

export const establecerActividadDeEstatusCompletada = async (idActividadEstatus, completado) => {
    return await apiRequest(URL_EMBARQUES + "/actividad-estatus/" + idActividadEstatus + "/establacer-completado-actividad", "PUT", null, "?completado=" + completado);
}

export const rectificarEstatus = async (idEmbarque) => {
    return await apiRequestV2({
        endpoint: URL_EMBARQUES + `/${idEmbarque}/rectificar-estatus`,
        showSuccessMessage: true
    });
}