import React, { useEffect, useState } from 'react'
import { formatMoney } from '../../../utils/formatMoney'
import { buscarPorIdCostoProveedor } from '../../../controllers/admin/facturas/facturaProveedorController';
import { ToastError } from '../../../utils/toastMessage';
import TableFacturas from './facturas/TableFacturas';
import { validateAddFacturaProveedor } from '../../admin/facturas/validations/validationsFacturaProveedor';
import { useSelector } from 'react-redux';
import { addItem } from '../../../utils/helpersState';
import { ID_ROL_LOGISTICA } from '../../../utils/constantes';
import { searchFacturaById } from '../../../controllers/proveedor/proveedorCostosController';

function CardCosto({ costo, fetctListarCostosDeProveedor }) {
    const [facturas, setFacturas] = useState([]);
    const roleUser = useSelector((state) => state.userAuth.roleUser);
    const [disabledButton, setDisabledButton] = useState({ disabled: false, msg: "" });

    useEffect(() => {
        fetchBuscarPorIdCostoProveedor();
        validateAddFacturaProveedor(costo, setDisabledButton)
    }, [])

    const fetchBuscarPorIdCostoProveedor = async () => {
        try {
            const res = await searchFacturaById(costo.id)
            if (res.error) return ToastError(res.message)
            setFacturas(res.data)
        } catch (e) {
            ToastError(e)
        }
    }

    return (
        <div className="card m-1" style={{ border: '1px solid #325D88' }}>
            <div className="card-header" align="left">
                <b>Tipo Moneda: </b> {costo.tipoMoneda} <br />
                {roleUser?.id == ID_ROL_LOGISTICA ? <><br /><b>Proveedor: </b>{costo.proveedor?.nombreComercial}</> : ""}
                {costo?.noteToProvider &&
                    <>
                        <b>Nota: </b> {costo.noteToProvider} <br />
                    </>
                }
            </div>
            <div className="card-body" style={{ backgroundColor: "#D7D7D7" }}>
                <span style={{ color: "crimson", fontSize: "15px" }}>{disabledButton.msg}</span>
                <div align="right" className='mt-2'>
                    <button type='button' className='btn btn-dark mb-2'
                        disabled={disabledButton.disabled}
                        onClick={() => { addItem(facturas, setFacturas) }}>
                        <i className="bi bi-plus-lg"></i> Agregar Factura
                    </button>
                </div>
                <TableFacturas
                    costo={costo}
                    facturas={facturas}
                    setFacturas={setFacturas}
                    fetchBuscarPorIdCostoProveedor={fetchBuscarPorIdCostoProveedor}
                    fetctListarCostosDeProveedor={fetctListarCostosDeProveedor}></TableFacturas>
            </div>
        </div>
    )
}

export default CardCosto