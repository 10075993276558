import { ErrorMessage, Field, FieldArray } from 'formik'
import FormikAdapterCustomFileUpl from '../../../formik/FormikAdapterCustomFileUpl'
import { ARCHIVOS_PERMITIDOS_V2 } from '../../../../utils/constantes'

function FieldArrayDocumentos({ values, documentToUpdate, attributeNameFile }) {
    return (
        <FieldArray
            name="documents"
            render={arrayHelpers => (
                <>

                    <div className="col-sm-12 mb-3" align="right">
                        {documentToUpdate ? <></> :
                            <button type="button" className='btn btn-primary' onClick={() => arrayHelpers.push({})}>
                                <i className="bi bi-plus" /> DOCUMENTO
                            </button>
                        }

                    </div>

                    {values.documents?.map((documento, index) => {
                        return (
                            <div key={index} className='row'>
                                <hr />
                                <div className="col-sm-4 mb-3">
                                    <Field id={attributeNameFile} name={`documents[${index}][${attributeNameFile}]`} className="form-control" />
                                    <ErrorMessage name={`documents[${index}][${attributeNameFile}]`} component='span' className='text-danger'></ErrorMessage>
                                </div>
                                <div className="col-sm-6 mb-3">
                                    <Field
                                        name={`documents[${index}].file`}
                                        id="file"
                                        component={FormikAdapterCustomFileUpl}
                                        fileTypes={ARCHIVOS_PERMITIDOS_V2}
                                        index={index}
                                        isMultiple={true} />
                                    <ErrorMessage name={`documents[${index}].file`} component='span' className='text-danger'></ErrorMessage>
                                </div>
                                <div className="col-sm-2 mb-3" align="right">
                                    {documentToUpdate ?
                                        <></>
                                        :
                                        <button id="deleteGenericDoc" className='btn btn-danger' type='button' onClick={() => arrayHelpers.remove(index)}><i className="bi bi-trash-fill"></i></button>
                                    }
                                </div>
                            </div>
                        )
                    })}
                </>)}
        />
    )
}

export default FieldArrayDocumentos