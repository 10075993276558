import MUIDataTable from 'mui-datatables';
import { OPTIONS_MUI_ONLY_TABLE, OPTIONS_CUSTOM_RETURN_COLUMN } from '../../../utils/mui/constantes';
import { useEffect } from 'react';
import ModalArchivo from '../../ModalArchivo';
import FormDocumentGeneric from './FormDocumentGeneric';
import ModalDeleteLogic from '../../ModalDeleteLogic';

function TableDocuments({ dataDocuments, attributeNameFile, attributeDocument, functionUpdateDocument, refreshData, functionDeleteDocument, baseUrlS3 }) {

  const columns = [
    { label: "Nombre", name: attributeNameFile},
    {
      name: "",
      options: {
        customBodyRenderLite: (index) => {
          return (
            <div align="right">
              {dataDocuments[index][attributeDocument] ?
                <ModalArchivo
                  title={`${dataDocuments[index][attributeNameFile]} `}
                  urlBase={baseUrlS3}
                  archivoS3={dataDocuments[index][attributeDocument]}></ModalArchivo> :
                ""
              }
              {dataDocuments[index][attributeDocument] ?
                <FormDocumentGeneric documentToUpdate={dataDocuments[index]} functionUpdateDocument={functionUpdateDocument} refreshData={refreshData} attributeNameFile={attributeNameFile} /> :
                ""
              }
              <ModalDeleteLogic
                textHeader={"¿Está seguro que deseas eliminar este documento?"}
                functionEliminar={functionDeleteDocument}
                id={dataDocuments[index].id}
                functionRefreshData={refreshData}
              />
            </div>
          )
        },
        ...OPTIONS_CUSTOM_RETURN_COLUMN
      },
    }
  ]


  return (
    <div className='row m-4'>
      <MUIDataTable
        title={"DOCUMENTOS"}
        data={dataDocuments}
        columns={columns}
        options={{
          ...OPTIONS_MUI_ONLY_TABLE,
          search: true,
          responsive: "standard",
          enableNestedDataAccess: '.'
        }}
      />
    </div>
  )
}

export default TableDocuments