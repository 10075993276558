import React, { useEffect, useState } from 'react'
import { getAuthUser } from '../../utils/authUtils';

function InicioAdmin() {
 const [user, setUser] = useState({});

  useEffect(()=>{
    const authUser = () =>{
      const res = getAuthUser();
      setUser(res);
    }
    authUser()
  },[])

  return (
    <div className='col-12 text-center'>
      <h2><b>BIENVENIDO</b></h2>
      <h3>{user.nombre} {user.primerApellido} {user.segundoApellido}</h3>
      <h4>{user.email}</h4>
      <h5><b>{user.role?.name.split("_")[1]}</b></h5>
    </div>
  )
}

export default InicioAdmin