import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import ModalEmbarques from '../../components/admin/embarques/ModalEmbarques'
import TableEmbarques from '../../components/admin/embarques/TableEmbarques'
import ModalAdministracionTipoUnidad from '../../components/admin/embarques/modal-catalogos/ModalAdministracionTipoUnidad';
import { findAllByFilters } from '../../controllers/admin/embarques/embarquesController';
import ModalAdministracionNavieras from '../../components/admin/embarques/modal-catalogos/ModalAdministracionNavieras';
import ModalAdministracionAerolineas from '../../components/admin/embarques/modal-catalogos/ModalAdministracionAerolineas';
import ModalAdministracionContenedorMaritimo from '../../components/admin/embarques/modal-catalogos/ModalAdministracionContenedorMaritimo';
import BuscadorAvanzado from '../../components/admin/embarques/buscador/BuscadorAvanzado';
import ModalAdministracionOrigenDestino from '../../components/admin/embarques/modal-catalogos/ModalAdministracionOrigenDestino';
import ModalReportes from '../../components/admin/reportes/ModalReportes';


function Embarques() {
  const [embarques, setEmbarques] = useState(null);
  const paramsEmbarque = useSelector((state) => state.filtrosBusquedas.paramsEmbarque);
  const filtersShipment = useSelector((state) => state.filtrosBusquedas.filters);

  useEffect(() => {
    fetchDataEmbarques()
  }, [])

  const fetchDataEmbarques = async () => {
      const data = await findAllByFilters()
      setEmbarques(data)
  };

  const handleEmbarqueSearch = async (newPage, pageSize, cancelSearch, paramsFunc, filters) => {
    const params = paramsFunc ? "&" + paramsFunc : paramsEmbarque && !cancelSearch ? "&" + paramsEmbarque : '';
    const validFilters = cancelSearch ? [] : filters ? filters : filtersShipment;
    const data = await findAllByFilters(`?page=${newPage}&size=${pageSize}${params}`, validFilters)
    setEmbarques(data)
  };

  return (
    <div className='row m-4'>
      <div className='col-12' align="right">
        <div className="dropdown m-1">
          <button className="btn btn-dark dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="bi bi-list-columns-reverse"></i> Catalogos
          </button>
          <ul className="dropdown-menu">
            <li>
              <ModalAdministracionTipoUnidad></ModalAdministracionTipoUnidad>
            </li>
            <li>
              <ModalAdministracionNavieras></ModalAdministracionNavieras>
            </li>
            <li>
              <ModalAdministracionAerolineas></ModalAdministracionAerolineas>
            </li>
            <li>
              <ModalAdministracionContenedorMaritimo></ModalAdministracionContenedorMaritimo>
            </li>
            <li>
              <ModalAdministracionOrigenDestino></ModalAdministracionOrigenDestino>
            </li>
          </ul>
        </div>
      </div>

      <div align="right">
        <ModalEmbarques
          titulo={"Registrar Embarque"}
          setEmbarques={setEmbarques}>
          <i className="bi bi-plus-lg"></i>
        </ModalEmbarques>
        <ModalReportes></ModalReportes>
        <BuscadorAvanzado
          handleEmbarqueSearch={handleEmbarqueSearch}></BuscadorAvanzado>
      </div>
      <TableEmbarques
        embarques={embarques}
        setEmbarques={setEmbarques}
        handleEmbarqueSearch={handleEmbarqueSearch}></TableEmbarques>
    </div>
  )
}

export default Embarques