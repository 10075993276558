import { findAllDireccionesBySearchText } from '../../../../../controllers/admin/direcciones/direccionesClienteController';
import FormikSelectSearchDB from '../../../../formik/FormikSelectSearchDB';
import FormikGenericDate from '../../../../formik/FormikGenericDate';
import { ErrorMessage, Field } from 'formik';

function DireccionEntrega({ values }) {
    return (
        <>
            <div className='row'>
                <div className='col-sm-12 mb-1'>
                    <Field
                        name="direccionEntrega"
                        component={FormikSelectSearchDB}
                        titulo="Direcciones de Entrega"
                        fetchSearch={findAllDireccionesBySearchText}
                        type={"DIRECCION"} />
                    <ErrorMessage name={`direccionEntrega`} component='span' className='text-danger'></ErrorMessage>
                </div>
                <div className='col-sm-6 mb-1'>
                    <Field
                        name="fechaEstimadaEntrega"
                        component={FormikGenericDate}
                        nameLabel="Fecha Estimada de Entrega" />
                </div>
                <div className='col-sm-6 mb-1'>
                    <Field
                        name="fechaEfectivaEntrega"
                        component={FormikGenericDate}
                        nameLabel="Fecha Efectiva de Entrega" />
                </div>
            </div>
        </>
    )
}

export default DireccionEntrega