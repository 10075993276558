import RepartosDireccionDesglose from '../RepartosDireccionDesglose';
import { ID_SERVICIO_MARITIMO_P2P, ID_SERVICIO_MARITIMO_D2D, MODALIDAD_MARITIMA_LCL, MODALIDAD_MARITIMA_FCL, ID_SERVICIO_EMBARQUE_EXPORTACION, ID_SERVICIO_EMBARQUE_IMPORTACION } from '../../../../../utils/constantes';
import DestinoFinal from './DestinoFinal';
import DimensionCarga from '../DimensionCarga';
import DetalleDeCargaMaritimo from './DetalleDeCargaMaritimo';
import TipoCarga from '../TipoCarga';
import PortToPort from './PortToPort';
import { ID_SERVICIO_MARITIMO_P2D } from '../../../../../utils/constantes';
import DireccionEntrega from '../inputs/DireccionEntrega';
import { findAllDireccionesBySearchText } from '../../../../../controllers/admin/direcciones/direccionesClienteController';
import FormikSelectSearchDB from '../../../../formik/FormikSelectSearchDB';
import FormikGenericDate from '../../../../formik/FormikGenericDate';
import { ErrorMessage, Field } from 'formik';
import { CATALOGO_TIPO_UNIDADES_MEDIDA } from '../../../../../utils/catalogosEstaticos';

function DesgloseMaritimo({
    values,
    embarqueData,
    tiposCargaDesglose,
    contenedoresMaritimos }) {

    return (
        <>{embarqueData.datosEmbarqueMaritimo
            && (embarqueData.datosEmbarqueMaritimo.servicioMaritimo?.id != ID_SERVICIO_MARITIMO_P2P
                && embarqueData.datosEmbarqueMaritimo.servicioMaritimo?.id != ID_SERVICIO_MARITIMO_P2D) ?
            <>
                {!embarqueData.soloDespachoAduanal ?
                    <>
                        <div className='row'>
                            <div className='col-sm-12 mb-1'>
                                <Field
                                    name="direccionRecoleccion"
                                    component={FormikSelectSearchDB}
                                    titulo="Direcciones de Recolección"
                                    fetchSearch={findAllDireccionesBySearchText}
                                    type={"DIRECCION"} />
                                <ErrorMessage name={`direccionRecoleccion`} component='span' className='text-danger'></ErrorMessage>
                            </div>
                            <div className='col-sm-6 mb-1'>
                                <Field
                                    name="fechaEstimadaRecoleccion"
                                    component={FormikGenericDate}
                                    nameLabel="Fecha Estimada de Recolección" />
                            </div>
                            <div className='col-sm-6 mb-1'>
                                <Field
                                    name="fechaEfectivaRecoleccion"
                                    component={FormikGenericDate}
                                    nameLabel="Fecha Efectiva de Recolección" />
                            </div>
                        </div>
                        {values.id ?
                            <RepartosDireccionDesglose
                                values={values}>
                            </RepartosDireccionDesglose>
                            :
                            ""
                        }
                    </>
                    :
                    ""
                }


                {embarqueData.tipoServicio?.id == ID_SERVICIO_EMBARQUE_EXPORTACION
                    && embarqueData.datosEmbarqueMaritimo?.modalidadMaritima == MODALIDAD_MARITIMA_LCL ?
                    <>
                        <div className='col-6 mb-1'>
                            <Field
                                name="datosDesgloseMaritimo.fechaIngresoMercancia"
                                component={FormikGenericDate}
                                nameLabel="Ingreso de mercancía" />
                        </div>
                        <div className='col-12 mb-1'></div>
                    </>
                    :
                    <></>
                }

            </>
            :
            <PortToPort
                values={values}></PortToPort>
        }
            {embarqueData.datosEmbarqueMaritimo?.servicioMaritimo?.id == ID_SERVICIO_MARITIMO_P2D
                && !embarqueData.soloDespachoAduanal ?
                <DireccionEntrega values={values}></DireccionEntrega>
                :
                <></>
            }

            {embarqueData.tipoServicio?.id == ID_SERVICIO_EMBARQUE_IMPORTACION ?
                <div className='col-12'>
                    <Field
                        name="datosDesgloseMaritimo.fechaRevalidacion"
                        component={FormikGenericDate}
                        nameLabel="Revalidación" />
                </div>
                :
                <></>
            }
            {(embarqueData.datosEmbarqueMaritimo
                && embarqueData.datosEmbarqueMaritimo.modalidadMaritima
                == MODALIDAD_MARITIMA_FCL) ?
                <>
                    <DetalleDeCargaMaritimo
                        values={values}
                        contenedoresMaritimos={contenedoresMaritimos}
                        tiposCargaDesglose={tiposCargaDesglose}></DetalleDeCargaMaritimo>
                </>

                :
                <></>

            }


            {(embarqueData.datosEmbarqueMaritimo
                && embarqueData.datosEmbarqueMaritimo.modalidadMaritima
                == MODALIDAD_MARITIMA_LCL) ?
                <>
                    {embarqueData.datosEmbarqueMaritimo?.modalidadMaritima
                        != MODALIDAD_MARITIMA_LCL ?
                        <div className='col-2 mb-3'>
                            <label className="form-label">Modalidad</label>
                            <br />
                            <label>
                                <Field type="radio" name={`modalidadUnidad`} value="SENCILLO" />
                                SENCILLO
                            </label><br />
                            <label>
                                <Field type="radio" name={`modalidadUnidad`} value="FULL" />
                                FULL
                            </label>
                        </div> : ""
                    }

                    <div className="col-2 mb-3">
                        <label htmlFor="mercanciaTrasladar" className="form-label">Mercancía a trasladar</label>
                        <Field id="mercanciaTrasladar" name={`mercanciaTrasladar`} className="form-control" component="textarea" />
                    </div>
                    <div className='col-2 mb-3'>
                        <TipoCarga
                            catalogoTipoCarga={values?.catalogoTipoCarga}
                            tiposCargaDesglose={tiposCargaDesglose}
                            name={""}></TipoCarga>
                    </div>
                    <div className="col-2 mb-3">
                        <label htmlFor="cantidadTotalBultos" className="form-label">Cantidad Total de Bultos</label>
                        <Field id="cantidadTotalBultos" name={`cantidadTotalBultos`} className="form-control" type={"number"} />
                    </div>
                    <div className="col-2 mb-3">
                        <label htmlFor="pesoTotalBruto" className="form-label">Peso Total Bruto</label>
                        <Field id="pesoTotalBruto" name={`pesoTotalBruto`} className="form-control" type={"number"} />
                    </div>
                    <div className='col-2 mb-3'>
                        <label htmlFor="unidadDeMedida" className="form-label">Unidad de medida</label>
                        <Field as="select" name={`unidadDeMedida`} className="form-select">
                            <option value="">Unidades de medida</option>
                            {CATALOGO_TIPO_UNIDADES_MEDIDA.map(tipoUnidad => {
                                return <option value={tipoUnidad.value}>{tipoUnidad.label}</option>
                            })}
                        </Field>
                    </div>
                    <DimensionCarga values={values}></DimensionCarga>
                </>
                :
                <></>}
            {embarqueData.datosEmbarqueMaritimo &&
                embarqueData.datosEmbarqueMaritimo.servicioMaritimo?.id == ID_SERVICIO_MARITIMO_D2D ?
                <>

                    <DestinoFinal
                        values={values}
                        name="datosDesgloseMaritimo."></DestinoFinal>
                </>
                :
                <></>
            }
        </>
    )
}

export default DesgloseMaritimo