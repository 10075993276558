import RepartosDireccionDesglose from '../RepartosDireccionDesglose';
import DesgloseAereoAduanaIngreso from './DesgloseAereoAduanaIngreso';
import DesgloseAereoDTAOD from './DesgloseAereoDTAOD';
import { ID_SERVICIO_AEREO_ATA, ID_SERVICIO_AEREO_ATD, ID_SERVICIO_AEREO_D2D, ID_SERVICIO_AEREO_DTAOD, ID_SERVICIO_EMBARQUE_IMPORTACION } from '../../../../../utils/constantes';
import DimensionCarga from '../DimensionCarga';
import TipoCarga from '../TipoCarga';
import { CATALOGO_TIPO_UNIDADES_MEDIDA } from '../../../../../utils/catalogosEstaticos';
import { findAllDireccionesBySearchText } from '../../../../../controllers/admin/direcciones/direccionesClienteController';
import FormikSelectSearchDB from '../../../../formik/FormikSelectSearchDB';
import FormikGenericDate from '../../../../formik/FormikGenericDate';
import { ErrorMessage, Field } from 'formik';

function DesgloseAereo({
    values,
    indexDesglose,
    embarqueData,
    tiposCargaDesglose, }) {

    return (
        <>
            {(embarqueData.datosEmbarqueAereo &&
                (embarqueData.datosEmbarqueAereo.servicioAereo?.id != ID_SERVICIO_AEREO_ATD
                    && embarqueData.datosEmbarqueAereo.servicioAereo?.id != ID_SERVICIO_AEREO_ATA)) ?
                !embarqueData.soloDespachoAduanal ?
                    <>
                        <div className='row'>
                            <div className='col-sm-12 mb-1'>
                                <Field
                                    name="direccionRecoleccion"
                                    component={FormikSelectSearchDB}
                                    titulo="Direcciones de Recolección"
                                    fetchSearch={findAllDireccionesBySearchText}
                                    type={"DIRECCION"} />
                                <ErrorMessage name={`direccionRecoleccion`} component='span' className='text-danger'></ErrorMessage>
                            </div>
                            <div className='col-sm-6 mb-1'>
                                <Field
                                    name="fechaEstimadaRecoleccion"
                                    component={FormikGenericDate}
                                    nameLabel="Fecha Estimada de Recolección" />
                            </div>
                            <div className='col-sm-6 mb-1'>
                                <Field
                                    name="fechaEfectivaRecoleccion"
                                    component={FormikGenericDate}
                                    nameLabel="Fecha Efectiva de Recolección" />
                            </div>
                        </div>
                    </>
                    :
                    ""
                :
                ""
            }
            {(embarqueData.datosEmbarqueAereo
                && (embarqueData.datosEmbarqueAereo.servicioAereo?.id != ID_SERVICIO_AEREO_DTAOD
                    && embarqueData.datosEmbarqueAereo.servicioAereo?.id != ID_SERVICIO_AEREO_ATA)) ?
                !embarqueData.soloDespachoAduanal ?
                    <>
                        <div className='row'>
                            <div className='col-sm-12 mb-1'>
                                <Field
                                    name="direccionEntrega"
                                    component={FormikSelectSearchDB}
                                    titulo="Direcciones de Entrega"
                                    fetchSearch={findAllDireccionesBySearchText}
                                    type={"DIRECCION"} />
                                <ErrorMessage name={`direccionEntrega`} component='span' className='text-danger'></ErrorMessage>
                            </div>
                            <div className='col-sm-6 mb-1'>
                                <Field
                                    name="fechaEstimadaEntrega"
                                    component={FormikGenericDate}
                                    nameLabel="Fecha Estimada de Entrega" />
                            </div>
                            <div className='col-sm-6 mb-1'>
                                <Field
                                    name="fechaEfectivaEntrega"
                                    component={FormikGenericDate}
                                    nameLabel="Fecha Efectiva de Entrega" />
                            </div>
                        </div>
                    </>
                    :
                    ""
                :
                ""
            }

            {values.id != null ?
                <RepartosDireccionDesglose values={values}>
                </RepartosDireccionDesglose>
                :
                <></>
            }
            {embarqueData.datosEmbarqueAereo &&
                embarqueData.datosEmbarqueAereo.servicioAereo?.id == ID_SERVICIO_AEREO_DTAOD ?
                <DesgloseAereoDTAOD values={values} />
                :
                <></>
            }
            {embarqueData.datosEmbarqueAereo &&
                embarqueData.datosEmbarqueAereo.servicioAereo?.id == ID_SERVICIO_AEREO_D2D ?
                <>
                    <DesgloseAereoAduanaIngreso values={values} />
                </>
                :
                <></>
            }
            {embarqueData.tipoServicio?.id == ID_SERVICIO_EMBARQUE_IMPORTACION ?
                <Field
                    name="datosDesgloseAereo.fechaRevalidacion"
                    component={FormikGenericDate}
                    nameLabel="Fecha de Revalidacion" />
                :
                <></>
            }
            <div className='row'>


                <div className="col-sm-4 mb-3">
                    <label htmlFor="inputDestino" className="form-label">Mercancía a trasladar</label>
                    <Field id="mercanciaTrasladar" name={`mercanciaTrasladar`} className="form-control" component="textarea" />
                </div>
                <div className='col-sm-4 mb-3'>
                    <TipoCarga
                        catalogoTipoCarga={values?.catalogoTipoCarga}
                        tiposCargaDesglose={tiposCargaDesglose}
                        name={""}></TipoCarga>
                </div>
                <div className="col-sm-4 mb-3">
                    <label className="form-label"></label>
                    <div className="form-check form-switch">
                        <Field type="checkbox" name="esFletePrincipal" className="form-check-input" role="switch" />
                        <label className="form-check-label" htmlFor={`fletePrincipal-${indexDesglose}`}>Flete Principal</label>
                    </div>
                </div>
                <div className="col-sm-4 mb-3">
                    <label htmlFor="inputDestino" className="form-label">Cantidad Total de Bultos</label>
                    <Field id="cantidadTotalBultos" name={`cantidadTotalBultos`} className="form-control" type={"number"} />
                </div>
                <div className="col-sm-4 mb-3">
                    <label htmlFor="inputDestino" className="form-label">Peso Total Bruto</label>
                    <Field id="pesoTotalBruto" name={`pesoTotalBruto`} className="form-control" type={"number"} />
                </div>
                <div className='col-sm-4 mb-3'>
                    <label htmlFor="unidadDeMedida" className="form-label">Unidad de medida</label>
                    <Field as="select" name={`unidadDeMedida`} className="form-select">
                        <option value="">Unidades de medida</option>
                        {CATALOGO_TIPO_UNIDADES_MEDIDA.map(tipoUnidad => {
                            return <option value={tipoUnidad.value}>{tipoUnidad.label}</option>
                        })}
                    </Field>
                </div>
            </div>

            <DimensionCarga values={values}></DimensionCarga>
        </>
    )
}

export default DesgloseAereo