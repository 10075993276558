import { URL_COSTO_PROVEEDOR } from "../../utils/constantes";
import { apiRequest } from "../handlerApiRequest";


export const listaCostosProveedorPorIdEmbarque = async (idEmbarque) =>{
    return await apiRequest(URL_COSTO_PROVEEDOR + "/embarque/"+idEmbarque)
}

export const guardarCostoProveedorDTO = async (costoProveedor) =>{
    return await apiRequest(URL_COSTO_PROVEEDOR, "POST", costoProveedor)
}

export const eliminadoLogicoCostoPorId = async (idCostoProveedor, razonEliminacion) =>{
    return await apiRequest(URL_COSTO_PROVEEDOR + "/" + idCostoProveedor, "DELETE", null, "?razonEliminacion="+razonEliminacion)
}