import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { verifyToken } from '../controllers/auth/authController';
import { useCookies } from 'react-cookie';
import { getAuthRoleUser} from '../utils/authUtils';
import { useDispatch } from 'react-redux';
import { changeVisible, setNavegation } from '../redux/features/navbar/navbarSlice';
import { ToastWarning } from '../utils/toastMessage';

const ProtectedRoute = ({ roleAllowed, token, children, linksNavbar }) => {
    const [cookie, setCookie, removeCookie] = useCookies();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const verifyTokenAndRole = async () => {
        const res = await verifyToken(token);
        if (res.error) {
            removeCookie('auth-token', { path: '/' });
            localStorage.removeItem('auth-user');
            dispatch(changeVisible(false));
            dispatch(setNavegation([]));
            ToastWarning("Tu sesión ha expirado, vuelve a iniciar sesión.")
            return navigate('/');
        } else {
            const roleUserRes = getAuthRoleUser();
            if (roleUserRes.name !== roleAllowed) {
                return navigate('/no-autorizado');
            }
            dispatch(changeVisible(true));
            dispatch(setNavegation(linksNavbar));
        }
    };

    useEffect(() => {
        verifyTokenAndRole(); // Verificar inmediatamente al cargar el componente

        const interval = setInterval(() => {
            verifyTokenAndRole(); // Verificar cada hora
        }, 3600000); // 3600000 ms = 1 hora

        return () => {
            clearInterval(interval); // Limpiar el intervalo al desmontar el componente
        };
        
    }, [cookie, setCookie]);

    return children ? children : <Outlet />;
};

export default ProtectedRoute;