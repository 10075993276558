import { createSlice } from '@reduxjs/toolkit';
import { construisEstatusEmbarquePorId } from '../../../controllers/admin/embarques/embarquesController';

const initialState = {
  estatus: [],
  isLoading: false,
  error: null,
};

export const estatusEmbarqueSlice = createSlice({
  name: 'estatusEmbarque',
  initialState,
  reducers: {
    fetchEstatusEmbarqueStart: (state) => {
      state.isLoading = true;
    },
    fetchEstatusEmbarqueSuccess: (state, action) => {
      state.estatus = action.payload;
      state.isLoading = false;
    },
    fetchEstatusEmbarqueFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    actualizarActividadEstatus: (state, action) =>{
      const {indexEstatus, indexActividad, data} = action.payload;
      state.estatus[indexEstatus].actividadEstatusEmbarqueEstatus[indexActividad] = data;
    },
    actualizarManualEstatus: (state, action) =>{
      state.estatus = action.payload;
      state.isLoading = false;
    }
  },
});

export const { 
    fetchEstatusEmbarqueStart, 
    fetchEstatusEmbarqueSuccess, 
    fetchEstatusEmbarqueFailure,
    actualizarActividadEstatus,
    actualizarManualEstatus } = estatusEmbarqueSlice.actions;

export const fetchEstatusEmbarque = (idEmbarque) => async (dispatch) => {
  try {
    dispatch(fetchEstatusEmbarqueStart());
    const res = await construisEstatusEmbarquePorId(idEmbarque);
    if (res.error) {
      dispatch(fetchEstatusEmbarqueFailure(res.message));
    } else {
      dispatch(fetchEstatusEmbarqueSuccess(res.data));
    }
  } catch (error) {
    dispatch(fetchEstatusEmbarqueFailure(error.message));
  }
};

export default estatusEmbarqueSlice.reducer;