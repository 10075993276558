import { useState } from 'react'
import Modal from '@mui/material/Modal';
import FormCondicionesServicio from '../forms/FormCondicionesServicio';
import TableCondicionesServicio from '../tables/TableCondicionesServicio';
import { CondicionServicio } from '../../../../models/admin/cotizaciones/CondicionServicio';


function ModalCondicionesServicio() {
    const [open, setOpen] = useState(false);
    const [refreshData, setRefreshData] = useState({});
    const [condicionServicio, setCondicionServicio] = useState(CondicionServicio);

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setCondicionServicio(CondicionServicio);
        setOpen(false);
    }


    return (
        <>
            <button
                className="btn btn-primary m-1"
                type="button" onClick={handleOpen}>
                <i className="bi bi-plus"></i>CONDICIONES SERVICIO
            </button>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <div className='card style-modal-sm'>
                    <div className='card-header d-flex justify-content-between'>
                        CONDICIONES DE SERVICIO
                        <button className='btn btn-dark btn-sm'
                            onClick={handleClose}>
                            <i className="bi bi-x-lg"></i>
                        </button>
                    </div>
                    <div className='card-body body-modal-scroll'>
                        <FormCondicionesServicio
                            setRefreshData={setRefreshData}
                            condicionServicio={condicionServicio}
                            setCondicionServicio={setCondicionServicio}></FormCondicionesServicio>
                        <TableCondicionesServicio
                            refreshData={refreshData}
                            setCondicionServicio={setCondicionServicio}></TableCondicionesServicio>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ModalCondicionesServicio