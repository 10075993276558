import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    paramsEmbarque: null,
    filters: null,
    fastSearch: {}
};

export const filtrosBusquedas = createSlice({
    name: 'filtrosBusquedas',
    initialState,
    reducers: {
      updateParamsEmbarque: (state, action) => {
        state.paramsEmbarque = action.payload;
      },
      updateFiltersEmbarque: (state, action) => {
        state.filters = action.payload;
      },
      createFilterFastSearch: (state, action) => {
        state.fastSearch = action.payload;
        state.fastSearch.tipoFiltro = state.fastSearch.value
      },
      updateSearchValue: (state, action) => {
        state.fastSearch.searchValue = action.payload;
      },
    },
  });


export const {
  updateParamsEmbarque,
  updateFiltersEmbarque,
  createFilterFastSearch,
  updateSearchValue
} = filtrosBusquedas.actions;

export default filtrosBusquedas.reducer;