import React, { useState } from 'react'
import Modal from '@mui/material/Modal';
import { addItem, deleteItem, handleChangeArrayText } from '../../utils/helpersState';
import { deleteUserEmail, findAllEmailsByIdUser, saveAllEmails } from '../../controllers/admin/userEmailController';
import { ToastError, ToastSuccess } from '../../utils/toastMessage';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/features/loaderSlice';

function ModalAdminEmails({ usuario, onlyOneEmail }) {
    const [open, setOpen] = useState(false);
    const [emails, setEmails] = useState([]);
    const dispatch = useDispatch();

    const handleClickOpen = () => {
        setOpen(true);
        fetchDataUserEmails();
    };

    const handleClose = () => {
        setOpen(false);
    };

    const fetchDataUserEmails = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const res = await findAllEmailsByIdUser(usuario.id);
            dispatch(setLoading({ isLoading: false }))
            if (res.error) return ToastError(res.message);
            setEmails(res.data);
        } catch (e) {
            ToastError(e.message);
        }
    };

    const onSubmit = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const res = await saveAllEmails(emails);
            dispatch(setLoading({ isLoading: false }))
            if (res.error) return ToastError(res.message);
            ToastSuccess(res.message)
            setEmails(res.data);
        } catch (e) {
            ToastError(e.message);
        }
    };

    const handlerDelete = async (index, userEmail) => {
        try {
            if (userEmail.id) {
                dispatch(setLoading({ isLoading: true }))
                const res = await deleteUserEmail(userEmail.id);
                dispatch(setLoading({ isLoading: false }))
                if (res.error) return ToastError(res.message);
                ToastSuccess(res.message)
            }
            deleteItem(index, emails, setEmails);
        } catch (e) {
            ToastError(e.message);
        }
    }

    return (
        <>
            <button type='button' className='btn btn-primary'
                onClick={handleClickOpen}>
                ADMINISTRAR
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="card style-modal-ssm">
                    <div className="card-header d-flex justify-content-between">
                        CORREOS ELECTRÓNICOS - {usuario?.nombreComercial}
                        <button className="btn btn-dark btn-sm" onClick={handleClose}>
                            <i className="bi bi-x-lg"></i>
                        </button>
                    </div>
                    <div className="card-body">
                        <div align="right">
                            {onlyOneEmail && emails.length >= 1 ?
                                "" :
                                <button type='button' className='btn btn-dark mt-2 mb-2 me-2'
                                    onClick={() => addItem(emails, setEmails, { email: "", user: usuario })}>
                                    <i className="bi bi-plus-lg"></i>
                                </button>
                            }

                        </div>

                        {emails.map((email, index) => {
                            return (
                                <div className="input-group mb-3" key={index}>
                                    <input
                                        type="email"
                                        className="form-control"
                                        name='email'
                                        value={email.email}
                                        onChange={(e) => { handleChangeArrayText(e, index, emails, setEmails) }} />
                                    <button type='button' className='btn btn-danger'
                                        onClick={() => handlerDelete(index, email)}>
                                        <i className="bi bi-trash"></i>
                                    </button>
                                </div>
                            )
                        })

                        }

                    </div>
                    <div className="card-footer" align="right">
                        <button type='button' className='btn btn-primary mt-2 mb-2 me-2'
                            onClick={() => onSubmit()}>
                            <i className="bi bi-floppy"></i> Guardar
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ModalAdminEmails