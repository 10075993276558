import { Worker } from '@react-pdf-viewer/core';
// Import the main component
import { Viewer } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useEffect, useState } from 'react'

function ViewDocuments() {
    const [pathDoc, setPathDoc] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    useEffect(() => {
        const currentUrl = window.location.href;
        setPathDoc(extractPathFromUrl(currentUrl))
    }, [])

    const extractPathFromUrl = (url) => {
        const basePath = "/documentos/";
        const index = url.indexOf(basePath);
        if (index !== -1) {
            return url.substring(index + basePath.length);
        }
        return null;
    };

    const handleError = (error) => {
        setErrorMessage(
            'No se pudo cargar el archivo. Por favor, consulte con el administrador.'
        );
    };

    return (
        <div className='row'>
            <div className='col-12'>
            {errorMessage ? (
                <div style={{ color: 'red', textAlign: 'center', marginTop: '20px' }}>
                    {errorMessage}
                </div>
            ) : (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
                    <Viewer
                        fileUrl={process.env.REACT_APP_PATH_DOCS_AWS + pathDoc}
                        plugins={[defaultLayoutPluginInstance]}
                        onLoadError={handleError}
                    />
                </Worker>
            )}
            </div>
        </div>
    )
}

export default ViewDocuments