import { styles } from "./styles-react-pdf"
import LogoKJ from '../../assets/imgs/logo.png'
import { Image, Text, View } from "@react-pdf/renderer"

function HeaderKjPdf() {
    return (
        <View style={styles.header}>
            <View style={styles.dataCol}>
                <Image src={LogoKJ} style={styles.logo} />
            </View>
            <View style={styles.dataCol}>
                <Text style={styles.textBold}>KLO210317KN1</Text>
                <Text style={styles.textBold}>KARLA-JOSIMAR LOGISTICS</Text>
                <Text style={styles.textBold}>Régimen fiscal: 601 - General de Ley Personas Morales</Text>
                <Text style={styles.textBold}>C.P. 76147</Text>
                <Text style={{ margin: 2 }}></Text>

                <Text style={styles.textBold}>Gerente de Aduanas</Text>
                <Text>
                    55 3443 9138 - josimar.ramirez@kj-logistics.com
                </Text>
                <Text style={styles.textBold}>Gerente de Logística</Text>
                <Text>
                    442 389 7588 - claudia.lopez@kj-logistics.com
                </Text>
                <Text style={{ margin: 2 }}></Text>
                <Text style={styles.textBold}>https://kj-logistics.com/</Text>
            </View>
        </View>
    )
}

export default HeaderKjPdf