
import { URL_CATALOGO_REGIMEN_FISCAL } from "../../../utils/constantes";
import { apiRequest } from "../../handlerApiRequest";

export const findAllCatalogoRegimenFiscal = async () =>{
    return await apiRequest(URL_CATALOGO_REGIMEN_FISCAL);
}

export const findAllByPerteneceA = async (pertenceA) =>{
    return await apiRequest(URL_CATALOGO_REGIMEN_FISCAL + "/pertenecea", "GET", null, "?tipoPersona="+pertenceA);
}