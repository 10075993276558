import BASE_URL_BACKEND from '../api/baseUrl';
import { authToken } from '../utils/authUtils';
import { saveHistory } from './historyController';

export const apiRequest = async (
    endpoint,
    method = 'GET',
    data = null,
    urlParams = '',
    isFormData
) => {
    const url = `${BASE_URL_BACKEND}${endpoint}${urlParams}`;

    let headers = {};
    if (!isFormData) {
        headers = {
            Accept: isFormData ? '' : 'application/json',
            'Content-Type': isFormData ? '' : 'application/json',

        };
    }

    headers.Authorization = `Bearer ${authToken()}`;

    const config = {
        method,
        headers,
        credentials: 'include',
    };

    const dataRequest = data ? isFormData ? data : JSON.stringify(data) : null;

    if (dataRequest) {
        config.body = dataRequest;
    }

    try {
        const res = await fetch(url, config);
        const responseData = await res.json();

        if (method != "GET" && endpoint != "/auth/login" && endpoint != "/admin/embarques/search") {
            saveHistory(method, `${endpoint}${urlParams}`, res.status, dataRequest, responseData.message);
        }

        if (res.status === 401) {
            //window.location.href = '/';
        }

        return responseData;
    } catch (err) {
        const errInt = { error: true, message: err.toString() };
        return errInt;
    }
};