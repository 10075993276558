import React from 'react'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { establecerActividadDeEstatusCompletada } from '../../../../controllers/admin/embarques/embarquesController';
import { ToastError } from '../../../../utils/toastMessage';
import { actualizarActividadEstatus } from '../../../../redux/features/admin/estatusEmbarqueSlice';
import { setLoading } from '../../../../redux/features/loaderSlice';

function LineaTiempoConActividadesEstatus({ idEmbarque }) {
    const dispatch = useDispatch();
    const estatus = useSelector((state) => state.estatusEmbarque.estatus);

    const fetchEstablecerActividadDeEstatusCompletada = async (
        idActividadEstatus, 
        completado,
        indexEstatus,
        indexActividad) => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const res = await establecerActividadDeEstatusCompletada(idActividadEstatus, completado);
            dispatch(setLoading({ isLoading: false }))
            if (res.error) return ToastError(res.message)

            //Actualizar el estatus
            dispatch(actualizarActividadEstatus({indexEstatus: indexEstatus, indexActividad: indexActividad, data: res.data}))
        } catch (err) {
            return ToastError(err)
        }
    }

    return (
        <div className='body-modal-scroll'>
            <Timeline position="alternate">
            {estatus.map((est, indexEstatus) => {
                return (
                    <TimelineItem key={indexEstatus}>
                        <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot color={est.completado ? 'success' : 'error'} />
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography variant="h6" component="span">
                                {est.estatusEmbarque.estatus}
                            </Typography>

                            {est.actividadEstatusEmbarqueEstatus ? est.actividadEstatusEmbarqueEstatus.map((act, indexActividad) => {
                                const estaCompletado = act.completado ? true : false;
                                return (
                                    <p key={indexActividad}>
                                        <Typography className={estaCompletado ? "" : "text-danger"} style={{ fontSize: "14px" }}>
                                            {act.actividadEstatus.actividad}
                                            {estaCompletado ? (
                                                <button className='btn btn-sm btn-danger small ms-1'
                                                    onClick={() => { fetchEstablecerActividadDeEstatusCompletada(act.id, false, indexEstatus, indexActividad) }}>
                                                    <i className="bi bi-x"></i>
                                                </button>
                                            ) : (
                                                <button className='btn btn-sm btn-primary small ms-1'
                                                    onClick={() => { fetchEstablecerActividadDeEstatusCompletada(act.id, true, indexEstatus, indexActividad) }}>
                                                    <i className="bi bi-check"></i>
                                                </button>
                                            )}
                                        </Typography>

                                    </p>
                                )
                            })
                                : ""}
                        </TimelineContent>
                    </TimelineItem>
                )
            })
            }
        </Timeline>
        </div>
    )
}

export default LineaTiempoConActividadesEstatus