import React, { useEffect, useState } from 'react'
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from 'react-redux';
import ModalArchivo from '../ModalArchivo';
import { BASE_URL_S3_PODS_EMBARQUES } from '../../utils/constantes';
import { OPTIONS_MUI_ONLY_TABLE } from '../../utils/mui/constantes';
import CustomFileUploader from '../inputs/CustomFileUploader';
import { findPods } from '../../controllers/proveedor/proveedorPodController';
import { validatePrintRole } from '../admin/embarques/validations/podsValidations';
import { bloquearEmbarque } from '../admin/embarques/validations/embarqueValidations';
import { addItem, deleteItem, handleChangeMultipleFile } from '../../utils/helpersState';
import { ToastError, ToastSuccess, ToastWarning } from '../../utils/toastMessage';
import { setLoading } from '../../redux/features/loaderSlice';
import { getAuthIdUser } from '../../utils/authUtils';
import { actualizarPod, subirPod } from '../../controllers/admin/embarques/podsController';
import { ARCHIVOS_PERMITIDOS_V2, ID_ROL_LOGISTICA, ID_ROL_PROVEEDORES } from '../../utils/constantes';

function PodsProveedor({ idEmbarque }) {
    const [pods, setPods] = useState([]);
    const dispatch = useDispatch();
    const embarqueData = useSelector((state) => state.embarque.embarqueData);
    const userAuth = useSelector((state) => state.userAuth);

    useEffect(() => {
        fetchBuscarPodsConFormatoPorIdEmbarque();
    }, [])

    const fetchBuscarPodsConFormatoPorIdEmbarque = async () => {
        const data = await findPods(idEmbarque);
        setPods(data)
    }

    const agregarPod = () => {
        addItem(pods, setPods, { desglosesEmbarque: [] })
    }

    const eliminarPod = (indexPOD) => {
        deleteItem(indexPOD, pods, setPods)
    }

    const onChangeFiles = async (indexPOD, file, name) => {
        await handleChangeMultipleFile(indexPOD, file, name, pods, setPods, dispatch)
    }

    const handlerOnSubmit = async (indexPOD, cargarTodos) => {
        try {
            if (cargarTodos) {
                pods.forEach((pod, index) => {
                    if (pod.archivoACargar) {
                        onSubmitFiles(pod.archivoACargar, index);
                    }
                });
            } else {
                if (!pods[indexPOD].archivoACargar) return ToastWarning("Se debe subir un archivo")
                onSubmitFiles(pods[indexPOD].archivoACargar, indexPOD);
            }
        } catch (error) {
            ToastError(error.message);
            dispatch(setLoading({ isLoading: false }))
        }
    };

    const onSubmitFiles = async (file, indexPOD) => {
        try {
            dispatch(setLoading({ isLoading: true }))
            let res;
            //Si es proveedor mandarle el id de este
            let isProveedor = userAuth.roleUser?.id == ID_ROL_PROVEEDORES || userAuth.roleUser?.id == ID_ROL_LOGISTICA ? true : false;
            let idProveedor = null;
            if (isProveedor) idProveedor = getAuthIdUser();
            if (pods[indexPOD].archivoPOD != null) {
                res = await actualizarPod(pods[indexPOD].archivoPOD, file, idProveedor)

            } else {
                res = await subirPod(idEmbarque, file, idProveedor)

            }

            dispatch(setLoading({ isLoading: false }))
            if (res.error) return ToastError(res.message);
            fetchBuscarPodsConFormatoPorIdEmbarque()


            ToastSuccess(res.message)
        } catch (error) {
            dispatch(setLoading({ isLoading: false }))
            return ToastError(error.message)
        }
    }

    const columns = [
        {
            name: "POD",
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <div align="center">
                            {pods[dataIndex].archivoPOD ?

                                <>
                                    <ModalArchivo
                                        title={"Prueba de entrega"}
                                        urlBase={BASE_URL_S3_PODS_EMBARQUES}
                                        archivoS3={pods[dataIndex].archivoPOD}></ModalArchivo>
                                    <br />
                                </>
                                :
                                <></>

                            }
                            {pods[dataIndex].nombreArchivo ?
                                <p>{pods[dataIndex].nombreArchivo}</p>
                                :
                                ""

                            }

                            {validatePrintRole(pods[dataIndex], false) ?
                                <CustomFileUploader
                                    handleChangeFile={onChangeFiles}
                                    index={dataIndex}
                                    fileTypes={ARCHIVOS_PERMITIDOS_V2}
                                    multiple={true}
                                    name={"archivoACargar"}
                                    nameFile={pods[dataIndex].archivoACargar?.name}></CustomFileUploader>
                                :
                                ""
                            }
                        </div>
                    )
                },
                sort: false,
                download: false,
            }
        },
        {
            name: "",
            options: {
                customBodyRenderLite: (indexPOD) => {
                    return (
                        validatePrintRole(pods[indexPOD], false) ?
                            <button type='button'
                                className='btn btn-dark'
                                onClick={() => { handlerOnSubmit(indexPOD) }}
                                disabled={bloquearEmbarque(embarqueData, userAuth)}>
                                <i className="bi bi-cloud-arrow-up-fill"></i>
                                {pods[indexPOD].archivoPOD ?
                                    " Actualizar" : " Subir"
                                }
                            </button>
                            :
                            ""
                    );
                },
                sort: false,
                download: false,
            }
        },
        {
            name: "",
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return (
                        pods[dataIndex].id ?
                            <>
                            </>
                            :
                            <button type='button' className='btn btn-danger'
                                onClick={() => eliminarPod(dataIndex)}>
                                <i className="bi bi-trash"></i>
                            </button>
                    );
                },
                sort: false,
                download: false,
            }
        }];

    return (
        <div>
            <div className='col-12' align="right">
                <button type='button' className='btn btn-dark mt-2 mb-2 me-2'
                    onClick={() => agregarPod()}
                    disabled={bloquearEmbarque(embarqueData, userAuth)}>
                    <i className="bi bi-plus-lg"></i> Agregar POD
                </button>
                {userAuth.roleUser?.id != ID_ROL_PROVEEDORES ?
                    <button type='button' className='btn btn-dark mt-2 mb-2'
                        onClick={() => handlerOnSubmit(null, true)}
                        disabled={bloquearEmbarque(embarqueData, userAuth)}>
                        <i className="bi bi-cloud-arrow-up-fill"></i> CARGAR TODOS
                    </button> : ""
                }

            </div>
            <MUIDataTable
                title={"Pruebas de Entrega"}
                data={pods}
                columns={columns}
                options={{
                    ...OPTIONS_MUI_ONLY_TABLE,
                    responsive: "standard",
                    setRowProps: (row, dataIndex) => {
                        if (pods[dataIndex].guardoProveedor) {
                            return {
                                style: { backgroundColor: "#9ca8b27c" }
                            };
                        }
                    }
                }}
            />
        </div>
    )
}

export default PodsProveedor