import React from 'react'
import LogoKJ from '../assets/imgs/logo.png'
import { Link } from 'react-router-dom';

function Error401() {
  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
            <div className="text-center row">
                <div className=" col-md-6">
                    <img src={LogoKJ} alt=""
                        className="img-fluid" />
                </div>
                <div className=" col-md-6 mt-5">
                    <p className="fs-3"> <span className="text-danger">CUIDADO, NO TIENES PERMISO PARA ACCEDER A ESTE CONTENIDO!</span></p>
                    <p className="lead">
                    </p>
                    <Link to={"/"} className="btn btn-primary">
                        Regresar
                    </Link>
                </div>

            </div>
        </div>
  )
}

export default Error401