import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import PodsProveedor from './PodsProveedor';

function ModalPods({idEmbarque}) {
    const [open, setOpen] = useState(false);

    const handleOpen = async () => {
        setOpen(true)
        localStorage.setItem("idEmbarque", idEmbarque);
    };

    const handleClose = async () => {
        setOpen(false);
        localStorage.removeItem("idEmbarque");
    }

    return (
        <div>
            <div align="center">
                <button className='btn btn-primary' onClick={handleOpen}>
                    <i className="bi bi-file-text"></i>
                </button>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='card style-modal-md'>
                    <div className='card-header d-flex justify-content-between'>
                        PRUEBAS DE ENTREGA
                        <button className='btn btn-dark btn-sm'
                            onClick={handleClose}>
                            <i className="bi bi-x-lg"></i>
                        </button>
                    </div>
                    <div className='card-body body-modal-scroll'>
                        <PodsProveedor
                        idEmbarque={idEmbarque}></PodsProveedor>

                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ModalPods