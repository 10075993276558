import { Formik, Field, Form, ErrorMessage } from 'formik';
import { CATALOGO_TIPOS_TRAFICO } from '../../../../utils/catalogosEstaticos';
import * as Yup from 'yup';
import FormikSelectSearch from '../../../formik/FormikSelectSearch';
import { formatCatalogoTrafico } from '../../../../utils/formats/formatCatalogos';
import { saveCondicionService, updateCondicionService } from '../../../../controllers/admin/cotizaciones/condicionesServicio';
import { CondicionServicio } from '../../../../models/admin/cotizaciones/CondicionServicio';

function FormCondicionesServicio({ setRefreshData, condicionServicio, setCondicionServicio }) {
    return (
        <Formik
            initialValues={condicionServicio}
            onSubmit={async (condicion, { resetForm }) => {
                const data = condicion.id ? await updateCondicionService(condicion,condicion.id) : await saveCondicionService(condicion);
                setRefreshData(data);
                resetForm();
                setCondicionServicio(CondicionServicio);
            }}
            enableReinitialize
            validationSchema={Yup.object({
                nombre: Yup.string()
                    .required('El nombre del servicio es requerido'),
                trafico: Yup.mixed().nullable().required('El trafico es requerido')
            })}
        >
            <Form>
                <div className="col-sm-12 mb-3">
                    <label htmlFor="nombreServicio" className="form-label">Nombre</label>
                    <Field id="nombreServicio" name="nombre" className="form-control" />
                    <ErrorMessage name='nombre' component='span' className='text-danger'></ErrorMessage>
                </div>

                <div className="col-sm-12 mb-3">

                    <Field
                        name="trafico"
                        component={FormikSelectSearch}
                        data={formatCatalogoTrafico(CATALOGO_TIPOS_TRAFICO)}
                        titulo={"Tráfico"}
                        isDisabled={false}
                        returnObject={true} />

                    <ErrorMessage name='trafico' component='span' className='text-danger'></ErrorMessage>
                </div>

                <div className='col-sm-12' align="right">
                    {condicionServicio.id ? <button className='btn btn-secondary' onClick={() => {setCondicionServicio(CondicionServicio)}}>CANCELAR</button> : <></>}
                    <button type="submit" className='btn btn-primary'>{condicionServicio.id ? "ACTUALIZAR" : "GUARDAR"}</button>
                </div>
            </Form>
        </Formik>
    )
}

export default FormCondicionesServicio