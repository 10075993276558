import { useEffect, useState } from "react";
import FormDocumentGeneric from "./FormDocumentGeneric";
import TableDocuments from "./TableDocuments";

function DocumentsGenerics({ functionFindAll, functionSaveDocument, functionUpdateDocument, functionDeleteDocument, attributeNameFile, attributeDocument, baseUrlS3 }) {
    const [documents, setDocuments] = useState([]);


    useEffect(() => {
        //attributeNameFile: is name of object attribute that it is use to acces in nmain object
        refreshData();
    }, []);

    const refreshData = async () => {
        const data = await functionFindAll();
        setDocuments(data);
    }

    return (
        <div className="row">
            <div className="col">
                <FormDocumentGeneric functionSaveDocument={functionSaveDocument} refreshData={refreshData} attributeNameFile={attributeNameFile} attributeDocument={attributeDocument}></FormDocumentGeneric>
                <TableDocuments
                    baseUrlS3={baseUrlS3}
                    dataDocuments={documents}
                    refreshData={refreshData}
                    attributeNameFile={attributeNameFile}
                    attributeDocument={attributeDocument}
                    functionUpdateDocument={functionUpdateDocument}
                    functionDeleteDocument={functionDeleteDocument} />
            </div>
        </div>
    )
}

export default DocumentsGenerics