import React from 'react'
import { useCookies } from 'react-cookie';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { changeVisible, setNavegation } from '../redux/features/navbar/navbarSlice';
import LogoKJ from '../assets/imgs/logo.png'
import ComplementoDePago from './ComplementoDePago'
import { ID_ROL_ADMIN, ID_ROL_OPERATIVO } from '../utils/constantes';

function Navbar() {
    const [cookie, setCookie, removeCookie] = useCookies();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const roleUser = useSelector((state) => state.userAuth.roleUser);

    const stateLinks = useSelector((state) => state.navbar.navegation);

    const logout = async () => {
        removeCookie('auth-token', { path: '/' })
        localStorage.removeItem('auth-user')
        dispatch(changeVisible(false))
        dispatch(setNavegation([]))
        navigate('/', { replace: true });
        window.location.reload();
    }

    const linksNavbar = stateLinks.links.map((link) => {
        if (link.dropdown ? link.dropdown.length : 0) {
            return (
                <li className="nav-item dropdown" key={link.name}>
                    <a className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {link.name}
                    </a>
                    <ul className="dropdown-menu">
                        {
                            link.dropdown.map((drop) => {
                                return (
                                    <li className="nav-item" key={drop.name}>
                                        <Link className="dropdown-item" to={drop.navigate}>{drop.name}</Link>
                                    </li>)
                            })
                        }

                    </ul>
                </li>
            )
        }
        return (
            <li className="nav-item" key={link.name}>
                <Link className="nav-link" to={link.navigate}>{link.name}</Link>
            </li>
        )
    })

    return (
        <nav className="navbar navbar-expand-lg bg-light">
            <div className="container-fluid">
                <Link className="navbar-brand" to={stateLinks.path}>
                    <img src={LogoKJ} className="mx-auto d-block" style={{ maxWidth: "100px" }} />
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        {linksNavbar}
                        {roleUser?.id == ID_ROL_ADMIN
                            || roleUser?.id == ID_ROL_OPERATIVO ?
                            <div className="dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="bi bi-archive-fill"></i>
                                </a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <ComplementoDePago />
                                    </li>
                                </ul>
                            </div> : ""
                        }
                    </ul>
                    <div className="d-flex" >
                        <button className="btn btn-danger" onClick={logout}>
                            <i className="bi bi-box-arrow-left"></i>
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar