import React from 'react'
import { FileUploader } from "react-drag-drop-files";
import { ToastWarning } from '../../utils/toastMessage';

function CustomFileUploader({ handleChangeFile, name, fileTypes, index, nameFile, multiple }) {

    function customHandleChangeFile(index, file, name) {
        try {
            if (file instanceof FileList) {
                const files = [...file];
                files.forEach(file => {
                    validateFile(file);
                });
            }
            if (file instanceof File) {
                validateFile(file);
            }
            handleChangeFile(index, file, name);
        } catch (error) {
            return ToastWarning(error.message);
        }
    }
    
    function validateFile(file) {
        if (file.size <= 0 || !file.size) {
            throw new Error("Error al cargar el archivo. Si lo intentaste cargar arrastrándolo ahora inténtalo nuevamente seleccionándolo. De continuar el error, consulta al operativo o administrador.");
        }
    }

    return (
        <FileUploader
            handleChange={(file) => { customHandleChangeFile(index, file, name) }}
            name={name}
            types={fileTypes}
            maxSize={15}
            multiple={multiple ? true : false}
            onTypeError={(err) => ToastWarning("No se permite este tipo de archivos")}
            onSizeError={(file) => ToastWarning("El archivo es muy grande")}>
            <div className='border-custom-file-input' align="center">
                <i className="bi bi-file-earmark-arrow-up-fill"></i>
                {nameFile ? nameFile : "Arrastrar o seleccionar el archivo"}
            </div>
        </FileUploader>
    )
}

export default CustomFileUploader