export const generateNameUser = (user) => {
    if(user?.nombreComercial && user?.nombreComercial != "") return user.nombreComercial;
    if(user){
        let nameUser = "";
        if(user.nombre) nameUser += user?.nombre
        if(user.primerApellido) nameUser += " " +user?.primerApellido
        if(user.segundoApellido) nameUser += " " + user?.segundoApellido
        return nameUser
    }
    return ""
}