import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { bloquearEmbarque } from '../validations/embarqueValidations';

function SelectDesglosesConCheckBox({ desgloses, handleChangeCheckbox, indexPOD }) {
  const [selected, setSelected] = React.useState([]);
  const embarqueData = useSelector((state) => state.embarque.embarqueData);
  const userAuth = useSelector((state) => state.userAuth);

  return (
    <FormControl fullWidth>
      <Select
        multiple
        displayEmpty
        value={selected}
        renderValue={(selected) => ('Desgloses')}
      >
        {desgloses.map((desglose, dataIndex) => (
          <MenuItem key={desglose.id} value={desglose.id}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={desglose.tieneRelacionConPod}
                    onChange={(e) => handleChangeCheckbox(e, dataIndex, indexPOD)}
                    disabled={bloquearEmbarque(embarqueData, userAuth)}
                  />
                }
                label={
                  `Desglose ${dataIndex + 1} - ` +
                  (desglose.proveedor?.nombreComercial
                    ? desglose.proveedor?.nombreComercial + ' - '
                    : '') +
                  (desglose.proveedor?.nombre
                    ? desglose.proveedor?.nombre +
                      ' ' +
                      desglose.proveedor?.primerApellido +
                      ' ' +
                      desglose.proveedor?.segundoApellido
                    : '')
                }
              />
            </FormGroup>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectDesglosesConCheckBox;