import React, { useState } from 'react'
import Modal from '@mui/material/Modal';
import { styleModalSM } from '../../../../assets/css/styles-react';
import { ToastError, ToastSuccess } from '../../../../utils/toastMessage';
import MUIDataTable from "mui-datatables";
import { useDispatch } from 'react-redux';
import ModalEliminarObjeto from '../../ModalEliminarObjeto';
import { setLoading } from '../../../../redux/features/loaderSlice';
import { deleteByIdContenedorMaritimo, findAllCatalogoContenedorMaritimo, saveContenedorMaritimo, updateContenedorMaritimo } from '../../../../controllers/admin/embarques/catalogos/catalogoContenedorMaritimoController';
import { BASIC_OPTIONS_MUI } from '../../../../utils/mui/constantes';

function ModalAdministracionContenedorMaritimo() {
    const contenedorEntity = {
        id: '',
        nombre: '',
        creadoEn: ''
      }
      const [open, setOpen] = useState(false);
      const [contenedor, setContenedor] = useState(contenedorEntity)
      const [contenedores, setContenedores] = useState([])
      const dispatch = useDispatch();
    
      const handleOpen = async () => {
        dispatch(setLoading({isLoading: true}))
        await fetchFindALLCatalogoNaviera();
        setOpen(true)
        dispatch(setLoading({isLoading: false}))
      };
    
      const handleChange = event => {
        event.target.value = event.target.value.toUpperCase();
    
        setContenedor({
          ...contenedor,
          [event.target.name]: event.target.value
        })
      }
    
      const addNewContenedor = (newContenedor) => {
        setContenedores((prevContenedores) => [
          ...prevContenedores,
          [
            newContenedor.id,
            newContenedor.nombre,
            newContenedor.creadoEn,
          ],
        ]);
      };
    
      const updateExistingContenedor = (updatedContenedor) => {
        setContenedores((prevContenedores) => {
          const indexContenedor = prevContenedores.findIndex(item => item[0] === updatedContenedor.id);
          const newContenedores = [...prevContenedores];
          newContenedores[indexContenedor] = [
            updatedContenedor.id,
            updatedContenedor.nombre,
            updatedContenedor.creadoEn,
          ];
          return newContenedores;
        });
      };
    
      const handleSubmit = async (event) => {
        event.preventDefault();
        try {
          const isUpdate = contenedor?.id != "";
          const action = isUpdate ? updateContenedorMaritimo : saveContenedorMaritimo;
    
          dispatch(setLoading({isLoading: true}))
          const res = await action(contenedor);
          dispatch(setLoading({isLoading: false}))
    
          if (res.error) return ToastError(res.message);
    
          ToastSuccess(res.message);
          isUpdate ? updateExistingContenedor(res.data) : addNewContenedor(res.data);
          setContenedor(contenedorEntity);
        } catch (error) {
          return ToastError(error);
        }
      };
    
    
      const fetchFindALLCatalogoNaviera = async () => {
        try {
          const res = await findAllCatalogoContenedorMaritimo()
          if (res.error) return ToastError(res.message)
          await formatoData(res.data)
        } catch (e) {
          ToastError(e)
        }
      }
    
      const fetchDeleteByIdNaviera = async (idContenedor) =>{
        try{
          const res = await deleteByIdContenedorMaritimo(idContenedor);
          if (res?.error) return ToastError(res.message);
    
          //Eliminar la contenedor del array
          setContenedores((prevContenedores) => {
            const indexContenedor = prevContenedores.findIndex(item => item[0] === idContenedor);
            const newContenedores = [...prevContenedores];
            newContenedores.splice(indexContenedor, 1)
            return newContenedores;
          });
          ToastSuccess(res.message);
        }catch(err){
          return ToastError(err)
        }
      }
    
      async function formatoData(data) {
        setContenedores(data.map((di) => [
          di.id,
          di.nombre,
          di.creadoEn
        ]))
      }
    
      const handleClose = () => setOpen(false);
    
      const columns = [
        {
          name: "Id",
          options: {
            display: "excluded",
            filter: false,
            sort: false,
            download: false,
          }
        },
        "contenedor",
        {
          name: "Fecha Registro",
          options: {
            display: "excluded",
            filter: false,
            sort: false,
            download: false,
          }
        },
        {
          name: "",
          options: {
            customBodyRenderLite: (dataIndex) => {
              return (
                <button className='btn btn-warning'
                  onClick={() => setContenedor({ id: contenedores[dataIndex][0], nombre: contenedores[dataIndex][1], creadoEn: contenedores[dataIndex][2] })}>
                  <i className="bi bi-pencil"></i>
                </button>
              )
            },
            filter: false,
            sort: false,
            download: false,
          }
        },
        {
          name: "",
          options: {
            customBodyRenderLite: (dataIndex) => {
              return (
                <ModalEliminarObjeto
                functionEliminar={fetchDeleteByIdNaviera}
                id={contenedores[dataIndex][0]}
                textHeader={"¿Estás seguro de eliminar esta contenedor?"}>
                </ModalEliminarObjeto>
              )
            },
            filter: false,
            sort: false,
            download: false,
          }
        },]
    
      return (
        <>
          <button className='dropdown-item' onClick={handleOpen}>
            Administración contenedores
          </button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="card" style={styleModalSM}>
              <div className="card-header">
                Administración contenedores
              </div>
              <div className="card-body body-modal-scroll">
                <form onSubmit={handleSubmit}>
                  <div className='row g-3 row-cols-3'>
                    <div className="col-12 mb-3">
                      <label htmlFor="inputNombre" className="form-label">Contenedor<span className='required-span'>*</span></label>
                      <input
                        type="text"
                        required
                        className="form-control"
                        name='nombre'
                        value={contenedor.nombre}
                        placeholder="Ingresa el nombre"
                        onChange={handleChange} />
                    </div>
                    <div className='col-12' align="right">
                      {contenedor.id ?
                        <>
                          <span type='button' className="btn btn-secondary col-4" onClick={() => setContenedor(contenedorEntity)}>Cancelar</span>
                          <button type="submit" className="btn btn-primary col-4">Actualizar</button>
                        </>
                        :
                        <button type="submit" className="btn btn-primary col-4">Guardar</button>
    
                      }
                    </div>
                  </div>
                </form>
    
                <MUIDataTable
                  data={contenedores}
                  columns={columns}
                  options={BASIC_OPTIONS_MUI}
                />
              </div>
            </div>
          </Modal>
        </>
      )
    }
    

export default ModalAdministracionContenedorMaritimo