export const validatePrintRole = (pod, isAdmin) => {

    if(pod?.id && !pod?.guardoProveedor && !isAdmin){
        return false;
    }

    return true;
}

export const podHaveRelationWithDesglose = (desglosesEmbarque) =>{
    let podHaveRelationWithDesglose = false;
    desglosesEmbarque.forEach(desglose => {
        if(desglose.tieneRelacionConPod) podHaveRelationWithDesglose = true
    });

    return podHaveRelationWithDesglose;
}