import React from 'react'
import { useSelector } from 'react-redux';
import { ID_SERVICIO_EMBARQUE_EXPORTACION, MODALIDAD_MARITIMA_FCL } from '../../../../../utils/constantes';
import FormikGenericDate from '../../../../formik/FormikGenericDate';

function PortToPort({ values }) {
    const embarqueData = useSelector((state) => state.embarque.embarqueData);

    const esExportacionYFCL = embarqueData.tipoServicio?.id == ID_SERVICIO_EMBARQUE_EXPORTACION
        && (embarqueData?.datosEmbarqueMaritimo.modalidadMaritima == MODALIDAD_MARITIMA_FCL)

    return (
        <>
            {esExportacionYFCL ?
                <>
                    <div className='col-4 mb-1'>
                        <Field
                            name="datosDesgloseMaritimo.fechaTomaVacio"
                            component={FormikGenericDate}
                            nameLabel="Toma Vacio" />
                    </div>
                </>
                :
                <></>

            }
        </>
    )
}

export default PortToPort