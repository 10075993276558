import { useEffect, useState } from 'react'
import ModalColumnDinamicTable from './ModalColumnDinamicTable'
import { addItem, deleteItem, handleChangeArrayText } from '../../utils/helpersState';
import { CATALOGO_TIPOS_FILA } from '../../utils/catalogosEstaticos';
import FechaGeneric from '../inputs/FechaGeneric';
import SelectSearchDB from '../inputs/SelectSearchDB';
import { validationsFetchPetition, validataTypeSearchDB } from '../../utils/validations/dinamicTable';
import '../../assets/css/resize-table.css'
import { adjustResizableTable, createResizableTable } from '../../utils/resize-table/resize-table';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { visibilityLoader } from '../../utils/loader';
import { validatePrintDataTable } from './defaults/cotizacion/defaultTablesCotizacionVentas';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function TableDinamic({ setDinamicTable, dinamicTable, isFormik, handleChange, functionGenerateTable, objectValue, attributeName }) {
  const [tableColumns, setTableColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [confPdf, setConfPdf] = useState({ orientation: "H" });
  const [showConfPdf, setShowConfPdf] = useState(false);

  useEffect(() => {
    if (dinamicTable) {
      setTableColumns(dinamicTable.columns || []);
      setTableData(dinamicTable.data || []);
      setConfPdf(dinamicTable.confPdf || {});
    }
  }, [])
  useEffect(() => {
    const formatDT = {
      confPdf: confPdf,
      columns: tableColumns,
      data: tableData
    }
    isFormik ? handleChange(formatDT) : setDinamicTable(formatDT);

  }, [tableColumns, tableData, confPdf]);

  const modalColumn = (isEditColumn, indexColumn) => {
    return <ModalColumnDinamicTable
      setTableColumns={setTableColumns}
      confPdf={confPdf}
      tableColumns={tableColumns}
      isEditColumn={isEditColumn}
      indexColumn={indexColumn}></ModalColumnDinamicTable>
  }

  const handleDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(tableColumns);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setTableColumns(items);
  };

  return (
    <>
      <div className="d-flex justify-content-end align-items-center">
        {modalColumn()}
        <button className='btn btn-dark' type='button' onClick={() => { addItem(tableData, setTableData, {}, true) }}><i className="bi bi-plus" />FILA</button>
        <div className="dropdown ms-1">
          <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="bi bi-gear-fill"></i>
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              {functionGenerateTable ?
                <button className='dropdown-item' type='button'
                  onClick={() => {
                    functionGenerateTable(objectValue, attributeName, setTableColumns, setTableData);
                    adjustResizableTable(setTableColumns, true);
                  }}><i className="bi bi-table"></i> GENERAR TABLA</button>
                :
                ""
              }
            </li>
            {tableColumns.length >= 1 ?
              <>
                <li>
                  <span className='dropdown-item' style={{ cursor: "pointer" }} onClick={() => createResizableTable(setTableColumns, "width", `resizable-col-${attributeName}`)}>
                    <i className="bi bi-aspect-ratio-fill"></i> REDIMENSIONAR
                  </span>
                </li>
                <li>
                  <span className='dropdown-item' style={{ cursor: "pointer" }} onClick={() => {
                    setShowConfPdf(true)
                    visibilityLoader(true)
                    setTimeout(() => {
                      createResizableTable(setTableColumns, confPdf?.orientation == "V" ? "widthVPdf" : "widthHPdf", `resizable-col-pdf-${attributeName}`);
                      adjustResizableTable(setTableColumns);
                      visibilityLoader(false)
                    }, "1000");
                  }}>
                    <i className="bi bi-aspect-ratio-fill"></i> CONFIGURAR TABLA PDF
                  </span>
                </li>
              </>
              : null}
          </ul>
        </div>
      </div>

      <div className='table-container'>
        <table className='table-rs' id={`resizable-table-${attributeName}`}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <thead>
              <Droppable droppableId="columns" direction="horizontal">
                {(provided) => (
                  <tr {...provided.droppableProps} ref={provided.innerRef}>
                    {tableColumns.map((column, indexColumn) => (
                      <Draggable key={column.idItem} draggableId={column.idItem} index={indexColumn}>
                        {(provided) => (
                          <th
                            className={`resizable-col-${attributeName}`}
                            style={{ width: column?.width ? column.width : '200px' }}
                          >
                            <span
                              style={{ ...provided.draggableProps.style }}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}>
                              {column.nombreColumna}
                              <i className="bi bi-trash item-pointer" onClick={() => deleteItem(indexColumn, tableColumns, setTableColumns)}></i>
                              {modalColumn(true, indexColumn)}
                            </span>
                          </th>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tr>
                )}
              </Droppable>
            </thead>
          </DragDropContext>
          <tbody>
            {tableData?.map((data, indexData) => {
              return (
                <>
                  <tr key={data.idItem}>
                    {tableColumns?.map((column) => {
                      const nameColumn = column.idItem;
                      const catalogo = column.catalogo;
                      return (
                        <td key={column.idItem}>

                          {column.tipoFila == CATALOGO_TIPOS_FILA[0].value ?
                            <>
                              <label className="form-label"></label>
                              <input
                                type="text"
                                className="form-control"
                                name={nameColumn}
                                value={data[nameColumn]}
                                onChange={(e) => { handleChangeArrayText(e, indexData, tableData, setTableData) }}
                              />
                            </> : <></>
                          }

                          {column.tipoFila == CATALOGO_TIPOS_FILA[1].value || column.tipoFila == CATALOGO_TIPOS_FILA[5].value ?
                            <>
                              <label className="form-label"></label>
                              <input
                                type="number"
                                className="form-control"
                                name={nameColumn}
                                value={data[nameColumn]}
                                onChange={(e) => { handleChangeArrayText(e, indexData, tableData, setTableData) }}
                              />
                            </> : <></>
                          }

                          {column.tipoFila == CATALOGO_TIPOS_FILA[2].value ?
                            <>
                              <label className="form-label"></label>
                              <textarea
                                type="text"
                                className="form-control"
                                name={nameColumn}
                                value={data[nameColumn]}
                                onChange={(e) => { handleChangeArrayText(e, indexData, tableData, setTableData) }}
                              />
                            </> : <></>
                          }

                          {column.tipoFila == CATALOGO_TIPOS_FILA[3].value ?
                            <FechaGeneric
                              fecha={data[nameColumn]}
                              handleDateTimeChange={(event) => {
                                handleChangeArrayText(event, indexData, tableData, setTableData)
                              }}
                              useEvent={true}
                              nameInput={nameColumn}
                              titulo={""}></FechaGeneric> : <></>
                          }

                          {column.tipoFila == CATALOGO_TIPOS_FILA[4].value
                            && catalogo != null ?
                            <SelectSearchDB
                              value={data[nameColumn]}
                              name={nameColumn}
                              handleChange={(event) => {
                                handleChangeArrayText(event, indexData, tableData, setTableData)
                              }}
                              fetchSearch={validationsFetchPetition(catalogo.value)}
                              type={validataTypeSearchDB(catalogo.value)}
                              required={true}
                              titulo={" "}></SelectSearchDB> : <></>
                          }

                        </td>
                      )
                    })}
                    <td align='center'>
                      <button className="btn btn-outline-danger" type='button' onClick={() => { deleteItem(indexData, tableData, setTableData) }} >
                        <i className="bi bi-trash-fill"></i>
                      </button>
                    </td>
                  </tr>
                </>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className='table-container-pdf'>
        {showConfPdf ?
          <div style={{ minWidth: `1250px`, maxWidth: `1250px` }} id='cont-conf-pdf'>
            <hr />
            <div className='col-sm-3'>
              <label>Orientación del documento</label>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(e) => {
                  const value = e.target.value;
                  const sizeWidth = value == "V" ? 766 : 1092;
                  createResizableTable(setTableColumns, value == "V" ? "widthVPdf" : "widthHPdf", `resizable-col-pdf-${attributeName}`);
                  setConfPdf({
                    ...confPdf,
                    orientation: value,
                    widthTable: sizeWidth
                  })
                }}
                value={confPdf?.orientation}
              >
                <FormControlLabel value="V" control={<Radio />} label="Vertical" />
                <FormControlLabel value="H" control={<Radio />} label="Horizontal" />
              </RadioGroup>
            </div>
            {confPdf?.orientation == "V" ?
              <div style={{ background: "#278196", width: `${confPdf?.widthTable}px`, fontSize: "12px", color: "white" }}>TAMAÑO PDF VERTICAL</div>
              :
              confPdf?.orientation == "H" ?
                <div style={{ background: "#0f2f36", width: `${confPdf?.widthTable}px`, fontSize: "12px", color: "white" }}>TAMAÑO PDF HORIZONTAL</div>
                : null
            }
            <table className='table-rs-pdf' id={`resizable-table-${attributeName}`} style={{ visibility: confPdf?.widthTable ? "visible" : "hidden" }}>
              <thead align="center">
                <tr style={{ fontSize: "10.67px" }}>
                  {tableColumns?.map((column, indexColumn) => {
                    return <th key={column.idItem} className={`resizable-col-pdf-${attributeName}`} style={{ width: `${confPdf?.orientation == "V" ? column?.widthVPdf : column?.widthHPdf}px` }}>
                      {column?.nombreColumna}
                    </th>
                  })}
                </tr>
              </thead>
              <tbody align="center">
                {tableData?.map((data, indexData) => {
                  return (
                    <>
                      <tr key={data.idItem}>
                        {tableColumns?.map((column) => {
                          const nameColumn = column.idItem;
                          return (
                            <td key={column.idItem} style={{ fontSize: "10.67px" }}>
                              {validatePrintDataTable(column, data, nameColumn)}
                            </td>
                          )
                        })}
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </table>
          </div>
          : null

        }
      </div>
    </>
  )
}

export default TableDinamic