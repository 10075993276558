import { URL_PROVEEDOR_EMBARQUES } from "../../utils/endpoints/proveedor";
import { apiRequestV2 } from "../handlerApiRequestV2";

export const listarEmbarquesDeProveedor = async (urlParams) => {
    return await apiRequestV2({
        endpoint: URL_PROVEEDOR_EMBARQUES,
        urlParams: urlParams
    })
}

export const listarEmbarquesDeLogistica = async (urlParams) => {
    return await apiRequestV2({
        endpoint: URL_PROVEEDOR_EMBARQUES+"/logistica",
        urlParams: urlParams
    })
}
