export const adminLinks = {
    path: '/admin',
    links: [
        {
            name: "Clientes",
            navigate: "/admin/clientes"
        },
        {
            name: "Proveedores",
            navigate: "/admin/proveedores"
        },
        {
            name: "Equipo",
            navigate: "/admin/equipo"
        },
        {
            name: "Embarques",
            navigate: "/admin/embarques"
        },
        {
            name: "Cotizaciones",
            navigate: "/admin/cotizaciones"
        }
        
    ]
}

export const operativoLinks = {
    path: '/operativo',
    links: [
        {
            name: "Clientes",
            navigate: "/operativo/clientes"
        },
        {
            name: "Proveedores",
            navigate: "/operativo/proveedores"
        },
        {
            name: "Embarques",
            navigate: "/operativo/embarques"
        },
        {
            name: "Cotizaciones",
            navigate: "/operativo/cotizaciones"
        }
    ]
}

export const proveedorLinks = {
    path: '/proveedor',
    links: [
    ]
}

export const logisticaLinks = {
    path: '/logistica',
    links: [
    ]
}

export const clienteLinks = {
    path: '/cliente',
    links: [
    ]
}