import React, { useEffect, useState } from 'react'
import { getAuthUser } from '../../utils/authUtils';

function Header() {
    const [user, setUser] = useState({});

    useEffect(() => {
        const authUser = () => {
            const res = getAuthUser();
            setUser(res);
        }
        authUser()
    }, [])

    return (
        <div className='col-12' align="center">
            <h4></h4>
            <h6>¡BIENVENIDO! <b>{user?.nombreComercial}</b> - {user?.email}</h6>
        </div>
    )
}

export default Header