import { URL_DIRECCION_FISCAL } from "../../../utils/constantes";
import { apiRequest } from "../../handlerApiRequest";

export const guardarDireccionFiscalDelUsuario = async (idUsuario, direccionFiscal) => {
    return await apiRequest(URL_DIRECCION_FISCAL + "/" + idUsuario, "POST", direccionFiscal);
}

export const actualizarDireccionFiscal = async (direccionFiscal) => {
    return await apiRequest(URL_DIRECCION_FISCAL, "PUT", direccionFiscal)
}
