import { OPTIONS_CUSTOM_RETURN_COLUMN } from '../../utils/mui/constantes';
import { handleChangeArrayText } from '../../utils/helpersState';
import ModalEliminarV2 from '../ModalEliminarV2';

export const columnDeleteData = (params) => {
    const { functionEliminar, state, setState, textHeader, textBody, isLogicDelete, nameLogicDelete, spliceItem, functionRefreshData } = params;

    return {
        name: "",
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (
                    <ModalEliminarV2
                        functionEliminar={functionEliminar}
                        functionRefreshData={functionRefreshData}
                        id={state[dataIndex].id}
                        state={state}
                        setState={setState}
                        index={dataIndex}
                        textHeader={textHeader}
                        textBody={textBody}
                        spliceItem={spliceItem}>
                        {isLogicDelete ?
                            <textarea
                                type="text"
                                className="form-control"
                                placeholder='Razón de la eliminación'
                                name={nameLogicDelete}
                                value={state[dataIndex][nameLogicDelete]}
                                onChange={(e) => { handleChangeArrayText(e, dataIndex, state, setState, true) }} />
                            :
                            ""
                        }
                    </ModalEliminarV2>
                )
            },
            ...OPTIONS_CUSTOM_RETURN_COLUMN
        }
    }
}