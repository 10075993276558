import { Font, StyleSheet } from "@react-pdf/renderer";
import Arial from '../../assets/fonts/arial/arial.TTF';
import ArialBold from '../../assets/fonts/arial/arial-bl.TTF';

Font.register({
    family: 'Arial',
    src: Arial
})

Font.register({
    family: 'Arial-Bold',
    src: ArialBold
})

export const generateColCW = (width) =>{
    return {
        width: width,
        borderStyle: "solid",
        padding: 2,
        textAlign: "center",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    }
}

export const generateColHeaderCW = (width, backGround, color) =>{
    return {
        width: width,
        borderStyle: "solid",
        backgroundColor: backGround ? backGround : "#0999C8",
        color: color ? color : '#ffff',
        padding: 2,
        textAlign: "center",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    }
}

export const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        fontFamily: 'Arial',
        fontSize: "10px"
    },
    logo: {
        maxWidth: 200,
    },
    iconsContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    icons: {
        maxHeight: 10,
        marginRight: "3px"
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 8,
    },
    dataCol: {
        flex: 1,
    },
    heading: {
        backgroundColor: '#0999C8',
        color: 'white',
        fontFamily: 'Arial-Bold',
        marginBottom: 5,
        marginTop: 5,
        borderRadius: 2,
        padding: 3,
        fontWeight: 'bold',
    },
    headingProforma: {
        backgroundColor: '#043f52',
        color: 'white',
        fontFamily: 'Arial-Bold',
        marginBottom: 5,
        marginTop: 5,
        borderRadius: 2,
        padding: 3,
        fontWeight: 'bold',
        textAlign: 'center' // Alineación centrada
    },
    table: {
        display: "table",
        width: "100%",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableCol: {
        width: "15%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol40: {
        width: "40%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol60: {
        width: "60%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableColHeader: {
        width: "15%",
        borderStyle: "solid",
        backgroundColor: "#0999C8",
        color: '#ffff',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableColHeader40: {
        width: "40%",
        borderStyle: "solid",
        backgroundColor: "#0999C8",
        color: '#ffff',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableColHeader60: {
        width: "60%",
        borderStyle: "solid",
        backgroundColor: "#0999C8",
        color: '#ffff',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCell: {
        fontSize: 9,
        textAlign: "center",
        padding: 2,
    },
    impuestosTable: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderStyle: "solid",
        borderBottomWidth: 1,
        borderRightWidth: 1,
    },
    totalRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10,
        fontSize: 12,
    },
    textBold: {
        fontFamily: 'Arial-Bold',
    },
    footer: {
        position: 'absolute',
        fontSize: 6,
        bottom: 5,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    pageBody: {
        paddingTop: 20,
        paddingBottom: 20,
        paddingHorizontal: 10,
    },
});