import React, { useEffect, useState } from 'react'
import TableClientes from '../../components/admin/clientes/TableClientes'
import ModalFormUsuarios from '../../components/admin/ModalFormUsuarios'
import ModalAdministracionDirecciones from '../../components/admin/direcciones/ModalAdministracionDirecciones'
import { listaUsuariosPorIdRol } from '../../controllers/admin/usuariosController'
import { ID_ROL_CLIENTES } from '../../utils/constantes'

function Clientes() {
  const [clientes, setClientes] = useState([]);

  useEffect(() => {
    fetchDataClientes();
  }, [])

  const fetchDataClientes = async () => {
    const data = await listaUsuariosPorIdRol(ID_ROL_CLIENTES)
    setClientes(data)
  };



  return (
    <div className='row m-4'>
      <div className='col-12' align="right">
        <div className="dropdown mb-1">
          <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="bi bi-gear-fill"></i> Direcciones
          </button>
          <ul className="dropdown-menu">
            <li>
              <ModalAdministracionDirecciones
                titulo={"Administración Direcciones Entrega y Recolección"}></ModalAdministracionDirecciones>
            </li>
          </ul>
        </div>
      </div>
      <ModalFormUsuarios
        url={"/guardar-cliente"}
        fetchDataUsuarios={fetchDataClientes}
        titulo={"Registrar Cliente"}>
        <i className="bi bi-plus-lg"></i>
      </ModalFormUsuarios>
      <TableClientes
        clientes={clientes}
        fetchDataUsuarios={fetchDataClientes}></TableClientes>
    </div>
  )
}

export default Clientes