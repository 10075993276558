export const styleModalSM = {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 800,
};

export const styleModalMD = {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 1200,
};