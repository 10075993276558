import React, { useEffect, useState } from 'react'
import ModalFormUsuarios from '../../components/admin/ModalFormUsuarios';
import TableProveedores from '../../components/admin/proveedores/TableProveedores'
import { listaUsuariosPorIdRol } from '../../controllers/admin/usuariosController';
import { ID_ROL_PROVEEDORES } from '../../utils/constantes';
import Logistica from './Logistica';

function Proveedor() {
  const [proveedores, setProveedores] = useState([]);

  useEffect(() => {
    fetchDataProveedores();
  }, [])

  const fetchDataProveedores = async () => {
      const data = await listaUsuariosPorIdRol(ID_ROL_PROVEEDORES);
      setProveedores(data);
  };

  return (
    <div className='row m-4'>
      <div align="right" >
        <Logistica />
        <ModalFormUsuarios
          url={"/guardar-proveedor"}
          fetchDataUsuarios={fetchDataProveedores}
          titulo={"Registrar Proveedor"}
          idRol={ID_ROL_PROVEEDORES}
          isProveedor={true}>
          <i className="bi bi-plus-lg"></i>
        </ModalFormUsuarios>

      </div>

      <TableProveedores
        proveedores={proveedores}
        fetchDataUsuarios={fetchDataProveedores}></TableProveedores>
    </div>
  )
}

export default Proveedor