export const CATALOGO_TIPO_UNIDADES_MEDIDA = [
    { id: "Kilogramos", nombre: "Kilogramos", value: "Kilogramos", label: "Kilogramos"  },
    { id: "Libras", nombre: "Libras", value: "Libras", label: "Libras" },
    { id: "Toneladas", nombre: "Toneladas", value: "Toneladas", label: "Toneladas" }
];

export const CATALOGO_SAT_USO_CFDI = [
    { claveSat: "G01", value: "G01", label: "G01 - Adquisición de mercancias" },
    { claveSat: "G02", value: "G02", label: "G02 - Devoluciones, descuentos o bonificaciones" },
    { claveSat: "G03", value: "G03", label: "G03 - Gastos en general" },
    { claveSat: "I01", value: "I01", label: "I01 - Construcciones" },
]

export const CATALOGO_SAT_METODO_PAGO = [
    { claveSat: "PUE", value: "PUE", label: "PUE - Pago en una sola exhibición" },
    { claveSat: "PPD", value: "PPD", label: "PPD - Pago en parcialidades o diferido" },
]

export const CATALOGO_SAT_FORMA_PAGO = [
    {claveSat: "01", value: "01", label: "01 - Efectivo"},
    {claveSat: "02", value: "02", label: "02 - Cheque nominativo"},
    {claveSat: "03", value: "03", label: "03 - Transferencia electrónica de fondos"},
    {claveSat: "04", value: "04", label: "04 - Tarjeta de crédito"},
    {claveSat: "05", value: "05", label: "05 - Monedero electrónico"},
    {claveSat: "06", value: "06", label: "06 - Dinero electrónico"},
    {claveSat: "08", value: "08", label: "08 - Vales de despensa"},
    {claveSat: "12", value: "12", label: "12 - Dación en pago"},
    {claveSat: "13", value: "13", label: "13 - Pago por subrogación"},
    {claveSat: "14", value: "14", label: "14 - Pago por consignación"},
    {claveSat: "15", value: "15", label: "15 - Condonación"},
    {claveSat: "17", value: "17", label: "17 - Compensación"},
    {claveSat: "23", value: "23", label: "23 - Novación"},
    {claveSat: "24", value: "24", label: "24 - Confusión"},
    {claveSat: "25", value: "25", label: "25 - Remisión de deuda"},
    {claveSat: "26", value: "26", label: "26 - Prescripción o caducidad"},
    {claveSat: "27", value: "27", label: "27 - A satisfacción del acreedor"},
    {claveSat: "28", value: "28", label: "28 - Tarjeta de débito"},
    {claveSat: "29", value: "29", label: "29 - Tarjeta de servicios"},
    {claveSat: "30", value: "30", label: "30 - Aplicación de anticipos"},
    {claveSat: "99", value: "99", label: "99 - Por definir"}
]
export const CATALOGO_SAT_TIPO_COMPROBANTE_FACTURA = [
    { claveSat: "I", value: "I", label: "Ingreso" },
    { claveSat: "E", value: "E", label: "Egreso" },
    { claveSat: "T", value: "T", label: "Traslado" },
]

//Al ser catalogos que practicamente no cambian se manejan de esta forma para ahorrar una peticion al back
export const CATALOGO_TIPOS_TRAFICO = [
    { id: 1, nombre:'TERRESTRE', value: {id: 1}, label: "TERRESTRE" },
    { id: 2, nombre:'MARITIMO', value: {id: 2}, label: "MARITIMO" },
    { id: 3, nombre:'AEREO', value: {id: 3}, label: "AEREO" },
    { id: 4, nombre:'INTERMODAL', value: {id: 4}, label: "INTERMODAL" },
]

export const CATALOGO_TIPOS_SERVICIO = [
    { id: 1, nombre:'NACIONAL', value: {id:1}, label:'NACIONAL' },
    { id: 2, nombre:'IMPORTACIÓN', value: {id:2}, label:'IMPORTACIÓN' },
    { id: 3, nombre:'EXPORTACIÓN', value: {id:3}, label:'EXPORTACIÓN' },
]

export const CATALOGO_INCOTERMS = [
    {
        value: 1,
        label: "(EXW) Ex Works"
    },
    {
        value: 2,
        label: "(FAS) Free Alongside Ship"
    },
    {
        value: 3,
        label: "(CFR) Cost and Freight "
    },
    {
        value: 4,
        label: "(CIF) Cost Insurance Freight "
    },
    {
        value: 5,
        label: "(DPU) Delivered at Place Unloaded "
    },
    {
        value: 6,
        label: "(DDP) Delivered Duty Paid "
    },
    {
        value: 7,
        label: "(FCA) Free Carrier "
    },
    {
        value: 8,
        label: "(FOB) Free on Board "
    },
    {
        value: 9,
        label: "(CPT) Carriage Paid To "
    },
    {
        value: 10,
        label: "(CIP) Carriage and Insurance "
    },
    {
        value: 11,
        label: "(DAP) Delivered at Place "
    }
]

export const CATALOGO_TIPO_CARGA_DESGLOSE = [
    {id:1,value:{id:1,nombre:"Carga General"},label:"Carga General"},
    {id:2,value:{id:2,nombre:"Carga Hazmat"},label:"Carga Hazmat"},
    {id:3,value:{id:3,nombre:"Carga Refrigerada"},label:"Carga Refrigerada"},
    {id:4,value:{id:4,nombre:"Carga Especial"},label:"Carga Especial"}
];

export const CATALOGO_TIPOS_SERVICIO_COTIZACIONES = [
    { id: 1, nombre:'NACIONAL' },
    { id: 2, nombre:'IMPORTACIÓN' },
    { id: 3, nombre:'EXPORTACIÓN' },
    { id: 4, nombre:'ADUANAS' },
]

export const CATALOGO_TIPO_CARGA = [
    { id: 1, nombre:'CARGA CONSOLIDADA', value:{id:1,nombre:"CARGA CONSOLIDADA"},label:"CARGA CONSOLIDADA" },
    { id: 2, nombre:'CARGA DEDICADA', value:{id:2,nombre:"CARGA DEDICADA",},label:"CARGA DEDICADA" }
]

export const CATALOGO_MODALIDAD = [
    { nombre:'LCL' },
    { nombre:'FCL' }
]

/**
 * OTS - OPEN TEXT SIMPLE (INPUT TEXT)
 * ONS - OPEN NUMBER SIMPLE (INPUT NUMBER)
 */
export const CATALOGO_TIPOS_FILA = [
    {value: "OTS", label: "ABIERTO TEXTO SIMPLE"},
    {value: "ONS", label: "ABIERTO NUMERO"},
    {value: "TEXTAREA", label: "ABIERTO TEXTO LARGO"},
    {value: "DATE", label: "FECHA"},
    {value: "SELECT", label: "CATALOGO"},
    {value: "MONEDA", label: "MONEDA"},
]

export const CATALOGO_TIPOS_CATALOGOS= [
    {id: "P", value: "P", label: "PROVEEDORES"},
    {id: "C", value: "C", label: "CLIENTES"},
    {id: "TLU", value: "TLU", label: "TODOS LOS USUARIOS"},
    {id: "U", value: "U", label: "UBICACIONES (ORIGEN/DESTINO)"},
]

export const CATALOGO_UNIDAD_MEDIDA_DIMENSION_C = [
    {value: 1, label: "CM"},
    {value: 2, label: "PULGADAS"},
    {value: 3, label: "METROS"}
]

export const CATALOGO_UNIDAD_MEDIDA_BULTOS = [
    { value: 1, label: "KG" }, 
    { value: 2, label: "LB" }, 
    { value: 3, label: "TN" }
]

export const CATALOGO_ESTATUS_COTIZACIONES = [
    {value: 1, label: "EN PROCESO"},
    {value: 2, label: "APROBADA"},
    {value: 3, label: "RECHAZADA"},
    {value: 4, label: "EN REVISIÓN"}
]

export function buscarLabel(value, catalogo){
    const item = catalogo?.find(item => item.value == value);
    return item ? item.label : "";
};

export const CATALOGO_OPERATORS_FILTERS_EMBARQUE = [
    {value: " OR ", label: "OR"},
    {value: " AND ", label: "AND"}
]