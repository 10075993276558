import React, { useEffect, useState } from 'react'
import { ID_SERVICIO_EMBARQUE_EXPORTACION, ID_SERVICIO_EMBARQUE_IMPORTACION } from '../../../utils/constantes';
import { ToastError } from '../../../utils/toastMessage';
import { findAllCatalogoAerolineas } from '../../../controllers/admin/embarques/catalogos/catalogoAerolineas';
import { findAllCatalogoTServicioAereo } from '../../../controllers/admin/embarques/catalogos/catalogoTipoServicioAereoController';
import FormikSelectSearch from '../../formik/FormikSelectSearch';
import FormikGenericDate from '../../formik/FormikGenericDate';
import { Field } from 'formik';
import FieldArrayPedimentos from './forms/FieldArrayPedimentos';
import FormikSelectSearchDB from '../../formik/FormikSelectSearchDB';
import { findProveedoresBysearchText } from '../../../controllers/admin/usuariosController';

function DatosEmbarqueAereo({ values }) {
    const [aerolineas, setAerolineas] = useState([]);
    const [catalogoSA, setCatalogoSA] = useState([]);

    useEffect(() => {
        findAllAerolineas()
        findAllCatalogoServicioAereo()
    }, [])

    const findAllAerolineas = async () => {
        try {
            const res = await findAllCatalogoAerolineas();
            if (res.error) return ToastError(res.message);
            setAerolineas(res.data.map(aerolinea => ({
                id: aerolinea.id,
                value: aerolinea,
                label: aerolinea.nombre
            })))
        } catch (err) {
            return ToastError(err)
        }
    }

    const findAllCatalogoServicioAereo = async () => {
        try {
            const res = await findAllCatalogoTServicioAereo();
            if (res.error) return ToastError(res.message);
            setCatalogoSA(res.data.map(sAereo => ({
                id: sAereo.id,
                value: sAereo,
                label: sAereo.nombre
            })))
        } catch (err) {
            return ToastError(err)
        }
    }

    return (
        <>
            <div className='row'>
                <div className='col-sm-3 mb-1'>
                    <Field
                        name={"datosEmbarqueAereo.servicioAereo"}
                        id="servicioAereo"
                        component={FormikSelectSearch}
                        data={catalogoSA}
                        titulo={"Tipo de Servicio Aereo"}
                        isDisabled={false}
                        returnObject={true} />
                </div>
                <div className='col-12'></div>
                <div className="col-sm-4 mb-1">
                    <label htmlFor="aeropuertoDeCarga" className="form-label">Aeropuerto de Carga</label>
                    <Field id="aeropuertoDeCarga" name="datosEmbarqueAereo.aeropuertoDeCarga" className="form-control" />
                </div>
                <div className="col-sm-4 mb-1">
                    <label htmlFor="aeropuertoDescarga" className="form-label">Aeropuerto de Descarga</label>
                    <Field id="aeropuertoDescarga" name="datosEmbarqueAereo.aeropuertoDescarga" className="form-control" />
                </div>
                <div className='col-sm-4 mb-1'>
                    <Field
                        name={"datosEmbarqueAereo.catalogoAerolinea"}
                        id="catalogoAerolinea"
                        component={FormikSelectSearch}
                        data={aerolineas}
                        titulo={"Aerolinea"}
                        isDisabled={false}
                        returnObject={true} />
                </div>
                <div className="col-sm-4 mb-1">
                    <label htmlFor="noDeVuelo" className="form-label">No. de Vuelo</label>
                    <Field id="noDeVuelo" name="datosEmbarqueAereo.noDeVuelo" className="form-control" />
                </div>
                <div className="col-sm-4 mb-1">
                    <label htmlFor="noDeGuia" className="form-label">No. de Guia</label>
                    <Field id="noDeGuia" name="datosEmbarqueAereo.noDeGuia" className="form-control" />
                </div>
                {values.tipoServicio?.id == ID_SERVICIO_EMBARQUE_EXPORTACION ?
                    <div className="col-sm-4 mb-1">
                        <label htmlFor="noDeBooking" className="form-label">No. de Booking</label>
                        <Field id="noDeBooking" name="datosEmbarqueAereo.noDeBooking" className="form-control" />
                    </div>
                    :
                    <div className='col-12'></div>
                }

                <div className={`col-sm-4 mb-1 ${values.soloDespachoAduanal ? 'd-none' : ''}`}>
                    <Field
                        name="datosEmbarqueAereo.fechaCierreDocumental"
                        component={FormikGenericDate}
                        nameLabel="Cierre Documental" />
                </div>
                <div className={`col-sm-4 mb-1 ${values.soloDespachoAduanal ? 'd-none' : ''}`}>
                    <Field
                        name="datosEmbarqueAereo.fechaCierreFisico"
                        component={FormikGenericDate}
                        nameLabel="Cierre Fisico" />
                </div>
                <div className='col-sm-1 mb-3'>
                    <div role="group" aria-labelledby="demo-controlled-radio-buttons-group">
                        <label className="form-label">Despacho Aduanal</label>
                        <div className='form-check'>
                            <label>
                                <Field type="radio" name="haceDespachoAduanal" value="CLIENTE" className="form-check-input" />
                                CLIENTE
                            </label>
                        </div>
                        <div className='form-check'>
                            <label>
                                <Field type="radio" name="haceDespachoAduanal" value="KJ" className="form-check-input" />
                                KJ LOGISTICS
                            </label>
                        </div>
                    </div>
                </div>
                {values.haceDespachoAduanal == "KJ" &&
                    <div className='col-sm-3 mb-3'>
                        <Field
                            name="kjcustomBrokerProvider"
                            component={FormikSelectSearchDB}
                            titulo="Proveedor despacho aduanal"
                            fetchSearch={findProveedoresBysearchText}
                            type={"USERS"} />
                    </div>

                }
                {values.haceDespachoAduanal == "KJ" ?
                    <FieldArrayPedimentos values={values}/> : <></>
                }
                <div className='col-12'></div>

                <div className='col-sm-4 mb-1'>
                    <Field
                        name="datosEmbarqueAereo.fechaETD"
                        component={FormikGenericDate}
                        nameLabel="ETD" />
                </div>

                <div className='col-sm-4 mb-1'>
                    <Field
                        name="datosEmbarqueAereo.fechaETA"
                        component={FormikGenericDate}
                        nameLabel="ETA" />
                </div>
                <div className='col-12'></div>
                <div className='col-sm-4 mb-1'>
                    <Field
                        name="datosEmbarqueAereo.fechaATD"
                        component={FormikGenericDate}
                        nameLabel="ATD" />
                </div>
                <div className='col-sm-4 mb-1'>
                    <Field
                        name="datosEmbarqueAereo.fechaATA"
                        component={FormikGenericDate}
                        nameLabel="ATA" />
                </div>
                {values.tipoServicio?.id == ID_SERVICIO_EMBARQUE_IMPORTACION ?
                < div className="col-sm-12 mb-3">
                    <label htmlFor="instruccionesEspeciales" className="form-label">Instrucciones Especiales</label>
                    <Field id="instruccionesEspeciales" name="datosEmbarqueAereo.instruccionesEspeciales" className="form-control" component="textarea" />
                </div>:null}

            </div>
        </>
    )
}

export default DatosEmbarqueAereo