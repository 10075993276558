import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { ToastError } from '../../utils/toastMessage';
import { generateNameUser } from '../../utils/helpersFormat';

function SelectSearchDB({
    titulo,
    value,
    name,
    handleChange,
    index,
    required,
    isDisabled,
    fetchSearch,
    type }) {
    const [data, setData] = useState([]);
    const [valueSelect, setValueSelect] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        switch (type) {
            case 'ORIGEN/DESTINO':
                const portCode = value?.portCode ? `(${value.portCode})` : '';
                value = {
                    id: value?.id,
                    value: value,
                    label: `${value?.nombre || ''} ${portCode}`
                }
                break;
            case 'USERS':
                value = {
                    id: value?.id,
                    value: value,
                    label: value?.nombreComercial ? value.nombreComercial : generateNameUser(value)
                }
                break;
            case 'DIRECCION':
                value = {
                    id: value?.id,
                    value: value,
                    label: value?.direccion
                }
                break;
            case 'COTIZACION':
                value = {
                    id: value?.id,
                    value: value,
                    label: value?.quoteReferenceKJ
                }
                break;
            default:
                value = {
                    id: value?.id,
                    value: value,
                    label: value?.nombre
                }
        }

        setValueSelect(value)
    }, [value])

    const handleInputChange = (inputValue, { action }) => {
        if (action === 'input-change' && inputValue.length >= 3) {
            handleFetchSearch(inputValue)
        }
    };

    const handleFetchSearch = async (inputValue) => {
        try {
            setLoading(true);
            const res = await fetchSearch(inputValue);

            switch (type) {
                case 'ORIGEN/DESTINO':
                    setData(
                        res.data.map(data => {
                            const portCode = data?.portCode ? `(${data.portCode})` : '';
                            return {
                                id: data.id,
                                value: data,
                                label: `${data?.nombre || ''} ${portCode}`
                            };
                        })
                    );
                    break;
                case 'USERS':
                    setData(
                        res.data.map(data => {
                            return {
                                id: data.id,
                                value: data,
                                label: data?.nombreComercial ? data.nombreComercial : generateNameUser(data)
                            };
                        })
                    );
                    break;
                case 'DIRECCION':
                    setData(
                        res.data.map(data => {
                            return {
                                id: data.id,
                                value: data,
                                label: data?.direccion
                            }
                        })
                    );
                    break;
                case 'COTIZACION':
                    setData(
                        res.data.map(data => {
                            return {
                                id: data.id,
                                value: data,
                                label: data?.quoteReferenceKJ
                            }
                        })
                    );
                    break;
                default:
                    setData(res.data.map(data => ({
                        id: data.id,
                        value: data,
                        label: data?.nombre
                    })))
            }

            setLoading(false);
        } catch (err) {
            return ToastError(err.message)
        }
    }

    return (
        <>
            {titulo ? <label className="form-label">{titulo}</label> : ""}
            <Select
                className="basic-single"
                classNamePrefix="select"
                value={valueSelect}
                isSearchable={true}
                name={name}
                options={data}
                isDisabled={isDisabled ? true : false}
                onInputChange={handleInputChange}
                onChange={(selectedOption) => {
                    handleChange(
                        {
                            target: {
                                name: name,
                                value: selectedOption.value,
                            },
                        },
                        index
                    );
                }}
                required={required ? true : false}
                isLoading={loading}
                noOptionsMessage={() => (loading ? 'Cargando...' : 'No se encontraron resultados, escribe las primeras tres letras de tu busqueda.')}
                styles={{
                    singleValue: (provided, state) => ({
                        ...provided,
                        overflow: 'visible',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal'
                    }),
                    menu: (provided, state) => ({
                        ...provided,
                        zIndex: 9999
                    })
                }}
            />
        </>
    )
}

export default SelectSearchDB