import React, { useState } from 'react'
import Modal from '@mui/material/Modal';
import { styleModalSM } from '../../../assets/css/styles-react';
import CommunDirecciones from '../direcciones/CommunDirecciones';
import { actualizarDireccionFiscal, guardarDireccionFiscalDelUsuario } from '../../../controllers/admin/direcciones/direccionFiscalController';
import { ToastSuccess, ToastError } from '../../../utils/toastMessage';
import { useDispatch } from 'react-redux';
import { sliceActualizarDireccionFiscal } from '../../../redux/features/usuarioSlice';

function ModalDireccionFiscal({ usuario }) {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const [direccionFiscal, setDireccionFiscal] = useState({
        id: null,
        nombreEmpresa: '',
        calle: '',
        numeroInt: '',
        numeroExt: '',
        colonia: '',
        municipio: '',
        codigoPostal: '',
        estado: '',
        pais: '',
        rfc: '',
    })

    const handleOpen = async () => {
        if (usuario.direccionFiscal != null) {
            setDireccionFiscal(usuario.direccionFiscal)
        }
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDireccionFiscal({
            ...direccionFiscal,
            [name]: value
        })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            let res;
            if(direccionFiscal.id != null){
                res = await actualizarDireccionFiscal(direccionFiscal);
            }else{
                res = await guardarDireccionFiscalDelUsuario(usuario.id, direccionFiscal);
            }
            if (res.error) return ToastError(res.message)
            setDireccionFiscal(res.data)
            dispatch(sliceActualizarDireccionFiscal(res.data))
            ToastSuccess(res.message)
        } catch (error) {
            return ToastError(error);
        }
    }

    return (
        <>
            <button className='btn btn-dark' onClick={handleOpen} type='button'>
                <i className="bi bi-pencil-fill"></i>
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='card' style={styleModalSM}>
                    <div className='card-header d-flex justify-content-between'>
                        Direccion Fiscal de: {usuario.nombreComercial}
                        <button className='btn btn-dark btn-sm'
                            onClick={handleClose}>
                            <i className="bi bi-x-lg"></i>
                        </button>
                    </div>
                    <div className='card-body'>
                        <form onSubmit={handleSubmit}>
                            <div className='row'>
                                <CommunDirecciones
                                    value={direccionFiscal}
                                    handleChange={handleChange}></CommunDirecciones>
                                <div className='col-12' align="right">
                                    <button type='submit' className='btn btn-dark'>
                                        {direccionFiscal.id ? "Actualizar" : "Guardar"}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ModalDireccionFiscal