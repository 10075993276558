export const formatCatalogoTrafico = (catalogos) =>{

    catalogos.map((catalogo) =>{
        catalogo.label = catalogo.nombre;
        catalogo.value = catalogo.id;
    })

    return catalogos;
}

export const formatGenericCatalogo = (catalogos) =>{

    catalogos.map((catalogo) =>{
        catalogo.label = catalogo.nombre;
        catalogo.value = catalogo.id;
    })

    return catalogos;
}

