import { Field } from 'formik';
import FormikGenericDate from '../../../../formik/FormikGenericDate';

function DesgloseAereoAduanaIngreso({ values }) {

    return (
        <div className='row'>
            <div className="col-sm-6 mb-3">
                <label htmlFor="inputDestino" className="form-label">Aduana de Ingreso</label>
                <Field id="datosDesgloseAereo.aduanaIngreso" name={`datosDesgloseAereo.aduanaIngreso`} className="form-control" />
            </div>
            <div className='col-sm-6 mb-1'>
                <Field
                    name="datosDesgloseAereo.fechaIngresoAduana"
                    component={FormikGenericDate}
                    nameLabel="Fecha Ingreso Aduana" />
            </div>
        </div>
    )
}

export default DesgloseAereoAduanaIngreso