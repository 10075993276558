import { useEffect } from "react";
import SelectTipoMoneda from "../../embarques/catalogos-select/SelectTipoMoneda";
import { useDispatch, useSelector } from "react-redux";
import {
  actualizarCostosProveedor,
  agregarCostoProveedor,
  eliminarCostoProveedor,
  fetchCostoProveedores,
} from "../../../../redux/features/admin/controlCostosSlice";
import {
  eliminadoLogicoCostoPorId,
  guardarCostoProveedorDTO,
} from "../../../../controllers/admin/costoProveedorController";
import {
  ToastError,
  ToastSuccess,
  ToastWarning,
} from "../../../../utils/toastMessage";
import FacturaProveedor from "../proveedor/FacturaProveedor";
import ModalEliminarObjeto from "../../ModalEliminarObjeto";
import { setLoading } from "../../../../redux/features/loaderSlice";
import AnticipoCostoProveedor from "../proveedor/AnticipoCostoProveedor";
import SelectSearchDB from "../../../inputs/SelectSearchDB";
import { findProveedoresBysearchText } from "../../../../controllers/admin/usuariosController";
import GeneradorFakeXML from "./GeneradorFakeXML";
import ToolTipMui from '../../../mui/ToolTipMui';
import moment from "moment";
import "moment/locale/es";
import { isDisabledDeleteButton } from "../validations/validationsCostosProveedores";
import { formatNameUser } from "../../../../utils/userFormat";
import Table from 'react-bootstrap/Table';

function CostosProveedores({ idEmbarque, recargarEmbarqueData }) {
  const controlCostoData = useSelector((state) => state.controlCostos);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchListaCostosProveedorPorIdEmbarque();
  }, []);

  const onChangeCostosProveedor = (event, indexCP) => {
    const { name, value } = event.target;
    dispatch(actualizarCostosProveedor({ name, value, indexCP }));
  };

  const fetchListaCostosProveedorPorIdEmbarque = async () => {
    dispatch(fetchCostoProveedores(idEmbarque));
  };

  const fetchGuardarCostoProveedorDTO = async (indexCP) => {
    try {
      if (
        Object.keys(controlCostoData.costosProveedores[indexCP].proveedor)
          .length === 0
      ) {
        return ToastWarning("Se necesita el proveedor");
      }

      if (
        controlCostoData.costosProveedores[indexCP].tipoMoneda === "" ||
        controlCostoData.costosProveedores[indexCP].tipoMoneda === null
      ) {
        return ToastWarning("Se necesita el tipo de moneda");
      }

      dispatch(setLoading({ isLoading: true }));
      const res = await guardarCostoProveedorDTO(
        controlCostoData.costosProveedores[indexCP]
      );
      dispatch(setLoading({ isLoading: false }));
      if (res.error) return ToastError(res.message);
      ToastSuccess(res.message);
      fetchListaCostosProveedorPorIdEmbarque();
      recargarEmbarqueData();
    } catch (e) {
      dispatch(setLoading({ isLoading: false }));
      ToastError(e);
    }
  };

  const fetchEliminarCostoPorId = async (idCostoProveedor, indexCP) => {
    try {
      const razonEliminacion =
        controlCostoData.costosProveedores[indexCP].razonEliminacion;
      const res = await eliminadoLogicoCostoPorId(
        idCostoProveedor,
        razonEliminacion
      );
      if (res.error) return ToastError(res.message);
      ToastSuccess(res.message);
      fetchListaCostosProveedorPorIdEmbarque();
      recargarEmbarqueData();
    } catch (e) {
      ToastError(e);
    }
  };

  const handleAgregarCostoProveedor = () => {
    //Este dispatch se establece de esta manera para poder establecer el idEmbarque
    dispatch(
      agregarCostoProveedor({
        idEmbarque: idEmbarque,
        proveedor: {},
        totalProvision: 0,
        totalNetoFacturas: 0,
        tipoMoneda: "",
        esAdicional: true,
        estaEliminado: false,
      })
    );
  };

  const validateDisabledUserSelect = (costoProveedor) => {
    if (!costoProveedor.id) {
      return false;
    }
    return !costoProveedor.esAdicional || (costoProveedor.esAdicional && costoProveedor.typeProvider !== "ADITIONAL");
  };

  return (
    <div>
      <div>
        <div align="right">
          <button
            type="button"
            className="btn btn-dark"
            onClick={() => {
              handleAgregarCostoProveedor();
            }}
          >
            <i className="bi bi-plus-lg"></i> Agregar Costo
          </button>
          <GeneradorFakeXML />
        </div>
        <Table style={{ backgroundColor: "white", minHeight: "200px" }}>
          <thead>
            <tr>
              <th>Proveedor</th>
              <th style={{width: "30px"}}>Días credito</th>
              <th>Tipo de moneda</th>
              <th>Total provision</th>
              <th>Total neto facturas</th>
              <th>Nota a proveedor</th>
              <th>Facturas</th>
              <th>Anticipos</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {controlCostoData.costosProveedores ?
              controlCostoData?.costosProveedores?.map((costoProveedor, indexCP) => {
                return (
                  <tr key={indexCP}>
                    <td>
                      <SelectSearchDB
                        value={costoProveedor.proveedor}
                        index={indexCP}
                        name={"proveedor"}
                        handleChange={onChangeCostosProveedor}
                        fetchSearch={findProveedoresBysearchText}
                        type={"USERS"}
                        isDisabled={validateDisabledUserSelect(costoProveedor)}
                      ></SelectSearchDB>
                    </td>
                    <td align="center">
                      {costoProveedor?.proveedor?.diasDeCredito}
                    </td>
                    <td>
                      <div className="input-group" style={{ minWidth: "150px" }}>
                        <SelectTipoMoneda
                          functionOnChange={onChangeCostosProveedor}
                          valueSelect={
                            costoProveedor.tipoMoneda
                          }
                          dataIndex={indexCP}
                        ></SelectTipoMoneda>
                        {costoProveedor.id && costoProveedor.tipoMoneda == "USD" ?
                          <ToolTipMui
                            title={
                              <div style={{ fontSize: "12px" }}>
                                <b>Valor Dolar: </b> {costoProveedor.valorDolarEnMxn}<br />
                                <b>Fecha del valor: </b> {
                                  moment(costoProveedor.fechaValorDolarEnMxn).format(
                                    "D MMMM YYYY, h:mm:ss a")}<br />
                              </div>}>
                          </ToolTipMui>
                          :
                          ""
                        }
                      </div>
                    </td>
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        name="totalProvision"
                        style={{ minWidth: "130px" }}
                        onChange={(e) => {
                          onChangeCostosProveedor(e, indexCP);
                        }}
                        value={
                          costoProveedor.totalProvision
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        name="totalNetoFacturas"
                        readOnly
                        style={{ minWidth: "130px" }}
                        value={
                          costoProveedor.totalNetoFacturas
                        }
                      />
                    </td>
                    <td>
                      <textarea
                        className="form-control"
                        name="noteToProvider"
                        onChange={(e) => {
                          onChangeCostosProveedor(e, indexCP);
                        }}
                        value={
                          costoProveedor.noteToProvider
                        }
                      />
                    </td>
                    <td align="center">
                      {costoProveedor.id ?
                        <FacturaProveedor
                          idCostoProveedor={
                            costoProveedor.id
                          }
                          nombreProveedor={formatNameUser(costoProveedor?.proveedor)}
                          fetchListaCostos={fetchListaCostosProveedorPorIdEmbarque}
                          recargarEmbarqueData={recargarEmbarqueData}
                          idEmbarque={idEmbarque}
                        ></FacturaProveedor> : ""}
                    </td>
                    <td align="center">
                      {costoProveedor?.id ?
                        <AnticipoCostoProveedor
                          idCostoProveedor={costoProveedor?.id}
                        ></AnticipoCostoProveedor> : ""}
                    </td>
                    <td>

                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          fetchGuardarCostoProveedorDTO(indexCP);
                        }}
                      >
                        {costoProveedor.id
                          ? "Actualizar"
                          : "Guardar"}
                      </button>
                    </td>
                    <td>
                      {

                        //Validar si tiene id para hacer borrado de slice o desde base de datos
                        costoProveedor?.id ? (
                          //Validar si el total neto facturas es mayor a 0.0 para no dejar borrar ya que una factura ya fue subida
                          costoProveedor?.totalNetoFacturas >
                            0 ? (
                            <span className="badge text-bg-danger">
                              No se puede eliminar
                            </span>
                          ) : (
                            <ModalEliminarObjeto
                              textHeader={"¿Seguro de eliminar este costo?"}
                              textBody={"Se recalculara el total de los costos"}
                              functionEliminar={fetchEliminarCostoPorId}
                              index1={indexCP}
                              id={costoProveedor?.id}
                              isDisabled={isDisabledDeleteButton(costoProveedor)}
                            >
                              <textarea
                                className="form-control"
                                placeholder="Escribe la razón por la que eliminas este costo..."
                                name="razonEliminacion"
                                onChange={(e) => {
                                  onChangeCostosProveedor(e, indexCP);
                                }}
                                value={
                                  costoProveedor?.razonEliminacion
                                }
                              ></textarea>
                            </ModalEliminarObjeto>
                          )
                        ) : (
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => {
                              dispatch(eliminarCostoProveedor({ indexCP }));
                            }}
                          >
                            <i className="bi bi-trash"></i>
                          </button>
                        )
                      }
                    </td>
                  </tr>
                )
              }) : "NO SE ENCONTRARON CONCEPTOS"}

          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default CostosProveedores;
