import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate, useLocation } from 'react-router-dom'; // Importa useLocation
import { useDispatch } from 'react-redux';
import { changeVisible, setNavegation } from '../redux/features/navbar/navbarSlice';

function ModalSessionExpired() {
    const [open, setOpen] = useState(false);
    const [cookie, setCookie, removeCookie] = useCookies();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation(); // Obtén la ubicación actual

    useEffect(() => {
        if (location.pathname === '/') {
            return; // No aplicar el comportamiento si está en la página de inicio de sesión
        }

        let timeout;

        const openModal = () => {
            setOpen(true);
            window.removeEventListener('mousemove', resetTimer);
        };

        const startTimer = () => {
            timeout = setTimeout(openModal, 1800000); // 30 minutos
            window.addEventListener('mousemove', resetTimer);
        };

        const resetTimer = () => {
            clearTimeout(timeout);
            startTimer();
        };

        startTimer();

        return () => {
            clearTimeout(timeout);
            window.removeEventListener('mousemove', resetTimer);
        };
    }, [location.pathname]); // Escucha cambios en la ubicación

    const handleClose = () => {
        removeCookie('auth-token', { path: '/' });
        localStorage.removeItem('auth-user');
        dispatch(changeVisible(false));
        dispatch(setNavegation([]));
        navigate('/', { replace: true });
        window.location.reload();
        setOpen(false);
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='card style-modal-ssm'>
                    <div className='card-header d-flex justify-content-between'>
                        ATENCIÓN
                    </div>
                    <div className='card-body' align="center">
                        <h2>TU SESIÓN HA EXPIRADO.</h2>
                        <button className='btn btn-primary' onClick={handleClose}>
                            VOLVER A INICIAR SESIÓN
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default ModalSessionExpired;