import CryptoJS from "crypto-js";
import { ID_ROL_ADMIN, ID_ROL_OPERATIVO } from "./constantes";

export const encryptUser = (user) => {
    try {
        if (user != null)
            return CryptoJS.AES.encrypt(JSON.stringify(user), process.env.REACT_APP_SECRETKEYAES).toString();
    } catch (e) {

    }
}

export const getAuthUser = () => {
    try {
        let authUser = localStorage.getItem('auth-user');
        if (authUser != null) {
            let bytes = CryptoJS.AES.decrypt(authUser, process.env.REACT_APP_SECRETKEYAES);
            let bytesString = bytes.toString(CryptoJS.enc.Utf8);
            let decryptedData = JSON.parse(bytesString);
            if (decryptedData != null && decryptedData != undefined) {
                return decryptedData
            }
        }
        //Redireccionar en caso de que no se encontro al user y que no este en la raiz
        redirectLogin()
        return null
    } catch (error) {
        console.error("Error catch getAuthUser: ", error)
    }
}

export const getAuthRoleUser = () => {
    try {
        const userAuth = getAuthUser()
        return userAuth.role;
    } catch (error) {
    }
}

export const authUserIsAdmin = () => {
    try {
        const userAuth = getAuthUser()
        if (userAuth.role.id == ID_ROL_ADMIN) {
            return true
        }
        return false
    } catch (error) {
        return false
    }
}

export const authUserIsAdminOrOperativo = () => {
    try {
        const userAuth = getAuthUser()
        if (userAuth.role.id == ID_ROL_ADMIN || userAuth.role.id == ID_ROL_OPERATIVO) {
            return true
        }
        return false
    } catch (error) {
        return false
    }
}

export function getAuthIdUser() {
    try {
        const userAuth = getAuthUser()
        return userAuth.id;
    } catch (err) {
    }
}

export const authToken = () => {
    try{
        const authToken = document.cookie.split(';')
        .find(cookie => cookie.trim().startsWith('auth-token='))
        .split('=')[1];
        return authToken;
    }catch(e){
        redirectLogin();
        console.error("Error catch authToken: ", e.message)
    }
}

export const getReferenciaKJ = () => {
    const d = new Date();
    const year = d.getFullYear().toString().slice(-2);
    const referenciaKJ = "KJ" + year;

    return referenciaKJ;
}

const redirectLogin = () =>{
    localStorage.clear();
    const pathname = window.location.pathname;
    if(pathname !== "/" && pathname !== "/no-autorizado" && !pathname.includes("/documentos")){
        window.location.href = "/";
    }
}
