import { ID_ESTATUS_FP_FACTURA_RECHAZADA, ID_ESTATUS_FP_PENDIENTE_APROBACION } from "../../../../utils/constantes"

export function validatePrint (factura, isAdmin){

    if(!factura.id){
        return true
    }

    if(factura.id && factura.estatus?.id == ID_ESTATUS_FP_FACTURA_RECHAZADA){
        return false
    }

    if(factura.guardoProveedor && !isAdmin && factura.estatus?.id == ID_ESTATUS_FP_PENDIENTE_APROBACION){
        return true
    }else if((!factura.guardoProveedor && isAdmin) || (factura.guardoProveedor && isAdmin)){
        return true
    }

    return false
}

export function validatePrintProveedor (factura){

    if(!factura.id){
        return true
    }

    if(factura.id && factura.estatus?.id == ID_ESTATUS_FP_FACTURA_RECHAZADA){
        return false
    }

    if(factura.guardoProveedor && factura.estatus?.id == ID_ESTATUS_FP_PENDIENTE_APROBACION){
        return true
    }

    return false
}

export function validateAddFactura(embarque, isAdmin){
    if(embarque.embarqueFinalizado && !isAdmin){
        return true
    }

    return false;
}

export function validateAddFacturaProveedor(costo , setDisabledButton){
    
    if(costo.embarque.embarqueFinalizado){
        return setDisabledButton({disabled: true, msg: ""})
    }

    if(costo.desgloseEmbarque != null && !costo.desgloseEmbarque?.archivoPOD){
        setDisabledButton({disabled: true, msg: "Necesitas primero cargar el POD para poder agregar tu factura."})
        return true
    }

    if(costo.totalProvision == 0){
        setDisabledButton({disabled: true, msg: "La provisión debe ser mayor a 0, para poder agregar una factura."})
        return true
    }
    
    return false;
}