import { useDispatch } from 'react-redux';
import { actualizarArchivoFacturaCliente, actualizarFacturaDTO, guardarFacturaDTO, logicDeleteFacturaCliente, subirArchivoFacturaCliente } from '../../../../controllers/admin/facturas/facturaClienteController';
import { updateFactura, eliminarFactura } from '../../../../redux/features/admin/controlCostosSlice';
import { fetchEstatusEmbarque } from '../../../../redux/features/admin/estatusEmbarqueSlice';
import { ARCHIVOS_PERMITIDOS, BASE_URL_S3_COMPROBANTES_CLIENTES, BASE_URL_S3_FACTURAS_CLIENTES } from '../../../../utils/constantes';
import { ToastError, ToastSuccess, ToastWarning } from '../../../../utils/toastMessage';
import SelectTipoMoneda from '../../embarques/catalogos-select/SelectTipoMoneda';
import ModalEliminarObjeto from '../../ModalEliminarObjeto';
import { setLoading } from '../../../../redux/features/loaderSlice';
import ModalArchivo from '../../../ModalArchivo';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { sePuedeConvertirAPDF } from '../../../../utils/imgToPdf';
import { generatePdf } from '../../../../utils/imgToPdf';
import SelectSearch from '../../../inputs/SelectSearch'
import { CATALOGO_SAT_FORMA_PAGO, CATALOGO_SAT_METODO_PAGO, CATALOGO_SAT_TIPO_COMPROBANTE_FACTURA, buscarLabel } from '../../../../utils/catalogosEstaticos';
import ProformaFactura from './ProformaFactura';
import GastosCuentaCliente from './GastosCuentaCliente';
import SelectSearchDB from '../../../inputs/SelectSearchDB';
import { findClientesBySearchText } from '../../../../controllers/admin/usuariosController';
import ConceptosFactura from './ConceptosFactura';
import ToolTipMui from '../../../mui/ToolTipMui';
import moment from "moment";
import "moment/locale/es";
import CustomFileUploader from '../../../inputs/CustomFileUploader';


function FacturaCliente({ idEmbarque, indexFC, facturaCliente, recargarEmbarqueData }) {
    const dispatch = useDispatch();

    const onChangeFactura = (event) => {
        const { name, value } = event.target;
        dispatch(updateFactura({ indexFC, name, value }))
    }

    const fetchProcesarFactura = async () => {
        try {
            if (Object.keys(facturaCliente.cliente).length === 0) return ToastWarning("Se necesita seleccionar el cliente de la factura");
            if (facturaCliente.tipoMoneda === "") return ToastWarning("Se necesita el tipo de moneda");
            let res;
            if (facturaCliente.id) {
                res = await actualizarFacturaDTO(facturaCliente);
            } else {
                res = await guardarFacturaDTO(facturaCliente, idEmbarque);
            }
            if (res.error) return ToastError(res.message)
            recargarEmbarqueData()
            ToastSuccess(res.message)
        } catch (e) {
            ToastError(e)
        }
    }

    const validarComprobante = async (archivo) => {
        if (sePuedeConvertirAPDF(archivo)) {
            const archivos = [...archivo]
            return archivo = await generatePdf(archivos)
        }
        return archivo[0]

    }

    const onChangeFiles = async (idFacturaCliente, archivo, tipoArchivo, importarDatos) => {
        dispatch(setLoading({ isLoading: true }))
        try {
            //Solo para el comprobante permitir img
            if (tipoArchivo == "archivoComprobantePagoFactura") {
                archivo = await validarComprobante(archivo);
            }

            let res;
            if (facturaCliente[tipoArchivo] != null) {
                res = await actualizarArchivoFacturaCliente(
                    idFacturaCliente,
                    facturaCliente[tipoArchivo],
                    archivo,
                    tipoArchivo,
                    importarDatos)
            } else {
                res = await subirArchivoFacturaCliente(
                    archivo,
                    idFacturaCliente,
                    tipoArchivo,
                    importarDatos)
            }

            dispatch(setLoading({ isLoading: false }))
            if (res.error) return ToastError(res.message)

            dispatch(updateFactura({ indexFC, name: tipoArchivo, value: res.data }))

            //Si se importan los datos, recargar la data
            if (importarDatos) recargarEmbarqueData()

            //Recarga estatus
            dispatch(fetchEstatusEmbarque(idEmbarque))
            ToastSuccess(res.message)
        } catch (error) {
            dispatch(setLoading({ isLoading: false }))
            return ToastError(error.message)
        }
    }

    const showConfirmationToast = (idFacturaCliente, file, tipoArchivo) => {
        dispatch(setLoading({ isLoading: true }))
        const ConfirmationToast = () => (
            <div>
                <p>¿Quieres importar los datos del XML?</p>
                <p>Se remplazara los datos y los conceptos correspondientes del XML.</p>
                <button onClick={() => handleFileUpload(idFacturaCliente, file, tipoArchivo, false)}
                    className="btn btn-dark mb-1">
                    {facturaCliente.archivoXMLFactura ? "Solo Actualizar" : "Solo Guardar"}
                </button>
                <button onClick={() => handleFileUpload(idFacturaCliente, file, tipoArchivo, true)}
                    className="btn btn-primary">
                    {facturaCliente.archivoXMLFactura ? "Importar y Actualizar" : "Importar y Guardar"}
                </button>
            </div>
        );

        toast.info(<ConfirmationToast />, {
            position: "top-right",
            autoClose: false,
            closeOnClick: false,
            draggable: false,
            closeButton: false,
            toastId: "confirmationToast"
        });
    };

    const handleFileUpload = async (idFacturaCliente, file, tipoArchivo, importarDatos) => {
        // Lógica para subir el archivo
        toast.dismiss("confirmationToast");
        await onChangeFiles(idFacturaCliente, file, tipoArchivo, importarDatos)
        dispatch(setLoading({ isLoading: false }))
    };

    const fetchLogicDelete = async (idFacturaCliente, index) => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const res = await logicDeleteFacturaCliente(idFacturaCliente, facturaCliente.razonEliminacion)
            dispatch(setLoading({ isLoading: false }))
            if (res.error) return ToastError(res.message)
            dispatch(eliminarFactura({ indexFC }))
            recargarEmbarqueData()
            return ToastSuccess(res.message)
        } catch (err) {
            return ToastError(err.message)
        }
    }


    return (
        <div>
            <div className='row'>
                <div className="col-sm-4 mb-3">
                    {facturaCliente.esComplementaria ?
                        <>
                            <SelectSearchDB
                                titulo={"Cliente"}
                                value={facturaCliente.cliente}
                                name={'cliente'}
                                handleChange={onChangeFactura}
                                fetchSearch={findClientesBySearchText}
                                type={"USERS"}></SelectSearchDB>
                        </>

                        :
                        <>
                            <label htmlFor="dimensionVolumen" className="form-label">Cliente</label>
                            <input
                                type="text"
                                disabled
                                readOnly
                                className="form-control"
                                value={
                                    facturaCliente.cliente.nombreComercial ? facturaCliente.cliente.nombreComercial :
                                        facturaCliente.cliente.nombre + " "
                                        + facturaCliente.cliente.primerApellido + " "
                                        + facturaCliente.cliente.segundoApellido + " "
                                } />
                        </>
                    }
                </div>
                <div className="col-sm-4 mb-3">
                    <label htmlFor="dimensionVolumen" className="form-label">Días de credito</label>
                    <input
                        type="number"
                        disabled
                        readOnly
                        className="form-control"
                        value={
                            facturaCliente.cliente.diasDeCredito
                        } />
                </div>
                <div className='col-sm-4 mb-3'>
                    <label htmlFor="dimensionVolumen" className="form-label">Tipo Moneda</label>
                    {facturaCliente.id && facturaCliente.tipoMoneda == "USD" ?
                        <ToolTipMui
                            title={
                                <div style={{ fontSize: "12px" }}>
                                    <b>Valor Dolar: </b> {facturaCliente.valorDolarEnMxn}<br />
                                    <b>Fecha del valor: </b> {
                                        moment(facturaCliente.fechaValorDolarEnMxn).format(
                                            "D MMMM YYYY, h:mm:ss a")}<br />
                                </div>}>
                        </ToolTipMui>
                        :
                        ""
                    }
                    <SelectTipoMoneda
                        functionOnChange={onChangeFactura}
                        valueSelect={facturaCliente.tipoMoneda}></SelectTipoMoneda>
                </div>
                <div className='col-12'></div>
                <div className='col-sm-4'>
                    <SelectSearch
                        value={facturaCliente.formaPago}
                        data={CATALOGO_SAT_FORMA_PAGO}
                        titulo={"Forma de Pago"}
                        name={"formaPago"}
                        handleChange={onChangeFactura}></SelectSearch>
                </div>
                <div className='col-sm-4 col-m'>
                    <SelectSearch
                        value={facturaCliente.tipoDeComprobante}
                        data={CATALOGO_SAT_TIPO_COMPROBANTE_FACTURA}
                        titulo={"Tipo de Comprobante"}
                        name={"tipoDeComprobante"}
                        handleChange={onChangeFactura}></SelectSearch>
                </div>
                <div className='col-sm-4'>
                    <label htmlFor="metodoPago" className="form-label">Metodo de Pago</label>
                    <input
                        type="text"
                        disabled
                        readOnly
                        className="form-control"
                        value={
                            buscarLabel(facturaCliente.cliente.metodoDePago, CATALOGO_SAT_METODO_PAGO)
                        } />
                </div>
                <div className='col-sm-4'>
                    <label className="form-label">Serie Folio</label>
                    <input
                        type="text"
                        disabled
                        readOnly
                        className="form-control"
                        value={facturaCliente.serieFolio} />
                </div>
                <div className='col-sm-4'>
                    <label className="form-label">Anticipo</label>
                    <input
                        type="number"
                        className="form-control"
                        name={"anticipo"}
                        value={facturaCliente.anticipo}
                        onChange={onChangeFactura} />
                </div>
                <div className='col-12' align="right">
                    {facturaCliente.id ?
                        <>
                            <ProformaFactura
                                factura={facturaCliente} />
                            <GastosCuentaCliente
                                facturaCliente={facturaCliente}></GastosCuentaCliente>
                        </>
                        :
                        ""
                    }
                    <br />
                    {facturaCliente.esComplementaria ?
                        facturaCliente.id ?
                            <ModalEliminarObjeto
                                textHeader={"¿Seguro de eliminar esta factura?"}
                                textBody={""}
                                functionEliminar={fetchLogicDelete}
                                id={facturaCliente.id}
                                index1={indexFC}>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    placeholder='Razón de la eliminación'
                                    name='razonEliminacion'
                                    value={facturaCliente.razonEliminacion}
                                    onChange={(e) => { onChangeFactura(e) }} />
                            </ModalEliminarObjeto>
                            :
                            <button type='button' className='btn btn-danger'
                                onClick={() => { dispatch(eliminarFactura({ indexFC })) }}>
                                <i className="bi bi-trash"></i>
                            </button>
                        :
                        ""
                    }
                    <button type='button' className='btn btn-primary m-2'
                        onClick={() => { fetchProcesarFactura() }}>
                        {facturaCliente.id ? "Actualizar" : "Guardar"}
                    </button>
                </div>

            </div>
            {facturaCliente.id ?
                <>
                    <ConceptosFactura
                        conceptos={facturaCliente.conceptosFactura}
                        indexFC={indexFC}
                        idFacturaCliente={facturaCliente.id}
                        recargarEmbarqueData={recargarEmbarqueData}
                        facturaCliente={facturaCliente}></ConceptosFactura>
                    <div className='col-12 mt-4'>
                        <div className='col-sm-12'>

                            <div className='row mb-2'>
                                <div className='col-sm-2'>
                                    <label>Factura PDF</label>
                                </div>
                                <div className='col-sm-3'>
                                    <CustomFileUploader
                                        handleChangeFile={onChangeFiles}
                                        index={facturaCliente.id}
                                        fileTypes={["PDF"]}
                                        name={"archivoFactura"}
                                    ></CustomFileUploader>
                                </div>
                                <div className='col-sm-3'>
                                    {facturaCliente.archivoFactura != null ?
                                        <ModalArchivo
                                            title={"Factura del cliente"}
                                            urlBase={BASE_URL_S3_FACTURAS_CLIENTES}
                                            archivoS3={facturaCliente.archivoFactura}></ModalArchivo>
                                        :
                                        <></>
                                    }
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-sm-2'>
                                    <label>Factura XML</label>
                                </div>
                                <div className='col-sm-3'>
                                    <CustomFileUploader
                                        handleChangeFile={showConfirmationToast}
                                        index={facturaCliente.id}
                                        fileTypes={["XML"]}
                                        name={"archivoXMLFactura"}
                                    ></CustomFileUploader>
                                </div>
                                <div className='col-sm-3'>
                                    {facturaCliente.archivoXMLFactura != null ?
                                        <button
                                            type='button'
                                            className='btn btn-dark ms-1 me-1'
                                            onClick={() => saveAs(BASE_URL_S3_FACTURAS_CLIENTES + facturaCliente.archivoXMLFactura.keyS3, facturaCliente.archivoXMLFactura.keyS3)}>
                                            <i className="bi bi-file-earmark-arrow-down"></i>
                                        </button>
                                        :
                                        <></>
                                    }
                                </div>

                            </div>
                            <div className='row'>
                                <div className='col-sm-2'>
                                    <label>Comprobante de Pago</label>
                                </div>
                                <div className='col-sm-3'>
                                    <CustomFileUploader
                                        handleChangeFile={onChangeFiles}
                                        index={facturaCliente.id}
                                        fileTypes={ARCHIVOS_PERMITIDOS}
                                        multiple={true}
                                        name={"archivoComprobantePagoFactura"}
                                    ></CustomFileUploader>
                                </div>
                                <div className='col-sm-3'>
                                    {facturaCliente.archivoComprobantePagoFactura != null ?
                                        <ModalArchivo
                                            title={"Comprobante de Pago del Cliente "}
                                            urlBase={BASE_URL_S3_COMPROBANTES_CLIENTES}
                                            archivoS3={facturaCliente.archivoComprobantePagoFactura}></ModalArchivo>
                                        :
                                        <></>
                                    }
                                </div>

                            </div>


                        </div>
                    </div>
                </>
                : ""}
        </div>
    )
}

export default FacturaCliente