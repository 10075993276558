import React, { useEffect, useState } from 'react'
import MUIDataTable from 'mui-datatables'
import { BASIC_OPTIONS_MUI, OPTIONS_CUSTOM_RETURN_COLUMN } from '../../../utils/mui/constantes'
import ModalFormUsuarios from '../ModalFormUsuarios';
import { ID_ROL_LOGISTICA } from '../../../utils/constantes';
import ModalAdminEmails from '../ModalAdminEmails';


function TableLogistica({ logisticas, fetchDataUsuarios }) {
  const [formatData, setFormatData] = useState([])

  useEffect(() => {
    setFormatData(
      logisticas.map(logistica => [
        logistica.id,
        logistica.nombreComercial,
        logistica.razonSocial,
        logistica.rfc,
        logistica.diasDeCredito,
        logistica.creadoEn
      ])
    );
  }, [logisticas]);

  const columns = [
    {
      name: "Id",
      options: {
        display: "excluded",
        filter: false,
        sort: false,
        download: false,
      }
    },
    "Nombre",
    "Razón Social",
    "RFC",
    "Días de credito",
    "Fecha Creación",
    {
      name: "CORREOS ELECTRÓNICOS",
      options: {
        ...OPTIONS_CUSTOM_RETURN_COLUMN,
        customBodyRenderLite: (dataIndex) => {
          return (
            <ModalAdminEmails
              usuario={logisticas[dataIndex]}></ModalAdminEmails>
          )
        },
      }
    },
    {
      name: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ModalFormUsuarios
              url={"/guardar-cliente"}
              idUsuario={tableMeta.rowData[0]}
              idRol={ID_ROL_LOGISTICA}
              fetchDataUsuarios={fetchDataUsuarios}>
              <i className="bi bi-pencil"></i>
            </ModalFormUsuarios>
          )
        },
        ...OPTIONS_CUSTOM_RETURN_COLUMN
      },
    },
  ]

  return (
    <MUIDataTable
      title={"Logística"}
      data={formatData}
      columns={columns}
      options={{
        ...BASIC_OPTIONS_MUI,
        responsive: "standard"
      }}
    />


  )
}

export default TableLogistica
