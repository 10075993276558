import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    roleUser: {}
}


export const userAuthSlice = createSlice({
    name: 'userAuth',
    initialState,
    reducers: {
        setRole: (state, action) =>{
            state.roleUser = action.payload
        },
    }
});

export const {setRole} = userAuthSlice.actions;

export default userAuthSlice.reducer;