import { visibilityLoader } from "../utils/loader";
import { ToastError, ToastSuccess } from "../utils/toastMessage";
import { apiRequest } from "./handlerApiRequest"

export const apiRequestV2 = async (objectRequest) => {

    if(typeof objectRequest != "object") ToastError("DEVELOPER, THIS PARAM IS NOT A OBJECT.");

    const loader = document.getElementById("loader-v2");

    const { endpoint,
        method = 'GET',
        data = null,
        urlParams = '',
        isFormData,
        showSuccessMessage = false,
        noShowLoader } = objectRequest;
    
    try{
        visibilityLoader(true);
        const res = await apiRequest(endpoint, method, data, urlParams, isFormData);
        visibilityLoader(false);
        if(res.error) ToastError(res.message);
        if(showSuccessMessage && !res.error) ToastSuccess(res.message);
        //Si sale error enviar la data como null, y verificar que si no es error y la data es null
        //enviar el objeto vacio
        return res.error ? null : !res.data && !res.error ? {} : res.data ;
    }catch(err){
        visibilityLoader(false);
        console.error("ERROR FETCH V2", err.message);
        ToastError(err.message);
    }

}