export const CATALOGO_ADVANCED_SEARCH = [
    {header: "DATOS EMBARQUE", atributeValue: "embarque.labelEmbarque", typeAttribute: "LABEL", avoid: false},
    {header: "Referencia", atributeValue: "embarque.referenciaKJ", typeAttribute: "STRING", avoid: false},
    {header: "Fecha Creación", atributeValue: "embarque.creadoEn", typeAttribute: "DATE", avoid: false},
    {header: "Cliente", atributeValue: "embarque.cliente.nombreComercial", typeAttribute: "STRING", avoid: false},
    {header: "Origen", atributeValue: "embarque.origen.nombre", typeAttribute: "STRING", avoid: false},
    {header: "Destino", atributeValue: "embarque.destino.nombre", typeAttribute: "STRING", avoid: false},
    {header: "Servicio", atributeValue: "embarque.tipoServicio.nombre", typeAttribute: "STRING", avoid: false},
    {header: "Trafico", atributeValue: "embarque.tipoTrafico.nombre", typeAttribute: "STRING", avoid: false},
    {header: "Estatus", atributeValue: "embarque.estatusNoCompletado.estatus", typeAttribute: "STRING", avoid: false},
    {header: "Usuario", atributeValue: "embarque.usuarioRegistro", typeAttribute: "USER", avoid: false},
    {header: "Serie Folio", atributeValue: "embarque.serieFolio", typeAttribute: "STRING", avoid: false},
    {header: "Venta", atributeValue: "embarque.totalVenta", typeAttribute: "MONEY", avoid: false},
    {header: "Costo", atributeValue: "embarque.totalCostos", typeAttribute: "MONEY", avoid: false},
    {header: "Profit", atributeValue: "embarque.profit", typeAttribute: "MONEY", avoid: false},
    {header: "No. de Pedimentos", atributeValue: "embarque.noDePedimentos", typeAttribute: "STRING", avoid: false},
    {header: "Fecha Pago Pedimentos", atributeValue: "embarque.fechaPagoPedimentos", typeAttribute: "STRING", avoid: false},
    {header: "Despacho Aduanal", atributeValue: "embarque.haceDespachoAduanal", typeAttribute: "STRING", avoid: false},

    {header: "DATOS AEREOS", atributeValue: "embarque.labelEmbarqueAereo", typeAttribute: "LABEL", avoid: false},
    {header: "Servicio Aereo", atributeValue: "embarque.datosEmbarqueAereo.servicioAereo.nombre", typeAttribute: "STRING", avoid: false},
    {header: "No. de Vuelo", atributeValue: "embarque.datosEmbarqueAereo.noDeVuelo", typeAttribute: "STRING", avoid: false},
    {header: "Aerolinea", atributeValue: "embarque.datosEmbarqueAereo.catalogoAerolinea.nombre", typeAttribute: "STRING", avoid: false},
    {header: "No. de Booking", atributeValue: "embarque.datosEmbarqueAereo.noDeBooking", typeAttribute: "STRING", avoid: false},
    {header: "No. de Guia", atributeValue: "embarque.datosEmbarqueAereo.noDeGuia", typeAttribute: "STRING", avoid: false},
    {header: "Cierre Documental", atributeValue: "embarque.datosEmbarqueAereo.fechaCierreDocumental", typeAttribute: "DATE", avoid: false},
    {header: "Cierre Fisico", atributeValue: "embarque.datosEmbarqueAereo.fechaCierreFisico", typeAttribute: "DATE", avoid: false},
    {header: "ETD", atributeValue: "embarque.datosEmbarqueAereo.fechaETD", typeAttribute: "DATE", avoid: false},
    {header: "ETA", atributeValue: "embarque.datosEmbarqueAereo.fechaETA", typeAttribute: "DATE", avoid: false},
    {header: "ATD", atributeValue: "embarque.datosEmbarqueAereo.fechaATD", typeAttribute: "DATE", avoid: false},
    {header: "ATA", atributeValue: "embarque.datosEmbarqueAereo.fechaATA", typeAttribute: "DATE", avoid: false},
    {header: "Aeropuerto de Carga", atributeValue: "embarque.datosEmbarqueAereo.aeropuertoDeCarga", typeAttribute: "STRING", avoid: false},
    {header: "Aeropuerto Descarga", atributeValue: "embarque.datosEmbarqueAereo.aeropuertoDescarga", typeAttribute: "STRING", avoid: false},
    {header: "Instrucciones Especiales", atributeValue: "embarque.datosEmbarqueAereo.instruccionesEspeciales", typeAttribute: "STRING", avoid: false},

    {header: "DATOS MARITIMOS", atributeValue: "embarque.labelEmbarqueMaritimo", typeAttribute: "LABEL", avoid: false},
    {header: "Servicio Maritimo", atributeValue: "embarque.datosEmbarqueMaritimo.servicioMaritimo.nombre", typeAttribute: "STRING", avoid: false},
    {header: "Modalidad Maritima", atributeValue: "embarque.datosEmbarqueMaritimo.modalidadMaritima", typeAttribute: "STRING", avoid: false},
    {header: "Buque", atributeValue: "embarque.datosEmbarqueMaritimo.buque", typeAttribute: "STRING", avoid: false},
    {header: "Viaje", atributeValue: "embarque.datosEmbarqueMaritimo.viaje", typeAttribute: "STRING", avoid: false},
    {header: "Naviera", atributeValue: "embarque.datosEmbarqueMaritimo.naviera.nombre", typeAttribute: "STRING", avoid: false},
    {header: "No. de Booking", atributeValue: "embarque.datosEmbarqueMaritimo.noDeBooking", typeAttribute: "STRING", avoid: false},
    {header: "No. de BL", atributeValue: "embarque.datosEmbarqueMaritimo.noDeBL", typeAttribute: "STRING", avoid: false},
    {header: "Cierre Documental", atributeValue: "embarque.datosEmbarqueMaritimo.fechaCierreDocumental", typeAttribute: "DATE", avoid: false},
    {header: "Cierre Fisico", atributeValue: "embarque.datosEmbarqueMaritimo.fechaCierreFisico", typeAttribute: "DATE", avoid: false},
    {header: "ETD", atributeValue: "embarque.datosEmbarqueMaritimo.fechaETD", typeAttribute: "DATE", avoid: false},
    {header: "ETA", atributeValue: "embarque.datosEmbarqueMaritimo.fechaETA", typeAttribute: "DATE", avoid: false},
    {header: "ATD", atributeValue: "embarque.datosEmbarqueMaritimo.fechaATD", typeAttribute: "DATE", avoid: false},
    {header: "ATA", atributeValue: "embarque.datosEmbarqueMaritimo.fechaATA", typeAttribute: "DATE", avoid: false},
    {header: "Puerto de Carga", atributeValue: "embarque.datosEmbarqueMaritimo.puertoDeCarga", typeAttribute: "STRING", avoid: false},
    {header: "Puerto Descarga", atributeValue: "embarque.datosEmbarqueMaritimo.puertoDescarga", typeAttribute: "STRING", avoid: false},
    {header: "Instrucciones Especiales", atributeValue: "embarque.datosEmbarqueMaritimo.instruccionesEspeciales", typeAttribute: "STRING", avoid: false},

    {header: "DESGLOSES", atributeValue: "desglosesEmbarque", typeAttribute: "LABEL", avoid: false},
    {header: "Proveedor", atributeValue: "proveedor.nombreComercial", typeAttribute: "STRING", avoid: false},
    {header: "Fecha Estimada Recolección", atributeValue: "fechaEstimadaRecoleccion", typeAttribute: "DATE", avoid: false},
    {header: "Fecha Efectiva Recolección", atributeValue: "fechaEfectivaRecoleccion", typeAttribute: "DATE", avoid: false},
    {header: "Fecha Estimada Entrega", atributeValue: "fechaEstimadaEntrega", typeAttribute: "DATE", avoid: false},
    {header: "Fecha Efectiva Entrega", atributeValue: "fechaEfectivaEntrega", typeAttribute: "DATE", avoid: false},
    {header: "Dirección Recolección", atributeValue: "direccionRecoleccion.direccion", typeAttribute: "STRING", avoid: false},
    {header: "Dirección Entrega", atributeValue: "direccionEntrega.direccion", typeAttribute: "STRING", avoid: false},
    {header: "Tipo Unidad", atributeValue: "tipoUnidad.nombre", typeAttribute: "STRING", avoid: false},
    {header: "Cantidad Unidad", atributeValue: "cantidadUnidad", typeAttribute: "NUMBER", avoid: false},
    {header: "Modalidad Unidad", atributeValue: "modalidadUnidad", typeAttribute: "STRING", avoid: false},
    {header: "Mercancia Trasladar", atributeValue: "mercanciaTrasladar", typeAttribute: "STRING", avoid: false},
    {header: "Tipo Carga", atributeValue: "catalogoTipoCarga.nombre", typeAttribute: "STRING", avoid: false},
    {header: "Flete Principal", atributeValue: "esFletePrincipal", typeAttribute: "BOOLEAN", avoid: false},
    {header: "Servicio", atributeValue: "tipoServicio.nombre", typeAttribute: "STRING", avoid: false},
    {header: "Total Bultos", atributeValue: "cantidadTotalBultos", typeAttribute: "NUMBER", avoid: false},
    {header: "Peso Total Bruto", atributeValue: "pesoTotalBruto", typeAttribute: "NUMBER", avoid: false},
    {header: "Unidad de Medida", atributeValue: "unidadDeMedida", typeAttribute: "STRING", avoid: false},
    {header: "Instrucciones Especiales", atributeValue: "instruccionesEspeciales", typeAttribute: "STRING", avoid: false},
    {header: "IDs Carga", atributeValue: "idsCarga", typeAttribute: "STRING", avoid: false},
    {header: "DESGLOSE DATOS AEREO", atributeValue: "EmbarqueAereo", typeAttribute: "LABEL", avoid: false},
    {header: "Aduana Ingreso", atributeValue: "datosDesgloseAereo.aduanaIngreso", typeAttribute: "STRING", avoid: false},
    {header: "Fecha Ingreso Aduana", atributeValue: "datosDesgloseAereo.fechaIngresoAduana", typeAttribute: "DATE", avoid: false},
    {header: "Aeropuerto Entrega", atributeValue: "datosDesgloseAereo.aeropuertoEntrega", typeAttribute: "STRING", avoid: false},
    {header: "Fecha Estimada Entrega Aeropuerto", atributeValue: "datosDesgloseAereo.fechaEstimadaEntregaAeropuerto", typeAttribute: "DATE", avoid: false},
    {header: "Fecha Efectiva Entrega Aeropuerto", atributeValue: "datosDesgloseAereo.fechaEfectivaEntregaAeropuerto", typeAttribute: "DATE", avoid: false},
    {header: "Fecha Revalidacion (Aéreo)", atributeValue: "datosDesgloseAereo.fechaRevalidacion", typeAttribute: "DATE", avoid: false},
    {header: "DESGLOSE DATOS MARITIMO", atributeValue: "EmbarqueMaritimo", typeAttribute: "LABEL", avoid: false},
    {header: "Proveedor Destino Final", atributeValue: "datosDesgloseMaritimo.proveedorDestinoFinal", typeAttribute: "STRING", avoid: false},
    {header: "Dirección Descarga Destino Final", atributeValue: "datosDesgloseMaritimo.direccionDescargaDestinoFinal", typeAttribute: "STRING", avoid: false},
    {header: "Fecha Entrega Efectiva Destino Final", atributeValue: "datosDesgloseMaritimo.fechaEntregaEfectivaDestinoFinal", typeAttribute: "DATE", avoid: false},
    {header: "Fecha Toma Vacio", atributeValue: "datosDesgloseMaritimo.fechaTomaVacio", typeAttribute: "DATE", avoid: false},
    {header: "Fecha Revalidacion (Marítimo)", atributeValue: "datosDesgloseMaritimo.fechaRevalidacion", typeAttribute: "DATE", avoid: false},
    {header: "Fecha Ingreso Mercancía", atributeValue: "datosDesgloseMaritimo.fechaIngresoMercancia", typeAttribute: "DATE", avoid: false},
    {header: "Detalles Carga Marítimo", atributeValue: "datosDesgloseMaritimo.detallesCargaMaritimo", typeAttribute: "ARRAY", 
        catalogue: [
            {header: "Contenedor", atributeValue: "contenedorMaritimo.nombre", typeAttribute: "STRING"},
            {header: "Número de Contenedor", atributeValue: "noDeContenedor", typeAttribute: "STRING"},
            {header: "Peso Bruto", atributeValue: "pesoBruto", typeAttribute: "NUMBER"},
            {header: "Volumen", atributeValue: "volumen", typeAttribute: "NUMBER"},
            {header: "Sello", atributeValue: "sello", typeAttribute: "STRING"},
            {header: "Modalidad", atributeValue: "modalidad", typeAttribute: "STRING"},
            {header: "Mercancia a Trasladar", atributeValue: "mercanciaTrasladar", typeAttribute: "STRING"},
            {header: "Tipo de Carga", atributeValue: "catalogoTipoCarga.nombre", typeAttribute: "STRING"},
            {header: "Fecha de Retorno Vacíos", atributeValue: "fechaRetornoVacios", typeAttribute: "DATE"},
            {header: "Cantidad Total de Bultos", atributeValue: "cantidadTotalBultos", typeAttribute: "NUMBER"},
            {header: "Unidad de Medida", atributeValue: "unidadDeMedida", typeAttribute: "STRING"}
        ]
    }
];
