import React from 'react'
import { ID_ESTATUS_FP_COMPLETADO, ID_ESTATUS_FP_FACTURA_RECHAZADA } from '../../../../utils/constantes'
import Tooltip from '@mui/material/Tooltip';

function TooltipEstatusFacturaProveedor({ facturaProveedor }) {
    return (
        <>
            {facturaProveedor.estatus?.id == ID_ESTATUS_FP_FACTURA_RECHAZADA ?
                <Tooltip title={facturaProveedor.razonEliminacion}
                    placement="top-start">
                    <span className="badge text-bg-danger">
                        Factura Rechazada
                        <i className="bi bi-info-circle-fill"></i>
                    </span>
                </Tooltip>
                :
                facturaProveedor.estaEliminado ?
                    <Tooltip title={facturaProveedor.razonEliminacion}
                        placement="top-start">
                        <span className="badge text-bg-danger">
                            Factura Eliminada
                            <i className="bi bi-info-circle-fill"></i>
                        </span>
                    </Tooltip>
                    :
                    facturaProveedor.estatus && facturaProveedor.estatus?.id === ID_ESTATUS_FP_COMPLETADO
                        ?
                        <span className="badge text-bg-success">{facturaProveedor.estatus?.estatus}</span>
                        :
                        <span className="badge text-bg-warning">{facturaProveedor.estatus?.estatus}</span>
            }
        </>
    )
}

export default TooltipEstatusFacturaProveedor