import { ErrorMessage, Field, Form, Formik } from 'formik';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import * as Yup from 'yup';
import { ID_ROL_ADMIN, ID_ROL_OPERATIVO } from '../../../utils/constantes';
import FormikSelectSearch from '../../formik/FormikSelectSearch';
import ModalAdminEmails from '../ModalAdminEmails';
import { actualizarUsuario, guardarAdmin } from '../../../controllers/admin/usuariosController';
import UpdatePasswordModal from '../ChangePasswordModal';

function ModalFormUsuarios({ usuario, buttonTittle, fetchDataUsuarios }) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true)
    };
    const handleClose = () => {
        setOpen(false);
    }


    return (
        <div>
            <button className='btn btn-primary mb-1' onClick={handleOpen}>
                {buttonTittle}
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='card style-modal-sm'>
                    <div className='card-header d-flex justify-content-between'>
                        {usuario?.id ? "ACTUALIZAR USUARIO" : "REGISTRAR USUARIO"}
                        <button className='btn btn-dark btn-sm'
                            onClick={handleClose}>
                            <i className="bi bi-x-lg"></i>
                        </button>
                    </div>
                    <div className='card-body body-modal-scroll'>
                        <Formik
                            initialValues={usuario}
                            onSubmit={async (usuario, { resetForm }) => {
                                let data = usuario?.id ? await actualizarUsuario(usuario) : await guardarAdmin(usuario);
                                if (data) {
                                    fetchDataUsuarios();
                                    handleClose();
                                }
                            }}
                            enableReinitialize
                            validationSchema={Yup.object({
                                role: Yup.mixed().nullable().required('El rol es requerido'),
                                nombre: Yup.string()
                                    .required('El nombre es requerido'),
                                password: Yup.string()
                                    .required('La contraseña es requerida'),
                            })}
                        >
                            <Form>
                                <div className='row'>
                                    <div className="col-sm-4 mb-3">
                                        <label htmlFor="nombre" className="form-label">Nombre</label>
                                        <Field id="nombre" name="nombre" className="form-control" />
                                        <ErrorMessage name='nombre' component='span' className='text-danger'></ErrorMessage>
                                    </div>
                                    <div className="col-sm-4 mb-3">
                                        <label htmlFor="primerApellido" className="form-label">Primer Apellido</label>
                                        <Field id="primerApellido" name="primerApellido" className="form-control" />
                                    </div>
                                    <div className="col-sm-4 mb-3">
                                        <label htmlFor="segundoApellido" className="form-label">Segundo Apellido</label>
                                        <Field id="segundoApellido" name="segundoApellido" className="form-control" />
                                    </div>
                                    <div className="col-sm-4 mb-3">
                                        <label htmlFor="inputPassword" className="form-label">Contraseña</label>
                                        <div className="input-group mb-3">
                                            <Field id="password" name="password" className="form-control" type="password" readOnly={usuario?.id} />
                                            {usuario.id ?
                                                <UpdatePasswordModal
                                                    idUsuario={usuario?.id}></UpdatePasswordModal>
                                                : ""}
                                        </div>
                                        <ErrorMessage name='password' component='span' className='text-danger'></ErrorMessage>
                                    </div>

                                    <div className="col-sm-4 mb-3">
                                        <label htmlFor="phoneNumber" className="form-label">Número de teléfono</label>
                                        <Field id="phoneNumber" name="phoneNumber" className="form-control" />
                                    </div>
                                    <div className='col-sm-4 mb-3'>
                                        <div className="form-check form-switch">
                                            <Field type="checkbox" name="enable" className="form-check-input" role="switch" />
                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Permitir acceso</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 mb-3">
                                        <Field
                                            name="role"
                                            component={FormikSelectSearch}
                                            data={[{ label: "ADMIN", value: { id: ID_ROL_ADMIN } }, { label: "OPERATIVO", value: { id: ID_ROL_OPERATIVO } }]}
                                            titulo={"Rol"}
                                            isDisabled={false} />
                                        <ErrorMessage name='role' component='span' className='text-danger'></ErrorMessage>
                                    </div>
                                    {usuario?.id ?
                                        <div className="col-sm-4 mb-3">
                                            <label htmlFor="inputEmail" className="form-label">Correo Electrónico</label>
                                            <br />
                                            <ModalAdminEmails
                                                usuario={usuario}></ModalAdminEmails>
                                        </div>
                                        :
                                        <></>
                                    }
                                    <div className='col-sm-12' align="right">
                                        <button type="submit" className='btn btn-primary'>{usuario?.id ? "ACTUALIZAR" : "GUARDAR"}</button>
                                    </div>

                                </div>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ModalFormUsuarios