import * as ExcelJS from 'exceljs';
import { downloadExcel } from '../../../../utils/helpersExcel';
import { formatMoney } from '../../../../utils/formatMoney';
import { getHeaderReport } from '../../../../utils/reports/headerReports';
import moment from 'moment-timezone';
import { formatNameUser } from '../../../../utils/userFormat';

// Función para obtener valores anidados de forma segura
const getNestedValue = (obj, path) => {
    if (!path || !obj) return null;
    return path.split('.').reduce((value, key) => value && value[key] !== undefined ? value[key] : null, obj);
};

export async function generarReporteEmbarque(embarques, CATALOGO_ADVANCED_SEARCH) {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Embarques');

    await getHeaderReport(sheet, workbook);

    // Establecer estilos para bordes de encabezados
    const headerBorder = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };

    // Filtrar encabezados del catálogo principal
    const headers = CATALOGO_ADVANCED_SEARCH
        .filter(item => !item.avoid && item.typeAttribute !== 'LABEL')
        .map(item => item.header);

    sheet.getRow(7).values = headers;

    // Aplicar bordes a los encabezados principales
    sheet.getRow(7).eachCell((cell) => {
        cell.border = headerBorder;
    });

    const columnWidths = headers.map(() => ({ width: 15 }));
    sheet.columns = columnWidths;

    // Procesar cada embarque
    embarques.forEach(embarque => {
        // Extraer valores del embarque y filtrar items con avoid true y LABEL
        const rowValues = CATALOGO_ADVANCED_SEARCH
            .filter(item => !item.avoid && item.typeAttribute !== 'LABEL')
            .map(item => {
                let value = getNestedValue(embarque, item.atributeValue);
                return validateValue(item, value);
            });

        sheet.addRow(rowValues);
    });

    const buffer = await workbook.xlsx.writeBuffer();
    downloadExcel(buffer, `ReporteEmbarque-${new Date().toISOString().split('T')[0]}.xlsx`);
}

const validateValue = (item, value) => {
    if (item.typeAttribute === 'DATE' && value) {
        return moment(value).format('DD/MM/YYYY');
    } else if (item.typeAttribute === 'USER' && value) {
        return formatNameUser(value);
    } else if (item.typeAttribute === 'MONEY' && value) {
        return formatMoney(value);
    } else if (item.typeAttribute === 'BOOLEAN') {
        return value ? 'Sí' : 'No';
    } else if (item.typeAttribute === 'NUMBER') {
        return value || 0;
    } else if (item.typeAttribute === 'ARRAY' && value) {
        return value.map((detalle, index) => {
            const detalleStrings = item.catalogue.map(subItem => {
                const subValue = getNestedValue(detalle, subItem.atributeValue);
                const validatedSubValue = validateValue(subItem, subValue);
                return `${subItem.header}: ${validatedSubValue}`;
            });
            return `Contenedor ${index + 1}: ${detalleStrings.join(', ')}`;
        }).join(', ');
    }
    return value;
};