import React, { useEffect, useState } from 'react'
import MUIDataTable from "mui-datatables";
import ModalFormUsuarios from '../ModalFormUsuarios';
import { ID_ROL_CLIENTES } from '../../../utils/constantes';
import { CATALOGO_SAT_METODO_PAGO, CATALOGO_SAT_USO_CFDI, buscarLabel } from '../../../utils/catalogosEstaticos';
import { BASIC_OPTIONS_MUI, OPTIONS_CUSTOM_RETURN_COLUMN } from '../../../utils/mui/constantes';
import ModalAdminEmails from '../ModalAdminEmails';

function TableClientes({ clientes, fetchDataUsuarios}) {
  const [formatData, setFormatData] = useState([])

  useEffect(() => {
    setFormatData(
      clientes.map(cliente => [
        cliente.id,
        cliente.nombreComercial,
        cliente.razonSocial,
        cliente.direccionFiscal ? cliente.direccionFiscal?.direccionFiscal : "",
        cliente.rfc,
        cliente.diasDeCredito,
        cliente.metodoDePago = buscarLabel(cliente.metodoDePago, CATALOGO_SAT_METODO_PAGO),
        cliente.usoDeCFDI = buscarLabel(cliente.usoDeCFDI, CATALOGO_SAT_USO_CFDI),
        cliente.creadoEn
      ])
    );
  }, [clientes]);

  const columns = [
    {
      name: "Id",
      options: {
        display: "excluded",
        filter: false,
        sort: false,
        download: false,
      }
    },
    "Nombre",
    "Razón Social",
    "Dirección Fiscal",
    "RFC",
    "Días de credito",
    "Metodo de Pago",
    "Uso de CFDI",
    "Fecha Creación",
    {
      name: "CORREOS ELECTRÓNICOS",
      options: {
        ...OPTIONS_CUSTOM_RETURN_COLUMN,
        customBodyRenderLite: (dataIndex) => {
          return (
            <ModalAdminEmails
              usuario={clientes[dataIndex]}></ModalAdminEmails>
          )
        },
      }
    },
    {
      name: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ModalFormUsuarios
              url={"/guardar-cliente"}
              idUsuario={tableMeta.rowData[0]}
              fetchDataUsuarios={fetchDataUsuarios}
              idRol={ID_ROL_CLIENTES}>
              <i className="bi bi-pencil"></i>
            </ModalFormUsuarios>
          )
        },
        ...OPTIONS_CUSTOM_RETURN_COLUMN
      },
    },
  ]

  return (
    <>
      <MUIDataTable
        title={"Clientes"}
        data={formatData}
        columns={columns}
        options={{...BASIC_OPTIONS_MUI,
        responsive: "standard"}}
      />
    </>
  )
}

export default TableClientes