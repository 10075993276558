import { useState } from "react";
import Modal from '@mui/material/Modal';
import FieldArrayDocumentos from "./fields/FieldArrayDocumentos";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

function FormDocumentGeneric({ functionSaveDocument, documentToUpdate, functionUpdateDocument, refreshData, attributeNameFile }) {
    const [open, setOpen] = useState(false);
    const documentos = documentToUpdate ? { documents: [documentToUpdate] } : { documents: [] };

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false);
    }

    const validateDocuments = Yup.object({
        documents: Yup.array().of(
            Yup.object().shape({
                [attributeNameFile]: Yup.string().required('El nombre del archivo es requerido.'),
                file: documentToUpdate ? null : Yup.mixed().nullable().required('El archivo es requerido.'),
            })
        )
    });


    return (
        <>  {documentToUpdate ?
            <button
                className={"btn btn-dark me-1"}
                type="button" onClick={handleOpen}>
                <i className="bi bi-pencil-fill"></i>
            </button>
            :
            <div align="right">
                <button
                    className={"btn btn-primary me-1"}
                    type="button" onClick={handleOpen}>
                    + SUBIR DOCUMENTOS
                </button>
            </div>
        }
            <Modal
                open={open}
                onClose={handleClose}
            >
                <div className='card style-modal-sm'>
                    <div className='card-header d-flex justify-content-between'>
                        {documentToUpdate ? "ACTUALIZAR DOCUMENTO" : "SUBIR DOCUMENTOS"}
                        <button className='btn btn-dark btn-sm'
                            onClick={handleClose}>
                            <i className="bi bi-x-lg"></i>
                        </button>
                    </div>
                    <div className={`card-body ${!documentToUpdate ? 'body-modal-scroll' : ''}`}>
                        <Formik
                            initialValues={documentos}
                            onSubmit={async (values) => {
                                documentToUpdate ? await functionUpdateDocument(values) : await functionSaveDocument(values);
                                await refreshData();
                                handleClose();
                            }}
                            enableReinitialize
                            validationSchema={validateDocuments}
                        >
                            {({ values, isValid }) => (
                                <Form>
                                    <FieldArrayDocumentos values={values} documentToUpdate={documentToUpdate} attributeNameFile={attributeNameFile}  />

                                    {values.documents.length > 0 ?
                                        <div className='col-sm-12' align="right">
                                            <button type="submit" className='btn btn-primary' disabled={!isValid}>{documentToUpdate ? "ACTUALIZAR" : "SUBIR"}</button>
                                        </div>
                                        :
                                        null
                                    }

                                </Form>
                            )}

                        </Formik>

                    </div>
                </div>
            </Modal>
        </>
    )
}

export default FormDocumentGeneric