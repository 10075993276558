import React from "react";
import "../assets/css/containerfixed.css";

function ContainerFixed({ centerContent, children }) {

  return (
    <>
      <div
        style={{
          position: "relative",
          zIndex: 999,
          display: "flex",
          justifyContent: centerContent ? "center" : "flex-start",
        }}
      >
        <div
          style={{
            position: "fixed",
            background: "#E3E3E3",
            width: "98%",
          }}
        >
          {children}
        </div>
      </div>
      <div style={{ visibility: "hidden" }}>{children}</div>
    </>
  );
}

export default ContainerFixed;
