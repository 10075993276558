import { PDFViewer } from '@react-pdf/renderer';
import { useState } from 'react';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import '../../../../assets/css/resize-table.css';
import { pdfCotizacion } from './CotizacionGenerateDoc';
import ToolTipMui from '../../../mui/ToolTipMui';
import { updateFileCotizacion } from '../../../../controllers/admin/cotizaciones/cotizacionController';

function CotizacionPdf({ cotizacion, embarque, nameButton, customClassButton }) {
    const [open, setOpen] = useState(false);
    const [pdf, setPdf] = useState(null);
    const [orientation, setOrientation] = useState("landscape")
    const [customSize, setCustomSize] = useState(false);
    const [fontSizeTitle, setFontSizeTitle] = useState(8);
    const [fontSizeContent, setFontSizeContent] = useState(8);
    const [fontSizeCondiciones, setFontSizeCondiciones] = useState(8);

    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    const handleOpen = () => {
        setOpen(true);
        generatePdf({
            orientation: orientation,
            customSize: customSize,
            fontSizeTitle: fontSizeTitle,
            fontSizeContent: fontSizeContent,
            fontSizeCondiciones: fontSizeCondiciones
        });
    };

    const generatePdf = ({ orientation, customSize, fontSizeTitle, fontSizeContent, fontSizeCondiciones }) => {
        const pdf = pdfCotizacion({
            orientation: orientation,
            customSize: customSize,
            fontSizeTitle: fontSizeTitle,
            fontSizeContent: fontSizeContent,
            fontSizeCondiciones: fontSizeCondiciones,
            cotizacion: cotizacion
        })
        setPdf(pdf);
    };

    const handleClose = () => {
        setOrientation("landscape");
        setCustomSize(false);
        setFontSizeContent(8);
        setFontSizeTitle(8);
        setFontSizeCondiciones(8);
        setOpen(false);
    }

    return (
        <>
            <button
                className={customClassButton ? customClassButton : `btn btn-dark m-1`}
                type="button" onClick={handleOpen}>
                <i className="bi bi-cloud-arrow-down"></i> {nameButton ? nameButton : "PDF"}
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='card style-modal-md'>
                    <div className='card-header d-flex justify-content-between'>
                        Preview Cotización - {cotizacion?.quoteReferenceKJ}
                        <button className='btn btn-dark btn-sm'
                            onClick={handleClose}>
                            <i className="bi bi-x-lg"></i>
                        </button>
                    </div>
                    <div className='card-body body-modal-scroll'>
                        <div className='row'>
                            <div className='col-sm-3'>
                                <label>Orientación del documento</label>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={(e) => {
                                        generatePdf({
                                            orientation: e.target.value,
                                            customSize: customSize,
                                            fontSizeTitle: fontSizeTitle,
                                            fontSizeContent: fontSizeContent
                                        })
                                        setOrientation(e.target.value)
                                    }}
                                    value={orientation}
                                >
                                    <FormControlLabel value="portrait" control={<Radio />} label="Vertical" />
                                    <FormControlLabel value="landscape" control={<Radio />} label="Horizontal" />
                                </RadioGroup>
                            </div>
                            <div className='col-sm-3'>
                                <label>Tamaño Titulos Tablas</label>
                                <input className='form-control form-control-sm' type='number' value={fontSizeTitle}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        generatePdf({
                                            fontSizeTitle: value,
                                            orientation: orientation,
                                            customSize: customSize,
                                            fontSizeContent: fontSizeContent
                                        });
                                        setFontSizeTitle(value)
                                    }} />
                            </div>
                            <div className='col-sm-3'>
                                <label>Tamaño Contenido Tablas</label>
                                <input className='form-control form-control-sm' type='number' value={fontSizeContent}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        generatePdf({
                                            fontSizeContent: value,
                                            orientation: orientation,
                                            customSize: customSize,
                                            fontSizeTitle: fontSizeTitle
                                        })
                                        setFontSizeContent(value)
                                    }} />
                            </div>
                            <div className='col-sm-3'>
                                <label>Tamaño Condiciones de Servicio</label>
                                <input className='form-control form-control-sm' type='number' value={fontSizeCondiciones}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        generatePdf({
                                            fontSizeContent: fontSizeContent,
                                            orientation: orientation,
                                            customSize: customSize,
                                            fontSizeTitle: fontSizeTitle,
                                            fontSizeCondiciones: value
                                        })
                                        setFontSizeCondiciones(value)
                                    }} />
                            </div>
                            {embarque && cotizacion ?
                                <div className='col-sm-3'>
                                    <button className='btn btn-dark mt-3'
                                        onClick={() => {
                                            updateFileCotizacion({
                                                orientation: orientation,
                                                customSize: customSize,
                                                fontSizeTitle: fontSizeTitle,
                                                fontSizeContent: fontSizeContent,
                                                cotizacion: cotizacion,
                                                fileCotizacion: embarque.fileCotizacion
                                            })
                                        }}>
                                        ACTUALIZAR
                                    </button><ToolTipMui title={"Actualizará el archivo que se subió cuando se relacionó la cotización con el embarque. Recuerda que una cotización puede tener muchos embarques y la actualización es por documento de embarque, solo afecta a: " + embarque.referenciaKJ}></ToolTipMui>
                                </div> : null}

                        </div>

                        <PDFViewer
                            style={{
                                width: '100%',
                                height: '100%',
                                border: 'none',
                            }}
                            plugins={[defaultLayoutPluginInstance]}>
                            {pdf}
                        </PDFViewer>

                    </div>
                </div>
            </Modal>
        </>
    )
}

export default CotizacionPdf