import React from 'react'
import FormikSelectSearch from '../../../formik/FormikSelectSearch';
import { Field } from 'formik';

function TipoCarga({
    catalogoTipoCarga,
    tiposCargaDesglose,
    name }) {

    return (
        <>
            <Field
                id={`${name}catalogoTipoCarga`}
                name={`${name}catalogoTipoCarga`}
                component={FormikSelectSearch}
                titulo="Tipo de Carga"
                data={tiposCargaDesglose}
                returnObject={true} />
            {catalogoTipoCarga?.id == 2 ?
                <>
                    <label className="form-label">No. UN</label>
                    <Field id={`${name}tipoCargaDetalle.noUn`} name={`${name}tipoCargaDetalle.noUn`} className="form-control" />
                </> : <></>
            }
            {catalogoTipoCarga?.id == 3 ?
                <>
                    <label className="form-label">Temperatura</label>
                    <Field id={`${name}tipoCargaDetalle.refrigeradaTemperatura`} name={`${name}tipoCargaDetalle.refrigeradaTemperatura`} className="form-control" type={"number"} />
                    <label className="form-label">Humedad</label>
                    <Field id={`${name}tipoCargaDetalle.refrigeradaHumedad`} name={`${name}tipoCargaDetalle.refrigeradaHumedad`} className="form-control" type={"number"} />
                    <label className="form-label">Ventilación</label>
                    <Field id={`${name}tipoCargaDetalle.refrigeradaVentilacion`} name={`${name}tipoCargaDetalle.refrigeradaVentilacion`} className="form-control" type={"number"} />
                </> : <></>
            }
        </>
    )
}

export default TipoCarga