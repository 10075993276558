import React, { useEffect, useState } from 'react'
import { listaUsuariosPorIdRol } from '../../controllers/admin/usuariosController'
import { ID_ROL_ADMIN, ID_ROL_OPERATIVO } from '../../utils/constantes'
import MUIDataTable from 'mui-datatables'
import { formatNameUser, formatRole } from '../../utils/userFormat'
import ModalAdminEmails from '../../components/admin/ModalAdminEmails'
import ModalFormUsuarios from '../../components/admin/equipo/ModalFormUsuarios'
import { BASIC_OPTIONS_MUI, OPTIONS_CUSTOM_RETURN_COLUMN } from '../../utils/mui/constantes'
import { getAuthUser } from '../../utils/authUtils'

function Equipo() {
  const [usuarios, setUsuarios] = useState([]);
  const NAMES = {
    ID: "Id",
    NOMBRE: "Nombre",
    ROLE: "Rol",
    ENABLE: "Acceso",
    CREADO_EN: "Fecha Creación",
    EMAILS: "Correo Electrónico",
    EDITAR: ""
  };

  useEffect(() => {
    fetchDataUsuarios();
  }, [])

  const fetchDataUsuarios = async () => {
    const admins = await listaUsuariosPorIdRol(ID_ROL_ADMIN);
    const operativos = await listaUsuariosPorIdRol(ID_ROL_OPERATIVO);
    const data = getAuthUser();
    const localUsuarios = [...admins, ...operativos].filter(usuario => usuario.id !== data.id);
    setUsuarios(localUsuarios);
  };

  const columns = [
    {
      name: "id",
      label: NAMES.ID,
      options: {
        ...OPTIONS_CUSTOM_RETURN_COLUMN,
        customBodyRenderLite: (dataIndex) => usuarios[dataIndex]?.id
      }
    },
    {
      name: "nombre",
      label: NAMES.NOMBRE,
      options: {
        ...OPTIONS_CUSTOM_RETURN_COLUMN,
        customBodyRenderLite: (dataIndex) => formatNameUser(usuarios[dataIndex])
      }
    },
    {
      name: "role",
      label: NAMES.ROLE,
      options: {
        ...OPTIONS_CUSTOM_RETURN_COLUMN,
        customBodyRenderLite: (dataIndex) => formatRole(usuarios[dataIndex]?.role?.name)
      }
    },
    {
      name: "enable",
      label: NAMES.ENABLE,
      options: {
        ...OPTIONS_CUSTOM_RETURN_COLUMN,
        customBodyRenderLite: (dataIndex) => usuarios[dataIndex]?.enable ? "SI" : "NO"
      }
    },
    {
      name: "creadoEn",
      label: NAMES.CREADO_EN,
      options: {
        ...OPTIONS_CUSTOM_RETURN_COLUMN,
        customBodyRenderLite: (dataIndex) => usuarios[dataIndex]?.creadoEn
      }
    },
    {
      name: "emails",
      label: NAMES.EMAILS,
      options: {
        ...OPTIONS_CUSTOM_RETURN_COLUMN,
        customBodyRenderLite: (dataIndex) => (
          <ModalAdminEmails usuario={usuarios[dataIndex]} onlyOneEmail={true} />
        ),
        searchable: false
      }
    },
    {
      name: "editar",
      label: NAMES.EDITAR,
      options: {
        ...OPTIONS_CUSTOM_RETURN_COLUMN,
        customBodyRenderLite: (dataIndex) => (
          <ModalFormUsuarios usuario={usuarios[dataIndex]} buttonTittle={<i className="bi bi-pencil"></i>} fetchDataUsuarios={fetchDataUsuarios} />
        ),
        searchable: false
      }
    }
  ];

  return (
    <div className='row m-4'>
      <div align="right">
        <ModalFormUsuarios
          usuario={{nombre: null, role: null, tipoDePersona: "PF", password:  null}}
          buttonTittle={"Nuevo Usuario"}
          fetchDataUsuarios={fetchDataUsuarios}
        ></ModalFormUsuarios>
      </div>

      <MUIDataTable
        title={"Equipo"}
        data={usuarios}
        columns={columns}
        options={{
          ...BASIC_OPTIONS_MUI,
          responsive: "standard"
        }}
      />
    </div>
  )
}

export default Equipo