import React, { useEffect, useState } from 'react'
import MUIDataTable from "mui-datatables";
import { OPTIONS_MUI_ONLY_TABLE, OPTIONS_CUSTOM_RETURN_COLUMN } from '../../utils/mui/constantes';
import { ToastError } from '../../utils/toastMessage';
import ModalCostos from './costos/ModalCostos';
import ModalPods from './ModalPods';
import TooltipEstatusFacturaProveedor from '../admin/facturas/proveedor/TooltipEstatusFacturaProveedor';
import { getAuthRoleUser } from '../../utils/authUtils';
import { useDispatch } from 'react-redux';
import { setRole } from '../../redux/features/userAuthSlice';
import ModalArchivo from '../ModalArchivo';
import ComplementoDePago from '../ComplementoDePago';
import { BASE_URL_S3_DEVELOPMENT, ID_ESTATUS_FP_COMPLETADO, ID_ESTATUS_FP_PROGRAMADA_PAGO } from '../../utils/constantes';
import moment from 'moment-timezone';
import { validatePaymentDay } from '../../utils/proveedor/validationsPayment';

function TableEmbarques({ fetchListarEmbarques }) {
    const [dataMuiEmbarques, setDataMuiEmbarques] = useState({
        data: [],
        count: 10,
        page: 0,
        rowsPerPage: 10,
        searchText: ""
    });
    const [search, setSearch] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        //Se vuelve a setear el rol en el store por si el proveedor o logistica recargan la pagina.
        setRol()
    }, [])

    useEffect(() => {
        fetchListarEmbarquesDeProveedor();
    }, [dataMuiEmbarques.page, dataMuiEmbarques.rowsPerPage, dataMuiEmbarques.searchText]);

    const fetchListarEmbarquesDeProveedor = async () => {
        const handlerSearchText = dataMuiEmbarques.searchText ? `&searchTextEmbarque=${dataMuiEmbarques.searchText}` : "";
        const data = await fetchListarEmbarques(`?page=${dataMuiEmbarques.page}&size=${dataMuiEmbarques.rowsPerPage}${handlerSearchText}`);
        setDataMuiEmbarques({
            data: data.content,
            count: data.totalElements,
            page: data.pageable?.pageNumber,
            rowsPerPage: data.pageable?.pageSize,
            searchText: dataMuiEmbarques.searchText
        });
    };

    const setRol = () => {
        const rol = getAuthRoleUser();
        dispatch(setRole(rol));
    }

    const handleChangeRowsPerPage = async (pageSize) => {
        setDataMuiEmbarques({
            ...dataMuiEmbarques,
            rowsPerPage: pageSize,
            page: 0
        })
    };

    const handleOnChangePage = async (newPage) => {
        setDataMuiEmbarques({
            ...dataMuiEmbarques,
            page: newPage
        })
    }

    const handleSearchChange = (event) => {
        const textSearch = event.target.value;
        setSearch(textSearch);
    };

    const submitSearch = () => {
        setDataMuiEmbarques({
            ...dataMuiEmbarques,
            page: 0,
            searchText: search
        });
    }

    const handleSearchReset = () => {
        setDataMuiEmbarques({
            ...dataMuiEmbarques,
            page: 0,
            searchText: ""
        });
        setSearch("")
    };

    const columns = [
        { label: "REFERENCIA", name: "referenciaKJ" },
        {
            name: "ORIGEN",
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return (
                        dataMuiEmbarques.data[dataIndex]?.origen?.nombre + ` (${dataMuiEmbarques.data[dataIndex]?.origen?.portCode})`
                    )
                },
            }
        },
        {
            name: "DESTINO",
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return (
                        dataMuiEmbarques.data[dataIndex]?.destino?.nombre + ` (${dataMuiEmbarques.data[dataIndex]?.destino?.portCode})`
                    )
                },
            }
        },
        {
            name: "TRAFICO",
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return (
                        dataMuiEmbarques.data[dataIndex]?.tipoTrafico?.nombre
                    )
                },
            }
        },
        {
            name: "SERIE FOLIO",
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            {
                                dataMuiEmbarques.data[dataIndex]?.facturasProveedor?.map(facturaProveedor => {
                                    return (
                                        <React.Fragment key={facturaProveedor.id}>
                                            {facturaProveedor.serie ? facturaProveedor.serie + " " : ""}
                                            {facturaProveedor.folio ? facturaProveedor.folio + " " : ""}
                                            {!facturaProveedor.serie && !facturaProveedor.folio && facturaProveedor.uuid ? facturaProveedor.uuid : ""}
                                            <br />
                                        </React.Fragment>
                                    )
                                })
                            }
                        </>
                    )
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            }
        },
        {
            name: "ESTATUS",
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <div>
                            {dataMuiEmbarques.data[dataIndex]?.facturasProveedor?.map(facturaProveedor => {

                                return (
                                    <React.Fragment key={facturaProveedor.id}>
                                        <TooltipEstatusFacturaProveedor
                                            facturaProveedor={facturaProveedor}
                                        ></TooltipEstatusFacturaProveedor>
                                        <br />
                                    </React.Fragment>
                                )
                            })
                            }
                        </div>
                    )
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            }
        },
        {
            name: "FECHA ESTIMADA PAGO",
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return (
                        dataMuiEmbarques.data[dataIndex]?.facturasProveedor?.map(facturaProveedor => {
                            let nextFriday = validatePaymentDay(facturaProveedor);
                            return (
                                <React.Fragment key={facturaProveedor.id}>
                                    {facturaProveedor.estatus?.id == ID_ESTATUS_FP_PROGRAMADA_PAGO ?
                                        <>
                                            {moment(nextFriday).format('DD/MM/YYYY')}
                                            <br />
                                        </>
                                        :
                                        <br />
                                    }
                                </React.Fragment>
                            )
                        })

                    )
                }
                ,
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            }
        },
        {
            name: "FECHA EFECTIVA PAGO",
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return (
                        dataMuiEmbarques.data[dataIndex]?.facturasProveedor?.map(facturaProveedor => {
                            return (
                                <React.Fragment key={facturaProveedor.id}>
                                    {facturaProveedor.estatus?.id == ID_ESTATUS_FP_COMPLETADO ?
                                        <>
                                            {moment(facturaProveedor.fechaEfectivaPago).format('DD/MM/YYYY')}
                                            <br />
                                        </>
                                        :
                                        <br />
                                    }
                                </React.Fragment>
                            )
                        })

                    )
                }
                ,
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            }
        },
        {
            name: "PODS",
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <ModalPods
                            idEmbarque={dataMuiEmbarques.data[dataIndex]?.id}></ModalPods>
                    )
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            }
        },
        {
            name: "COSTOS",
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <ModalCostos
                            embarque={dataMuiEmbarques.data[dataIndex]}
                            fetchEmbarque={fetchListarEmbarquesDeProveedor}></ModalCostos>
                    )
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            }
        },
    ]

    return (
        <>
            <div className='col-12' align="right">
                <div className='col-12 col-sm-4 m-4'>
                    <ModalArchivo
                        title={"GUIA"}
                        urlBase={BASE_URL_S3_DEVELOPMENT}
                        archivoS3={{ keyS3: "MANUAL+PROVEEDOR.pdf", tipoArchivo: "application/pdf" }}
                        textButton={"GUIA"}
                        customIcon={<i className="bi bi-journal-richtext"></i>}></ModalArchivo>
                    <ComplementoDePago></ComplementoDePago>
                    <div className="input-group mb-1 mt-1">
                        <input
                            type="text"
                            className='form-control'
                            value={search}
                            onChange={(e) => { handleSearchChange(e) }}
                            placeholder="Buscar por referencia..."
                        />
                        <button className='btn btn-primary btn-sm'
                            onClick={() => submitSearch()}><i className="bi bi-search"></i></button>
                        <button onClick={handleSearchReset} className="btn btn-dark btn-sm">
                            <i className="bi bi-x"></i>
                        </button>
                    </div>
                </div>
            </div >
            <div className='col-12'>
                <MUIDataTable
                    title={"Embarques"}
                    data={dataMuiEmbarques.data}
                    columns={columns}
                    options={{
                        ...OPTIONS_MUI_ONLY_TABLE,
                        search: false,
                        onChangePage: handleOnChangePage,
                        onChangeRowsPerPage: handleChangeRowsPerPage,
                        rowsPerPageOptions: [5, 10, 50, 100, 1000],
                        count: dataMuiEmbarques.count,
                        page: dataMuiEmbarques.page,
                        rowsPerPage: dataMuiEmbarques?.rowsPerPage,
                        serverSide: true,
                        responsive: "standard"
                    }} />
            </div>
        </>

    )
}

export default TableEmbarques