import { findProveedoresBysearchText } from '../../../../../controllers/admin/usuariosController';
import FormikSelectSearchDB from '../../../../formik/FormikSelectSearchDB';
import FormikGenericDate from '../../../../formik/FormikGenericDate';
import { Field } from 'formik';

function DestinoFinal({ values, name }) {
    return (
        <>
            <div className='row'>

                <div className='col-sm-12'>
                    <Field
                        name={`${name}proveedorDestinoFinal`}
                        component={FormikSelectSearchDB}
                        titulo="Proveedor Destino Final"
                        fetchSearch={findProveedoresBysearchText}
                        type={"USERS"} />
                </div>
                <div className="col-sm-6 mb-3">
                    <label className="form-label">Direccion Descarga Destino Final</label>
                    <Field id={`${name}direccionDescargaDestinoFinal`} name={`${name}direccionDescargaDestinoFinal`} className="form-control" />
                </div>
                <div className='col-sm-6 mb-1'>
                    <Field
                        name={`${name}fechaEntregaEfectivaDestinoFinal`}
                        component={FormikGenericDate}
                        nameLabel="Fecha Entrega Efectiva Destino Final" />
                </div>
            </div>
        </>
    )
}

export default DestinoFinal