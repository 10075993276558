import { URL_CONDICIONES_SERIVICO } from "../../../utils/endpoints/cotizaciones"
import { apiRequestV2 } from "../../handlerApiRequestV2"


export const findAllCondicionesServicio = async () => {
    return await apiRequestV2({
        endpoint: URL_CONDICIONES_SERIVICO
    })
}

export const saveCondicionService = async (condicion) => {
    return await apiRequestV2({
        endpoint: URL_CONDICIONES_SERIVICO,
        method: "POST",
        data: condicion,
        showSuccessMessage: true
    })
}

export const updateCondicionService = async (condicion, idCS) => {
    return await apiRequestV2({
        endpoint: `${URL_CONDICIONES_SERIVICO}/${idCS}`,
        method: "PUT",
        data: condicion,
        showSuccessMessage: true
    })
}

export const deleteCondicionService = async (idCS) => {
    return await apiRequestV2({
        endpoint: `${URL_CONDICIONES_SERIVICO}/${idCS}`,
        method: "DELETE",
        showSuccessMessage: true
    })
}

export const findAllByIdTrafico = async (idTrafico) => {
    return await apiRequestV2({
        endpoint: URL_CONDICIONES_SERIVICO,
        urlParams: `/trafico?idTrafico=${idTrafico}`
    })
}

/**
 * Function que busca las condiciones de servicio de acuerdo a los traficos de las ventas
 * @param {*} values 
 */
export const getCondicionesServicio = async (values) => {

        let traficosSeleccionados = [];
        let condiciones = [];

        values.ventas?.forEach(venta => {
            if (venta.trafico && !traficosSeleccionados.includes(venta.trafico)) {
                traficosSeleccionados.push(venta.trafico);
            }
        });

        // Usar Promise.all para esperar todas las operaciones asíncronas
        await Promise.all(traficosSeleccionados.map(async (trafico) => {
            const data = await findAllByIdTrafico(trafico.id);

            if (Array.isArray(data)) {
                const formatCondicion = data.map((condicion) => [
                    condicion.nombre
                ]);
                condiciones.push(...formatCondicion);
            }
        }));
        return condiciones;

}
