import { createSlice } from '@reduxjs/toolkit';
import { listaCostosProveedorPorIdEmbarque } from '../../../controllers/admin/costoProveedorController';

const initialState = {
    facturasCliente: [
        {
            cliente: {},
            tipoMoneda: "",
            esComplementaria: true,
            conceptosFactura: [
            ]
        }
    ],
    facturasProveedor: [
        {
            estatus: {},
            costoNeto: 0,
            estaEliminado: false
        }

    ],
    costosProveedores: [
        {
            proveedor: {},
            totalProvision: 0,
            totalNetoFacturas: 0,
            tipoMoneda: "",
            esAdicional: true
        }
    ]
}

const modelConceptosFactura = {

    cantidad: 1,
    noIdentificacion: "",
    descripcion: "COORDINACION LOGISTICA",
    valorUnitario: 0,
    importe: 0,
    esAdicional: true,
    tieneIVA: false,

}

export const controlCostosSlice = createSlice({
    name: 'controlCostos',
    initialState,
    reducers: {
        resetControlCostoData: (state) => {
            state.facturasCliente = initialState.facturasCliente;
            state.facturasProveedor = initialState.facturasProveedor;
            state.costosProveedores = initialState.costosProveedores;
        },
        //Factura Clientes
        setFacturasCliente: (state, action) => {
            state.facturasCliente = action.payload;
        },
        agregarFacturaCliente: (state) => {
            state.facturasCliente.push(initialState.facturasCliente[0])
        },
        updateFactura: (state, action) => {
            const { indexFC, name, value } = action.payload;
            state.facturasCliente[indexFC][name] = value
        },
        eliminarFactura: (state, action) => {
            const { indexFC } = action.payload;
            state.facturasCliente.splice(indexFC, 1);
        },
        updateFacturaConcepto: (state, action) => {
            const { indexFC, name, value, indexCF } = action.payload;
            state.facturasCliente[indexFC].conceptosFactura[indexCF][name] = value
            //calcular la base del concepto
            if (name === 'valorUnitario' || name === 'cantidad') {
                let cantidad = state.facturasCliente[indexFC].conceptosFactura[indexCF].cantidad;
                let valorUnitario = state.facturasCliente[indexFC].conceptosFactura[indexCF].valorUnitario;
                let base = valorUnitario * cantidad
                state.facturasCliente[indexFC].conceptosFactura[indexCF].base = base
            }
        },
        agregarConceptoFactura: (state, action) => {
            const { indexFC } = action.payload;
            state.facturasCliente[indexFC].conceptosFactura.push(modelConceptosFactura)
        },
        eliminarConceptoFactura: (state, action) => {
            const { indexFC, indexCF } = action.payload;
            state.facturasCliente[indexFC].conceptosFactura.splice(indexCF, 1);
        },
        //Cosotos Proveedores
        setCostosProveedores: (state, action) => {
            state.costosProveedores = action.payload;
        },
        agregarCostoProveedor: (state, action) => {
            state.costosProveedores.push(action.payload)
        },
        eliminarCostoProveedor: (state, action) => {
            const { indexCP } = action.payload;
            state.costosProveedores.splice(indexCP, 1);
        },
        actualizarCostosProveedor: (state, action) => {
            const { value, name, indexCP } = action.payload;
            state.costosProveedores[indexCP][name] = value
        },
        //Factura Proveedores
        setFacturasProveedor: (state, action) => {
            state.facturasProveedor = action.payload;
        },
        agregarFacturaProveedor: (state) => {
            state.facturasProveedor.push(initialState.facturasProveedor[0])
        },
        eliminarFacturaProveedor: (state, action) => {
            const { indexFP } = action.payload;
            state.facturasProveedor.splice(indexFP, 1);
        },
        actualizarFacturaProveedor: (state, action) => {
            const { value, name, indexFP } = action.payload;
            state.facturasProveedor[indexFP][name] = value
        }
    }
});

export const {
    setControlCostoData,
    resetControlCostoData,
    updateFacturaConcepto,
    agregarConceptoFactura,
    eliminarConceptoFactura,
    agregarCostoProveedor,
    eliminarCostoProveedor,
    setFacturasCliente,
    agregarFacturaCliente,
    updateFactura,
    eliminarFactura,
    setCostosProveedores,
    actualizarCostosProveedor,
    setFacturasProveedor,
    agregarFacturaProveedor,
    eliminarFacturaProveedor,
    actualizarFacturaProveedor } = controlCostosSlice.actions;

export const fetchCostoProveedores = (idEmbarque) => async (dispatch) => {
    try {
        const res = await listaCostosProveedorPorIdEmbarque(idEmbarque);
        dispatch(setCostosProveedores(res.data))
    } catch (error) {

    }
};

export default controlCostosSlice.reducer;
