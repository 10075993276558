import React from "react";
import Header from "../../components/proveedor/Header";
import { listarEmbarquesPorCliente } from "../../controllers/cliente/clienteEmbarqueController";
import TableEmbarquesClientes from "../../components/clientes/TableEmbarquesClientes";

function InicioCliente() {
  return (
    <div>
      <Header></Header>
      <TableEmbarquesClientes
        fetchListarEmbarques={listarEmbarquesPorCliente}
      ></TableEmbarquesClientes>
    </div>
  );
}

export default InicioCliente;
