import React from 'react'
import Header from '../../components/proveedor/Header'
import TableEmbarques from '../../components/proveedor/TableEmbarques'
import { listarEmbarquesDeLogistica } from '../../controllers/proveedor/proveedorEmbarqueController'

//El rol logistica es practicamente un proveedor, unicamente servira informativo para mostrar embarques y poder
//subir pods y facturas. julio 19 2023
function InicioLogistica() {

  return (
    <div className='row'>
      <Header></Header>
      <TableEmbarques
      fetchListarEmbarques={listarEmbarquesDeLogistica}></TableEmbarques>

    </div>
  )
}

export default InicioLogistica