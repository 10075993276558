import { apiRequestV2 } from "../../handlerApiRequestV2";
import { URL_NOTAS } from "../../../utils/constantes";

export const findNotas = async (idEmbarque) =>{
    const urlParams = `${idEmbarque}`;
    return await apiRequestV2({
        endpoint: URL_NOTAS,
        urlParams: urlParams
    })
}

export const saveNota = async (notaText, idEmbarque) => {
    const id = `${idEmbarque}`;
    const req = {nota: notaText}
    return await apiRequestV2({
        endpoint: `${URL_NOTAS}add?idEmbarque=${id}`,
        method: "POST",
        data: req,
        showSuccessMessage: true
    })
}