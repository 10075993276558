import { useState, useEffect } from 'react'
import Modal from '@mui/material/Modal';
import { OPTIONS_CUSTOM_RETURN_COLUMN, OPTIONS_MUI_ONLY_SEARCH } from '../utils/mui/constantes';
import { deleteItem, handleChangeArrayText } from '../utils/helpersState';
import { findAllComplementosFilter, logicDeleteComplementoPago, saveComplementoDePago, updateComplementoDePago } from '../controllers/admin/facturas/complementoPagoController';
import ModalEliminarObjeto from './admin/ModalEliminarObjeto'
import ModalArchivo from './ModalArchivo';
import { BASE_URL_S3_COMPLEMENTO_PAGO } from '../utils/constantes';
import { authUserIsAdmin } from '../utils/authUtils';
import CustomMuiDataTable from '../components/mui/CustomMuiDataTable';
import FormikAdapterCustomFileUpl from './formik/FormikAdapterCustomFileUpl';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

function ComplementoDePago() {
    const [open, setOpen] = useState(false);
    const [complementos, setComplementos] = useState([]);
    const [authEsAdmin, setEsAdmin] = useState(false);
    const [newItem, setNewItem] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    const complemento = {archivoPdfACargar: {}, archivoXMLACargar: {}};

    const handleOpen = () => {
        setEsAdmin(authUserIsAdmin());
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false);
    }

    const onSubmit = async (values) => {
        let data;
        if (complemento.id) {
            data = await updateComplementoDePago(
                values.archivoPdfACargar,
                values.archivoXMLACargar,
                complemento
            );
        } else {
            data = await saveComplementoDePago(
                values.archivoPdfACargar,
                values.archivoXMLACargar,
                complemento
            );
        }
        if (data) setNewItem(data)
    }

    const fetchLogicDelete = async (idComplemento, indexComplemento) => {
        const data = await logicDeleteComplementoPago(idComplemento, complementos[indexComplemento]);
        setNewItem(data)
    }


    const handleOpenForm = () => {
        setOpenForm(true)
    }

    const handleCloseForm = () => {
        setOpenForm(false);
    }

    const validateDocuments = Yup.object({
        documents: Yup.array().of(
            Yup.object().shape({
                file: Yup.mixed().nullable().required('El archivo es requerido.'),
            })
        )
    });

    const columnsComplemento = [
        {

            name: "PDF",
            options: {
                customBodyRenderLite: (indexComplemento) => {
                    return (
                        <div align="center" className='pb-2'>
                            {complementos[indexComplemento]?.archivoPDF ?
                                <div>
                                    <ModalArchivo
                                        title={"Complemento Pago"}
                                        urlBase={BASE_URL_S3_COMPLEMENTO_PAGO}
                                        archivoS3={complementos[indexComplemento].archivoPDF}></ModalArchivo>
                                </div>
                                : ""}
                        </div>
                    )
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            }
        },
        {
            name: "XML",
            options: {
                customBodyRenderLite: (indexComplemento) => {
                    return (
                        <div align="center" className='pb-2'>
                            {complementos[indexComplemento]?.archivoXML ?
                                <div>
                                    <button
                                        type='button'
                                        className='btn btn-dark'
                                        onClick={() => saveAs(BASE_URL_S3_COMPLEMENTO_PAGO + complementos[indexComplemento].archivoXML.keyS3, complementos[indexComplemento].archivoXML.keyS3)}>
                                        <i className="bi bi-file-earmark-arrow-down"></i>
                                    </button>
                                </div>
                                : ""}
                        </div>
                    )
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            }
        },
        {
            name: "FOLIOS FISCALES FACTURAS",
            options: {
                customBodyRenderLite: (indexComplemento) => {
                    return (
                        <div align="center" className='pb-2'>
                            {complementos[indexComplemento]?.id ?
                                complementos[indexComplemento]?.facturasRelacionadas.map((factura, index) => {
                                    return (
                                        <li key={index}>{factura.facturaProveedor.uuid}</li>
                                    )
                                })
                                :
                                null
                            }
                        </div>
                    )
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            }
        },
        {
            name: "REFERENCIA",
            options: {
                customBodyRenderLite: (indexComplemento) => {
                    return (
                        <div align="center" className='pb-2'>
                            {complementos[indexComplemento]?.id ?
                                complementos[indexComplemento]?.facturasRelacionadas.map((factura, index) => {
                                    return (
                                        <li key={index}>{factura.facturaProveedor?.costoProveedor?.embarque?.referenciaKJ}</li>
                                    )
                                })
                                :
                                ""
                            }
                        </div>
                    )
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            }
        },
        {
            name: "",
            options: {
                customBodyRenderLite: (indexComplemento) => {
                    return (
                        <>{complementos[indexComplemento]?.id ?
                            ""
                            :
                            <button type='button' className='btn btn-primary'
                                onClick={() => onSubmit(indexComplemento)}>
                                Guardar
                            </button>
                        }
                        </>
                    )
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            }
        },
        {
            name: "",
            options: {
                customBodyRenderLite: (indexComplemento) => {
                    return (
                        <>
                            {authEsAdmin && !complementos[indexComplemento]?.estaEliminado ?
                                <>
                                    {
                                        complementos[indexComplemento]?.id ?
                                            <ModalEliminarObjeto
                                                textHeader={"¿Seguro de eliminar este complemento?"}
                                                textBody={""}
                                                functionEliminar={fetchLogicDelete}
                                                id={complementos[indexComplemento]?.id}
                                                index1={indexComplemento}>
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    placeholder='Razón de la eliminación'
                                                    name='razonEliminacion'
                                                    value={complementos[indexComplemento]?.razonEliminacion}
                                                    onChange={(e) => { handleChangeArrayText(e, indexComplemento, complementos, setComplementos, true) }} />
                                            </ModalEliminarObjeto>
                                            :
                                            <button type='button' className='btn btn-danger'
                                                onClick={() => deleteItem(indexComplemento, complementos, setComplementos)}>
                                                <i className="bi bi-trash"></i>
                                            </button>
                                    }
                                </>
                                :
                                ""
                            }
                        </>
                    )
                },
                ...OPTIONS_CUSTOM_RETURN_COLUMN
            }
        }
    ]

    return (
        <>
            <button className="btn btn-dark" type="button" onClick={handleOpen}>
                <i className="bi bi-cash"></i> COMPLEMENTOS PAGO
            </button>
            <Modal open={open} onClose={handleClose}>
                <div className="card style-modal-md">
                    <div className="card-header d-flex justify-content-between">
                        COMPLEMENTOS DE PAGO
                        <button className="btn btn-dark btn-sm" onClick={handleClose}>
                            <i className="bi bi-x-lg"></i>
                        </button>
                    </div>
                    <div className="card-body body-modal-scroll">
                        <div className="col-12" align="right">
                            <button
                                type="button"
                                className="btn btn-dark m-2"
                                onClick={handleOpenForm}>
                                <i className="bi bi-plus"></i> SUBIR COMPLEMENTO
                            </button>
                        </div>

                        <Modal
                            open={openForm}
                            onClose={handleCloseForm}
                        >
                            <div className='card style-modal-ssm'>
                                <div className='card-header d-flex justify-content-between'>
                                    SUBE TU COMPLEMENTO DE PAGO
                                    <button className='btn btn-dark btn-sm'
                                        onClick={handleCloseForm}>
                                        <i className="bi bi-x-lg"></i>
                                    </button>
                                </div>
                                <div className={`card-body`}>
                                    <Formik
                                        onSubmit={async (values) => {
                                            await onSubmit(values);
                                            handleCloseForm();
                                        }}
                                        enableReinitialize
                                        validationSchema={validateDocuments}
                                        initialValues={complemento}
                                    >
                                        {({ values, isValid }) => (
                                            <Form>
                                                <div className='row' align="center">
                                                    <div className="col-sm-12 mb-3">
                                                        <label htmlFor="nombreServicio" className="form-label">Archivo PDF</label><br/>
                                                        <Field
                                                            name={`archivoPdfACargar`}
                                                            id="archivoPdfACargar"
                                                            component={FormikAdapterCustomFileUpl}
                                                            fileTypes={["PDF"]} />
                                                    </div>
                                                    <div className="col-sm-12 mb-3">
                                                        <label htmlFor="nombreServicio" className="form-label">Archivo XML</label><br/>
                                                        <Field
                                                            name={`archivoXMLACargar`}
                                                            id="archivoXMLACargar"
                                                            component={FormikAdapterCustomFileUpl}
                                                            fileTypes={["XML"]}/>
                                                    </div>

                                                    <div className='col-sm-12' align="right">
                                                        <button type="submit" className='btn btn-primary' disabled={!isValid}>SUBIR</button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </Modal>

                        <CustomMuiDataTable
                            title={'Complementos de Pago'}
                            columns={columnsComplemento}
                            newItem={newItem}
                            customOptions={{
                                ...OPTIONS_MUI_ONLY_SEARCH,
                                setRowProps: (row, dataIndex) => {
                                    if (complementos?.[dataIndex]?.guardoProveedor) {
                                        return {
                                            style: { backgroundColor: "#9ca8b27c" },
                                        };
                                    }
                                }
                            }}
                            fetchData={findAllComplementosFilter}
                            setState={setComplementos}
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default ComplementoDePago