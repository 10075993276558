import React, { useState } from 'react'
import Modal from '@mui/material/Modal';
import { Worker } from '@react-pdf-viewer/core';
// Import the main component
import { Viewer } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { TIPO_ARCHIVOS_IMG, TIPO_ARCHIVO_PDF, TIPO_ARCHIVO_ZIP } from '../utils/constantes';
import { saveAs } from 'file-saver';
import { ToastInfo } from '../utils/toastMessage';


function ModalArchivo({ title, urlBase, archivoS3, customIcon, textButton, children }) {
    const [open, setOpen] = useState(false);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    const handleOpen = () => {
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false);
    }

    const handleSaveFile = () =>{
        saveAs(urlBase + archivoS3.keyS3, archivoS3.keyS3);
    }

    return (
        <>
            <button
                className="btn btn-dark ms-1 me-1"
                type="button" onClick={handleOpen}>
                {customIcon ? customIcon : <i className="bi bi-eye"></i>}
                {textButton ? textButton : " "}
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='card style-modal-md'>
                    <div className='card-header d-flex justify-content-between'>
                        {title} - {archivoS3.keyS3}
                        <button className='btn btn-dark btn-sm'
                            onClick={handleClose}>
                            <i className="bi bi-x-lg"></i>
                        </button>
                    </div>
                    <div className='card-body body-modal-scroll' align="center">

                        {![...TIPO_ARCHIVOS_IMG, TIPO_ARCHIVO_PDF].includes(archivoS3.tipoArchivo) ?
                            <div align="center">
                                <p>No puedes visualizar este archivo. Porque no es un PDF o una imagen</p>
                                <button type='button' className='btn btn-warning'
                                onClick={() => handleSaveFile()}>
                                    DESCARGAR <i className="bi bi-cloud-arrow-down"></i>
                                </button>
                            </div>
                            :
                            ""

                        }
                        
                        {children}

                        {archivoS3.tipoArchivo == TIPO_ARCHIVO_PDF ?
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
                                <Viewer fileUrl={urlBase + archivoS3.keyS3 + "?" + archivoS3.actualizadoEn}
                                    plugins={[defaultLayoutPluginInstance]} />
                            </Worker>
                            :
                            <></>
                        }

                        {TIPO_ARCHIVOS_IMG.includes(archivoS3.tipoArchivo) ? (
                            <img
                                className='img-fluid'
                                src={urlBase + archivoS3.keyS3}
                                alt={archivoS3.keyS3}
                            />
                        ) : (
                            <></>
                        )}

                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ModalArchivo