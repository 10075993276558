import { createSlice } from '@reduxjs/toolkit';
import { calcularPesoTotalBruto, calcularTotalBultos } from '../../helpers/helperEmbarqueSlice';

const initialState = {
    embarqueData: {
        id: null,
        referenciaKJ: null,
        cliente: null,
        cotizacion: null,
        idIncoterm: null,
        origen: '',
        destino: '',
        tipoServicio: null,
        tipoTrafico: null,
        soloDespachoAduanal: false,
        totalVenta: 0,
        totalCostos: 0,
        totalVentaUSD: 0,
        totalCostosUSD: 0,
        idUsuarioRegistro: null,
        tieneFechaEfectivaEntregaNula: true,
        fileCotizacion: null,
        haceDespachoAduanal: "KJ",
        datosEmbarqueMaritimo: {servicioMaritimo: null, modalidadMaritima: null},
        datosEmbarqueAereo: {servicioAereo: null},
        desglosesEmbarques: [{
            proveedor: {},
            direccionRecoleccion: null,
            fechaEstimadaRecoleccion: null,
            fechaEfectiaRecoleccion: null,
            direccionEntrega: null,
            fechaEstimadaEntrega: null,
            fechaEfectivaEntrega: null,
            tipoUnidad: null,
            modalidadUnidad: '',
            mercanciaTrasladar: null,
            catalogoTipoCarga: null,
            tipoCargaDetalle: {},
            esFletePrincipal: false,
            tipoServicio: null,
            cantidadTotalBultos: null,
            pesoTotalBruto: null,
            unidadDeMedida: "",
            instruccionesEspeciales: "",
            instruccionesEspeciales: "",
            poCliente: null,
            dimensionesCarga: [{
                cantidadBultos: null,
                alto: 0,
                largo: 0,
                ancho: 0,
                volumen: 0,
                pesoPorBulto: 0
            }],
            repartosDireccion: [{
                direccionDeReparto: null,
                fechaEstimada: null,
                fechaEfectiva: null,
            }],
            datosDesgloseMaritimo: { detallesCargaMaritimo: [] },
            datosDesgloseAereo: {}
        }]
    },
}


export const embarqueSlice = createSlice({
    name: 'embarque',
    initialState,
    reducers: {
        setIdEmbarque: (state, action) => {
            state.idEmbarque = action.payload;
        },
        setEmbarque: (state, action) => {
            state.embarqueData = action.payload;
        },
        resetEmbarque: (state) => {
            state.embarqueData = initialState.embarqueData;
        },
        actualizarDatosEmbarqueMaritimo: (state, action) => {
            const { name, value } = action.payload;
            state.embarqueData.datosEmbarqueMaritimo[name] = value
        },
        actualizarDatosEmbarqueAereo: (state, action) => {
            const { name, value } = action.payload;
            state.embarqueData.datosEmbarqueAereo[name] = value
        },
        updateDesgloseEmbarque: (state, action) => {
            const { index, name, value } = action.payload;
            state.embarqueData.desglosesEmbarques[index][name] = value;
            if (name === 'catalogoTipoCarga') {
                state.embarqueData.desglosesEmbarques[index].tipoCargaDetalle = {}
            } else if (name === 'idTipoServicioDesgloseEmb') {
                state.embarqueData.desglosesEmbarques[index].dimensionesCarga = []
            }
        },
        eliminarDesgloseEmbarque: (state, action) => {
            const { indexDesglose } = action.payload;
            state.embarqueData.desglosesEmbarques.splice(indexDesglose, 1);
        },
        updateTipoCargaDetalle: (state, action) => {
            const { index, name, value } = action.payload;
            state.embarqueData.desglosesEmbarques[index].tipoCargaDetalle[name] = value
        },
        nuevaDimensionDeCarga: (state, action) => {
            state.embarqueData.desglosesEmbarques[action.payload].dimensionesCarga.push({});
        },
        actualizarDimensionDeCarga: (state, action) => {
            const { indexDesglose, indexDimension, name, value } = action.payload;
            state.embarqueData.desglosesEmbarques[indexDesglose].dimensionesCarga[indexDimension][name] = value;
            if (name === 'largo' || name === 'alto' || name === 'ancho') {
                let largo = state.embarqueData.desglosesEmbarques[indexDesglose].dimensionesCarga[indexDimension].largo;
                let alto = state.embarqueData.desglosesEmbarques[indexDesglose].dimensionesCarga[indexDimension].alto;
                let ancho = state.embarqueData.desglosesEmbarques[indexDesglose].dimensionesCarga[indexDimension].ancho;

                if (largo != null && alto != null && ancho != null) {
                    let calcularVolumen = largo * alto * ancho;
                    state.embarqueData.desglosesEmbarques[indexDesglose].dimensionesCarga[indexDimension].volumen = calcularVolumen.toFixed(2);
                }
            }
            if (name === 'cantidadBultos' || name === 'pesoPorBulto') {
                dimensionCargaBultos(state, indexDesglose)
            }
        },
        eliminarDimensionDeCarga: (state, action) => {
            const { indexDesglose, indexDimensionCarga } = action.payload;
            state.embarqueData.desglosesEmbarques[indexDesglose].dimensionesCarga.splice(indexDimensionCarga, 1);
            dimensionCargaBultos(state, indexDesglose)
        },
        nuevoRepartoDireccion: (state, action) => {
            const { indexDesglose } = action.payload;
            state.embarqueData.desglosesEmbarques[indexDesglose].repartosDireccion.push({});
        },
        actualizarRepartoDireccion: (state, action) => {
            const { indexDesglose, indexRD, name, value } = action.payload;
            state.embarqueData.desglosesEmbarques[indexDesglose].repartosDireccion[indexRD][name] = value;
        },
        eliminarRepartoDireccion: (state, action) => {
            const { indexDesglose, indexRD } = action.payload;
            state.embarqueData.desglosesEmbarques[indexDesglose].repartosDireccion.splice(indexRD, 1)
        },
        remplazarRepartoDireccion: (state, action) => {
            const { indexDesglose, indexRD, data } = action.payload;
            state.embarqueData.desglosesEmbarques[indexDesglose].repartosDireccion[indexRD] = data;
        },
        actualizarDatosDesgloseEmbarqueMaritimo: (state, action) => {
            const { indexDesglose, name, value } = action.payload;
            state.embarqueData.desglosesEmbarques[indexDesglose].datosDesgloseMaritimo[name] = value;
        },
        nuevoContenedorMaritimo: (state, action) => {
            const { indexDesglose } = action.payload;
            state.embarqueData.desglosesEmbarques[indexDesglose].datosDesgloseMaritimo.detallesCargaMaritimo.push({});
        },
        actualizarContenedorMaritimo: (state, action) => {
            const { indexDesglose, indexContenedorMaritimo, name, value } = action.payload;
            state.embarqueData.desglosesEmbarques[indexDesglose].datosDesgloseMaritimo.detallesCargaMaritimo[indexContenedorMaritimo][name] = value;
        },
        actualizarTipoCargaDetalleEnMaritimo: (state, action) => {
            const { indexDesglose, index, name, value } = action.payload;
            if (!state.embarqueData.desglosesEmbarques[indexDesglose].datosDesgloseMaritimo.detallesCargaMaritimo[index].tipoCargaDetalle) {
                state.embarqueData.desglosesEmbarques[indexDesglose].datosDesgloseMaritimo.detallesCargaMaritimo[index].tipoCargaDetalle = {}
            }
            state.embarqueData.desglosesEmbarques[indexDesglose].datosDesgloseMaritimo.detallesCargaMaritimo[index].tipoCargaDetalle[name] = value;
        },
        eliminarContenedorMaritimo: (state, action) => {
            const { indexDesglose, indexDetalleDeCarga } = action.payload;
            state.embarqueData.desglosesEmbarques[indexDesglose].datosDesgloseMaritimo.detallesCargaMaritimo.splice(indexDetalleDeCarga, 1);
        },
        actualizarDatosDesgloseAereo: (state, action) => {
            const { indexDesglose, name, value } = action.payload;
            state.embarqueData.desglosesEmbarques[indexDesglose].datosDesgloseAereo[name] = value;
        }
    }
});

function dimensionCargaBultos(state, indexDesglose) {
    const dimensionesCarga = state.embarqueData.desglosesEmbarques[indexDesglose].dimensionesCarga;

    const totalDeBultos = calcularTotalBultos(dimensionesCarga);

    const pesoTotalBruto = calcularPesoTotalBruto(dimensionesCarga);

    state.embarqueData.desglosesEmbarques[indexDesglose].cantidadTotalBultos = totalDeBultos;
    state.embarqueData.desglosesEmbarques[indexDesglose].pesoTotalBruto = pesoTotalBruto;
}

export const {
    setEmbarque,
    setIdEmbarque,
    resetEmbarque,
    actualizarDatosEmbarqueMaritimo,
    actualizarDatosEmbarqueAereo,
    updateDesgloseEmbarque,
    updateTipoCargaDetalle,
    nuevaDimensionDeCarga,
    actualizarDimensionDeCarga,
    eliminarDimensionDeCarga,
    eliminarDesgloseEmbarque,
    nuevoRepartoDireccion,
    actualizarRepartoDireccion,
    eliminarRepartoDireccion,
    remplazarRepartoDireccion,
    actualizarDatosDesgloseEmbarqueMaritimo,
    nuevoContenedorMaritimo,
    actualizarContenedorMaritimo,
    eliminarContenedorMaritimo,
    actualizarTipoCargaDetalleEnMaritimo,
    actualizarDatosDesgloseAereo } = embarqueSlice.actions;

export default embarqueSlice.reducer;