import React, { useEffect } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { useDispatch, useSelector } from 'react-redux';
import { listarFacturasClientePorIdEmbarque } from '../../../../controllers/admin/facturas/facturaClienteController';
import { agregarFacturaCliente, setFacturasCliente } from '../../../../redux/features/admin/controlCostosSlice';
import { setLoading } from '../../../../redux/features/loaderSlice';
import FacturaCliente from './FacturaCliente';
import { ToastError } from '../../../../utils/toastMessage';

function FacturasCliente({ idEmbarque, fetchbuscarEmbarqueReqDTOPorId, clientes }) {
    const facturasCliente = useSelector((state) => state.controlCostos.facturasCliente);
    const dispatch = useDispatch();

    useEffect(() => {
        fetchListarFacturasClientePorIdEmbarque();
    }, [])

    const fetchListarFacturasClientePorIdEmbarque = async () => {
        try {
            dispatch(setLoading({isLoading: true}))
            const res = await listarFacturasClientePorIdEmbarque(idEmbarque);
            dispatch(setLoading({isLoading: false}))
            if (res.error) return ToastError(res.message)
            dispatch(setFacturasCliente(res.data))
        } catch (e) {
            ToastError(e.message)
        }
    }

    const recargarEmbarqueData = async () =>{
        await fetchListarFacturasClientePorIdEmbarque();
        await fetchbuscarEmbarqueReqDTOPorId(idEmbarque)
    }

    return (
        <div className='m-2'>
            <Accordion defaultActiveKey={0}>
                {facturasCliente.map((facturaCliente, indexFC) => {
                    return (
                        <Accordion.Item eventKey={indexFC} key={indexFC}>
                            <Accordion.Header>
                                {facturaCliente.esComplementaria ? "Factura Complementaria" : "Factura"}
                            </Accordion.Header>
                            <Accordion.Body>
                                <FacturaCliente
                                indexFC={indexFC}
                                facturaCliente={facturaCliente}
                                idEmbarque={idEmbarque}
                                recargarEmbarqueData={recargarEmbarqueData}
                                clientes={clientes}></FacturaCliente>
                            </Accordion.Body>
                        </Accordion.Item>)
                })
                }
            </Accordion>
            <div className='col-12 mt-2 mb-2' align="right">
                <button type='button' className='btn btn-dark'
                onClick={(()=>{dispatch(agregarFacturaCliente())})}>
                    <i className="bi bi-plus-lg"></i>
                    Factura Complementaria
                </button>
            </div>
        </div>
    )
}

export default FacturasCliente