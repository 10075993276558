//MUI
export const BASIC_OPTIONS_MUI = {
    print: false,
    selectableRowsHideCheckboxes: true,
    downloadOptions: {
        filterOptions: {
            useDisplayedColumnsOnly: true,
            useDisplayedRowsOnly: true,
        }
    },
    textLabels: {
        body: {
            noMatch: "Lo sentimos, no se encontraron registros.",
            toolTip: "Sort",
            columnHeaderTooltip: column => `Sort for ${column.label}`
        },
        pagination: {
            next: "Siguiente",
            previous: "Anterior",
            rowsPerPage: "Filas por página",
            displayRows: "de"
        },
    }
}

export const OPTIONS_MUI_ONLY_TABLE = {
    search: false, // oculta la barra de búsqueda
    download: false, // oculta el botón de descarga
    print: false, // oculta el botón de impresión
    filter: false, // oculta la función de filtrado
    viewColumns: false, // oculta el botón de visualización de columnas
    selectableRowsHideCheckboxes: true, // oculta la selección de filas
    textLabels: {
        body: {
            noMatch: "Lo sentimos, no se encontraron registros.",
            toolTip: "Sort",
            columnHeaderTooltip: column => `Sort for ${column.label}`
        },
        pagination: {
            next: "Siguiente",
            previous: "Anterior",
            rowsPerPage: "Filas por página",
            displayRows: "de"
        },
    }
}

export const OPTIONS_MUI_ONLY_SEARCH = {
    download: false, // oculta el botón de descarga
    print: false, // oculta el botón de impresión
    filter: false, // oculta la función de filtrado
    viewColumns: false, // oculta el botón de visualización de columnas
    selectableRowsHideCheckboxes: true, // oculta la selección de filas
    textLabels: {
        body: {
            noMatch: "Lo sentimos, no se encontraron registros.",
            toolTip: "Sort",
            columnHeaderTooltip: column => `Sort for ${column.label}`
        },
        pagination: {
            next: "Siguiente",
            previous: "Anterior",
            rowsPerPage: "Filas por página",
            displayRows: "de"
        },
    }
}

export const OPTIONS_ID_MUI = {
    display: "excluded",
    filter: false,
    sort: false,
    download: false,
}

export const OPTIONS_CUSTOM_RETURN_COLUMN = {
    filter: false,
    sort: false,
    download: false,
}