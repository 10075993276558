//URLS CONTROLLERS
export const URL_ADMIN_USERS = "/admin/usuarios";
export const URL_EMBARQUES = "/admin/embarques";
export const URL_DESGLOSE_EMBARQUE = "/admin/desglose-embarque"
export const URL_DIMENSION_CARGA = "/admin/dimension-carga";
export const URL_CATALOGO_TIPO_SERVICIO = "/admin/embarques/catalogos/tipo-servicios";
export const URL_CATALOGO_TIPO_TRAFICO = "/admin/embarques/catalogos/tipo-trafico";
export const URL_CATALOGO_TIPO_UNIDAD_DESGLOSE = "/admin/embarques/catalogos/tipo-unidad-desglose";
export const URL_CATALOGO_TIPO_CARGA_DESGLOSE = "/admin/embarques/catalogos/tipo-carga-desglose";
export const URL_CATALOGO_TIPO_SERVICIO_DESGLOSE = "/admin/embarques/catalogos/tipo-servicio-desglose";
export const URL_FACTURA_CLIENTE = "/admin/factura-cliente"
export const URL_COSTO_PROVEEDOR = "/admin/costos-proveedor";
export const URL_FACTURA_PROVEEDOR = "/factura/proveedor"
export const URL_DIRECCION_FISCAL = "/admin/direccion-fiscal";
export const URL_REPARTO_DIRECCION_DESGLOSE = "/admin/reparto-direccion-desglose";
export const URL_CATALOGO_TIPO_MARITIMO_SERVICIO = "/admin/embarques/catalogos/tipo-servicio-maritimo";
export const URL_CATALOGO_NAVIERA = "/admin/embarques/catalogos/naviera";
export const URL_CATALOGO_AEROLINEA = "/admin/embarques/catalogos/aerolinea";
export const URL_CATALOGO_TIPO_SERVICIO_AEREO = "/admin/embarques/catalogos/tipo-servicio-aereo";
export const URL_CATALOGO_CONTENEDOR_MARITIMO = "/admin/embarques/catalogos/contenedor-maritimo";
export const URL_DETALLE_CARGA_MARITIMO = "/admin/embarques/desglose/detalle-carga-maritimo"
export const URL_CATALOGO_REGIMEN_FISCAL = "/admin/sat/catalogo-regimen-fiscal";
export const URL_CATALOGO_INCOTERMS = "/admin/embarques/catalogos/incoterms";
export const URL_CATALOGO_BUSCADOR = "/admin/catalogo/buscador"
export const URL_GASTO_FACTURA_CLIENTE = "/admin/factura/gastos"
export const URL_COMPLEMENTO_PAGO = "/complemento-pago"
export const URL_PODS = "/pods"
export const URL_ANTICIPO_COSTO_PROVEEDOR = "/admin/anticipo-proveedor"
export const URL_CATALOGO_UBICAICON = "/admin/embarques/catalogos/ubicacion"
export const URL_PAISES = "/admin/paises"
export const URL_USER_EMAILS = "/admin/user-email"
export const URL_SEND_EMAILS = "/admin/send-email"
export const URL_HISTORY = "/history";
export const URL_NOTAS = "/admin/notas/";

export const ID_ROL_CLIENTES = 3;
export const ID_ROL_PROVEEDORES = 2;
export const ID_ROL_LOGISTICA = 5;
export const ID_ROL_ADMIN = 1;
export const ID_ROL_OPERATIVO = 4;
export const URL_DIRECCION_CLIENTE = "/admin/direccion-cliente";

//File uploader
export const ARCHIVOS_PERMITIDOS = ["PDF", "JPG", "PNG", "JPEG"]
export const ARCHIVOS_PERMITIDOS_V2 = ["PDF", "JPG", "PNG", "JPEG", "ZIP", "RAR", "eml"]
export const TIPO_ARCHIVO_PDF = "application/pdf";
export const TIPO_ARCHIVOS_IMG = ["image/jpeg", "image/png", "image/jpg"]
export const TIPO_ARCHIVO_ZIP = ["application/x-zip-compressed", "application/x-compressed", "application/zip", "application/rar"]
export const TIPO_ARCHIVO_OUTLOOK = ["message/rfc822"]

//Tipos DE SERVICIOS EMBARQUES
export const ID_SERVICIO_EMBARQUE_NACIONAL = 1;
export const ID_SERVICIO_EMBARQUE_IMPORTACION = 2;
export const ID_SERVICIO_EMBARQUE_EXPORTACION = 3;
export const ID_SERVICIO_EMBARQUE_ADUANAS = 4;

//TIPOS DE TRAFICOS
export const ID_TRAFICO_TERRESTRE = 1;
export const ID_TRAFICO_MARITIMO = 2;
export const ID_TRAFICO_AEREO = 3;
export const ID_TRAFICO_INTERMODAL = 4; //El intermodal lleva los mismos datos que el maritimo

//TIPOS DE SERVICIOS 
export const ID_SERVICIO_CARGA_CONSOLIDADA = 1;
export const ID_SERVICIO_CARGA_DEDICADA = 2;

//SERVICIOS MARITIMOS
export const ID_SERVICIO_MARITIMO_D2D = 1;
export const ID_SERVICIO_MARITIMO_D2T_DISCHARGE = 2;
export const ID_SERVICIO_MARITIMO_P2P = 3;
export const ID_SERVICIO_MARITIMO_P2D = 4;

//MODALIDADES MARITIMAS
export const MODALIDAD_MARITIMA_LCL = "LCL";
export const MODALIDAD_MARITIMA_FCL = "FCL"

//SERVICIOS AEREOS
export const ID_SERVICIO_AEREO_D2D = 1;
export const ID_SERVICIO_AEREO_DTAOD = 2;
export const ID_SERVICIO_AEREO_ATD = 3;
export const ID_SERVICIO_AEREO_ATA = 4;

//ESTATUS FACTURA PROVEEDOR
export const ID_ESTATUS_FP_PROGRAMADA_PAGO = 1;
export const ID_ESTATUS_FP_COMPLETADO = 2;
export const ID_ESTATUS_FP_PENDIENTE_APROBACION = 3;
export const ID_ESTATUS_FP_FACTURA_RECHAZADA = 4;


//AWS S3
export const BASE_URL_S3_DEVELOPMENT ="https://kjlogistics.s3.us-west-1.amazonaws.com/"
export const BASE_URL_S3 =BASE_URL_S3_DEVELOPMENT//"https://kjlogistics.s3.us-west-1.amazonaws.com/"
export const BASE_URL_S3_FACTURAS_PROVEEDORES = BASE_URL_S3 + "facturas-proveedores/";
export const BASE_URL_S3_ARCHIVOS_USUARIOS = BASE_URL_S3 + "archivos-usuarios/"
export const BASE_URL_S3_FACTURAS_CLIENTES = BASE_URL_S3 + "facturas-clientes/"
export const BASE_URL_S3_COMPROBANTES_CLIENTES = BASE_URL_S3 + "comprobantes-facturas-clientes/"
export const BASE_URL_S3_COMPROBANTES_PROVEEDORES = BASE_URL_S3 + "comprobantes-facturas-proveedores/"
export const BASE_URL_S3_PODS_EMBARQUES = BASE_URL_S3 + "pods_embarques/"
export const BASE_URL_S3_DOCUMENTOS_EMBARQUE = BASE_URL_S3 + "documentos-embarque/"
export const BASE_URL_S3_COMPLEMENTO_PAGO = BASE_URL_S3 + "complementos-pago-proveedor/"
export const BASE_URL_S3_ANTICIPOS_PROVEEDORES = BASE_URL_S3 + "anticipos-proveedores/"
export const BASE_URL_S3_COTIZACIONES = BASE_URL_S3 + "cotizaciones/"
export const BASE_URL_S3_QUOTATION_FILES = BASE_URL_S3 + "quotation-files/"

//Estatus Facutra Proveedores
export const ESTATUS_FP_FALTA_COMPROBANTE = 1;
export const ESTATUS_FP_COMPLETADO = 2;


