import React from 'react'
import { Link } from 'react-router-dom';
import LogoKJ from '../assets/imgs/logo.png'

function Error404() {

    return (
        <div className="d-flex align-items-center justify-content-center vh-100">
            <div className="text-center row">
                <div className=" col-md-6">
                    <img src={LogoKJ} alt=""
                        className="img-fluid" />
                </div>
                <div className=" col-md-6 mt-5">
                    <p className="fs-3"> <span className="text-danger">Opps!</span> Pagina no encontrada.</p>
                    <p className="lead">
                        La página que buscas no existe.
                    </p>
                    <Link to={"/"} className="btn btn-primary">
                        Regresar
                    </Link>
                </div>

            </div>
        </div>
    )
}

export default Error404