import { updateAttributeArray } from "../helpersState";

export const createResizableTable = function (setWidthColumns, attributeName, colsName) {
    const cols = document.getElementsByClassName(colsName);
    [].forEach.call(cols, function (col, index) {
        // Add a resizer element to the column
        const resizer = document.createElement('div');
        resizer.classList.add('resizer');

        // Set the height
        resizer.style.height = "100px";

        col.appendChild(resizer);
        createResizableColumn(col, resizer, setWidthColumns, attributeName, index);
    });
};

const createResizableColumn = function (col, resizer, setWidthColumns, attributeName, index) {
    let x = 0;
    let w = 0;

    const mouseDownHandler = function (e) {
        x = e.clientX;

        const styles = window.getComputedStyle(col);
        w = parseInt(styles.width, 10);

        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);

        resizer.classList.add('resizing');
    };

    const mouseMoveHandler = function (e) {
        const dx = e.clientX - x;
        updateAttributeArray(index, setWidthColumns, {[attributeName]: w+dx})
        col.style.width = (w + dx) + 'px';
    };

    const mouseUpHandler = function () {
        resizer.classList.remove('resizing');
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
    };

    resizer.addEventListener('mousedown', mouseDownHandler);
};

export const adjustResizableTable = (setTableColumns, hasNewColumn) => {
    let sizeTableV = 766;
    let sizeTableH = 1092;

    setTableColumns((tableColumns) => {
      const mTableColumns = [...tableColumns];
      const totalColumns = mTableColumns.length;
      const widthVPerColumn = sizeTableV / totalColumns;
      const widthHPerColumn = sizeTableH / totalColumns;
      mTableColumns.forEach((column) => {
        column.widthVPdf = column.widthVPdf || widthVPerColumn;
        column.widthHPdf = column.widthHPdf || widthHPerColumn;
        if (hasNewColumn) {
            column.widthVPdf = widthVPerColumn;
            column.widthHPdf = widthHPerColumn;
        }
    });
      return mTableColumns;
    });
  };