import { CATALOGO_ESTATUS_COTIZACIONES, buscarLabel } from "../../../../../utils/catalogosEstaticos";
import { OPTIONS_CUSTOM_RETURN_COLUMN } from '../../../../../utils/mui/constantes';

export const columnEstatusCotizacion = (params) => {
    const { state } = params;

    return {
        name: "ESTATUS",
        options: {
            customBodyRenderLite: (dataIndex) => {
                const idStatus = state[dataIndex].idStatus
                const nameStatus = buscarLabel(idStatus, CATALOGO_ESTATUS_COTIZACIONES);
                
                const nameBadge = idStatus == 1 ? "primary" : idStatus == 2 ? "success" : idStatus == 3 ? "danger" : idStatus == 4 ? "warning" : ""
                return <span className={`badge bg-${nameBadge}`}>{nameStatus}</span>
            },
            ...OPTIONS_CUSTOM_RETURN_COLUMN
        }
    }
}