import { Field, FieldArray } from 'formik';
import FormikGenericDate from '../../../formik/FormikGenericDate';

function RepartosDireccionDesglose({ values }) {

    return (
        <>
            <FieldArray
                name="repartosDireccion"
                render={arrayHelpers => (
                    <>
                        <div className="col-sm-12 mb-3" align="right">
                            <button type="button" className='btn btn-primary' onClick={() => arrayHelpers.push({})}>
                                <i className="bi bi-plus" /> Reparto
                            </button>
                        </div>
                        {values.repartosDireccion?.map((repartoDireccion, index) => {
                            return (
                                <div className='row mt-2 mb-2' key={index}>
                                    {index == 0 ?
                                        <hr /> : <></>
                                    }
                                    <div className='col-sm-6 mb-1'>
                                        <label htmlFor="inputOrigen" className="form-label">Direccion de Reparto</label>
                                        <Field id="direccionDeReparto" name={`repartosDireccion[${index}].direccionDeReparto`} className="form-control" />
                                    </div>
                                    <div className='col-sm-2 mb-1'>
                                        <Field
                                            name={`repartosDireccion[${index}].fechaEstimada`}
                                            component={FormikGenericDate}
                                            nameLabel="Fecha Estimada" />
                                    </div>
                                    <div className='col-sm-2 mb-1'>
                                        <Field
                                            name={`repartosDireccion[${index}].fechaEfectiva`}
                                            component={FormikGenericDate}
                                            nameLabel="Fecha Efectiva" />
                                    </div>
                                    <div className='col-sm-2'>
                                        <br />
                                        <button type='button' className='btn btn-sm btn-danger'
                                            onClick={() => arrayHelpers.remove(index)}>
                                            <i className="bi bi-trash"></i>
                                        </button>
                                    </div>

                                </div>
                            )
                        })}
                        {values.repartosDireccion.length >= 1 ?
                            <hr /> : <></>
                        }
                    </>)} />
        </>
    )
}

export default RepartosDireccionDesglose