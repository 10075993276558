import { URL_CATALOGO_TIPO_UNIDAD_DESGLOSE } from '../../../../utils/constantes';
import { apiRequest } from '../../../handlerApiRequest';
import { apiRequestV2 } from '../../../handlerApiRequestV2';

export const listarCatalogoTiposUnidades = async () => {
    return await apiRequestV2({
        endpoint: URL_CATALOGO_TIPO_UNIDAD_DESGLOSE
    });
}

export const guardarTipoUnidad = async (tipoUnidad) => {
    URL_CATALOGO_TIPO_UNIDAD_DESGLOSE, "POST", tipoUnidad
    return await apiRequestV2({
        endpoint: URL_CATALOGO_TIPO_UNIDAD_DESGLOSE,
        method: "POST",
        data: tipoUnidad,
        showSuccessMessage: true
    });
}