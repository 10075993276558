import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { listaUsuariosPorIdRol } from "../../controllers/admin/usuariosController";
import { setLoading } from "../../redux/features/loaderSlice";
import { ID_ROL_LOGISTICA } from "../../utils/constantes";
import ModalFormUsuarios from "../../components/admin/ModalFormUsuarios";
import TableLogistica from "../../components/admin/Logistica/TableLogistica";
import { Modal } from "@mui/material";

function Logistica() {
  const [logisticas, setLogisticas] = useState([]);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);


  const handleOpen = async () => {
    dispatch(setLoading({ isLoading: true }))
    setOpen(true);
    dispatch(setLoading({ isLoading: false }))
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchDataLogistica();
  }, []);

  const fetchDataLogistica = async () => {
      const data = await listaUsuariosPorIdRol(ID_ROL_LOGISTICA);
      setLogisticas(data);
  };

  return (
    <>

      <button className="btn btn-dark m-1" align="right" type="button" onClick={handleOpen}>
        <i className="bi bi-plus-lg"></i> Logísticas
      </button>

      <Modal open={open} onClose={handleClose}>
        <div className="card style-modal-xl">
          <div className="card-header d-flex justify-content-between">
            Logística
            <button className="btn btn-dark btn-sm" onClick={handleClose}>
              <i className="bi bi-x-lg"></i>
            </button>
          </div>
          <div className="card-body body-modal-scroll">

            <div className="row m-4">
              <ModalFormUsuarios
                url={"/guardar-logistica"}
                fetchDataUsuarios={fetchDataLogistica}
                titulo={"Registrar Logística"}
                idRol={ID_ROL_LOGISTICA}
              >
                <i className="bi bi-plus-lg"></i>
              </ModalFormUsuarios>
              <TableLogistica
                logisticas={logisticas}
                fetchDataUsuarios={fetchDataLogistica}
              ></TableLogistica>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Logistica;


