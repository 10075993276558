import React from 'react';
import Notas from './notas/Notas';


function CollapseLandingPage({idEmbarque}) {
    return (
        <div className="row">
            <div className="col" >
                <button className="btn btn-primary btn-sm" type="button" data-bs-toggle="collapse" data-bs-target="#collapseWidthExample" aria-expanded="false" aria-controls="collapseWidthExample">
                    LANDING PAGE
                </button>
                <div className="collapse" id="collapseWidthExample" style={{ position: "absolute", zIndex: 99999, width: "100%", backgroundColor: "#21222273" }}>
                    <div className='card style-modal-md'>
                        <div className="card-body body-modal-scroll">
                            <Notas idEmbarque={idEmbarque}></Notas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CollapseLandingPage