import BASE_URL_BACKEND from "../api/baseUrl";
import { authToken, getAuthUser } from "../utils/authUtils";
import { URL_HISTORY } from "../utils/constantes";
import { ToastError } from "../utils/toastMessage";
import { apiRequest } from "./handlerApiRequest";
import { getIPFromApi64 } from "../utils/webUtils";

export const saveHistory = async (method, endpoint, status, requestBody, messageResponse) => {
    try {
        const url = `${BASE_URL_BACKEND}${URL_HISTORY}`;
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken()}`
        };

        const userRequest = getAuthUser();

        const idEmbarque = localStorage.getItem("idEmbarque");

        const ipRequest = await getIPFromApi64();

        const body = {
            emailRequest: userRequest?.email,
            metodoHTTP: method,
            endPoint: endpoint,
            ipAddressRequest: ipRequest,
            statusHTTP: status,
            requestBody: status == 200 || status == 201 ? requestBody instanceof Object ? "ES ARCHIVO, VERIFICAR HISTORIAL EN S3 DE AWS" : requestBody : null,
            messageResponse: messageResponse
        }

        if (idEmbarque) body.embarque = { id: idEmbarque }

        const config = {
            method: "POST",
            headers,
            credentials: 'include',
            body: JSON.stringify(body)
        };


        const res = await fetch(url, config);
        const data = await res.json();
    } catch (e) {
        console.error("OCURRIO UN ERROR AL GUARDAR EL HISTORIAL", e.message);
    }

}
export const findHistoryByIdEmbarque = async (idEmbarque) => {
    const res = await apiRequest(URL_HISTORY + `/embarque/${idEmbarque}`);
    if (res.error) return ToastError(res.message)
    return res;
}