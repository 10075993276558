import React, { useState } from 'react'
import Modal from '@mui/material/Modal';
import { addItem, deleteItem, handleChangeArrayText, handleChangeMultipleFile } from '../../../../utils/helpersState';
import ModalArchivo from '../../../ModalArchivo';
import CustomFileUploader from '../../../inputs/CustomFileUploader';
import { OPTIONS_MUI_ONLY_TABLE } from '../../../../utils/mui/constantes';
import MUIDataTable from "mui-datatables";
import { ARCHIVOS_PERMITIDOS, BASE_URL_S3_ANTICIPOS_PROVEEDORES } from '../../../../utils/constantes';
import { useDispatch } from 'react-redux';
import { findAllByIdCostoProveedor, logicDeleteAnticipo, saveAnticipoCostoProveedor } from '../../../../controllers/admin/facturas/anticipoCostoProveedorController';
import { setLoading } from '../../../../redux/features/loaderSlice';
import { ToastError, ToastSuccess } from '../../../../utils/toastMessage';
import ModalEliminarObjeto from '../../ModalEliminarObjeto';

function AnticipoCostoProveedor({ idCostoProveedor }) {
    const [open, setOpen] = useState(false);
    const [anticipos, setAnticipos] = useState([]);
    const dispatch = useDispatch()

    const handleOpen = async () => {
        fetchAnticipos()
        setOpen(true)
    }

    const handleClose = () => {
        setAnticipos([])
        setOpen(false);
    }

    const handlerHandleChangeFile = async (index, file, name) => {
        await handleChangeMultipleFile(index, file, name, anticipos, setAnticipos, dispatch);

    }

    const onSubmit = async (indexAnticipo) => {
        try {
            dispatch(setLoading({ isLoading: true }));
            const res = await saveAnticipoCostoProveedor(
                anticipos[indexAnticipo].archivoPdfACargar,
                anticipos[indexAnticipo]
            );

            dispatch(setLoading({ isLoading: false }))
            if (res.error) return ToastError(res.message)
            fetchAnticipos()
            return ToastSuccess(res.message)
        } catch (e) {

            return ToastError(e.message)
        }
    }

    const fetchAnticipos = async () => {
        const res = await findAllByIdCostoProveedor(idCostoProveedor);
        setAnticipos(res.data)
    }


    const fetchLogicDelete = async (idAnticipo, indexAnticipo) => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const res = await logicDeleteAnticipo(idAnticipo, anticipos[indexAnticipo])
            dispatch(setLoading({ isLoading: false }))
            if (res.error) return ToastError(res.message)
            deleteItem(indexAnticipo, anticipos, setAnticipos)
            return ToastSuccess(res.message)
        } catch (err) {
            return ToastError(err)
        }
    }


    const columnsComplemento = [
        {
            name: "Id",
            options: {
                display: "excluded",
                filter: false,
                sort: false,
                download: false,
            }
        },
        {
            name: "PDF",
            options: {
                customBodyRenderLite: (indexAnticipo) => {
                    return (
                        <div className='pb-2'>
                            <CustomFileUploader
                                handleChangeFile={handlerHandleChangeFile}
                                index={indexAnticipo}
                                fileTypes={ARCHIVOS_PERMITIDOS}
                                multiple={true}
                                name={"archivoPdfACargar"}
                                nameFile={anticipos[indexAnticipo].archivoPdfACargar?.name}></CustomFileUploader>
                            {anticipos[indexAnticipo].archivoPDF ?
                                <ModalArchivo
                                    title={"Anticpos"}
                                    urlBase={BASE_URL_S3_ANTICIPOS_PROVEEDORES}
                                    archivoS3={anticipos[indexAnticipo].archivoPDF}></ModalArchivo>
                                : ""}
                        </div>
                    )
                },
                filter: false,
                sort: false,
                download: false,
            }
        },
        {
            name: "DESCRIPCIÓN",
            options: {
                customBodyRenderLite: (indexAnticipo) => {
                    return (
                        <>
                            <textarea className="form-control mt-1"
                                type="text"
                                value={anticipos[indexAnticipo].descripcion}
                                onChange={(e) => { handleChangeArrayText(e, indexAnticipo, anticipos, setAnticipos, true) }}
                                name="descripcion"></textarea>
                        </>
                    )
                },
                filter: false,
                sort: false,
                download: false,
            }
        },
        {
            name: "",
            options: {
                customBodyRenderLite: (indexAnticipo) => {
                    return (
                        <button type='button' className='btn btn-primary'
                            onClick={() => onSubmit(indexAnticipo)}>
                            {anticipos[indexAnticipo].id ? "ACTUALIZAR" : "GUARDAR"}
                        </button>
                    )
                },
                filter: false,
                sort: false,
                download: false,
            }
        },
        {
            name: "",
            options: {
                customBodyRenderLite: (indexAnticipo) => {
                    return (
                        <>
                            {!anticipos[indexAnticipo].estaEliminado ?
                                <>
                                    {anticipos[indexAnticipo].id ?
                                        <ModalEliminarObjeto
                                            textHeader={"¿Seguro de eliminar este complemento?"}
                                            textBody={""}
                                            functionEliminar={fetchLogicDelete}
                                            id={anticipos[indexAnticipo].id}
                                            index1={indexAnticipo}>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                placeholder='Razón de la eliminación'
                                                name='razonEliminacion'
                                                value={anticipos[indexAnticipo].razonEliminacion}
                                                onChange={(e) => { handleChangeArrayText(e, indexAnticipo, anticipos, setAnticipos, true) }} />
                                        </ModalEliminarObjeto>
                                        :
                                        <button type='button' className='btn btn-danger'
                                            onClick={() => deleteItem(indexAnticipo, anticipos, setAnticipos)}>
                                            <i className="bi bi-trash"></i>
                                        </button>}

                                </>
                                :
                                ""
                            }
                        </>
                    )
                },
                filter: false,
                sort: false,
                download: false,
            }
        }
    ]

    return (
        <>
            <button
                className="btn btn-dark btn-sm"
                type="button" onClick={handleOpen}>
                <i className="bi bi-cash-coin"></i>
            </button>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <div className='card style-modal-md'>
                    <div className='card-header d-flex justify-content-between'>
                        ANTICIPOS
                        <button className='btn btn-dark btn-sm'
                            onClick={handleClose}>
                            <i className="bi bi-x-lg"></i>
                        </button>
                    </div>
                    <div className='card-body body-modal-scroll'>
                        <div className='col-12' align="right">
                            <button type='button' className='btn btn-dark m-2'
                                onClick={() => { addItem(anticipos, setAnticipos, { costoProveedor: { id: idCostoProveedor } }) }}>
                                <i className="bi bi-plus"></i> AGREGAR ANTICIPO
                            </button>
                        </div>
                        <MUIDataTable
                            title={"Anticipos de Proveedores"}
                            data={anticipos}
                            columns={columnsComplemento}
                            options={OPTIONS_MUI_ONLY_TABLE}
                        />
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default AnticipoCostoProveedor