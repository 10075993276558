import { OPTIONS_CUSTOM_RETURN_COLUMN } from '../../utils/mui/constantes';
import { generateNameUser } from '../../utils/helpersFormat';

export const columnFormatNameUser = (params) => {
    const { state, nameAttribute, nameColumn } = params;
    
    return {
        name: nameColumn,
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (
                    generateNameUser(state[dataIndex][nameAttribute])
                )
            },
            ...OPTIONS_CUSTOM_RETURN_COLUMN
        }
    }
}