import { CATALOGO_TIPOS_SERVICIO_COTIZACIONES } from "../../../utils/catalogosEstaticos"
import { URL_CONDICIONES_ADUANA } from "../../../utils/endpoints/cotizaciones"
import { apiRequestV2 } from "../../handlerApiRequestV2"


export const findAllCondicionesAduana = async () => {
    return await apiRequestV2({
        endpoint: URL_CONDICIONES_ADUANA
    })
}

export const saveCondicionAduana = async (condicion) => {
    return await apiRequestV2({
        endpoint: URL_CONDICIONES_ADUANA,
        method: "POST",
        data: condicion,
        showSuccessMessage: true
    })
}

export const updateCondicionAduana = async (condicion, idCS) => {
    return await apiRequestV2({
        endpoint: `${URL_CONDICIONES_ADUANA}/${idCS}`,
        method: "PUT",
        data: condicion,
        showSuccessMessage: true
    })
}

export const deleteCondicionAduana = async (idCS) => {
    return await apiRequestV2({
        endpoint: `${URL_CONDICIONES_ADUANA}/${idCS}`,
        method: "DELETE",
        showSuccessMessage: true
    })
}

export const getCondicionesAduanas = async (values) => {
        let serviceIsAduanas = false;
        let condiciones = [];

        values.ventas?.forEach(venta => {
            if (venta.idServicio == CATALOGO_TIPOS_SERVICIO_COTIZACIONES[3].id) {
                serviceIsAduanas = true;
                return;
            }
        });

        if (serviceIsAduanas) {
            const data = await findAllCondicionesAduana();
            if (Array.isArray(data)) {
                const formatCondicion = data.map((condicion) => [
                    condicion.nombre
                ]);
                condiciones.push(...formatCondicion);
            }

            return condiciones;
        }
}
