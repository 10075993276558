import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import SelectSearchDB from "../../../inputs/SelectSearchDB";
import { findProveedoresBysearchText } from "../../../../controllers/admin/usuariosController";
import FechaGeneric from "../../../inputs/FechaGeneric";
import moment from "moment-timezone";
import { ToastWarning } from "../../../../utils/toastMessage";

function GeneradorFakeXML() {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});

  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setData({})
  };

  const handleDateTimeChange = (date, nameInput) => {
    const formattedDateTime = date
      ? moment(date).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
      : null;
    let e = {
      target: {
        name: nameInput,
        value: formattedDateTime,
      },
    };
    handlerChangeInputs(e);
  };

  const handlerChangeInputs = (e) => {
    const copyState = { ...data };
    copyState[e.target.name] = e.target.value;
    setData(copyState);
  };

  const generateUUID = () => {
    var d = new Date().getTime();
    var uuid = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );

    return uuid;
  };

  const descargarXML = (e) => {
    e.preventDefault();
    if (data.proveedores == null) return ToastWarning("Porfavor ingresa un proveedor.");
    const uuid = generateUUID();
    let xml = "";

    xml += "<cfdi:Comprobante\n";
    xml += 'xmlns:cfdi="http://www.sat.gob.mx/cfd/4"\n';
    xml +=
      'xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sat.gob.mx" Version="4.0" TipoDeComprobante="I" NoCertificado="0000" SubTotal="' +
      data?.subtotal +
      '" Folio="' +
      data?.folio +
      '" Serie="' +
      data?.serie +
      '" Fecha="' +
      data?.fechaTimbrado +
      '" Total="' +
      data?.total +
      '" Moneda="MXN" Exportacion="01" MetodoPago="' +
      data?.proveedores?.metodoDePago +
      '" LugarExpedicion="91700" FormaPago="99" Sello="SELLO-FAKE" Certificado="CERTIFICADO-FAKE">';
    xml +=
      '<cfdi:Emisor Rfc="CEN9812024W6" Nombre="' +
      data?.proveedores?.nombreComercial +
      '" RegimenFiscal="601"/>';
    xml +=
      '<cfdi:Receptor Rfc="KLO210317KN1" Nombre="KARLA-JOSIMAR LOGISTICS" DomicilioFiscalReceptor="76240" RegimenFiscalReceptor="601" UsoCFDI="G03"/>';
    xml += "<cfdi:Complemento>\n";
    xml += "<tfd:TimbreFiscalDigital\n";
    xml +=
      'xmlns:tfd="http://" xsi:schemaLocation="http://" Version="1.1" UUID="' +
      uuid +
      '" FechaTimbrado="' +
      data?.fechaTimbrado +
      '" RfcProvCertif="SAT970701NN3" SelloCFD="fake-sello" NoCertificadoSAT="fake-certificado" SelloSAT="facek-sello"/>\n';
    xml += "</cfdi:Complemento>\n";
    xml += "</cfdi:Comprobante>\n";

    // crea un nuevo objeto `Date`
    let today = new Date();

    // obtener la fecha de hoy en formato `MM/DD/YYYY`
    let fecha = today.toLocaleDateString("en-US");

    const element = document.createElement("a");
    const file = new Blob([xml], { type: "text/xml" });
    element.href = URL.createObjectURL(file);
    element.download =
      "xml-generico-" + data?.proveedores.nombreComercial + "-" + fecha + ".xml";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <>
      <button className="btn btn-dark m-1 " type="button" onClick={handleOpen}>
        <i className="bi bi-file-earmark-arrow-down-fill"></i> XML GENERICO
      </button>
      <Modal open={open} onClose={handleClose}>
        <div className="card style-modal-sm">
          <div className="card-header d-flex justify-content-between">
            XML GENERICO
            <button className="btn btn-dark btn-sm" onClick={handleClose}>
              <i className="bi bi-x-lg"></i>
            </button>
          </div>
          <div className="card-body body-modal-scroll">
            <form className="row" onSubmit={descargarXML}>
              <div className="col-sm-6">
                <SelectSearchDB
                  titulo="Proveedores"
                  value={data?.proveedores}
                  name={"proveedores"}
                  handleChange={handlerChangeInputs}
                  fetchSearch={findProveedoresBysearchText}
                  type={"USERS"}
                  required={true}
                />
              </div>

              <div className="col-sm-6 mb-3">
                <label htmlFor="dimensionVolumen" className="form-label">
                  {" "}
                  Subtotal{" "}
                </label>
                <input
                  type="number"
                  className="form-control col-sm-6"
                  name="subtotal"
                  step="0.0001"
                  required={true}
                  onChange={(e) => handlerChangeInputs(e)}
                  value={data?.subtotal}
                />
              </div>

              <div className="col-sm-6  mb-3">
                <label htmlFor="dimensionVolumen" className="form-label">
                  {" "}
                  Total{" "}
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="total"
                  step="0.0001"
                  onChange={(e) => handlerChangeInputs(e)}
                  value={data?.total}
                />
              </div>

              <div className="col-sm-6 mb-3">
                <label htmlFor="dimensionVolumen" className="form-label">
                  {" "}
                  Folio{" "}
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="folio"
                  onChange={(e) => handlerChangeInputs(e)}
                  value={data?.folio}
                />
              </div>
              <div className="col-sm-6 mb-3">
                <label htmlFor="dimensionVolumen" className="form-label">
                  {" "}
                  Serie{" "}
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="serie"
                  onChange={(e) => handlerChangeInputs(e)}
                  value={data?.serie}
                />
              </div>
              <div className="col-sm-6 mb-3">
                <FechaGeneric
                  fecha={data?.fechaTimbrado}
                  handleDateTimeChange={handleDateTimeChange}
                  nameInput={"fechaTimbrado"}
                  nameLabel={"Fecha Timbrado"}
                  required={true}
                ></FechaGeneric>
              </div>
              <div className="col-sm-6 mb-3">
                <label htmlFor="dimensionVolumen" className="form-label">
                  {" "}
                  Metodo de pago{" "}
                </label>
                <input
                  type="select"
                  className="form-control"
                  name="metodoDePago"
                  disabled="true"
                  value={data?.proveedores?.metodoDePago}
                />
              </div>

              <div align="right">
                <button type="submit" className="btn btn-primary m-1 col-sm-3 ">
                  <i className="bi bi-plus-lg"></i>  GENERAR XML
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default GeneradorFakeXML;
