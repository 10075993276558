import BASE_URL_BACKEND from '../../api/baseUrl'
import { encryptUser } from '../../utils/authUtils';
import { errInt } from '../../utils/resCatch'
import { apiRequest } from "../handlerApiRequest";
import { saveLogging } from './logginFilterController';

//Se encripta usuario antes de guardarlo.
const authLogin = async (email, pass) => {
    try {
        const res = await fetch(BASE_URL_BACKEND + "/auth/login", {
            method: "POST",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                password: pass,
            })
        })
        const data = await res.json();
        if (data.data != null)
            data.data.user = encryptUser(data.data.user);

        //Guardar los usuarios que inicia sesion
        saveLogging(email, data.status);
        return data
    } catch (err) {
        errInt.message += err
        return errInt
    }
}

const verifyToken = async (token) => {
    return await apiRequest("/auth/token-verify")
}

export {
    authLogin,
    verifyToken
}