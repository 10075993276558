import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { eliminarDesgloseEmbarque, setEmbarque } from '../../../../redux/features/admin/embarqueSlice';
import { ID_TRAFICO_TERRESTRE, ID_SERVICIO_MARITIMO_P2P, ID_TRAFICO_AEREO, ID_SERVICIO_AEREO_ATA } from '../../../../utils/constantes';
import { actualizarDesgloseEmbarque, guardarDesgloseEmbarque } from '../../../../controllers/admin/embarques/embarquesController';
import ModalEliminarObjeto from '../../ModalEliminarObjeto';
import { eliminarDesgloseEmbarquePorId } from '../../../../controllers/admin/embarques/desgloses/desgloseEmbarqueController';
import DesgloseTerrestre from './terrestre/DesgloseTerrestre';
import DesgloseMaritimo from './maritimo/DesgloseMaritimo';
import DesgloseAereo from './aereo/DesgloseAereo';
import { fetchEstatusEmbarque } from '../../../../redux/features/admin/estatusEmbarqueSlice';
import GenerarCartaPorte from './carta-porte/GenerarCartaPorte';
import { fetchCostoProveedores } from '../../../../redux/features/admin/controlCostosSlice';
import { findLogisticaesBysearchText, findProveedoresBysearchText } from '../../../../controllers/admin/usuariosController';
import { bloquearEmbarque, isMaritimoAndIntermodal } from '../validations/embarqueValidations';
import { validatePrintIdsCarga } from './validations/validations';
import { CATALOGO_TIPO_CARGA, CATALOGO_TIPO_CARGA_DESGLOSE } from '../../../../utils/catalogosEstaticos';
import { Field, Form, Formik } from 'formik';
import FormikSelectSearchDB from '../../../formik/FormikSelectSearchDB';
import { calcularPesoTotalBruto, calcularTotalBultos } from '../../../../redux/helpers/helperEmbarqueSlice';

function DesglosesEmbarque({
    fetchbuscarEmbarqueReqDTOPorId,
    tiposUnidadDesglose,
    contenedoresMaritimos }) {
    const embarqueData = useSelector((state) => state.embarque.embarqueData);
    const userAuth = useSelector((state) => state.userAuth);
    const [indexSelected, setInexSelected] = useState(0);
    const dispatch = useDispatch();

    const handleSubmitDesgloseEmbarque = async (values) => {
        values.id ? await actualizarDesgloseEmbarque(embarqueData.id, values) : await guardarDesgloseEmbarque(embarqueData.id, values);
        fetchbuscarEmbarqueReqDTOPorId(embarqueData.id)
        dispatch(fetchEstatusEmbarque(embarqueData.id))
        dispatch(fetchCostoProveedores(embarqueData.id))
    }

    const nuevoDesgloseEmbarque = () => {
        //Inicializar componentes controlados
        const agregarDatosDesgloseEmbarqueMaritimo =
            embarqueData.datosEmbarqueMaritimo;
        const agregarDatosDesgloseAereo = embarqueData.datosEmbarqueAereo;


        dispatch(setEmbarque({
            ...embarqueData,
            desglosesEmbarques: [
                ...embarqueData.desglosesEmbarques,
                {
                    dimensionesCarga: [],
                    tipoCargaDetalle: {},
                    datosDesgloseMaritimo: agregarDatosDesgloseEmbarqueMaritimo ? { detallesCargaMaritimo: [] } : null,
                    datosDesgloseAereo: agregarDatosDesgloseAereo ? {} : null,
                    esFletePrincipal: true,
                    modalidadUnidad: 'SENCILLO',
                },
            ],
        }));
    };

    const fetchEliminarDesgloseEmbarque = async (idDesgloseEmbarque, indexDesglose) => {
        await eliminarDesgloseEmbarquePorId(idDesgloseEmbarque);
        dispatch(eliminarDesgloseEmbarque({ indexDesglose }))
    }

    return (
        <>
            {!embarqueData.archivoPOD ?
                <div className='col-12 mt-1 mb-1' align="right">
                    <button type='button' className='btn btn-dark'
                        onClick={nuevoDesgloseEmbarque}
                        disabled={bloquearEmbarque(embarqueData, userAuth)}>
                        <i className="bi bi-plus-lg"></i> Agregar Desglose
                    </button>
                </div>
                :
                <></>
            }
            <div className='row'>
                <div className='col-2'>
                    {embarqueData.desglosesEmbarques.map((des, indexDesglose) => {
                        return (<div key={indexDesglose+Math.random()}>
                            <span style={{ color: indexSelected == indexDesglose ? "#043071" : null, cursor: "pointer", padding: "1px", fontSize: "15px" }} onClick={() => setInexSelected(indexDesglose)}>
                                {indexSelected == indexDesglose ? <i className="bi bi-caret-right-fill"></i> : <i className="bi bi-caret-right-fill" style={{ visibility: "hidden" }}></i>}
                                DETALLES DE CARGA {des.id ? indexDesglose + 1 : <span className="badge text-bg-warning">No Guardado</span>}
                            </span>
                            {
                                des?.id == null ?
                                    <span style={{ color: "red", cursor: "pointer" }} onClick={() => { dispatch(eliminarDesgloseEmbarque({ indexDesglose })) }}>
                                        <i className="bi bi-trash"></i>
                                    </span>
                                    : null
                            }
                            {des?.datosDesgloseMaritimo?.detallesCargaMaritimo.map((detalleDeCarga) => {
                                return (
                                    <>
                                        <br />
                                        <span style={{ color: "gray", padding: "1px", fontSize: "12px", paddingLeft: "10%" }}>
                                            {detalleDeCarga?.noDeContenedor ? detalleDeCarga?.noDeContenedor : "???????????"} - {detalleDeCarga?.contenedorMaritimo?.nombre ? detalleDeCarga?.contenedorMaritimo?.nombre : "????"}
                                        </span>
                                    </>
                                )
                            })}
                            <br />
                        </div>)
                    })}
                </div>
                <div className='col-10' style={{ overflowY: 'auto', minHeight: embarqueData.desglosesEmbarques?.length > 0 ? '500px' : '100%', maxHeight: '500px' }}>
                    {embarqueData.desglosesEmbarques.map((des, indexDesglose) => {
                        return (
                            <>
                                {indexSelected == indexDesglose ?
                                    <div key={indexDesglose+Math.random()}>
                                        <Formik
                                            initialValues={des}
                                            onSubmit={async (values) => {
                                                if(values.dimensionesCarga?.length >= 1){
                                                    const totalDeBultos = calcularTotalBultos(values.dimensionesCarga);
                                                    const pesoTotalBruto = calcularPesoTotalBruto(values.dimensionesCarga);
                                                    values.cantidadTotalBultos = totalDeBultos;
                                                    values.pesoTotalBruto = pesoTotalBruto;
                                                }
                                                await handleSubmitDesgloseEmbarque(values)
                                            }}
                                            enableReinitialize
                                        //validationSchema={}
                                        >
                                            {({ values }) => (
                                                <Form>
                                                    <div className='row'>
                                                        <div className='col-12' align="right">
                                                            {des.id ?
                                                                <>
                                                                    <GenerarCartaPorte
                                                                        desglose={des}>
                                                                    </GenerarCartaPorte>
                                                                    <ModalEliminarObjeto
                                                                        textHeader={"¿Está seguro que deseas eliminar este desglose de embarque?"}
                                                                        textBody={"Se eliminara permanentemente de la base de datos con sus dimensiones de carga y los costos."}
                                                                        functionEliminar={fetchEliminarDesgloseEmbarque}
                                                                        id={des.id}
                                                                        index1={indexDesglose}
                                                                        isDisabled={bloquearEmbarque(embarqueData, userAuth)}></ModalEliminarObjeto>
                                                                </>
                                                                :
                                                                <button type='button' className='btn btn-danger ms-auto'
                                                                    onClick={() => { dispatch(eliminarDesgloseEmbarque({ indexDesglose })) }}>
                                                                    <i className="bi bi-trash"></i>
                                                                </button>
                                                            }
                                                        </div>
                                                        {
                                                            embarqueData.tipoTrafico?.id == ID_TRAFICO_TERRESTRE ||
                                                                (embarqueData.datosEmbarqueMaritimo
                                                                    && embarqueData.datosEmbarqueMaritimo.servicioMaritimo?.id != ID_SERVICIO_MARITIMO_P2P)
                                                                || (embarqueData.tipoTrafico?.id == ID_TRAFICO_AEREO && embarqueData.datosEmbarqueAereo
                                                                    && embarqueData.datosEmbarqueAereo.servicioAereo?.id != ID_SERVICIO_AEREO_ATA) ?
                                                                <>
                                                                    <div className="col-sm-6 mb-1">
                                                                        <Field
                                                                            name="proveedor"
                                                                            component={FormikSelectSearchDB}
                                                                            titulo="Proveedor"
                                                                            fetchSearch={findProveedoresBysearchText}
                                                                            type={"USERS"} />
                                                                    </div>
                                                                    <div className='col-sm-6'>
                                                                        <Field
                                                                            name="logistica"
                                                                            component={FormikSelectSearchDB}
                                                                            titulo="Logística"
                                                                            fetchSearch={findLogisticaesBysearchText}
                                                                            type={"USERS"} />
                                                                    </div>
                                                                </>
                                                                :
                                                                null
                                                        }
                                                        <div className='col-12'></div>
                                                        {embarqueData.tipoTrafico?.id == ID_TRAFICO_TERRESTRE ?
                                                            <DesgloseTerrestre
                                                                values={values}
                                                                indexDesglose={indexDesglose}
                                                                tiposUnidadDesglose={tiposUnidadDesglose}
                                                                tiposCargaDesglose={CATALOGO_TIPO_CARGA_DESGLOSE}
                                                                tiposServicioDesglose={CATALOGO_TIPO_CARGA}
                                                            ></DesgloseTerrestre>
                                                            :
                                                            <></>
                                                        }
                                                        {isMaritimoAndIntermodal(embarqueData) ?
                                                            <DesgloseMaritimo
                                                                values={values}
                                                                embarqueData={embarqueData}
                                                                tiposCargaDesglose={CATALOGO_TIPO_CARGA_DESGLOSE}
                                                                contenedoresMaritimos={contenedoresMaritimos}></DesgloseMaritimo>
                                                            :
                                                            <></>
                                                        }
                                                        {embarqueData.tipoTrafico?.id == ID_TRAFICO_AEREO ?
                                                            <DesgloseAereo
                                                                values={values}
                                                                embarqueData={embarqueData}
                                                                indexDesglose={indexDesglose}
                                                                tiposCargaDesglose={CATALOGO_TIPO_CARGA_DESGLOSE}></DesgloseAereo>
                                                            :
                                                            <></>
                                                        }
                                                        <div className="col-12 mb-3">
                                                            <label className="form-label">Instrucciones Especiales</label>
                                                            <Field id="instruccionesEspeciales" name="instruccionesEspeciales" className="form-control" component="textarea" />
                                                        </div>
                                                        {validatePrintIdsCarga(embarqueData) ?
                                                            <div className="col-12 mb-3">
                                                                <label className="form-label">ID's de Carga</label>
                                                                <Field id="idsCarga" name="idsCarga" className="form-control" component="textarea" />
                                                            </div>
                                                            :
                                                            ""
                                                        }
                                                        <div className='col-12' align="right">
                                                            <button type="submit" className="btn btn-primary"
                                                                disabled={bloquearEmbarque(embarqueData, userAuth)}>
                                                                {values.id ?
                                                                    "Actualizar Desglose" : "Guardar Desglose"
                                                                }
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                    : null
                                }
                            </>
                        )
                    })
                    }
                </div>
            </div>
        </>
    )
}

export default DesglosesEmbarque