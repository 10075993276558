import { URL_DIMENSION_CARGA } from "../../../../utils/constantes";
import { apiRequest } from "../../../handlerApiRequest";

export const eliminarDimensionCargaPorId = async (idDimensionCarga) => {
    return await apiRequest(URL_DIMENSION_CARGA + "/" + idDimensionCarga, "DELETE");
}


export const formikCalculateVolume = (e, index, formik, attributeName) => {
    const { name, value } = e.target;

    formik.setFieldValue(name, parseFloat(value), false);

    const largo = document.getElementById(`${attributeName}largo-${index}`).value || 0;
    const alto = document.getElementById(`${attributeName}alto-${index}`).value || 0;
    const ancho = document.getElementById(`${attributeName}ancho-${index}`).value || 0;
    const volumen = largo * alto * ancho;


    formik.setFieldValue(`${attributeName}dimensionesCarga[${index}].dimensionVolumen`, volumen.toFixed(2));

};