import { Modal } from "@mui/material";
import { useState } from "react";

function ModalDeleteLogic({
    textBody,
    textHeader,
    functionEliminar,
    id,
    textButton,
    customIcon,
    functionRefreshData,
    customClassButton,
    isDisabled }) {
    const [open, setOpen] = useState(false);
    const [reasonDeleted, setReasonDeleted] = useState("");


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleEliminar = async () => {
        const res = await functionEliminar(id, reasonDeleted);
        if (res && functionRefreshData) functionRefreshData();
        setOpen(false);
    };

    return (
        <>
            <button type='button' className={customClassButton ? customClassButton : 'btn btn-danger'}
                onClick={handleClickOpen}
                disabled={isDisabled ? true : false}>
                {customIcon ? customIcon : <i className="bi bi-trash"></i>}
                {textButton ? textButton : ""}
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="card modal-eliminar">
                    <div className="card-header">
                        <b>{textHeader}</b>
                    </div>
                    <div className="card-body">
                        {textBody}
                        <textarea
                            type="text"
                            className="form-control"
                            placeholder='Razón de la eliminación'
                            name={"reasonDeleted"+id}
                            value={reasonDeleted}
                            onChange={(e) => { setReasonDeleted(e.target.value) }} />
                    </div>
                    <div className="card-footer" align="right">
                        <button type='button' className='btn btn-secondary'
                            onClick={handleClose}>
                            Cancelar
                        </button>
                        <button type='button' className='btn btn-danger'
                            onClick={handleEliminar}>
                            Confirmar
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ModalDeleteLogic