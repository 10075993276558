import './App.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './security/ProtectedRoute';
import { useCookies } from 'react-cookie';
import Login from './pages/auth/Login';
import InicioAdmin from './pages/admin/InicioAdmin';
import InicioCliente from './pages/cliente/InicioCliente'
import InicioProveedor from './pages/proveedor/InicioProveedor'
import Navbar from "./components/Navbar";
import { useDispatch, useSelector } from 'react-redux';
import { adminLinks, proveedorLinks, clienteLinks, operativoLinks, logisticaLinks } from "./utils/navbar/navbarLinks";
import Clientes from './pages/admin/Clientes';
import Embarques from './pages/admin/Embarques';
import Proveedor from './pages/admin/Proveedor';
import Loader from './components/Loader';
import "react-datepicker/dist/react-datepicker.css";
import Error404 from './pages/Error404';
import Error401 from './pages/Error401';
import InicioLogistica from './pages/logistica/InicioLogistica';
import { useEffect, useState } from 'react';
import { getAuthRoleUser } from './utils/authUtils';
import { setRole } from './redux/features/userAuthSlice';
import ModalSessionExpired from './components/ModalSessionExpired';
import Cotizaciones from './pages/admin/Cotizaciones';
import Equipo from './pages/admin/Equipo';
import ViewDocuments from './pages/ViewDocuments';

function App() {
  const [cookies] = useCookies(['auth-token']);
  const navIsVisible = useSelector(state => state.navbar.isVisible);
  const isLoading = useSelector(state => state.loader.loading);
  const [pathName, setPathName] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setRoleInStore()
    const pathnameLocal = window.location.pathname;
    setPathName(pathnameLocal);
    localStorage.removeItem("idEmbarque");
  }, [])

  const setRoleInStore = () => {
    const userRol = getAuthRoleUser();
    dispatch(setRole(userRol))
  }

  return (
    <>
      <BrowserRouter>
        <ModalSessionExpired></ModalSessionExpired>
        {navIsVisible ?
          <Navbar></Navbar> :
          <></>}
        <Routes>
          <Route path='/' element={<Login />} />

          <Route path='/admin' element={
            <ProtectedRoute
              token={cookies['auth-token']}
              roleAllowed={'ROLE_ADMIN'}
              linksNavbar={adminLinks}>
              <InicioAdmin />
            </ProtectedRoute>
          } />
          <Route path='/admin/embarques' element={
            <ProtectedRoute
              token={cookies['auth-token']}
              roleAllowed={'ROLE_ADMIN'}
              linksNavbar={adminLinks}>
              <Embarques />
            </ProtectedRoute>
          } />
          <Route path='/admin/clientes' element={
            <ProtectedRoute
              token={cookies['auth-token']}
              roleAllowed={'ROLE_ADMIN'}
              linksNavbar={adminLinks}>
              <Clientes />
            </ProtectedRoute>
          } />
          <Route path='/admin/proveedores' element={
            <ProtectedRoute
              token={cookies['auth-token']}
              roleAllowed={'ROLE_ADMIN'}
              linksNavbar={adminLinks}>
              <Proveedor />
            </ProtectedRoute>
          } />
          <Route path='/admin/cotizaciones' element={
            <ProtectedRoute
              token={cookies['auth-token']}
              roleAllowed={'ROLE_ADMIN'}
              linksNavbar={adminLinks}>
              <Cotizaciones/>
            </ProtectedRoute>
          } />
          <Route path='/admin/equipo' element={
            <ProtectedRoute
              token={cookies['auth-token']}
              roleAllowed={'ROLE_ADMIN'}
              linksNavbar={adminLinks}>
              <Equipo/>
            </ProtectedRoute>
          } />

          <Route path='/operativo' element={
            <ProtectedRoute
              token={cookies['auth-token']}
              roleAllowed={'ROLE_OPERATIVO'}
              linksNavbar={operativoLinks}>
              <InicioAdmin />
            </ProtectedRoute>
          } />
          <Route path='/operativo/embarques' element={
            <ProtectedRoute
              token={cookies['auth-token']}
              roleAllowed={'ROLE_OPERATIVO'}
              linksNavbar={operativoLinks}>
              <Embarques />
            </ProtectedRoute>
          } />
          <Route path='/operativo/clientes' element={
            <ProtectedRoute
              token={cookies['auth-token']}
              roleAllowed={'ROLE_OPERATIVO'}
              linksNavbar={operativoLinks}>
              <Clientes />
            </ProtectedRoute>
          } />
          <Route path='/operativo/proveedores' element={
            <ProtectedRoute
              token={cookies['auth-token']}
              roleAllowed={'ROLE_OPERATIVO'}
              linksNavbar={operativoLinks}>
              <Proveedor />
            </ProtectedRoute>
          } />
          <Route path='/operativo/cotizaciones' element={
            <ProtectedRoute
              token={cookies['auth-token']}
              roleAllowed={'ROLE_OPERATIVO'}
              linksNavbar={adminLinks}>
              <Cotizaciones/>
            </ProtectedRoute>
          } />

          <Route path='/cliente' element={
            <ProtectedRoute
              token={cookies['auth-token']}
              roleAllowed={'ROLE_CLIENTE'}
              linksNavbar={clienteLinks}>
              <InicioCliente />
            </ProtectedRoute>
          } />
          <Route path='/logistica' element={
            <ProtectedRoute
              token={cookies['auth-token']}
              roleAllowed={'ROLE_LOGISTICA'}
              linksNavbar={logisticaLinks}>
              <InicioLogistica />
            </ProtectedRoute>
          } />
          <Route path='/proveedor' element={
            <ProtectedRoute
              token={cookies['auth-token']}
              roleAllowed={'ROLE_PROVEEDOR'}
              linksNavbar={proveedorLinks}>
              <InicioProveedor />
            </ProtectedRoute>
          } />

          <Route path="/no-autorizado" element={<Error401></Error401>} />
          <Route path="/documentos/*" element={<ViewDocuments />} />
          <Route path="*" element={<Error404></Error404>} />
        </Routes>
      </BrowserRouter>

      {isLoading ?
        <Loader></Loader>
        :
        <></>
      }

      <div id='loader-v2' style={{ visibility: 'hidden'}}>
        <Loader/>
      </div>

      <div id='super-loader-v2' style={{ visibility: 'hidden'}}>
        <Loader/>
      </div>

      
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  )
}

export default App
