import React from "react";
import ModalArchivo from "../ModalArchivo";
import { Card } from "react-bootstrap";
import {
  BASE_URL_S3_FACTURAS_CLIENTES
} from "../../utils/constantes";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

function PreviewPDF({ archivo }) {
  return (
    <Card>
      <Card.Title style={{ textAlign: "center" }}>
        {archivo.serieFolio}
      </Card.Title>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
        <div style={{ height: "250px", width: "250px", margin: "0 auto" }}>
          <Viewer
            fileUrl={
              BASE_URL_S3_FACTURAS_CLIENTES +
              archivo.archivoFactura.keyS3 +
              "?" +
              archivo.archivoFactura.actualizadoEn
            }
            alt={archivo.archivoFactura}
          />
        </div>
      </Worker>
      <Card.Body style={{ backgroundColor: "white" }}>
        <center className="mt-2">
          <ModalArchivo
            title={"PDF"}
            urlBase={BASE_URL_S3_FACTURAS_CLIENTES}
            archivoS3={archivo.archivoFactura}
          />
          {archivo.archivoXMLFactura && (
            <button
              type="button"
              className="btn btn-dark ms-1 me-1"
              onClick={() =>
                saveAs(
                  BASE_URL_S3_FACTURAS_CLIENTES +
                  archivo.archivoXMLFactura.keyS3,
                  archivo.archivoXMLFactura.keyS3
                )
              }
            >
              <i className="bi bi-file-earmark-arrow-down"></i> XML
            </button>
          )}
        </center>
      </Card.Body>
    </Card>
  );
}

export default PreviewPDF;
