import * as ExcelJS from 'exceljs';
import { downloadExcel } from "../../../../utils/helpersExcel";
import { formatMoney } from '../../../../utils/formatMoney';
import { facturasClienteWithFilters } from '../../../../controllers/admin/facturas/facturaClienteController';
import { calcularDiasVencimiento } from '../utils-reportes';
import { getHeaderReport } from '../../../../utils/reports/headerReports';

const getParams = (user, startDate, endDate) => {
    const params = new URLSearchParams();
    if (user != null) params.set('idUser', user.id)
    if (startDate != null) params.set('startDate', startDate)
    if (endDate != null) params.set('endDate', endDate)

    return params
}

export const fetchFacturasClienteWithFilters = async (user, startDate, endDate) => {
    const params = getParams(user, startDate, endDate)
    const res = await facturasClienteWithFilters(`?${params}`)
    return res;
}

export const facturasCliente = async (facturas) => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Factura Clientes');

    await getHeaderReport(sheet, workbook);

    sheet.getRow(7).values = ['Referencia', 'Cliente', 'Origen', 'Destino', 'Servicio', 'Trafico', 'PO/Cliente', 'No. de Contenedores', 'Instrucciones Especiales',
        'Serie Folio', 'Metodo Pago', 'Estatus', 'Subtotal Factura', 'Total', 'Fecha Timbrado', 'Dias de credito', 'Dias Faltantes en Vencer'];

    sheet.columns = [
        { key: 'Referencia', width: 15 },
        { key: 'Cliente', width: 15 },
        { key: 'Origen', width: 15 },
        { key: 'Destino', width: 15 },
        { key: 'Servicio', width: 15 },
        { key: 'Trafico', width: 15 },
        { key: 'PO/Cliente', width: 15 },
        { key: 'No. de Contenedores', width: 15 },
        { key: 'Instrucciones Especiales', width: 30 },
        { key: 'Serie Folio', width: 15 },
        { key: 'Metodo Pago', width: 15 },
        { key: 'Estatus', width: 15 },
        { key: 'Subtotal Factura', width: 15 },
        { key: 'Total', width: 15 },
        { key: 'Fecha Timbrado', width: 15 },
        { key: 'Dias de credito', width: 15 },
        { key: 'Dias Faltantes en Vencer', width: 15 },
    ];

    facturas.forEach(factura => {
        let data = [];
        data.push(factura.embarque?.referenciaKJ)
        data.push(factura.embarque?.cliente?.nombreComercial)
        data.push(factura.embarque?.origen?.nombre)
        data.push(factura.embarque?.destino?.nombre)
        data.push(factura.embarque?.tipoServicio?.nombre)
        data.push(factura.embarque?.tipoTrafico?.nombre)
        data.push(factura.embarque?.poCliente)
        //Instrucciones especiales desde el desglose
        let instruccionesEspeciales = "";
        let contenedores = "";
        factura.embarque?.desglosesEmbarques.forEach((desglose, index, array) => {
            let isLastElement = index === array.length - 1;

            desglose.instruccionesEspeciales != null
                ? (instruccionesEspeciales += desglose.instruccionesEspeciales + (isLastElement ? '' : ', \n'))
                : '';

            desglose.datosDesgloseMaritimo?.detallesCargaMaritimo?.forEach((detalleCarga, detalleIndex, detalleArray) => {
                let isDetalleLastElement = detalleIndex === detalleArray.length - 1;

                detalleCarga.noDeContenedor != null
                    ? (contenedores += detalleCarga.noDeContenedor + (isDetalleLastElement && isLastElement ? '' : ', \n'))
                    : '';
            });
        });
        data.push(contenedores)
        data.push(instruccionesEspeciales)
        data.push(factura.serieFolio)
        data.push(factura.metodoPago)
        let facturaConComprobante = factura.archivoComprobantePagoFactura;
        data.push(factura.embarque?.estaCancelado ? "EMBARQUE CANCELADO" : facturaConComprobante ? "PAGADA" : "NO PAGADA")
        data.push(formatMoney(factura.subTotal))
        data.push(formatMoney(factura.total))
        data.push(factura.fechaTimbrado?.split('T')[0])
        data.push(!facturaConComprobante ? factura.cliente?.diasDeCredito : "")
        const diasFaltantes = calcularDiasVencimiento(factura.fechaTimbrado, factura.cliente?.diasDeCredito)
        data.push(!factura.fechaTimbrado ? "" : !facturaConComprobante ? diasFaltantes : "")

        sheet.addRow(data);
    });

    const buffer = await workbook.xlsx.writeBuffer();

    downloadExcel(buffer, "ReporteFacturasCliente-" + new Date().toJSON().slice(0, 10) + ".xlsx")
}