import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { actualizarEmbarque, buscarEmbarquePorId, cancelarEmbarque, guardarEmbarque, rectificarEstatus } from '../../../controllers/admin/embarques/embarquesController';
import { fetchEstatusEmbarque } from '../../../redux/features/admin/estatusEmbarqueSlice';
import { setEmbarque } from '../../../redux/features/admin/embarqueSlice';
import DatosEmbarqueMaritimo from './DatosEmbarqueMaritimo';
import DatosEmbarqueAereo from './DatosEmbarqueAereo';
import ModalEliminarObjeto from '../ModalEliminarObjeto';
import { resetEmbarque } from '../../../redux/features/admin/embarqueSlice';
import { ID_SERVICIO_EMBARQUE_IMPORTACION, ID_SERVICIO_EMBARQUE_NACIONAL, ID_TRAFICO_AEREO, ID_TRAFICO_MARITIMO, ID_TRAFICO_TERRESTRE } from '../../../utils/constantes';
import { findAllUbicacionesByTextSearch } from '../../../controllers/admin/embarques/catalogos/catalogoUbicacionController';
import { findClientesBySearchText, findProveedoresBysearchText } from '../../../controllers/admin/usuariosController';
import { bloquearEmbarque, isMaritimoAndIntermodal } from './validations/embarqueValidations';
import { findCotizacionBySearchText, uploadFileCotizacion } from '../../../controllers/admin/cotizaciones/cotizacionController';
import { CATALOGO_INCOTERMS, CATALOGO_TIPOS_SERVICIO, CATALOGO_TIPOS_TRAFICO } from '../../../utils/catalogosEstaticos';
import FormikSelectSearchDB from '../../formik/FormikSelectSearchDB';
import FormikSelectSearch from '../../formik/FormikSelectSearch';
import FormikGenericDate from '../../formik/FormikGenericDate';
import { fetchCostoProveedores } from '../../../redux/features/admin/controlCostosSlice';
import FieldArrayPedimentos from './forms/FieldArrayPedimentos';

function FormEmbarque({
    setEmbarques,
    setOpen,
    indexEmbarque,
    fetchbuscarEmbarqueReqDTOPorId }) {
    const embarqueData = useSelector((state) => state.embarque.embarqueData);
    const userAuth = useSelector((state) => state.userAuth);
    const dispatch = useDispatch();

    const handleSubmitFormik = async (values) => {
        const data = embarqueData.id ? await actualizarEmbarque(values) : await guardarEmbarque(values);
        if (data) {
            dispatch(fetchEstatusEmbarque(data.id));
            localStorage.setItem("idEmbarque", data.id);
            //Si al guardar el embarque no da error subir el archivo de la cotizacion
            //Si aun no existe
            const cotizacion = data.cotizacion;
            if (cotizacion && !data.fileCotizacion) {
                await uploadFileCotizacion(cotizacion, data.id);
            }
            //Actualizar redux
            fetchbuscarEmbarqueReqDTOPorId(data.id);
            dispatch(fetchEstatusEmbarque(data.id))
            dispatch(fetchCostoProveedores(data.id))
        }
    }

    const handleChange = e => {
        if (e.target.type === "text") {
            e.target.value = e.target.value.toUpperCase();
        }
        let { name, value, type, checked } = e.target;
        if (type === "checkbox") value = checked;
        dispatch(setEmbarque({
            ...embarqueData,
            [name]: value
        }));
    }

    const fetchCancelarEmbarque = async () => {
        await cancelarEmbarque(embarqueData.id, embarqueData.razonCancelamiento);
        const data = await buscarEmbarquePorId(embarqueData.id);
        setEmbarques((prevState) => {
            const newEmbarques = { ...prevState };
            newEmbarques.content.splice(indexEmbarque, 1, data);
            return newEmbarques;
        });
        setOpen(false);
        dispatch(resetEmbarque());
    }

    const fetchRectificarEstatus = async () => {
        await rectificarEstatus(embarqueData.id);
        await actualizarEmbarque(embarqueData); // Se actualiza el embarque para que tambien cree las actividades
        dispatch(fetchEstatusEmbarque(embarqueData.id))
    }

    return (
        <div>
            <Formik
                initialValues={embarqueData}
                onSubmit={async (values) => {
                    await handleSubmitFormik(values)
                }}
                enableReinitialize
                validationSchema={Yup.object({
                    cliente: Yup.mixed().nullable().required('El cliente es requerido'),
                    idIncoterm: Yup.mixed().nullable().required('El incoterm es requerido'),
                    cotizacion: Yup.mixed().nullable().required('La cotizacion es requerida'),
                    origen: Yup.mixed().nullable().required('El origen es requerido'),
                    destino: Yup.mixed().nullable().required('El destino es requerido'),
                    tipoTrafico: Yup.mixed().nullable().required('El trafico es requerido'),
                    tipoServicio: Yup.mixed().nullable().required('El servicio es requerido'),
                })}
            >
                {({ values }) => (
                    <Form>
                        <div className='row'>
                            <div className='col-sm-9'></div>
                            <div className='col-sm-3'>
                                <div className="input-group input-group-sm">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Referencia:</span>
                                    <Field id="referenciaKJ" name="referenciaKJ" className="form-control" disabled={true} />
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Fecha de Creación:</span>
                                    <Field id="creadoEn" name="creadoEn" className="form-control" disabled={true} />
                                </div>
                            </div>
                            <div className='row col-12'>
                                <div className="col-sm-4 mb-1">
                                    <Field
                                        name="cliente"
                                        component={FormikSelectSearchDB}
                                        titulo="Cliente"
                                        fetchSearch={findClientesBySearchText}
                                        type={"USERS"} />
                                    <ErrorMessage name={`cliente`} component='span' className='text-danger'></ErrorMessage>
                                </div>

                                <div className='col-sm-4 mb-1'>
                                    <Field
                                        name={"idIncoterm"}
                                        id="idIncoterm"
                                        component={FormikSelectSearch}
                                        data={CATALOGO_INCOTERMS}
                                        titulo={"Incoterm"}
                                        isDisabled={false}
                                        returnObject={false} />
                                    <ErrorMessage name={`idIncoterm`} component='span' className='text-danger'></ErrorMessage>
                                </div>
                                <div className="col-sm-4 mb-1">
                                    <Field
                                        name="cotizacion"
                                        component={FormikSelectSearchDB}
                                        isDisabled={embarqueData.id && userAuth?.roleUser?.id == 4}
                                        titulo="Cotización"
                                        fetchSearch={findCotizacionBySearchText}
                                        type={"COTIZACION"} />
                                    <ErrorMessage name={`cotizacion`} component='span' className='text-danger'></ErrorMessage>
                                </div>
                                <div className='col-sm-6 mb-1'>
                                    <Field
                                        name="origen"
                                        component={FormikSelectSearchDB}
                                        titulo="Origen"
                                        fetchSearch={findAllUbicacionesByTextSearch}
                                        type={"ORIGEN/DESTINO"} />
                                    <ErrorMessage name={`origen`} component='span' className='text-danger'></ErrorMessage>
                                </div>
                                <div className='col-sm-6 mb-1'>
                                    <Field
                                        name="destino"
                                        component={FormikSelectSearchDB}
                                        titulo="Destino"
                                        fetchSearch={findAllUbicacionesByTextSearch}
                                        type={"ORIGEN/DESTINO"} />
                                    <ErrorMessage name={`destino`} component='span' className='text-danger'></ErrorMessage>
                                </div>
                                <br />
                                <div className='col-sm-4 mb-1'>
                                    <Field
                                        name={"tipoTrafico"}
                                        id="tipoTrafico"
                                        component={FormikSelectSearch}
                                        data={CATALOGO_TIPOS_TRAFICO}
                                        titulo={"Tráfico"}
                                        isDisabled={embarqueData.desglosesEmbarques.id && embarqueData.desglosesEmbarques.length >= 1 ? true : false}
                                        returnObject={true} />
                                    <ErrorMessage name={`tipoTrafico`} component='span' className='text-danger'></ErrorMessage>
                                </div>
                                <div className='col-sm-4 mb-1'>
                                    <Field
                                        name={"tipoServicio"}
                                        id="tipoServicio"
                                        component={FormikSelectSearch}
                                        data={CATALOGO_TIPOS_SERVICIO}
                                        titulo={"Servicio"}
                                        isDisabled={false}
                                        returnObject={true} />
                                    <ErrorMessage name={`tipoServicio`} component='span' className='text-danger'></ErrorMessage>
                                </div>
                                <div className='col-sm-2 mb-1 pt-2'>
                                    <label className="form-label">Solo Despacho Aduanal</label>
                                    <div className="form-check form-switch">
                                        <Field type="checkbox" name="soloDespachoAduanal" className="form-check-input" role="switch" />
                                    </div>
                                </div>
                                {values.tipoServicio?.id == ID_SERVICIO_EMBARQUE_IMPORTACION ?
                                    < div className='col-sm-2 mb-1'>
                                        <Field
                                            name="fechaPrevio"
                                            component={FormikGenericDate}
                                            nameLabel="Fecha Previo" />
                                    </div> : null
                                }
                                {values.tipoServicio?.id != ID_SERVICIO_EMBARQUE_NACIONAL
                                    && isMaritimoAndIntermodal(values) ?
                                    <DatosEmbarqueMaritimo values={values}></DatosEmbarqueMaritimo>
                                    :
                                    <></>
                                }

                                {values.tipoServicio?.id != ID_SERVICIO_EMBARQUE_NACIONAL
                                    && values.tipoTrafico?.id == ID_TRAFICO_AEREO ?
                                    <DatosEmbarqueAereo values={values}></DatosEmbarqueAereo>
                                    :
                                    <></>
                                }

                                {values.tipoTrafico?.id == ID_TRAFICO_TERRESTRE && (values.tipoServicio != null && values.tipoServicio?.id != ID_SERVICIO_EMBARQUE_NACIONAL) &&
                                    <>
                                        <div className='col-12'></div>
                                        <div className='col-sm-1 mb-3'>
                                            <div role="group" aria-labelledby="demo-controlled-radio-buttons-group">
                                                <label className="form-label">Despacho Aduanal</label>
                                                <div className='form-check'>
                                                    <label>
                                                        <Field type="radio" name="haceDespachoAduanal" value="CLIENTE" className="form-check-input" />
                                                        CLIENTE
                                                    </label>
                                                </div>
                                                <div className='form-check'>
                                                    <label>
                                                        <Field type="radio" name="haceDespachoAduanal" value="KJ" className="form-check-input" />
                                                        KJ LOGISTICS
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        {values?.haceDespachoAduanal == "KJ" &&
                                            <div className='col-sm-3 mb-3'>
                                                <Field
                                                    name="kjcustomBrokerProvider"
                                                    component={FormikSelectSearchDB}
                                                    titulo="Proveedor despacho aduanal"
                                                    fetchSearch={findProveedoresBysearchText}
                                                    type={"USERS"} />
                                            </div>

                                        }
                                        <FieldArrayPedimentos values={values} />
                                    </>
                                }

                                <div className="col-sm-12 mb-1">
                                    <label htmlFor="inputOrigen" className="form-label">PO/Cliente</label>
                                    <Field id="poCliente" name="poCliente" className="form-control" />
                                </div>
                            </div>
                            <div className='col-sm-12' align="right">
                                <button type="submit" className='btn btn-primary me-2'>{embarqueData?.id ? "ACTUALIZAR" : "GUARDAR"}</button>
                                {embarqueData.id ?
                                    <>
                                        <ModalEliminarObjeto
                                            textHeader={"¿Está seguro que deseas cancelar este embarque?"}
                                            functionEliminar={fetchCancelarEmbarque}
                                            isDisabled={bloquearEmbarque(embarqueData, userAuth)}
                                            textButton={" CANCELAR EMBARQUE"}
                                            customIcon={<i className="bi bi-x-octagon-fill"></i>}>
                                            <div className="col-12 mb-3">
                                                <label htmlFor="inputDestino" className="form-label">Escribe la razón de cancelar el embarque</label>
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    name='razonCancelamiento'
                                                    onChange={handleChange} />
                                            </div>
                                        </ModalEliminarObjeto>
                                        <button className='btn btn-secondary m-2'
                                            type='button' onClick={() => fetchRectificarEstatus()}>
                                            <i className="bi bi-arrow-clockwise"></i>
                                            RECTIFICAR ESTATUS</button>
                                    </> : null
                                }
                            </div>

                        </div>
                    </Form>
                )}

            </Formik>
        </div>
    )
}

export default FormEmbarque