import {configureStore} from '@reduxjs/toolkit';
import controlCostosSlice from './features/admin/controlCostosSlice';
import embarqueSlice from './features/admin/embarqueSlice';
import estatusEmbarqueSlice from './features/admin/estatusEmbarqueSlice';
import loaderSlice from './features/loaderSlice';
import navbarSlice from './features/navbar/navbarSlice';
import usuarioSlice from './features/usuarioSlice';
import userAuthSlice from './features/userAuthSlice';
import filtrosBusquedas from './features/admin/filtrosBusquedas';

export const store = configureStore({
    reducer: {
        navbar: navbarSlice,
        usuario: usuarioSlice,
        embarque: embarqueSlice,
        controlCostos: controlCostosSlice,
        loader: loaderSlice,
        estatusEmbarque: estatusEmbarqueSlice,
        filtrosBusquedas: filtrosBusquedas,
        userAuth: userAuthSlice
    }
})