import { apiRequest } from "../handlerApiRequest";
import { URL_PROVEEDOR_COSTOS } from "../../utils/endpoints/proveedor";

export const searchFacturaById = async (idCostoProveedor) => {
    return await apiRequest(URL_PROVEEDOR_COSTOS+`/${idCostoProveedor}/factura-proveedor`);
}

export const listarCostosDeProveedor = async (idEmbarque) => {
    return await apiRequest(URL_PROVEEDOR_COSTOS+`?idEmbarque=${idEmbarque}`);
}

export const listarCostosDeLogistica = async (idEmbarque) => {
    return await apiRequest(URL_PROVEEDOR_COSTOS+"/logistica"+`?idEmbarque=${idEmbarque}`);
}
