import React, { useState } from 'react'
import Modal from '@mui/material/Modal';
import { addItem, deleteItem, handleChangeArrayText } from '../../utils/helpersState';
import { useEffect } from 'react';
import { findAllEmailsByIdUser } from '../../controllers/admin/userEmailController';
import { ToastError, ToastInfo } from '../../utils/toastMessage';
import { useGenericSendEmail } from '../../controllers/admin/sendEmailController';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/features/loaderSlice';

function ModalSendEmails({ sendEmail, setSendEmail, user, logistica, entity, cardHeader, endPoint }) {
    const [emails, setEmails] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        if(sendEmail) fetchDataUserEmails();
    }, [sendEmail])

    const handleClose = () => {
        setSendEmail(false);
    };

    const onSubmit = async () => {
        try{
            dispatch(setLoading({ isLoading: true }))
            const res = await useGenericSendEmail(emails, entity, endPoint);
            dispatch(setLoading({ isLoading: false }))
            if(res.error) return ToastError(res.message);
            setSendEmail(false);
            return ToastInfo(res.message);
        }catch(e){
            ToastError(e.message)
        }
    }

    const fetchDataUserEmails = async () => {
        try {
            const res = await findAllEmailsByIdUser(user.id);
            const emailsSend = [];
            if (res.error) return ToastError(res.message);
            emailsSend.push(...res.data)

            if(logistica) {
                const resLog = await findAllEmailsByIdUser(logistica.id);
                if (resLog.error) return ToastError(resLog.message);
                emailsSend.push(...resLog.data)
            }
            setEmails(emailsSend);
        } catch (e) {
            ToastError(e.message);
        }
    };

    return (
        <>
            <Modal
                open={sendEmail}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="card style-modal-ssm">
                    <div className="card-header d-flex justify-content-between" align="center">
                        {cardHeader}
                    </div>
                    <div className="card-body">
                        <div align="right">
                            <button type='button' className='btn btn-dark mt-2 mb-2 me-2'
                                onClick={() => addItem(emails, setEmails, {email: ""})}>
                                <i className="bi bi-plus-lg"></i>
                            </button>
                        </div>

                        {emails.map((email, index) => {
                            return (
                                <div className="input-group mb-3" key={index}>
                                    <input
                                        type="email"
                                        className="form-control"
                                        name='email'
                                        value={email?.email}
                                        onChange={(e) => { handleChangeArrayText(e, index, emails, setEmails) }} />
                                    <button type='button' className='btn btn-danger'
                                        onClick={() => deleteItem(index, emails, setEmails)}>
                                        <i className="bi bi-dash-lg"></i>
                                    </button>
                                </div>
                            )
                        })

                        }

                    </div>
                    <div className="card-footer" align="right">
                        <button type='button' className='btn btn-warning mt-2 mb-2 me-2'
                            onClick={() => handleClose()}>
                            <i className="bi bi-floppy"></i> No Enviar
                        </button>
                        <button type='button' className='btn btn-primary mt-2 mb-2 me-2'
                            onClick={() => onSubmit()}>
                            <i className="bi bi-floppy"></i> Enviar
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ModalSendEmails