import { URL_SEND_EMAILS } from "../../utils/constantes";
import { apiRequest } from "../handlerApiRequest";

export const useGenericSendEmail = async(emailsUser, entity, endPoint) =>{
    let recipients = [];
    emailsUser.forEach(emailUser => {
        recipients.push(emailUser.email);
    });

    return await apiRequest(URL_SEND_EMAILS+endPoint, 'POST', entity, `?recipients=${recipients}`);
}