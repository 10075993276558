import React, { useState } from 'react'
import Modal from '@mui/material/Modal';
import { styleModalSM } from '../../../../assets/css/styles-react';
import { ToastError, ToastSuccess } from '../../../../utils/toastMessage';
import MUIDataTable from "mui-datatables";
import { useDispatch } from 'react-redux';
import ModalEliminarObjeto from '../../ModalEliminarObjeto';
import { BASIC_OPTIONS_MUI, SEARCHING_ON_OBJECTS } from '../../../../utils/mui/constantes';
import { deleteByIdUbicacion, findAllCatalogoUbicaciones, saveUbicacion, updateUbicacion } from '../../../../controllers/admin/embarques/catalogos/catalogoUbicacionController';
import SelectSearchDB from '../../../inputs/SelectSearchDB';
import { findAllByTextSearch } from '../../../../controllers/admin/paisController';
import { setLoading } from '../../../../redux/features/loaderSlice';

function ModalAdministracionOrigenDestino() {
  const ubicacionEntity = {
    id: '',
    nombre: '',
    portCode: '',
    pais: {},
    creadoEn: ''
  }
  const [open, setOpen] = useState(false);
  const [ubicacion, setUbicacion] = useState(ubicacionEntity)
  const [ubicaciones, setUbicaciones] = useState([])
  const dispatch = useDispatch();

  const handleOpen = async () => {
    dispatch(setLoading({ isLoading: true }))
    await fetchFindAllUbicaciones();
    dispatch(setLoading({ isLoading: false }))
    setUbicacion(ubicacionEntity)
    setOpen(true)
  };

  const handleChange = event => {
    if (event.target.type === "text") {
      event.target.value = event.target.value.toUpperCase();
    }
    setUbicacion({
      ...ubicacion,
      [event.target.name]: event.target.value
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const isUpdate = ubicacion?.id != "";
      const action = isUpdate ? updateUbicacion : saveUbicacion;

      dispatch(setLoading({ isLoading: true }))
      const res = await action(ubicacion);
      dispatch(setLoading({ isLoading: false }))

      if (res.error) return ToastError(res.message);

      ToastSuccess(res.message);
      fetchFindAllUbicaciones()
      setUbicacion(ubicacionEntity);
    } catch (error) {
      return ToastError(error.message);
    }
  };

  const fetchFindAllUbicaciones = async () => {
    try {
      dispatch(setLoading({ isLoading: true }))
      const res = await findAllCatalogoUbicaciones()
      dispatch(setLoading({ isLoading: false }))
      if (res.error) return ToastError(res.message)
      setUbicaciones(res.data)
    } catch (e) {
      ToastError(e)
    }
  }

  const fetchDeleteByIdUbicacion = async (idUbicacion) => {
    try {
      const res = await deleteByIdUbicacion(idUbicacion);
      if (res?.error) return ToastError(res.message);

      //Eliminar la ubicacion del array
      setUbicaciones((prevAerolineas) => {
        const indexAerolinea = prevAerolineas.findIndex(item => item[0] === idUbicacion);
        const newAerolineas = [...prevAerolineas];
        newAerolineas.splice(indexAerolinea, 1)
        return newAerolineas;
      });
      ToastSuccess(res.message);
    } catch (err) {
      return ToastError(err)
    }
  }

  const handleClose = () => setOpen(false);

  const columns = [
    { label: "NOMBRE", name: "nombre" },
    { label: "CODIGO", name: "portCode" },
    {
      name: "PAIS",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return ubicaciones[dataIndex].pais?.nombre
        },
      }
    },
    {
      name: "",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <button className='btn btn-warning'
              onClick={() => setUbicacion(ubicaciones[dataIndex])}>
              <i className="bi bi-pencil"></i>
            </button>
          )
        },
        filter: false,
        sort: false,
        download: false,
      }
    },
    {
      name: "",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <ModalEliminarObjeto
              functionEliminar={fetchDeleteByIdUbicacion}
              id={ubicaciones[dataIndex].id}
              textHeader={"¿Estás seguro de eliminar esta ubicacion?"}>
            </ModalEliminarObjeto>
          )
        },
        filter: false,
        sort: false,
        download: false,
      }
    },]

  return (
    <>
      <button className='dropdown-item' onClick={handleOpen}>
        Administración origen/destino
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="card" style={styleModalSM}>
          <div className="card-header">
            Administración ORIGEN/DESTINO
          </div>
          <div className="card-body body-modal-scroll">
            <form onSubmit={handleSubmit}>
              <div className='row g-3 row-cols-3'>
                <div className="col-6 mb-1">
                  <label htmlFor="inputNombre" className="form-label">Nombre<span className='required-span'>*</span></label>
                  <input
                    type="text"
                    required
                    className="form-control"
                    name='nombre'
                    value={ubicacion.nombre}
                    placeholder="Ingresa el nombre"
                    onChange={handleChange} />
                </div>
                <div className="col-6 mb-1">
                  <label htmlFor="inputNombre" className="form-label">Codigo<span className='required-span'>*</span></label>
                  <input
                    type="text"
                    required
                    className="form-control"
                    name='portCode'
                    value={ubicacion.portCode}
                    placeholder="Ingresa el codigo"
                    onChange={handleChange} />
                </div>
                <div className="col-12 mb-1">
                  <SelectSearchDB
                    titulo={"Pais"}
                    name={"pais"}
                    value={ubicacion.pais}
                    fetchSearch={findAllByTextSearch}
                    handleChange={handleChange}
                  ></SelectSearchDB>
                </div>
                <div className='col-12 mb-1' align="right">
                  {ubicacion.id ?
                    <>
                      <span type='button' className="btn btn-secondary col-4" onClick={() => setUbicacion(ubicacionEntity)}>Cancelar</span>
                      <button type="submit" className="btn btn-primary col-4">Actualizar</button>
                    </>
                    :
                    <button type="submit" className="btn btn-primary col-4">Guardar</button>

                  }
                </div>
              </div>
            </form>

            <MUIDataTable
              data={ubicaciones}
              columns={columns}
              options={{
                ...BASIC_OPTIONS_MUI,
                search: true,  // Esto habilitará la búsqueda en todo el DataTable
                filter: false,  // Esto desactivará la funcionalidad de filtro de cada columna
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}


export default ModalAdministracionOrigenDestino