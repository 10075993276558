import * as ExcelJS from 'exceljs';
import { facturasProveedorPPDToReporte, facturasProveedorWithFilters } from "../../../../controllers/admin/facturas/facturaProveedorController";
import { downloadExcel } from "../../../../utils/helpersExcel";
import { formatMoney } from '../../../../utils/formatMoney';
import { calcularDiasVencimiento } from '../utils-reportes';
import { validatePaymentDay } from '../../../../utils/proveedor/validationsPayment';
import moment from "moment";
import "moment/locale/es";
import { getHeaderReport } from '../../../../utils/reports/headerReports';

const getParams = (user, startDate, endDate) => {
    const params = new URLSearchParams();
    if (user != null) params.set('idUser', user.id)
    if (startDate != null) params.set('startDate', startDate)
    if (endDate != null) params.set('endDate', endDate)

    return params
}

export const fetchFacturasProveedorWithFilters = async (user, startDate, endDate) => {
    const params = getParams(user, startDate, endDate)
    const res = await facturasProveedorWithFilters(`?${params}`)
    return res;
}

export const fetchFacturasProveedorPPDToReporte = async (user, startDate, endDate) => {
    const params = getParams(user, startDate, endDate)
    const res = await facturasProveedorPPDToReporte(`?${params}`)
    return res;
}

export const facturasProveedor = async (facturas) => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Factura Proveedor');

    await getHeaderReport(sheet, workbook);

    sheet.getRow(7).values = ['Referencia', 'Cliente', 'Origen', 'Destino', 'Servicio', 'Trafico', 'Serie Factura', 'Folio Factura', 'Metodo Pago', 'Estatus',
        'Fecha Estimada Pago', 'Fecha Timbrado', 'Dias de credito', 'Dias Faltantes', 'Proveedor', 'Subtotal Factura', 'Total Factura'];
    sheet.columns = [
        { key: 'Referencia', width: 15 },
        { key: 'Cliente', width: 15 },
        { key: 'Origen', width: 15 },
        { key: 'Destino', width: 15 },
        { key: 'Servicio', width: 15 },
        { key: 'Trafico', width: 15 },
        { key: 'Serie Factura', width: 15 },
        { key: 'Folio Factura', width: 15 },
        { key: 'Metodo Pago', width: 15 },
        { key: 'Estatus', width: 15 },
        { key: 'Fecha Estimada Pago', width: 15 },
        { key: 'Fecha Timbrado', width: 15 },
        { key: 'Dias de credito', width: 15 },
        { key: 'Dias Faltantes en Vencer', width: 15 },
        { key: 'Proveedor', width: 15 },
        { key: 'Subtotal Factura', width: 15 },
        { key: 'Total Factura', width: 15 }
    ];
    facturas.forEach(factura => {
        let data = [];
        data.push(factura.costoProveedor?.embarque?.referenciaKJ)
        data.push(factura.costoProveedor?.embarque?.cliente?.nombreComercial)
        data.push(factura.costoProveedor?.embarque?.origen?.nombre)
        data.push(factura.costoProveedor?.embarque?.destino?.nombre)
        data.push(factura.costoProveedor?.embarque?.tipoServicio?.nombre)
        data.push(factura.costoProveedor?.embarque?.tipoTrafico?.nombre)
        data.push(factura.serie)
        data.push(factura.folio)
        data.push(factura.metodoPago)
        data.push(factura?.costoProveedor?.desgloseEmbarque?.embarque?.estaCancelado ? "EMBARQUE CANCELADO" : factura.estatus?.estatus)
        let getPaymentDay = validatePaymentDay(factura);
        let formatPaymentDay = getPaymentDay != null ? moment(getPaymentDay).format('DD-MM-YYYY') : "";
        data.push(formatPaymentDay)
        let fechaTimbrado = factura.fechaTimbrado;
        let formatFechaTimbrado = fechaTimbrado != null ? moment(fechaTimbrado).format('DD-MM-YYYY') : "";
        data.push(formatFechaTimbrado)
        data.push(factura.estatus?.id == 1 || factura.estatus?.id == 3 ? factura.costoProveedor?.proveedor?.diasDeCredito : "")
        const diasFaltantes = calcularDiasVencimiento(factura.fechaTimbrado, factura.costoProveedor?.proveedor?.diasDeCredito)
        data.push(factura.estatus?.id == 1 || factura.estatus?.id == 3 ? diasFaltantes : "")
        data.push(factura.costoProveedor?.proveedor?.nombreComercial)
        data.push(formatMoney(factura.costoNeto))
        data.push(formatMoney(factura.total))

        sheet.addRow(data);
    });

    const buffer = await workbook.xlsx.writeBuffer();

    downloadExcel(buffer, "ReporteFacturasProveedor-" + new Date().toJSON().slice(0, 10) + ".xlsx")
}

export const facturasProveedorPPDValidateComplemento = async (facturas) => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Complemento Factura Proveedor');

    await getHeaderReport(sheet, workbook);

    sheet.getRow(7).values = ['Referencia', 'Cliente', 'Origen', 'Destino', 'Servicio', 'Trafico', 'Serie Factura', 'Folio Factura', 'Metodo Pago', 'Estatus',
        'Proveedor', '¿Tiene complemento?', 'Subtotal Factura', 'Total Factura'];

    sheet.columns = [
        { key: 'Referencia', width: 15 },
        { key: 'Cliente', width: 15 },
        { key: 'Origen', width: 15 },
        { key: 'Destino', width: 15 },
        { key: 'Servicio', width: 15 },
        { key: 'Trafico', width: 15 },
        { key: 'Serie Factura', width: 15 },
        { key: 'Folio Factura', width: 15 },
        { key: 'Metodo Pago', width: 15 },
        { key: 'Estatus', width: 15 },
        { key: 'Proveedor', width: 15 },
        { key: 'tieneComplemento', width: 15 },
        { key: 'Subtotal Factura', width: 15 },
        { key: 'Total Factura', width: 15 }
    ];

    facturas.forEach(factura => {
        let data = [];
        data.push(factura.costoProveedor?.embarque?.referenciaKJ)
        data.push(factura.costoProveedor?.embarque?.cliente?.nombreComercial)
        data.push(factura.costoProveedor?.embarque?.origen?.nombre)
        data.push(factura.costoProveedor?.embarque?.destino?.nombre)
        data.push(factura.costoProveedor?.embarque?.tipoServicio?.nombre)
        data.push(factura.costoProveedor?.embarque?.tipoTrafico?.nombre)
        data.push(factura.serie)
        data.push(factura.folio)
        data.push(factura.metodoPago)
        data.push(factura?.costoProveedor?.desgloseEmbarque?.embarque?.estaCancelado ? "EMBARQUE CANCELADO" : factura.estatus?.estatus)
        data.push(factura.costoProveedor?.proveedor?.nombreComercial)
        data.push(factura.tieneComplementos ? "SI" : "NO")
        data.push(formatMoney(factura.costoNeto))
        data.push(formatMoney(factura.total))

        sheet.addRow(data);
    });

    const buffer = await workbook.xlsx.writeBuffer();

    downloadExcel(buffer, "ReporteFacturasProveedor-ComplementosPago-" + new Date().toJSON().slice(0, 10) + ".xlsx")
}