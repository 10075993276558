import { Field, FieldArray } from 'formik'
import React from 'react'
import { formatCatalogoTrafico, formatGenericCatalogo } from '../../../../../utils/formats/formatCatalogos'
import { CATALOGO_TIPOS_SERVICIO_COTIZACIONES, CATALOGO_TIPOS_TRAFICO, CATALOGO_TIPO_CARGA } from '../../../../../utils/catalogosEstaticos'
import FormikSelectSearch from '../../../../formik/FormikSelectSearch'
import FormikTableDinamic from '../../../../formik/FormikTableDinamic'
import FieldArrayDimensionCarga from '../../../embarques/desgloses/FieldArrayDimensionCarga'
import { templateTableCotizacionVentas } from '../../../../table-dinamic/defaults/cotizacion/defaultTablesCotizacionVentas'

function FieldArrayVentas({ values }) {
    return (
        <FieldArray
            name="ventas"
            render={arrayHelpers => (
                <>
                    <div className="col-sm-12 mb-3" align="right">
                        <hr />
                        <h6>VENTA</h6>

                        <button type="button" className='btn btn-primary' onClick={() => arrayHelpers.push({})}>
                            <i className="bi bi-plus" /> VENTA
                        </button>
                    </div>
                    {values.ventas?.map((venta, index) => {
                        return (
                            <>
                                <div className="accordion mt-1" id={"venta" + index}>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id={"heading" + index}>
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + index} aria-expanded="true" aria-controls={"collapse" + index}>
                                                VENTA - {venta?.mercancia}
                                            </button>
                                        </h2>
                                        <div id={"collapse" + index} className="accordion-collapse collapse" aria-labelledby={"heading" + index} data-bs-parent={"#venta" + index}>
                                            <div className="accordion-body">
                                                <div key={index} className='row'>
                                                    <hr />
                                                    <div className="col-sm-12 mb-3" align="right">
                                                        <button className='btn btn-danger' type='button' onClick={() => arrayHelpers.remove(index)}><i className="bi bi-trash-fill"></i></button>
                                                    </div>
                                                    <div className="col-sm-6 mb-3">
                                                        <Field
                                                            name={`ventas[${index}].trafico`}
                                                            id="traificoVentasCot"
                                                            component={FormikSelectSearch}
                                                            data={formatCatalogoTrafico(CATALOGO_TIPOS_TRAFICO)}
                                                            titulo={"Tráfico"}
                                                            isDisabled={false}
                                                            returnObject={true} />
                                                    </div>

                                                    <div className="col-sm-6 mb-3">
                                                        <Field
                                                            name={`ventas[${index}].idServicio`}
                                                            id="servicioVentasCot"
                                                            component={FormikSelectSearch}
                                                            data={formatGenericCatalogo(CATALOGO_TIPOS_SERVICIO_COTIZACIONES)}
                                                            titulo={"Servicio"}
                                                            isDisabled={false} />
                                                    </div>

                                                    <div className="col-sm-12 mb-3">
                                                        <Field
                                                            name={`ventas[${index}].tipoCarga`}
                                                            id="tipoCargaVentaCot"
                                                            component={FormikSelectSearch}
                                                            data={formatGenericCatalogo(CATALOGO_TIPO_CARGA)}
                                                            titulo={"Tipo de Carga"}
                                                            isDisabled={false}
                                                            returnObject={true} />
                                                    </div>

                                                    <div className="col-sm-12 mb-3">
                                                        <label htmlFor="mercanciaVentaCot" className="form-label">Mercancia</label>
                                                        <Field id="mercanciaVentaCot" name={`ventas[${index}].mercancia`} className="form-control" component="textarea" />
                                                    </div>

                                                    {venta?.tipoCarga?.id == CATALOGO_TIPO_CARGA[0]?.id ?
                                                        <FieldArrayDimensionCarga
                                                            values={values}
                                                            attributeName={`ventas[${index}]`}></FieldArrayDimensionCarga>
                                                        :
                                                        ""
                                                    }



                                                    <div className="col-sm-12 mb-3">
                                                        <Field
                                                            name={`ventas[${index}].tableVentas`}
                                                            component={FormikTableDinamic}
                                                            functionGenerateTable={templateTableCotizacionVentas}
                                                            objectValue={values}
                                                            attributeName={`ventas[${index}]`} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </>
                        )
                    })}
                </>)} />
    )
}

export default FieldArrayVentas