export const Cotizacion = {
    cliente: null,
    vendedor: null,
    details: "",
    ventas: [],
    arrayCondicionesServicio: null,
    vigencia: null,
    addUnregisteredClient: false,
    isDeleted: false,
    totalUpdatedVigencia: 0,
    idStatus: 1
}