import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Search } from "@mui/icons-material";

const CustomMuiDataTable = ({
  title,
  columns,
  fetchData,
  newItem,
  setState,
  customOptions,
}) => {
  const [data, setData] = useState({
    data: [],
    count: 10,
    page: 0,
    rowsPerPage: 10,
    searchText: "",
  });
  const [search, setSearch] = useState("");

  useEffect(() =>{
    petitionData();
  }, [newItem])

  useEffect(() => {
    petitionData();
  }, [data.page, data.rowsPerPage, data.searchText]);

  const petitionData = async () => {
    try {
      const params = new URLSearchParams({
        page: data.page,
        size: data.rowsPerPage,
        searchText: data.searchText || ''
      });
      const urlParams = `?${params.toString()}`;
      const dataResponse = await fetchData(urlParams);
      setData({
        data: dataResponse.content,
        count: dataResponse.totalElements,
        page: dataResponse.pageable ? dataResponse.pageable.pageNumber : null,
        rowsPerPage: dataResponse.pageable
          ? dataResponse.pageable.pageSize
          : null,
        searchText: data.searchText ? data.searchText : "",
      });

      setState(dataResponse.content);
    } catch (err) {
      console.error("error ", err);
    }
  };

  const handleSearchChange = (event) => {
    const textSearch = event.target.value;
    setSearch(textSearch);
  };

  const handleTextSearch = () => {
    setData({
      ...data,
      searchText: search,
      page: 0
    });
  };

  const handleSearchReset = () => {
    setData({
      ...data,
      searchText: "",
      page: 0,
    });
    setSearch("");
  };

  const handleOnChangePage = async (newPage) => {
    setData({
      ...data,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = async (pageSize) => {
    setData({
      ...data,
      rowsPerPage: pageSize,
      page: 0,
    });
  };

  const mergedOptions = {
    onChangePage: handleOnChangePage,
    onChangeRowsPerPage: handleChangeRowsPerPage,
    rowsPerPageOptions: [5, 10, 50, 100, 1000],
    count: data.count,
    page: data.page,
    serverSide: true,
    responsive: "standard",
    customSearchRender: (searchText, handleSearch, hideSearch, options) => (
      <div className="input-group input-group-sm">
        <input
          type="text"
          className="form-control"
          value={search}
          onChange={(e) => {
            handleSearchChange(e);
          }}
          placeholder="Buscar..."
        />
        <button onClick={handleTextSearch} className="btn btn-primary btn-sm">
          <Search />
        </button>
        <button onClick={handleSearchReset} className="btn btn-danger btn-sm">
          <i className="bi bi-x"></i>
        </button>
      </div>
    ),
    ...customOptions,
  };

  return (
    <>
      <MUIDataTable
        title={title}
        data={data.data}
        columns={columns}
        options={mergedOptions}
      />
    </>
  );
};

export default CustomMuiDataTable;
