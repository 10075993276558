import { Field } from 'formik';
import FormikGenericDate from '../../../../formik/FormikGenericDate';

function DesgloseAereoDTAOD({ values }) {

    return (
        <div className='row'>
            <div className="col-sm-12 mb-3">
                <label htmlFor="inputDestino" className="form-label">Aeropuerto de Entrega</label>
                <Field id="datosDesgloseAereo.aeropuertoEntrega" name={`datosDesgloseAereo.aeropuertoEntrega`} className="form-control" />
            </div>
            <div className='col-sm-6 mb-1'>
                <Field
                    name="datosDesgloseAereo.fechaEstimadaEntregaAeropuerto"
                    component={FormikGenericDate}
                    nameLabel="Fecha Estimada Entrega Aeropuerto" />
            </div>
            <div className='col-sm-6 mb-1'>
                <Field
                    name="datosDesgloseAereo.fechaEfectivaEntregaAeropuerto"
                    component={FormikGenericDate}
                    nameLabel="Fecha Efectiva Entrega Aeropuerto" />
            </div>
        </div>
    )
}

export default DesgloseAereoDTAOD