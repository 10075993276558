import React from 'react'
import Select from 'react-select'

function SelectSearch({
    data,
    titulo,
    value,
    name,
    handleChange,
    index,
    required,
    isDisabled,
    returnObject }) {

    let dataSelected;
    if (typeof value === "object" && value?.id) {
        dataSelected = data.find(data => data.id == value.id);
    } else {
        
        dataSelected = data.find(data => data.value == value);
    }
    
    return (
        <>
            <label className="form-label">{titulo}</label>
            <Select
                className="basic-single"
                classNamePrefix="select"
                value={dataSelected}
                isSearchable={true}
                name={name}
                options={data}
                isDisabled={isDisabled ? true : false}
                onChange={(selectedOption) => {
                    handleChange(
                        {
                            target: {
                                name: name,
                                value: returnObject ? selectedOption : selectedOption.value,
                            },
                        },
                        index
                    );
                }}
                required={required ? true : false}
            />
        </>
    )
}

export default SelectSearch