import MUIDataTable from 'mui-datatables'
import React from 'react'
import { useState } from 'react'
import { findHistoryByIdEmbarque } from '../../../../controllers/historyController';
import { useEffect } from 'react';
import { BASIC_OPTIONS_MUI } from '../../../../utils/mui/constantes';
import moment from "moment";
import "moment/locale/es";
import { formatNameUser } from '../../../../utils/userFormat';
import { traductHttpMethods } from '../../../../utils/webUtils';
import { ToastInfo } from '../../../../utils/toastMessage';

function EmbarqueHistory({ idEmbarque }) {
    const [history, setHistory] = useState([]);

    useEffect(()=>{
        fetchFindHistoryByIdEmbarque()
    },[])

    const fetchFindHistoryByIdEmbarque = async () =>{
        const res = await findHistoryByIdEmbarque(idEmbarque);
        const listHistory = res.data?.map(history => [
            history.embarque?.referenciaKJ,
            formatNameUser(history.userRequest),
            history.emailRequest,
            traductHttpMethods(history.metodoHTTP),
            history.endPoint,
            history.statusHTTP != 200 ? "ERROR" : "EXITOSO",
            history.statusHTTP != 200 ? history.messageResponse : "",
            moment(history.creadoEn).format("D MMMM YYYY, h:mm:ss a"),
            history.requestBody
        ])
        setHistory(listHistory);
    }

    const columns = [
        "Referencia",
        "Usuario",
        "Email",
        "Metodo",
        "End Point",
        "Estatus",
        "Error",
        "Fecha",
        {
            name: "Cuerpo",
            options: {
              customBodyRenderLite: (dataIndex) => {
                let body = history[dataIndex][8];
                return (
                  body && body != "" ?
                  <button className="btn btn-primary" onClick={() => {
                    navigator.clipboard.writeText(body)
                    ToastInfo("El cuerpo se copio correctamente a tu portapapeles")}}><i className="bi bi-clipboard"></i></button>
                  :
                  ""
                )
              },
            },
        }
    ]

    return (
        <div>
            <MUIDataTable
                data={history}
                columns={columns}
                options={{...BASIC_OPTIONS_MUI, filter: false, responsive: "standard"}}
            />
        </div>
    )
}

export default EmbarqueHistory