import { URL_DESGLOSE_EMBARQUE } from "../../../../utils/constantes";
import { apiRequest } from "../../../handlerApiRequest";
import { apiRequestV2 } from "../../../handlerApiRequestV2";

export const eliminarDesgloseEmbarquePorId = async (idDesgloseEmbarque) => {
    return await apiRequestV2({
        endpoint: `${URL_DESGLOSE_EMBARQUE}/${idDesgloseEmbarque}`,
        method : "DELETE",
        showSuccessMessage: true
    })
}

export const relacionarDesgloseEmbarqueConPod = async (idDesgloseEmbarque, archivoS3) => {
    return await apiRequest(URL_DESGLOSE_EMBARQUE+"/"+idDesgloseEmbarque+"/relacionar-pod", "PUT", archivoS3);
}

export const eliminarRelacionDesgloseEmbarqueConPod = async (idDesgloseEmbarque) => {
    return await apiRequest(URL_DESGLOSE_EMBARQUE+"/"+idDesgloseEmbarque+"/eliminar-relacion-pod", "PUT");
}