import React, { useState } from 'react'
import { ID_TRAFICO_TERRESTRE } from '../../../utils/constantes';
import { ToastError } from '../../../utils/toastMessage';
import Modal from '@mui/material/Modal';
import { buscarEmbarqueReqDTOPorId } from '../../../controllers/admin/embarques/embarquesController';
import { useDispatch, useSelector } from 'react-redux';
import { resetEmbarque, setEmbarque, setIdEmbarque } from '../../../redux/features/admin/embarqueSlice';
import DesglosesEmbarque from './desgloses/DesglosesEmbarque';
import { resetControlCostoData } from '../../../redux/features/admin/controlCostosSlice';
import { setLoading } from "../../../redux/features/loaderSlice"
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import PODS from './PODS';
import { listarCatalogoTiposUnidades } from '../../../controllers/admin/embarques/catalogos/catalogoTipoUnidadDesgloseController';
import { findAllCatalogoContenedorMaritimo } from '../../../controllers/admin/embarques/catalogos/catalogoContenedorMaritimoController';
import LineaTiempoEstatus from './estatus/LineaTiempoEstatus';
import LineaTiempoConActividadesEstatus from './estatus/LineaTiempoConActividadesEstatus';
import { fetchEstatusEmbarque } from '../../../redux/features/admin/estatusEmbarqueSlice';
import { buscarEmbarquePorId } from '../../../controllers/admin/embarques/embarquesController';
import DocumentosEmbarque from './DocumentosEmbarque';
import FormEmbarque from './FormEmbarque';
import ContainerFixed from '../../ContainerFixed';
import EmbarqueHistory from './historial/EmbarqueHistory';
import CollapseLandingPage from './CollapseLandingPage';
import { formatNameUser } from '../../../utils/userFormat';
import ControlCostos from '../facturas/control-costos/ControlCostos';
import PrincipalTimeLine from './estatus/PrincipalTimeLine';

function ModalEmbarques({
    idEmbarque,
    titulo,
    children,
    setEmbarques,
    indexEmbarque }) {
    const embarqueData = useSelector((state) => state.embarque.embarqueData);
    const [tiposUnidadDesglose, setTiposUnidadDesglose] = useState([]);
    const [contenedoresMaritimos, setContenedoresMaritimos] = useState([]);
    const [showTimeline, setShowTimeline] = useState(true);

    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);

    const handleOpen = async () => {

        if (idEmbarque) {
            dispatch(setLoading({ isLoading: true }))
            await fetchbuscarEmbarqueReqDTOPorId(idEmbarque);
            dispatch(setLoading({ isLoading: false }))
            //Establecer estatus desde la tabla
            dispatch(fetchEstatusEmbarque(idEmbarque))
        }

        setOpen(true)
        localStorage.setItem("idEmbarque", idEmbarque ? idEmbarque : null);
        //Mandar a llamar los catalogos
        fetchDataTiposUnidades()
        fetchCatalogoContenedoresMaritimos()
    };

    const handleClose = async () => {
        if (embarqueData.id) {
            const data = await buscarEmbarquePorId(embarqueData.id);

            if (indexEmbarque === undefined || indexEmbarque === null) {
                setEmbarques((prevState) => {
                    const newEmbarques = { ...prevState };
                    newEmbarques.content.unshift(data)
                    return newEmbarques;
                });
            } else { //Si actualizacion lo busca y sustituye
                setEmbarques((prevState) => {
                    const newEmbarques = { ...prevState }; // Crear una copia del array original
                    newEmbarques.content.splice(indexEmbarque, 1, data); // Reemplazar el elemento en el índice dado
                    return newEmbarques; // Actualizar el estado con el nuevo array modificado
                });
            }
        }

        dispatch(setIdEmbarque(null))
        dispatch(resetEmbarque())
        dispatch(resetControlCostoData())
        localStorage.removeItem("idEmbarque");
        setOpen(false);
    }

    /**
     * Funcion que busca el embarque por el id
     * y guardar en el redux 
     */
    const fetchbuscarEmbarqueReqDTOPorId = async (idEmbarque) => {
        const data = await buscarEmbarqueReqDTOPorId(idEmbarque);
        dispatch(setEmbarque(data));
        return data;
    }

    const fetchDataTiposUnidades = async () => {
        const data = await listarCatalogoTiposUnidades();
        if (data) {
            setTiposUnidadDesglose(data.map(tipoUnidad => ({
                id: tipoUnidad.id,
                value: tipoUnidad,
                label: tipoUnidad.nombre
            })))
        }
    }

    const fetchCatalogoContenedoresMaritimos = async () => {
        try {
            const res = await findAllCatalogoContenedorMaritimo();
            if (res.error) return ToastError(res.message)
            setContenedoresMaritimos(res.data.map(contenedor => ({
                id: contenedor.id,
                value: contenedor,
                label: contenedor.nombre
            })))
        } catch (error) {
            ToastError(error)
        }
    }

    return (
        <>
            <button className='btn btn-primary m-1' onClick={handleOpen}>
                {children}{titulo}
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='card style-modal-xl'>
                    <div className='card-header'>
                        <div className='d-flex justify-content-between'>
                            {embarqueData.id ?
                                <CollapseLandingPage idEmbarque={embarqueData.id}></CollapseLandingPage>
                                : <></>
                            }

                            <span style={{ fontSize: "15px", justifyContent: "center" }}>
                                <b>{embarqueData.referenciaKJ ? embarqueData.referenciaKJ : ""}</b> |
                                Cliente: <b>{embarqueData.cliente ? ` ${formatNameUser(embarqueData.cliente)} | ` : ""}</b>
                                Origen: <b>{embarqueData.origen?.nombre ? ` ${embarqueData.origen?.nombre} (${embarqueData.origen?.portCode}) | ` : ""}</b>
                                Destino: <b>{embarqueData.destino?.nombre ? ` ${embarqueData.destino?.nombre} (${embarqueData.destino?.portCode}) ` : ""}</b>
                            </span>
                            <div>
                                {embarqueData.id ?
                                    <button className="btn btn-primary btn-sm me-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTimeLine" aria-expanded="true" aria-controls="collapseTimeLine"
                                        onClick={() => setShowTimeline(!showTimeline)}>
                                        {showTimeline ? <i className="bi bi-eye-fill"></i> : <i className="bi bi-eye-slash"></i>}
                                    </button>

                                    :
                                    <></>
                                }
                                <button className='btn btn-dark btn-sm'
                                    onClick={handleClose}>
                                    <i className="bi bi-x-lg"></i>
                                </button>

                            </div>

                        </div>
                    </div>

                    <div className='card-body body-modal-scroll'>
                        <ContainerFixed>
                            <PrincipalTimeLine />
                        </ContainerFixed>
                        <Tab.Container defaultActiveKey="embarque">
                            <ContainerFixed>
                                <Nav variant="pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="embarque">EMBARQUE</Nav.Link>
                                    </Nav.Item>
                                    {embarqueData.desglosesEmbarques.length >= 1 && embarqueData.id ?
                                        <Nav.Item>
                                            <Nav.Link eventKey="pods">PODS</Nav.Link>
                                        </Nav.Item>
                                        :
                                        ""}
                                    {embarqueData.id ?
                                        <Nav.Item>
                                            <Nav.Link eventKey="documentos">DOCUMENTOS</Nav.Link>
                                        </Nav.Item>
                                        : ""}
                                    {embarqueData.id
                                        && embarqueData.tipoTrafico?.id != ID_TRAFICO_TERRESTRE ?
                                        <Nav.Item>
                                            <Nav.Link eventKey="actividades">ACTIVIDADES</Nav.Link>
                                        </Nav.Item>
                                        : ""}
                                    {embarqueData.id ?
                                        <Nav.Item>
                                            <Nav.Link eventKey="controlCostos">CONTROL COSTOS</Nav.Link>
                                        </Nav.Item>
                                        : ""}
                                    {embarqueData.id ?
                                        <Nav.Item>
                                            <Nav.Link eventKey="embarqueHistory">HISTORIAL</Nav.Link>
                                        </Nav.Item>
                                        : ""}
                                </Nav>
                            </ContainerFixed>

                            <Tab.Content>
                                <Tab.Pane eventKey="embarque">
                                    <FormEmbarque
                                        setEmbarques={setEmbarques}
                                        setOpen={setOpen}
                                        indexEmbarque={indexEmbarque}
                                        fetchbuscarEmbarqueReqDTOPorId={fetchbuscarEmbarqueReqDTOPorId}>
                                    </FormEmbarque>
                                    {embarqueData.id ?
                                        <DesglosesEmbarque
                                            fetchbuscarEmbarqueReqDTOPorId={fetchbuscarEmbarqueReqDTOPorId}
                                            tiposUnidadDesglose={tiposUnidadDesglose}
                                            contenedoresMaritimos={contenedoresMaritimos}></DesglosesEmbarque>
                                        :
                                        ""
                                    }
                                </Tab.Pane>
                                {embarqueData.desglosesEmbarques.length >= 1 && embarqueData.id ?
                                    <Tab.Pane eventKey="pods">
                                        <PODS
                                            idEmbarque={embarqueData.id}></PODS>

                                    </Tab.Pane>
                                    : ""}
                                {embarqueData.id ?
                                    <Tab.Pane eventKey="documentos">
                                        <DocumentosEmbarque
                                        ></DocumentosEmbarque>
                                    </Tab.Pane> : ""}
                                {embarqueData.id && embarqueData.tipoTrafico?.id != ID_TRAFICO_TERRESTRE ?
                                    <Tab.Pane eventKey="actividades">
                                        <LineaTiempoConActividadesEstatus
                                            idEmbarque={embarqueData.id}></LineaTiempoConActividadesEstatus>
                                    </Tab.Pane> : ""}
                                {embarqueData.id ?
                                    <Tab.Pane eventKey="controlCostos">
                                        <ControlCostos
                                            idEmbarque={embarqueData.id}></ControlCostos>
                                    </Tab.Pane> : ""}
                                {embarqueData.id ?
                                    <Tab.Pane eventKey="embarqueHistory">
                                        <EmbarqueHistory idEmbarque={embarqueData.id}></EmbarqueHistory>
                                    </Tab.Pane> : ""}
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </Modal >
        </>
    )
}

export default ModalEmbarques
