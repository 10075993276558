import { URL_PODS } from "../../../utils/constantes";
import { apiRequest } from "../../handlerApiRequest";

export const eliminadoLogicoPOD = async (idEmbarqueArchivo, razonEliminacion) =>{
    return await apiRequest(URL_PODS + `/${idEmbarqueArchivo}`, "DELETE", null, "?razonEliminacion=" + razonEliminacion)
}

export const subirPod = async (idEmbarque, archivo, idProveedor) => {

    const formData = new FormData();
    if (archivo != null) formData.append("archivo", archivo, archivo.name);

    let paramIdProveedor = "";
    if(idProveedor) paramIdProveedor = "?idProveedor="+idProveedor

    return await apiRequest(URL_PODS + "/" + idEmbarque + "/subir-archivo", "POST", formData, paramIdProveedor, true);
}

export const actualizarPod = async (archivoS3, archivo, idProveedor) => {

    const formData = new FormData();
    if (archivo != null) formData.append("archivo", archivo, archivo.name);
    formData.append("archivoS3", new Blob([JSON.stringify(archivoS3)], {
        type: "application/json"
    }));
    return await apiRequest(URL_PODS + "/actualizar-archivo", "PUT", formData, "", true);
}

export const buscarPodsConFormatoPorIdEmbarque = async (idEmbarque) => {
    return await apiRequest(URL_PODS+`/embarque/${idEmbarque}`)
}
