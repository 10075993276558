import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { OPTIONS_MUI_ONLY_TABLE, OPTIONS_CUSTOM_RETURN_COLUMN } from "../../utils/mui/constantes";
import { ToastError } from "../../utils/toastMessage";
import { getAuthRoleUser, getAuthUser } from "../../utils/authUtils";
import { useDispatch } from "react-redux";
import { setRole } from "../../redux/features/userAuthSlice";
import ModalPodsClientes from "../clientes/ModalPodsClientes";
import ModalFacturaClientes from "./ModalFactuaClientes";
import { validatePrintIdsCarga } from "./validations/validations";

function TableEmbarquesClientes({ fetchListarEmbarques }) {
  const [dataMuiEmbarques, setDataMuiEmbarques] = useState({
    data: [],
    count: 10,
    page: 0,
    rowsPerPage: 10,
    searchText: ""
  });
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  const [cliente, setCliente] = useState("");

  useEffect(() => {
    setRol();
    getUserLogin();
  }, [])

  useEffect(() => {
    fetchListarEmbarquesDeProveedor();
  }, [dataMuiEmbarques.page, dataMuiEmbarques.rowsPerPage, dataMuiEmbarques.searchText]);

  const fetchListarEmbarquesDeProveedor = async () => {
    const handlerSearchText = dataMuiEmbarques.searchText ? `&searchText=${dataMuiEmbarques.searchText}` : "";
    const res = await fetchListarEmbarques(
      `?page=${dataMuiEmbarques.page}&size=${dataMuiEmbarques.rowsPerPage}${handlerSearchText}`
    );
    if (res.error) return ToastError(res.message);
    setDataMuiEmbarques({
      data: res.data.content,
      count: res.data.totalElements,
      page: res.data.pageable?.pageNumber,
      rowsPerPage: res.data.pageable?.pageSize,
      searchText: dataMuiEmbarques.searchText
    });
  };

  const setRol = () => {
    const rol = getAuthRoleUser();
    dispatch(setRole(rol));
  };

  const handleChangeRowsPerPage = async (pageSize) => {
    setDataMuiEmbarques({
      ...dataMuiEmbarques,
      rowsPerPage: pageSize,
      page: 0
    });
  };

  const handleOnChangePage = async (newPage) => {
    setDataMuiEmbarques({
      ...dataMuiEmbarques,
      page: newPage,
    });
  };

  const handleSearchChange = (event) => {
    const textSearch = event.target.value;
    setSearch(textSearch);
  };

  const submitSearch = () => {
    setDataMuiEmbarques({
      ...dataMuiEmbarques,
      page: 0,
      searchText: search
    });
  }

  const handleSearchReset = () => {
    setDataMuiEmbarques({
      ...dataMuiEmbarques,
      page: 0,
      searchText: ""
    });
    setSearch("");
  };

  const getUserLogin = () => {
    const user = getAuthUser();
    setCliente(user);
  };

  const validation = validatePrintIdsCarga(cliente);

  const columns = [
    {
      name: "REFERENCIA",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return dataMuiEmbarques.data[dataIndex].referenciaKJ;
        },
      },
    },
    {
      name: "ORIGEN",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            dataMuiEmbarques.data[dataIndex]?.origen?.nombre +
            ` (${dataMuiEmbarques.data[dataIndex]?.origen?.portCode})`
          );
        },
      },
    },
    {
      name: "DESTINO",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            dataMuiEmbarques.data[dataIndex]?.destino?.nombre +
            ` (${dataMuiEmbarques.data[dataIndex]?.destino?.portCode})`
          );
        },
      },
    },
    {
      name: "SERIE FOLIO",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              {dataMuiEmbarques.data[dataIndex]?.facturasCliente
                ? dataMuiEmbarques.data[dataIndex]?.facturasCliente.map(
                  (factura, index) => (
                    <React.Fragment key={index}>
                      {factura.serieFolio}
                      <br />
                    </React.Fragment>
                  )
                )
                : ""}
            </>
          );
        },
        ...OPTIONS_CUSTOM_RETURN_COLUMN
      },
    },
    {
      name: "ESTATUS PAGO FACTURA",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              {dataMuiEmbarques.data[dataIndex]?.facturasCliente
                ? dataMuiEmbarques.data[dataIndex]?.facturasCliente.map(
                  (factura, index) => (

                    <React.Fragment key={index}>
                      {factura?.archivoFactura == null ?
                        <span className="badge bg-warning">FACTURA PENDIENTE</span> :
                        factura?.archivoComprobantePagoFactura == null ?
                          <span className="badge bg-warning">PAGO PENDIENTE</span> :
                          <span className="badge bg-success">FACTURA PAGADA</span>}
                    </React.Fragment>
                  )
                )
                : ""}
            </>
          );
        },
        ...OPTIONS_CUSTOM_RETURN_COLUMN
      },
    },
    {
      name: "SERVICIO",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return dataMuiEmbarques.data[dataIndex]?.tipoServicio?.nombre;
        },
        ...OPTIONS_CUSTOM_RETURN_COLUMN
      },
    },
    {
      name: "TRAFICO",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return dataMuiEmbarques.data[dataIndex]?.tipoTrafico?.nombre;
        },
      },
    },

    {
      name: "PO/CLIENTE",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return dataMuiEmbarques.data[dataIndex]?.poCliente;
        },
      },
    },
    {
      name: validation ? "IDs Carga" : "",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              {validation &&
                (dataMuiEmbarques.data[dataIndex]?.desglosesEmbarque
                  ? dataMuiEmbarques.data[dataIndex]?.desglosesEmbarque.map(
                    (desglose, index) => (
                      <React.Fragment key={index}>
                        {desglose.idsCarga}
                        <br />
                      </React.Fragment>
                    )
                  )
                  : "")}
            </>
          );
        },
        ...OPTIONS_CUSTOM_RETURN_COLUMN
      },
    },
    {
      name: "PODS",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <ModalPodsClientes
              desglosesEmbarque={
                dataMuiEmbarques.data[dataIndex]?.desglosesEmbarque
              }
            />
          );
        },
        ...OPTIONS_CUSTOM_RETURN_COLUMN
      },
    },
    {
      name: "FACTURAS",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <ModalFacturaClientes
              archivos={dataMuiEmbarques.data[dataIndex]?.facturasCliente}
            />
          );
        },
        ...OPTIONS_CUSTOM_RETURN_COLUMN
      },
    },
  ];

  return (
    <>
      <div className="col-12" align="right">
        <div className="col-12 col-sm-6 m-4">
          <div className="input-group mb-1 mt-1">
            <input
              type="text"
              className="form-control"
              value={search}
              onChange={(e) => { handleSearchChange(e) }}
              placeholder="Buscar... (Por referencia, serie folio, po/cliente o Ids de Carga)."
            />
            <button className='btn btn-primary btn-sm'
              onClick={() => submitSearch()}><i className="bi bi-search"></i></button>
            <button onClick={() => { handleSearchReset() }} className="btn btn-dark btn-sm">
              <i className="bi bi-x"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="col-12">
        <MUIDataTable
          title={"Embarques"}
          data={dataMuiEmbarques.data}
          columns={columns}
          options={{
            ...OPTIONS_MUI_ONLY_TABLE,
            search: false,
            onChangePage: handleOnChangePage,
            onChangeRowsPerPage: handleChangeRowsPerPage,
            rowsPerPageOptions: [5, 10, 50, 100, 1000],
            count: dataMuiEmbarques.count,
            page: dataMuiEmbarques.page,
            rowsPerPage: dataMuiEmbarques?.rowsPerPage,
            serverSide: true,
            responsive: "standard",
          }}
        />
      </div>
    </>
  );
}

export default TableEmbarquesClientes;
