import React from 'react'
import Header from '../../components/proveedor/Header'
import TableEmbarques from '../../components/proveedor/TableEmbarques'
import { listarEmbarquesDeProveedor } from '../../controllers/proveedor/proveedorEmbarqueController'

function InicioProveedor() {

  return (
    <div className='row m-1'>
      <Header></Header>
      <TableEmbarques
      fetchListarEmbarques={listarEmbarquesDeProveedor}></TableEmbarques>
    </div>
  )
}

export default InicioProveedor