import { Field, FieldArray, getIn, useFormikContext } from 'formik'
import { formikCalculateVolume } from '../../../../controllers/admin/embarques/desgloses/dimensionCargaControlller';
import { useEffect, useState } from 'react';
import { CATALOGO_UNIDAD_MEDIDA_BULTOS, CATALOGO_UNIDAD_MEDIDA_DIMENSION_C } from '../../../../utils/catalogosEstaticos';
import FormikSelectSearch from '../../../formik/FormikSelectSearch'

function FieldArrayDimensionCarga({ values, attributeName }) {
    const [dimensionesCarga, setDimensionesCarga] = useState([]);
    const { setFieldValue } = useFormikContext();

    const calculateTotalVolume = (index, volumen, cantidadBultos) => {
        let totalVolumen = (cantidadBultos * volumen)?.toFixed(2);
        setFieldValue(`${attributeName}dimensionesCarga[${index}].totalVolumen`, totalVolumen);
        return totalVolumen;
    };

    useEffect(() => {
        const dimensionesCargaArray = getIn(values, attributeName)?.dimensionesCarga;
        if (Array.isArray(dimensionesCargaArray)) {
            setDimensionesCarga(dimensionesCargaArray);
        } else {
            if (dimensionesCargaArray) {
                setDimensionesCarga(JSON.parse(dimensionesCargaArray))
                console.log("[FieldArrayDimensionCarga] LA DIMENSION DE CARGA NO ES UN ARRAY. REALIZANDO CONVERCION.")
            }
        }
    }, [getIn(values, attributeName)?.dimensionesCarga])

    useEffect(() => {
        const dimensionesCargaArray = getIn(values, attributeName)?.dimensionesCarga;
        if (Array.isArray(dimensionesCargaArray)) {
            let totalVolumenes = 0;
            dimensionesCargaArray?.map((cc, index) => {
                const totalVolumen = calculateTotalVolume(index, cc.dimensionVolumen, cc.cantidadBultos);
                totalVolumenes += parseFloat(totalVolumen) || 0
            })
            setFieldValue(`${attributeName}totalVolumenes`, totalVolumenes?.toFixed(2));
        }
    }, [getIn(values, attributeName)?.dimensionesCarga])

    return (
        <FieldArray
            name={`${attributeName}.dimensionesCarga`}
            render={arrayHelpers => (
                <>
                    <div className="row">
                        <div className="col-sm-2">
                            <label htmlFor="totalVolumen" className="form-label">Total volumenes</label>
                            <Field id="totalVolumen" name={`${attributeName}totalVolumenes`} className="form-control" type={"number"} readOnly disabled />
                        </div>
                    </div>
                    <div className="col-sm-12 mb-3" align="right">
                        <hr />
                        <h6>DIMENSIONES DE CARGA </h6>
                        <div className="col-sm-2">
                            <button type="button" className='btn btn-primary' onClick={() => arrayHelpers.push({})}>
                                <i className="bi bi-plus" /> DIMENSION
                            </button>
                        </div>
                    </div>
                    {dimensionesCarga?.map((dimensionCarga, index) => {
                        return (
                            <div key={index} className='row' style={{ fontSize: "13px" }}>
                                <hr />
                                <div className="col-sm-12 mb-3" align="right">
                                    <button className='btn btn-danger btn-sm' type='button' onClick={() => arrayHelpers.remove(index)}><i className="bi bi-trash-fill"></i></button>
                                </div>

                                <div className="col-sm-1 mb-3">
                                    <label htmlFor="cantidadBultosDC" className="form-label">Bultos</label>
                                    <Field attribute="cantidadBultosDC" name={`${attributeName}dimensionesCarga[${index}].cantidadBultos`} className="form-control" type="number" />
                                </div>

                                <div className="col-sm-1 mb-3">
                                    <label htmlFor="pesoPorBultoDC" className="form-label">Peso por Bulto</label>
                                    <Field id="pesoPorBultoDC" name={`${attributeName}dimensionesCarga[${index}].pesoPorBulto`} className="form-control" type="number" step="0.01" />
                                </div>

                                <div className="col-sm-2 mb-3">
                                    <Field
                                        name={`${attributeName}dimensionesCarga[${index}].idUnidadMedidaBultos`}
                                        id="idUnidadMedidaBultos"
                                        component={FormikSelectSearch}
                                        data={CATALOGO_UNIDAD_MEDIDA_BULTOS}
                                        titulo={"Unidad de Medida"}
                                        isDisabled={false} />
                                </div>

                                <div className="col-sm-2 mb-3">
                                    <label htmlFor="embalajeDC" className="form-label">Embalaje</label>
                                    <Field id="embalajeDC" name={`${attributeName}dimensionesCarga[${index}].embalaje`} className="form-control" component="textarea" />
                                </div>

                                <div className='col-sm-1 mb-3'>
                                    <Field
                                        name={`${attributeName}dimensionesCarga[${index}].idUnidadMedidaDimension`}
                                        id="idUnidadMedidaDimension"
                                        component={FormikSelectSearch}
                                        data={CATALOGO_UNIDAD_MEDIDA_DIMENSION_C}
                                        titulo={"Unidad de Medida"}
                                        isDisabled={false} />
                                </div>

                                <div className="col-sm-1 mb-3">
                                    <label htmlFor="dimensionLargoDC" className="form-label">Largo</label>

                                    <Field name={`${attributeName}dimensionesCarga[${index}].dimensionLargo`}>
                                        {({ field, form, meta }) => (
                                            <input type="number" {...field} className='form-control' id={`${attributeName}largo-${index}`}
                                                onChange={(e) => {
                                                    formikCalculateVolume(e, index, form, attributeName)
                                                }} step="0.01" />
                                        )}
                                    </Field>
                                </div>

                                <div className="col-sm-1 mb-3">
                                    <label htmlFor="dimensionAltoDC" className="form-label">Alto</label>

                                    <Field name={`${attributeName}dimensionesCarga[${index}].dimensionAlto`}>
                                        {({ field, form, meta }) => (
                                            <input type="number" {...field} className='form-control' id={`${attributeName}alto-${index}`}
                                                onChange={(e) => {
                                                    formikCalculateVolume(e, index, form, attributeName)
                                                }} step="0.01" />
                                        )}
                                    </Field>
                                </div>

                                <div className="col-sm-1 mb-3">
                                    <label htmlFor="dimensionAnchoDC" className="form-label">Ancho</label>

                                    <Field name={`${attributeName}dimensionesCarga[${index}].dimensionAncho`}>
                                        {({ field, form, meta }) => (
                                            <input type="number" {...field} className='form-control' id={`${attributeName}ancho-${index}`}
                                                onChange={(e) => {
                                                    formikCalculateVolume(e, index, form, attributeName)
                                                }} step="0.01" />
                                        )}
                                    </Field>
                                </div>

                                <div className="col-sm-1 mb-3">
                                    <label htmlFor="dimensionVolumenDC" className="form-label">Volumen</label>
                                    <Field id="dimensionVolumenDC" name={`${attributeName}dimensionesCarga[${index}].dimensionVolumen`} className="form-control" type="number" disabled />
                                </div>

                                <div className="col-sm-1 mb-3">
                                    <label htmlFor="totalVolumen" className="form-label">Total volumen</label>
                                    <Field id="totalVolumen" name={`${attributeName}dimensionesCarga[${index}].totalVolumen`} className="form-control" type={"number"} readOnly disabled />
                                </div>
                            </div>
                        )
                    })}
                </>)} />
    )
}

export default FieldArrayDimensionCarga