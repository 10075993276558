import React, { useEffect } from "react";
import { useState } from "react";
import {
  findNotas,
  saveNota,
} from "../../../../controllers/admin/embarques/NotasController";
import moment from 'moment-timezone';
import "quill/dist/quill.snow.css";
import Modal from '@mui/material/Modal';
import { right } from "@popperjs/core";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ToastError } from "../../../../utils/toastMessage";
import { formatNameUser } from "../../../../utils/userFormat";

function Notas({ idEmbarque }) {
  const [notas, setNotas] = useState([]);
  const [notaText, setNotaText] = useState('');
  const [showModal, setShowModal] = useState(false);


  useEffect(() => {
    fetchFindNotasByIdEmbarque();
  }, []);

  const fetchFindNotasByIdEmbarque = async () => {
    try {
      const res = await findNotas(idEmbarque);
      setNotas(res.content);
    } catch (error) {
      console.error("Error al obtener las notas:", error);
    }
  };

  const handleSendNota = () => {
    try {
      if (!notaText) {
        return ToastError("El contenido de la nota está vacío. Por favor, escribe algo antes de enviar.");
      }
      saveNota(notaText, idEmbarque).then((data) => {
        if (data) {
          fetchFindNotasByIdEmbarque();
          handleCloseModal();
        }
      });
    } catch (err) {
      return ToastError(err.message);
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setNotaText('');
  };

  const cardsArray = notas.map((nota) => {
    if (nota.creadoEn != null) {
      const fecha = nota.creadoEn.split("T")[0];
    }
    return (
      <div className="col-12">
        <div className="card mt-2" style={{ border: "solid 1px #278196" }}>
          <div className="card-header" style={{ fontSize: "14px" }}>
            {formatNameUser(nota.user)}
          </div>
          <div className="card-body">
            <blockquote className="blockquote mb-0">
              <div dangerouslySetInnerHTML={{ __html: nota.nota }} style={{ fontSize: "15px" }} />
            </blockquote>
          </div>
          <div className="card-footer text-body-secondary" style={{ fontSize: "12px" }}>
            {moment(nota.creadoEn).local().format("DD/MM/YYYY, h:mm:ss a")}
          </div>
        </div>
        <hr />
      </div>
    );
  });

  return (
    <>
      <div className="container">
        <div style={{ textAlign: right }} >
          <button type='button' className='btn btn-primary btn-sm me-1'
            onClick={handleOpenModal}>
            <i className="bi bi-plus"></i> NOTA
          </button>
          <button className="btn btn-dark btn-sm" type="button" data-bs-toggle="collapse" data-bs-target="#collapseWidthExample" aria-expanded="false" aria-controls="collapseWidthExample">
            <i className="bi bi-x-lg"></i>
          </button>
        </div>
        <div className="row">
          {cardsArray}
        </div>
      </div>

      <Modal
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="card style-modal-sm">
          <div className="card-header d-flex justify-content-between">
            NUEVA NOTA
            <button className="btn btn-dark btn-sm" onClick={handleCloseModal}>
              <i className="bi bi-x-lg"></i>
            </button>
          </div>
          <div className="card-body">
            <div style={{ backgroundColor: "white" }}>
              <ReactQuill theme="snow" value={notaText} onChange={setNotaText} />
            </div>
          </div>
          <div className="card-footer" align="right">
            <button type='button' className='btn btn-primary mt-2 mb-2 me-2'
              onClick={() => handleSendNota()}>
              <i className="bi bi-floppy"></i> Guardar
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Notas;
