export function formatMoney(num) {
  if (typeof num === 'number' && !isNaN(num)) {
    let isNegative = false;
    if (num < 0) {
      isNegative = true;
      num = Math.abs(num);
    }

    let p = num.toFixed(2).split(".");
    let formattedNum = "$" + p[0].split("").reverse().reduce(function (acc, num, i, orig) {
      return num + (num !== "-" && i && !(i % 3) ? "," : "") + acc;
    }, "") + "." + p[1];

    return isNegative ? "-" + formattedNum : formattedNum;
  }

  return num;
}
